<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n"
const { t } = useI18n()
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(['handleBill', 'handleBillAdd']);
const props = defineProps({
    showBill: Boolean,
});
const show = ref(props.showBill);
watch(
    () => props.showBill,
    (val) => {
        show.value = val;
    }
)
onMounted(() => {
    getInfo()
})
const form = ref({})
// 查看资源账单配置
const getInfo = () => {
    api.manage
        .getBaseBillApi({
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                form.value = res.data
            }
        })
}
// 关闭
const handleCancel = () => {
    show.value = !show.value
    getInfo()
    emit('handleBill')
}
//同意
const handleUpload = () => {
    form.value.userId = userId.value
    show.value = !show.value
    emit('handleBillAdd',form.value)
};
</script>

<template>
    <div>
        <!-- Azure账单详情 -->
        <el-dialog :z-index="100" v-model="show" destroy-on-close align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div style="display: flex;">
                        <div class="tit_img">
                            <img src="@/assets/manage/bill.svg" alt="">
                        </div>
                        Azure{{ $t('manage.baseForm.base49') }}
                    </div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-form :model="form" label-position="top">
                    <el-form-item prop="tenantId" label="Tenant ID ：">
                        <el-input v-model="form.tenantId" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="clientId" label="Client ID ：">
                        <el-input v-model="form.clientId" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="clientSecret" label="Client Secret ：">
                        <el-input v-model="form.clientSecret" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="scope" label="Scope ：">
                        <el-input v-model="form.scope" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="grantType" label="Grant Type ：">
                        <el-input v-model="form.grantType" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="subscriptionId" label="Subscription ID ：">
                        <el-input v-model="form.subscriptionId" class="comtent"></el-input>
                    </el-form-item>
                    <el-form-item prop="bill_start_date" :label="$t('manage.baseForm.base50')">
                        <el-date-picker v-model="form.bill_start_date" type="date" placeholder="Pick a day" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">{{ $t('manage.baseForm.base45') }}</div>
                    <div @click="handleUpload" class="confirm" v-if="$hasPermission('10059')==true">{{ $t('manage.baseForm.base46') }}</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 520px;
    border-radius: 30px;
    height: 700px;
    padding: 30px;
    background-color: #F7F8FF;
}

:deep(.el-select__wrapper) {
    width: 450px !important;
    border-radius: 12px !important;
}

:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
    width: 100%;
}

:deep(.el-select--large) {
    width: 450px !important;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 428px !important;
    border-radius: 12px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";

    .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-right: 12px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }

}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 18px;
    color: #8A8A8A;
    height: 550px;
    overflow-x: hidden;
    overflow: auto;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 400;
    }
}


.form-name {
    font-size: 17px;
    color: #3D3D3D;

    .tit {
        margin-bottom: 8px;
        display: flex;

        div {
            margin-left: 5px;
            margin-top: 4px;
        }
    }

    .comtent {
        margin-bottom: 8px;
        display: flex;

        .log_img {
            width: 25px;
            height: 25px;
            border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }

        .log_name {
            margin-left: 8px;
            color: #8A8A8A;
            height: 25px;
            line-height: 25px;
        }

        .log_item {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #fff;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .log_itemActive {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #DBDFFF;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}
.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">
        {{ props.addType == "add" ? t("base.base19") : t("base.base20") }}
      </div>
      <img :src="addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <div class="addBody">
      <!-- 选择模式 -->
      <div class="flexBetWeen marginBtomm20px">
        <!--           :class="form.type == index ? 'itemDivNavActive' : 'itemDivNav'"
 -->
        <el-button
          v-for="(item, index) in navList"
          :key="item"
          :disabled="props.addType != 'add'"
          class="itemDivNavActive"
          @click="changeNav(index)"
        >
          <img :src="item.url" alt="" class="navImg" />
          <div :class="form.type == index ? 'itemTextActive' : 'itemText'">
            {{ t(item.name) }}
          </div>
        </el-button>
      </div>
      <!-- 表单 -->
      <div>
        <span class="addLabel nameLabel">{{ t("base.base21") }}</span>
      </div>
      <el-input
        class="itemInput marginBtomm20px"
        maxlength="20"
        show-word-limit
        :placeholder="t('base.base22')"
        v-model="form.name"
      ></el-input>
      <div class="addLabel">{{ t("base.base23") }}</div>
      <el-input
        v-model="form.desc"
        class="textInput marginBtomm20px"
        maxlength="100"
        show-word-limit
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 5 }"
        :placeholder="t('base.base24')"
      ></el-input>
      <div class="addLabel marginBtomm20px">{{ t("base.base25") }}</div>
      <div class="flexBetWeen marginBtomm20px">
        <div
          v-for="(item, index) in upTypeList"
          :key="index"
          @click="changeFile(index)"
          :class="{
            bottomFileActive: fileActive == index,
            bottomFile: fileActive != index,
            moseNo: index == 1,
          }"
        >
          <div class="flexCenter">
            <img
              class="fileImg"
              :src="fileActive == index ? item.acIcon : item.icon"
              alt=""
            />
            <div :class="fileActive == index ? 'itemTextActive' : 'itemText'">
              {{ t(item.name) }}
            </div>
          </div>
          <el-tooltip
            effect="dark"
            :content="t(item.tip)"
            placement="top-start"
          >
            <div :class="fileActive == index ? 'fileTipActive' : 'fileTip'">
              {{ t(item.tip) }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <span class="addLabel nameLabel">{{ t("base.base26") }}</span>
        <el-tooltip
          effect="dark"
          :content="t('base.base27')"
          placement="top-start"
        >
          <span class="ptTip">{{ t("base.base27") }}</span>
        </el-tooltip>
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <el-button
          v-for="item in ptList"
          :key="item.id"
          :class="getPt(item.id) ? 'ptDivItemActive' : 'ptDivItem'"
          :disabled="getPtType(item.id)"
          @click="clickPt(item.id)"
        >
          <img :src="item.icon" alt="" class="ptImg" />
        </el-button>
      </div>
      <div class="marginBtomm20px addLabel">{{ t("base.base28") }}</div>
      <div class="flexCenter marginBtomm20px">
        <el-tooltip popper-class="toolTip" :content="t('base.base302')">
          <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
            <div :class="form.image_path ? 'txCenter' : 'step2Img'">
              <img
                v-if="form.imageName == null || form.imageName == ''"
                :src="bImg"
                alt=""
                class="stepImgIcon"
              />
              <img
                v-else
                :src="'/openai/chat/v4/image/get/' + form.imageName"
                alt=""
                class="stepImgIcons"
              />
            </div>
          </uploadIndex>
        </el-tooltip>
        <div class="step2RightImg">
          <div class="pa">
            <img
              :src="leftReson"
              v-if="imgList.length > 2"
              alt=""
              class="leftResonIcon"
              @click="changeLeftReson(resonOne)"
            />
            <div class="imgFlex" ref="resonOne">
              <div class="getImgLoading" v-if="getImgLoading">
                <span class="loader"></span>
              </div>
              <div
                v-for="(item, index) in imgList"
                :key="item"
                class="itemImgDiv"
              >
                <img
                  @click="changeImg(item, index)"
                  class="itemImg"
                  :src="item.url"
                  alt=""
                />
                <img
                  :src="imgYes"
                  alt=""
                  v-if="imgActive == index"
                  class="imgYes"
                />
              </div>
            </div>
            <img
              :src="rightReson"
              v-if="imgList.length > 2"
              alt=""
              class="rightResonIcon"
              @click="changeRightReson(resonOne)"
            />
          </div>
          <el-button class="stepImgAi" @click="addImg" :loading="imgLoading">
            <div class="aiImgIcon">
              <img :src="imgAi" alt="" class="wenshengtuImg" />
            </div>
            <div class="aiImgTitle">{{ t("base.base29") }}</div>
          </el-button>
        </div>
      </div>
      <div class="marginBtomm20px">
        <span class="addLabel"
          >{{ t("base.base30") }}
          <el-tooltip popper-class="toolTip" :content="t('base.base305')">
            <el-icon class="tipIcon"><Warning /></el-icon>
          </el-tooltip>
        </span>
      </div>
      <el-select
        class="marginBtomm20px elSelete"
        :placeholder="t('base.base31')"
        v-model="form.promptId"
        filterable
        remote
        reserve-keyword
        remote-show-suffix
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="item in botList"
          :key="item.templateId"
          :label="item.templateName"
          :value="item.templateId"
        >
          <span class="dropText">{{ item.templateName }}</span>
        </el-option>
      </el-select>
      <div class="marginBtomm20px flexBetweenAdd">
        <span class="addLabel">{{ t("base.base32") }} </span>
        <el-button class="addUserBtn" @click="addUser">{{
          t("base.base33")
        }}</el-button>
      </div>
      <el-row class="rightTable">
        <el-col :span="10" class="tableNmae">{{ t("base.base34") }}</el-col>
        <el-col :span="8" class="tableNmae">
          {{ t("base.base35") }}
          <el-tooltip
            popper-class="toolTip"
            raw-content
            :content="t('base.base288')"
          >
            <el-icon class="tipIcon"><Warning /></el-icon>
          </el-tooltip>
        </el-col>
        <el-col :span="6">{{ t("base.base36") }}</el-col>
      </el-row>
      <el-row
        class="tableItem marginBtomm20px"
        v-for="(item, index) in form.shareUserIds"
        :key="item.id"
      >
        <el-col :span="10" class="flexCenter padding10" v-if="item.name">
          <div class="userImg" :style="'background-color:' + item.color">
            {{ item.name?.slice(0, 1) }}
          </div>
          <span class="tableTitleNmae">{{ item.name }}</span>
        </el-col>
        <el-col :span="10" class="flexCenter padding10" v-else>
          <span class="tableTitleNmae">-</span>
        </el-col>
        <el-col :span="8" class="padding10">
          <div class="tableRoleType" v-if="item.type == 0">
            <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
            <div class="tableRole">{{ t("base.base38") }}</div>
          </div>
          <div class="tableRoleType1" v-if="item.type == 1">
            <div class="tableLogo1">{{ t("base.base39") }}</div>
            <div class="tableRole1">{{ t("base.base40") }}</div>
          </div>
          <div class="tableRole" v-if="item.type == 2">
            {{ t("base.base41") }}
          </div>
        </el-col>
        <el-col :span="6" class="flexCenter">
          <el-button
            class="deleUserBtn"
            @click="deleteUser(item, index)"
            v-if="item.type != 0"
          >
            {{ t("base.base42") }}
          </el-button>
          <el-dropdown
            @command="changeUser($event, item)"
            v-if="item.type != 0 && item.ownerType != 2"
          >
            <el-icon class="moreUser">
              <MoreFilled />
            </el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="item.type == 1" :command="2">
                  <span class="dropText">{{ t("base.base43") }}</span>
                </el-dropdown-item>
                <el-dropdown-item :command="1" v-if="item.type == 2">
                  <span class="dropText">{{ t("base.base44") }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
    <div class="addBottomBtn">
      <el-button class="closeBtn" @click="closeAdd">{{
        t("base.base45")
      }}</el-button>
      <el-button
        class="nextBtn"
        v-if="props.addType == 'add'"
        @click="save"
        :loading="props.addLoading"
      >
        {{ t("base.base46") }}
      </el-button>
      <el-button
        class="nextBtn"
        v-else
        @click="save"
        :loading="props.addLoading"
      >
        {{ t("base.base47") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <userView
    v-if="addDialog"
    :userList="userList"
    :userObj="userObj"
    :name="form.name"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
  <spView
    v-if="spDialog"
    @closeSp="closeSp"
    @saveSp="saveSp"
    :type="1"
  ></spView>
</template>

<script setup>
import item1 from "@/assets/base/item1.svg";
import excelImg from "@/assets/base/excel.svg";
import zhImg from "@/assets/base/zh.svg";
import addFile from "@/assets/base/addFile.svg";
import addFileAc from "@/assets/base/addFileAc.svg";
import spAc from "@/assets/base/spAc.svg";
import spNo from "@/assets/base/spNo.svg";
import bImg from "@/assets/base/b.svg";
import imgAi from "@/assets/base/img.svg";
import rightReson from "@/assets/chat/rightReson.svg";
import leftReson from "@/assets/chat/leftReson.svg";
import imgYes from "@/assets/chat/imgYes.svg";
import addClose from "@/assets/base/addClose.svg";
import azure from "@/assets/base/azure.svg";
import aliyun from "@/assets/base/aliyun.svg";
import baidu from "@/assets/base/baidu.svg";
import uploadIndex from "@/components/upload/index.vue";
import spView from "./spView.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { message } from "ant-design-vue";
import userView from "./user.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["addType", "addLoading", "editObj"]);
const emit = defineEmits(["closeAdd", "saveAdd", "saveEdit"]);
const store = useStore();
const fileActive = ref(0);
const userId = ref(store.state.userId);
const resonOne = ref(null);
const navList = ref([
  // {
  //   name: "base.base11",
  //   url: item1,
  // },
  // {
  //   name: "base.base12",
  //   url: excelImg,
  // },
  {
    name: "base.base13",
    url: zhImg,
  },
]);
const upTypeList = ref([
  {
    name: "base.base48",
    icon: addFile,
    acIcon: addFileAc,
    tip: "base.base49",
  },
  {
    name: "base.base332",
    icon: spNo,
    acIcon: spAc,
    tip: "base.base325",
  },
]);
const ptList = ref([
  {
    id: "Azure",
    icon: azure,
  },
  {
    id: "Ali",
    icon: aliyun,
  },
  {
    id: "Baidu",
    icon: baidu,
  },
]);
const form = ref({
  userId: store.state.userId,
  name: "", //微模型名称
  desc: "", //微模型描述
  type: 0, //微模型类型
  promptId: -1, //提示词ID
  shareUserIds: [], //知识库成员
  resourcePlatform: [], //资源平台
  imageName: "",
  site: {},
  resourcetype: 0, //知识来源
});
const botList = ref([]);
const addDialog = ref(false);
const imgList = ref([]);
const imgActive = ref(null);
const getImgLoading = ref(false);
const userObj = ref({});
const ptUserList = ref([]);
const templateName = ref("");
const userList = ref({
  departmentList: [],
  userList: [],
  gropuList: [],
}); //回显
const imgLoading = ref(false);
onMounted(() => {
  getBotList();
  getPtList();
  // 新增
  if (props.addType == "add") {
    // 新增设置本人为主创
    form.value.shareUserIds.push({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  } else {
    form.value.name = props.editObj.knowledge_name;
    form.value.desc = props.editObj.knowledge_desc;
    form.value.type = props.editObj.knowledge_type;
    form.value.promptId = props.editObj.prompt_value.promptId;
    form.value.imageName = props.editObj.image_path;
    form.value.resourcetype = props.editObj.resourcetype;
    fileActive.value = props.editObj.resourcetype;
    if (props.editObj.userInfo.length != 0) {
      props.editObj.userInfo.forEach((item) => {
        form.value.shareUserIds.push({
          userId: item.ownerId,
          id: item.ownerType == 2 ? item.ownerId : Number(item.ownerId),
          type: item.type, //0 拥有者 1 创作者 2 普通用户
          userName: item.ownerName,
          name: item.ownerName,
          ownerType: item.ownerType, //0个人 1部门 2群组
          color: item.color,
        });
      });
      console.log(form.value.shareUserIds);
    }
    if (props.editObj.aliEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Ali");
    }
    if (props.editObj.azureEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Azure");
    }
    if (props.editObj.baiduEmbeddingOn == 1) {
      form.value.resourcePlatform.push("Baidu");
    }
  }
});
// 关闭sp
const closeSp = () => {
  spDialog.value = false;
};
// 保存sp
const saveSp = (site) => {
  form.value.site = site;
  spDialog.value = false;
};
// 禁用资源平台
const getPtType = (id) => {
  if (form.value.resourcePlatform.includes(id)) {
    return false;
  }
  const embeddingTypeKey = {
    Azure: "azureEmbedding",
    Ali: "aliEmbedding",
    Baidu: "baiduEmbedding",
  };
  if (!embeddingTypeKey[id]) {
    return true;
  }
  const ids = ptUserList.value.filter((item) =>
    [embeddingTypeKey[id]].includes(item)
  );
  return ids.length === 0;
};
// 获取可用资源平台
const getPtList = () => {
  api.base
    .userEmbedding({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        ptUserList.value = res.data;
      }
    });
};
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          form.value.imageName = res.data;
          console.log(res.data);
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  form.value.imageName = item.id;
};
// ai生成图片
const addImg = () => {
  // if (!form.value.name) {
  //   message.warning(t("base.base22"));
  //   return;
  // }
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: form.value.name,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            form.value.imageName = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = true;
    });
};
// 更改成员权限
const changeUser = (e, item) => {
  if (props.addType == "edit") {
    api.base
      .userTypeChange({
        userId: userId.value,
        libraryId: props.editObj.knowledge_library_id,
        changeOwnerId: item.id,
        changeOwnerType: item.ownerType,
        changeType: e,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          item.type = e;
        }
      });
  } else {
    item.type = e;
  }
};
// 确定保存
const saveUser = (arr) => {
  if (props.addType == "add") {
    form.value.shareUserIds = form.value.shareUserIds.concat(arr);
    // 去重
    const uniqueMap = new Map(
      form.value.shareUserIds.map((item) => [item.id, item])
    );
    form.value.shareUserIds = Array.from(uniqueMap.values());
    console.log(form.value.shareUserIds);

    addDialog.value = false;
  } else {
    form.value.shareUserIds = form.value.shareUserIds.concat(arr);
    // 去重
    const uniqueMap = new Map(
      form.value.shareUserIds.map((item) => [item.id, item])
    );
    form.value.shareUserIds = Array.from(uniqueMap.values());
    let addUserIds = [];
    form.value.shareUserIds.forEach((item) => {
      addUserIds.push({
        changeOwnerId: item.id,
        changeOwnerType: item.ownerType,
        changeType: item.type,
      });
    });
    api.base
      .userAdd({
        userId: userId.value,
        libraryId: props.editObj.knowledge_library_id,
        addUserIds: addUserIds,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base50"));
          addDialog.value = false;
        }
      });
  }
};
//添加成员
const addUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 移除成员
const deleteUser = (item, index) => {
  if (props.addType == "add") {
    form.value.shareUserIds.splice(index, 1);
  } else {
    api.base
      .userDelete({
        userId: userId.value,
        libraryId: props.editObj.knowledge_library_id,
        changeOwnerId: item.id || item.ownerId,
        changeOwnerType: item.ownerType,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base51"));
          form.value.shareUserIds.splice(index, 1);
        }
      });
  }
};
// 搜索
const remoteMethod = (name) => {
  templateName.value = name;
  getBotList();
};
// 获取智能体列表
const getBotList = () => {
  api.base
    .getBaseBot({
      userId: userId.value,
      templateName: templateName.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        botList.value = res.data;
      }
    });
};
// 判断有没有这个id 有就返回true  class判断
const getPt = (id) => {
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex((item) => item === id);
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 选择的平台
const clickPt = (id) => {
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex((item) => item === id);
  if (index !== -1) {
    // 如果存在，删除该id
    form.value.resourcePlatform.splice(index, 1);
  } else {
    // 如果不存在，添加该id到数组中
    form.value.resourcePlatform.push(id);
  }
};
// 取消
const closeAdd = () => {
  emit("closeAdd");
};
// 切换选择
const changeNav = (index) => {
  form.value.type = index;
};
const spDialog = ref(false);
const changeFile = (index) => {
  if (index == 1) return;
  form.value.resourcetype = index;
  fileActive.value = index;
  if (index == 1) {
    spDialog.value = true;
  } else {
    spDialog.value = false;
  }
};
// 保存
const save = () => {
  if (!form.value.name) {
    message.warning(t("base.base22"));
    return;
  }
  // if (!form.value.desc) {
  //   message.warning("输入微模型主题、内容或作用的简要描述");
  //   return;
  // }
  if (form.value.resourcePlatform.length == 0) {
    message.warning(t("base.base52"));
    return;
  }
  let newForm = JSON.parse(JSON.stringify(form.value));
  newForm.shareUserIds = newForm.shareUserIds.map((item) => {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
      ownerType: item.ownerType,
      color: item.color,
    };
  });
  console.log(newForm);
  if (props.addType == "add") {
    emit("saveAdd", newForm);
  } else {
    emit("saveEdit", newForm);
  }
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.2;
}
.addContent {
  position: fixed;
  width: 540px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 30px;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.addBottomBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.closeBtn {
  height: 44px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  padding: 12px 20px;
}
.nextBtn {
  height: 48px;
  border-radius: 16px;
  color: #fefefe;
  background-color: #3376ff;
  border: 0px;
  font-size: 18px;
  font-family: "Regular";
  padding: 12px 20px;
}
.addBody {
  height: calc(100% - 140px);
  overflow: auto;
  padding: 5px 5px;
}
.navImg {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
}
.itemDivNav {
  width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
.itemDivNavActive {
  // width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #eceeff;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
:deep(.el-button.is-disabled:hover) {
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border: 2px solid #eceeff;
  background-color: #eceeff;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
.addLabel {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.itemInput {
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  font-family: "Regular";
}
.textInput {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Regular";
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.fileImg {
  width: 23px;
  margin-right: 6px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTipActive {
  font-size: 16px;
  color: #a3acec;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomFileActive {
  width: calc(49.5% - 44px);
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 14px 20px;
  border-radius: 18px;
  cursor: pointer;
}
.bottomFile {
  width: calc(49.5% - 44px);
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #feffff;
  padding: 14px 20px;
  border-radius: 18px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3376ff;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 60px;
  border-radius: 18px;
  margin-left: 10px;
  width: calc(100% - 130px);
  padding: 12px 18px;
  display: flex;
  align-items: center;
}
.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}
.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.elSelete {
  :deep(.el-select__wrapper) {
    font-size: 18px;
    height: 44px;
    border-radius: 18px;
    border: 2px solid #d9d9d9;
    color: #3d3d3d;
    box-shadow: 0px 0px 0px;
  }
}
.rightTable {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  border-top: 2px solid #edeef6;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableIcon {
  margin-right: 15px;
  width: 24px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableLogo1 {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #ffc300;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole1 {
  color: #ffc300;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableRoleType1 {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #feecb2;
  border-radius: 8px;
  justify-content: center;
}
.wenshengtuImg {
  width: 21px;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.addUserBtn {
  height: 36px;
  border-radius: 12px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-family: "Regular";
  border: 0px;
  padding: 6px 20px;
}
.flexBetweenAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Medium";
  max-width: 300px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ptDivItemActive {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #dbdfff;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
.moreUser {
  margin-left: 10px;
  font-size: 20px;
}
.itemImg {
  height: 59px;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 230px;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.itemImgDiv {
  position: relative;
}
.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-family: "Semibold";
  margin-right: 6px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  padding-left: 10px;
}
.padding10 {
  padding-left: 10px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
:deep(.el-input__inner::placeholder) {
  font-family: "Regular";
  font-size: 18px;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
  padding-left: 10px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #edeef6;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-family: "Regular";
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-family: "Regular";
  font-size: 18px;
}
.moseNo {
  cursor: not-allowed;
}
</style>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["closeEdit", "submitEdit", "submitAdd"]);
const props = defineProps({
  showEdit: Boolean,
  resoureInfo: Object,
  formType: String,
});
const editForm = ref({
  userId: userId.value,
  name: "",
  description: "",
  groupIds: [],
  limits: {},
});
const limitMessages = ref({
  limitAliImageTimes: "manage.resoureForm.resoure65",
  limitBaiduImageTimes: "manage.resoureForm.resoure65",
  limitImageTimes: "manage.resoureForm.resoure65",
  limitDocumentChatNum: "manage.resoureForm.resoure66",
  limitDocumentNum: "manage.resoureForm.resoure66",
  limitAzureChunkTimes: "menu.index28",
  limitBaiduChunkTimes: "menu.index28",
  limitAliChunkTimes: "menu.index28",
  limitAzureOcrTimes: "menu.index31",
  limitBaiduOcrTimes: "menu.index31",
  limitAliOcrTimes: "menu.index31",
  limit35Token: "manage.resoureForm.resoure67",
  limit4Token: "manage.resoureForm.resoure67",
  limit4oToken: "manage.resoureForm.resoure67",
  limit4ominiToken: "manage.resoureForm.resoure67",
  limitQianFanModel1Token: "manage.resoureForm.resoure67",
  limitQianFanModel2Token: "manage.resoureForm.resoure67",
  limitQianFanModel3Token: "manage.resoureForm.resoure67",
  limitqwenMaxTokens: "manage.resoureForm.resoure67",
  limitqwenTurboTokens: "manage.resoureForm.resoure67",
  limitqwenPlusTokens: "manage.resoureForm.resoure67",
});
onMounted(() => {
  if (props.formType == "edit") {
    nextTick(() => {
      if (props.resoureInfo.resourceInfo?.length != 0) {
        resoureSelect.value = props.resoureInfo.resourceInfo?.map(
          (item) => item.modelType
        );
      } else {
        resoureSelect.value = [];
      }
      if (props.resoureInfo.groupIds?.length != 0) {
        props.resoureInfo.groupIds = props.resoureInfo.groupIds.map(
          (item) => item
        );
      } else {
        props.resoureInfo.groupIds = [];
      }
    });
  }
  getGroupList();
  getNewList();
});
const editNum = ref(true);
// 编辑配额
const editNumBtn = () => {
  editNum.value = false;
};
const limit = ref({});
// input失焦
const newObj = ref({});
const changeInput = (e) => {
  limit.value[e.limit.limitType] = e.limit.value;
  Object.assign(newObj.value, limit.value);
};
const formRef = ref(null);
const rules = ref({
  name: [
    {
      required: true,
      message: t("manage.resoureForm.resoure68"),
    },
    { min: 1, max: 20, message: t("login.login71"), trigger: "blur" },
  ],
  description: [
    {
      required: true,
      message: t("manage.resoureForm.resoure69"),
    },
  ],
  groupIds: [
    {
      required: true,
      message: t("manage.resoureForm.resoure70"),
    },
  ],
  resourceInfo: [
    {
      required: true,
      message: t("manage.resoureForm.resoure71"),
    },
  ],
});
// 保存配额编辑
const submitNumBtn = () => {
  editNum.value = true;
  props.resoureInfo.resourceInfo.forEach((item) => {
    limit.value[item.limit.limitType] = Number(item.limit.value);
    Object.assign(newObj.value, limit.value);
  });
};
// 群组下拉
const groupTree = ref(null);
const groupList = ref([]);
const defaultProps = ref({
  label: "groupName",
  id: "groupId",
});
const getGroupList = () => {
  api.manage
    .queryGroupList({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode === 200) {
        groupList.value = res.data;
      }
    });
};
// 企业租户下所有资源组的资源列表
const resoureSelect = ref([]);
const resoureList = ref([]);
const resoureTree = ref(null);
const defaultProps2 = ref({
  id: "modelType",
  label: "modelType",
});
const getNewList = () => {
  api.manage
    .getResourceTree({
      departmentId: 1,
      resourceType: -1,
      ifLimit: true,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        resoureList.value = res.data;
      }
    });
};
const handleCheckChange2 = (data, checked) => {
  if (checked) {
    //去重
    props.resoureInfo.resourceInfo.push(data);
  } else {
    props.resoureInfo.resourceInfo = props.resoureInfo.resourceInfo.filter(
      (item) => item.modelType !== data.modelType
    );
  }
};
// 所属群组移除
const removeTag2 = (val) => {
  const index = props.resoureInfo.resourceInfo.indexOf(val);
  if (index > -1) {
    props.resoureInfo.resourceInfo.splice(index, 1);
  }
};
// 拒绝
const handleCancel = () => {
  formRef.value.resetFields();
  emit("closeEdit");
};
//提交
const handleConfirm = async (formRef) => {
  if (editNum.value == false) {
    message.error(t("manage.resoureForm.resoure72"));
    return;
  } else {
    if (props.resoureInfo.description.length > 100) {
      message.warning(t("login.login72"));
      return;
    }
    editForm.value.name = props.resoureInfo.name;
    editForm.value.description = props.resoureInfo.description;
    editForm.value.groupIds = props.resoureInfo.groupIds;
    for (let i = 0; i < props.resoureInfo.resourceInfo.length; i++) {
      changeInput(props.resoureInfo.resourceInfo[i]);
    }
    editForm.value.limits = newObj.value;
    await formRef.validate((valid) => {
      if (valid) {
        emit("submitEdit", editForm.value);
        resoureSelect.value = [];
      }
    });
  }
};
// 新增确定
const addSave = async (formRef) => {
  if (editNum.value == false) {
    message.error(t("manage.resoureForm.resoure72"));
    return;
  } else {
    if (props.resoureInfo.description.length > 100) {
      message.warning(t("login.login72"));
      return;
    }
    editForm.value.name = props.resoureInfo.name;
    editForm.value.description = props.resoureInfo.description;
    editForm.value.groupIds = props.resoureInfo.groupIds;
    for (let i = 0; i < props.resoureInfo.resourceInfo.length; i++) {
      changeInput(props.resoureInfo.resourceInfo[i]);
    }
    editForm.value.limits = newObj.value;
    await formRef.validate((valid) => {
      if (valid) {
        emit("submitAdd", editForm.value);
        resoureSelect.value = [];
      }
    });
  }
};
</script>

<template>
  <!-- 白名单详情 -->
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="props.showEdit"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>
            {{
              props.formType === "edit"
                ? $t("manage.resoureForm.resoure30")
                : $t("manage.resoureForm.resoure73")
            }}{{ $t("manage.resoureForm.resoure74") }}
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider />
      <div class="main">
        <el-form
          ref="formRef"
          :model="resoureInfo"
          :rules="rules"
          label-position="top"
        >
          <el-form-item
            prop="name"
            :label="$t('manage.resoureForm.resoure24')"
            class="input-item"
          >
            <el-input
              class="comtent"
              v-model="resoureInfo.name"
              :placeholder="$t('manage.resoureForm.resoure75')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('manage.resoureForm.resoure76')"
            class="input-item"
          >
            <el-input
              class="comtent"
              v-model="resoureInfo.description"
              :placeholder="$t('manage.resoureForm.resoure76')"
            ></el-input>
          </el-form-item>
          <el-form-item
            props="groupIds"
            :label="$t('manage.resoureForm.resoure77')"
          >
            <!-- 添加群组下拉 -->
            <el-tree-select
              clearable
              multiple
              v-model="resoureInfo.groupIds"
              :data="groupList"
              check-on-click-node
              :props="defaultProps"
              node-key="groupId"
              ref="groupTree"
            >
              <template #default="{ data: { groupName } }">
                <span style="float: left">{{ groupName }}</span>
              </template>
            </el-tree-select>
          </el-form-item>
          <el-form-item props="resourceInfo">
            <div class="addUser">
              <div class="left">{{ $t("manage.resoureForm.resoure78") }}</div>
              <div class="twoBtn">
                <div @click="editNumBtn" v-if="editNum" class="oneBtn">
                  {{ $t("manage.resoureForm.resoure79") }}
                </div>
                <div @click="submitNumBtn" v-if="!editNum" class="submitBtn">
                  {{ $t("manage.resoureForm.resoure80") }}
                </div>
              </div>
            </div>
            <!-- 企业资源下拉 -->
            <el-tree-select
              multiple
              v-model="resoureSelect"
              :data="resoureList"
              show-checkbox
              :props="defaultProps2"
              node-key="modelType"
              ref="resoureTree"
              @check-change="handleCheckChange2"
              @remove-tag="removeTag2"
            />
            <el-table :data="resoureInfo.resourceInfo" class="table">
              <el-table-column
                prop="modelType"
                :label="$t('manage.resoureForm.resoure81')"
              />
              <el-table-column
                prop="functionType"
                :label="$t('manage.resoureForm.resoure82')"
              />
              <el-table-column
                prop="limit"
                :label="$t('manage.resoureForm.resoure83')"
              >
                <template #default="scope">
                  <el-input
                    v-model="scope.row.limit.value"
                    :disabled="editNum"
                    type="number"
                    :max="2147483647"
                    @blur="changeInput(scope.row)"
                  >
                    <template #append>
                      <div v-if="limitMessages[scope.row.limit.limitType]">
                        {{ $t(limitMessages[scope.row.limit.limitType]) }}
                      </div>
                    </template>
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType == 'edit'">
          <div @click="handleCancel(formRef)" class="cancel">
            {{ $t("manage.resoureForm.resoure42") }}
          </div>
          <div @click="handleConfirm(formRef)" class="confirm">
            {{ $t("manage.resoureForm.resoure41") }}
          </div>
        </div>
        <div class="dialog-footer" v-if="props.formType == 'add'">
          <div @click="handleCancel(formRef)" class="cancel">
            {{ $t("manage.resoureForm.resoure42") }}
          </div>
          <div @click="addSave(formRef)" class="confirm">
            {{ $t("manage.resoureForm.resoure41") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-select__wrapper) {
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

.input-item {
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    width: 130px !important;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

:deep(.el-dialog) {
  --el-dialog-width: 756px;
  border-radius: 30px;
  padding: 30px;
  max-height: 872px;
  z-index: 3 !important;
}

:deep(.el-form-item__label) {
  margin-top: 10px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.main {
  overflow-y: auto;
  height: 590px;
}

.twoBtn {
  display: flex;

  .oneBtn {
    width: 80px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #3376ff;
    cursor: pointer;
    margin-left: 10px;
    color: #fff;
  }
}

.submitBtn {
  width: 80px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 8px;
  background-color: #767bfa;
  cursor: pointer;
  margin-left: 10px;
  color: #fff;
}

.move_tag {
  width: 48px;
  padding: 4px 8px;
  background-color: #f7f8ff;
  border-radius: 8px;
  color: #f02d63;
  cursor: pointer;
}

.close-icon {
  font-size: 20px;
}

.content {
  display: flex;
  color: #3d3d3d;

  .name {
    font-size: 24px;
    font-weight: 600;
    margin-right: 6px;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #dbdfff;
    color: #0256ff;
    padding: 0px 6px;
    height: 28px;
    line-height: 28px;
    border-radius: 6px;
    font-size: 16px;
    margin: auto 0;
  }
}

.introduce {
  margin-top: 12px;
  font-size: 18px;
  height: 25px;
  line-height: 25px;
}

.addUser {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .left {
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 600;
    height: 36px;
    line-height: 36px;
  }

  .add {
    width: 112px;
    height: 36px;
    font-size: 18px;
    color: #fff;
    background-color: #3376ff;
    line-height: 36px;
    text-align: center;
    border-radius: 12px;
  }
}

.table {
  margin-top: 12px;
  width: 100%;
  height: 220px;
}

.form-name {
  margin-top: 12px;
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    width: 144px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
</style>

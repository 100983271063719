<template>
  <div class="content">
    <div class="topFlex">
      <div class="titile">{{ t("bot.bot109") }}</div>
      <!-- 发布 -->
      <publishView
        ref="publishViewRef"
        type="bot"
        @openDetail="openDetail"
        @changeModel="changeModel"
        @saveEdit="saveEdit"
      ></publishView>
    </div>
    <div class="bottomDiv">
      <!-- 左侧 -->
      <div style="position: relative" v-if="!lookDialog">
        <div class="leftDiv">
          <!-- 设定和逻辑介绍 -->
          <div class="bigDiv">
            <div class="divFlex">
              <div class="divTitle">{{ t("bot.bot110") }}</div>
              <el-button
                class="wsBtn"
                @click="oneKeyPerfect"
                :loading="perLoading"
              >
                <div class="botWsDiv">
                  <img :src="botWsSvg" class="botWsSvg" />
                </div>
                <span class="botWsText">{{ t("bot.bot111") }}</span>
              </el-button>
            </div>
            <el-input
              maxlength="2000"
              :placeholder="t('bot.bot32')"
              v-model="botItem.templateValue"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 10 }"
            />
          </div>
          <!-- 高级 -->
          <div class="hightDiv" @click="highType = !highType">
            <div class="flexCenterHigh">
              <img :src="highSvg" alt="" class="highSvg" />
              <span class="hightTitle">{{ t("bot.bot112") }}</span>
            </div>
            <img :src="botRight" alt="" class="botRight" v-if="!highType" />
            <img :src="botBottom" class="botRight" alt="" v-else />
          </div>
          <el-collapse-transition>
            <div v-if="highType">
              <!-- 开场白 -->
              <div class="bigDIvNoBorder">
                <div class="divFlex">
                  <div class="divTitle">{{ t("bot.bot44") }}</div>
                </div>
                <el-input
                  maxlength="2000"
                  :placeholder="t('bot.bot45')"
                  v-model="botItem.templateCall"
                  show-word-limit
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5 }"
                />
              </div>
              <!-- 提问灵感 -->
              <div class="bigDIvNoBorder">
                <div class="divFlex">
                  <div class="divTitle">{{ t("bot.bot46") }}</div>
                  <el-button
                    class="wsBtn"
                    :disabled="botItem.suggest.length == 3"
                    @click="addLg"
                  >
                    <img :src="addSvg" class="addSvg" />
                    <span class="botWsText">{{ t("bot.bot47") }}</span>
                  </el-button>
                </div>
                <div
                  v-for="(item, index) in botItem.suggest"
                  :key="item"
                  class="inputDelete"
                >
                  <el-input
                    class="lgInput"
                    maxlength="30"
                    show-word-limit
                    v-model="item.value"
                  />
                  <el-icon class="deleteIcon" @click="clearInput(index)">
                    <CircleCloseFilled />
                  </el-icon>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="testDiv">
          <el-tooltip popper-class="toolTip" :content="t('bot.bot113')">
            <div class="testBtn" @click="templateTest(true)">
              {{ t("bot.bot114") }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <!-- 右侧 -->
      <div :class="lookDialog ? 'rightDiv1' : 'rightDiv'">
        <div class="rightTopFlex">
          <div class="divTitle">{{ t("bot.bot115") }}</div>
        </div>
        <div class="bigQaP" ref="qaDivRef">
          <div
            class="qaDIv"
            :style="'height:' + qaHeight + 'px'"
            ref="containerRef"
          >
            <botQa
              @saveMessage="saveMessage"
              @releteTalk="releteTalk"
              @openFileView="openFileView"
              @openTalk="openTalk"
              :botItem="rightInfo"
              :debugtemplateId="debugtemplateId"
              :chatList="chatList"
              :templateCall="templateCall"
            ></botQa>
          </div>
          <!-- input -->
          <div ref="inputDivRef" style="position: relative">
            <botInput
              @getDivHeight="getDivHeight"
              @sendMessage="sendMessage"
              @changeTag="changeTag"
              @openFileView="openFileView"
              @changeBaseId="changeBaseId"
              @clearTalk="clearTalk"
              :botItem="rightInfo"
              :debugtemplateId="debugtemplateId"
              :model="model"
              ref="botInputRef"
            ></botInput>
          </div>
        </div>
      </div>
      <!-- 文件预览 -->
      <fileView
        v-if="lookDialog && lookType == 'file'"
        type="chat"
        class="fileViewDiv"
        :fileItem="fileItem"
        :chunkDialog="true"
        :chunkList="chunkList"
        :qaType="qaType"
        @closeFileView="closeFileView"
        @getFileList="getTalk"
      ></fileView>
      <!-- 对话预览 -->
      <topicView
        v-if="lookDialog && lookType == 'talk'"
        :sessionInfo="sessionInfo"
        :talkList="talkList"
        @closeTalk="closeTalk"
        @openTalk="openTalk"
        @talkOpenFile="talkOpenFile"
      ></topicView>
    </div>
  </div>
  <!-- 详情 -->
  <detailBot
    v-if="detailType"
    :botInfo="botInfo"
    :botIsOwner="1"
    @closeDetail="closeDetail"
    @openEdit="openEdit"
  ></detailBot>
  <!-- 新建智能体弹窗 -->
  <addDialog
    v-if="showDialog"
    :botInfo="botInfo"
    formType="edit"
    @closeBtn="closeBtn"
    @editBtn="editBtn"
  ></addDialog>
</template>

<script setup>
import publishView from "@/components/publish/index.vue";
import botWsSvg from "@/assets/publish/botWs.svg";
import addSvg from "@/assets/publish/add.svg";
import highSvg from "@/assets/bot/high.svg";
import botRight from "@/assets/bot/botRight.svg";
import botBottom from "@/assets/bot/botBottom.svg";
import botQa from "@/components/qa/botQa.vue";
import detailBot from "./components/detailBot.vue";
import addDialog from "./components/addBot.vue";
import botInput from "./components/input.vue";
import fileView from "@/components/fileView/index.vue";
import topicView from "@/components/chat/topicView.vue";
import api from "@/api";
import { message } from "ant-design-vue";
import { onMounted, ref, watch, nextTick, onUpdated } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { encryptApi, decryptApi } from "@/utils/crypto";
const { t } = useI18n();
const store = useStore();
const botItem = ref(JSON.parse(JSON.stringify(store.state.botItem)));
const highType = ref(true);
const detailType = ref(false);
const showDialog = ref(false);
const userId = ref(store.state.userId);
const model = ref(null);
const botInputRef = ref(null);
const qaDivRef = ref(null);
const qaHeight = ref(null);
const inputDivRef = ref(null);
const chatList = ref([]);
const debugtemplateId = ref(null);
const publishViewRef = ref(null);
onMounted(() => {
  store.commit("SET_CHATTYPE", "chat");
  if (botItem.value.suggest.length != 0) {
    botItem.value.suggest = botItem.value.suggest.map((item) => {
      return {
        value: item,
      };
    });
  }
  templateTest(false);
  window.addEventListener("resize", () => {
    getDivHeight();
  });
  setTimeout(() => {
    getDivHeight();
  }, 100);
});
onUpdated(() => {
  scrollBottom();
});
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    botItem.value = JSON.parse(JSON.stringify(store.state.botItem));
    if (botItem.value.suggest.length != 0) {
      botItem.value.suggest = botItem.value.suggest.map((item) => {
        return {
          value: item,
        };
      });
    }
  }
);
// 滚动向下
const containerRef = ref(null);
const scrollBottom = () => {
  nextTick(() => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  });
};
// 清楚上下文
const clearTalk = () => {
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  api.chat
    .chatClear({
      userId: userId.value,
      topicId: debugtemplateId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("login.login50"));
      }
    });
};
// 切换知识库
const changeBaseId = (id) => {
  publishViewRef.value.getModel(id);
};
// 提交
const saveEdit = () => {
  api.bot.templateEdit(botItem.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("bot.bot116"));
    }
  });
};
// 打开话题
const talkList = ref([]);
const sessionInfo = ref({});
const openTalk = (item) => {
  if (item.readable == 0) {
    message.warning(t("base.base321"));
    return;
  }
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: item.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.conversationInfo.forEach((items) => {
            items.chatActive = 1;
            items.resonType = false;
            items.resonList = [];
            items.tipDialog = false;
            items.editType = false;
            items.loading = false;
          });
        }
        talkList.value = res.data.conversationInfo;
        sessionInfo.value = res.data.sessionInfo;
        lookDialog.value = true;
        lookType.value = "talk";
      }
    });
};
// 打开文件
const qaType = ref(false);
const fileItem = ref({});
const chunkList = ref([]);
const lookType = ref("file");
const lookDialog = ref(false);
const openFileView = (item, list) => {
  qaType.value = false;
  if (item.fileType == 2) {
    openTalk({ sessionId: item.id });
    return;
  }
  fileItem.value = item;
  chunkList.value = list;
  lookType.value = "file";
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 重新生成
const releteTalk = (item) => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  item.resonType = false;
  item.answers.push({
    answerContent: "",
    answerId: "",
    strategy: item.strategy,
  });
  item.chatActive = item.answers.length;
  let time = Math.round(new Date() / 1000);
  let obj = {
    userId: userId.value,
    model: model.value,
    topicId: debugtemplateId.value,
    conversationId: item.conversationId,
    strategy: item.strategy,
    indebug: true,
  };
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (obj.model == "GPT-o1-preview") {
    props.item.loading = true;
    api.chat.chatRegenerate(obj).then((res) => {
      if (res.returnCode == 200) {
        item.answers[item.answers.length - 1].answerContent = res.data;
        item.loading = false;
      }
    });
  } else {
    regenerateFn(postObj, lang, "/openai/chat/v4/normal/regenerate", item);
  }
};
// 重新生成chat
const regenerateFn = async (postObj, lang, url, item) => {
  scrollBottom();
  try {
    item.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning(t("base.base182"));
      item.answers[item.answers.length - 1].answerContent = t("base.base182");
      item.loading = false;
      return;
    }
    if (response.status === 504) {
      message.warning(t("base.base183"));
      item.answers[item.answers.length - 1].answerContent = t("base.base183");
      item.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      item.answers[item.answers.length - 1].answerContent = data;
      scrollBottom();
      if (done) {
        item.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          // 结束
          console.log(data);
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          item.answers[item.answers.length - 1].answerContent =
            t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            item.chatActive = item.answers.length - 1;
            item.answers.splice(item.chatActive, 1);
          }
        }
        break;
      }
    }
  } catch (err) {
    item.loading = false;
  }
};
// 获取model
const changeModel = (e) => {
  model.value = e;
};
// 判断是否有正在输出的内容
const getLoading = () => {
  return chatList.value.some((item) => item.loading === true);
};
// 发送消息
const sendMessage = (obj) => {
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!obj.message) {
    message.warning(t("share.input1"));
    return;
  }
  if (!obj.model) {
    message.warning(t("base.base249"));
    return;
  }
  if (obj.imageFlag == 1) {
    obj.pictureModelType = obj.model;
  }
  //加密时间
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let chatObj = {
    question: obj.message,
    chatActive: 1,
    loading: false,
    answers: [
      {
        answerContent: "",
        strategy: obj.strategy,
      },
    ],
    model: obj.model,
  };
  chatList.value.push(chatObj);
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (model.value == "GPT-o1-preview") {
    chatList.value[chatList.value.length - 1].loading = true;
    scrollBottom();
    api.chat.o1Chat(obj).then((res) => {
      if (res.returnCode == 200) {
        chatList.value[chatList.value.length - 1].loading = false;
        nextTick(() => {
          getTalk();
        });
      }
    });
  } else {
    sendFn(postObj, lang, "/openai/chat/v4/memory/chat");
  }
};
const sendFn = async (postObj, lang, url) => {
  scrollBottom();
  try {
    chatList.value[chatList.value.length - 1].loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning(t("base.base182"));
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = t("base.base182");
      chatList.value[chatList.value.length - 1].loading = false;
      return;
    }
    if (response.status === 504) {
      message.warning(t("base.base183"));
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = t("base.base183");
      chatList.value[chatList.value.length - 1].loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = data;
      scrollBottom();
      if (done) {
        chatList.value[chatList.value.length - 1].loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          getTalk();
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          chatList.value[chatList.value.length - 1].answers[
            chatList.value[chatList.value.length - 1].chatActive - 1
          ].answerContent = t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            chatList.value[chatList.value.length - 1].answers[
              chatList.value[chatList.value.length - 1].chatActive - 1
            ].answerContent = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    chatList.value[chatList.value.length - 1].loading = false;
  }
};
// 获取历史记录
const templateCall = ref(null);
const getTalk = () => {
  api.share
    .chatDetailQuery({
      userId: userId.value,
      topicId: debugtemplateId.value,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.chatActive = 1;
          item.resonType = false;
          item.resonList = [];
          item.tipDialog = false;
          item.editType = false;
          item.loading = false;
        });
        templateCall.value = res.templateCall;
        chatList.value = res.data;
        scrollBottom();
      }
    });
};
// 调试
const rightInfo = ref({});
const templateTest = (type) => {
  let obj = JSON.parse(JSON.stringify(botItem.value));
  obj.suggest = obj.suggest.map((item) => item.value);
  obj.indebug = true;
  api.bot.templateAdd(obj).then((res) => {
    if (res.returnCode == 200) {
      api.chat
        .addChat({
          userId: userId.value,
          topicName: botItem.value.templateName.slice(0, 250),
          templateId: res.data.debugtemplateId,
          indebug: true,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            debugtemplateId.value = res.data.table_id;
            rightInfo.value = JSON.parse(JSON.stringify(botItem.value));
            chatList.value = [];
            if (type) {
              message.success(t("base.base213"));
            }
          }
        });
    }
  });
};
// 切换tag
const changeTag = (index) => {
  if (publishViewRef.value) {
    if (index == 2) {
      publishViewRef.value.getImgModel();
    } else {
      publishViewRef.value.getModel();
    }
  }
};
// 一键完善
const perLoading = ref(false);
const oneKeyPerfect = () => {
  perLoading.value = true;
  api.bot
    .templatePerfect({
      message: botItem.value.templateValue,
      templateName: botItem.value.templateName,
      templateValue: botItem.value.templateValue,
      userId: userId.value,
    })
    .then((res) => {
      perLoading.value = false;
      if (res.returnCode === 200) {
        botItem.value.templateCall = res.data.templateCall;
        botItem.value.templateDesc = res.data.templateDesc;
        botItem.value.templateValue = res.data.templateValue;
        highType.value = true;
        message.success(t("bot.bot18"));
      }
    })
    .catch((err) => {
      perLoading.value = false;
    });
};
// 计算高度
const getDivHeight = () => {
  nextTick(() => {
    if (qaDivRef.value && inputDivRef.value) {
      qaHeight.value =
        qaDivRef.value.offsetHeight - inputDivRef.value.offsetHeight;
    }
  });
};
// 新增灵感
const addLg = () => {
  botItem.value.suggest.push({
    value: "",
  });
};
// 点击灵感
const saveMessage = (message) => {
  botInputRef.value.saveMessage(message);
};
// 删除开场白
const clearInput = (e) => {
  botItem.value.suggest.splice(e, 1);
};
// 打开详情
const botInfo = ref({});
const openDetail = () => {
  api.bot
    .templateDetail({
      userId: userId.value,
      templateId: botItem.value.templateId || botItem.value.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.templateId = res.data.id;
        res.data.suggest = res.data.suggestQuestion;
        botInfo.value = res.data;
        detailType.value = true;
      }
    });
};
// 关闭详情
const closeDetail = () => {
  detailType.value = false;
};
//打开编辑
const openEdit = () => {
  detailType.value = false;
  botItem.value.templateId = botItem.value.id;
  showDialog.value = true;
};
// 关闭编辑
const closeBtn = () => {
  showDialog.value = false;
};
// 保存编辑
const editBtn = (form) => {
  api.bot.templateEdit(form).then((res) => {
    if (res.returnCode == 200) {
      showDialog.value = false;
      message.success(t("bot.bot5"));
      api.bot
        .templateDetail({
          userId: userId.value,
          templateId: form.templateId,
        })
        .then((ress) => {
          if (ress.returnCode == 200) {
            ress.data.templateId = ress.data.id;
            ress.data.suggest = ress.data.suggestQuestion;
            store.commit("SET_BOT", ress.data);
          }
        });
    }
  });
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 30px;
}
.titile {
  font-size: 24px;
  color: #3d3d3d;
  font-family: "Semibold";
}
.topFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin-bottom: 20px;
}
.divTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.botWsText {
  color: #0256ff;
  font-family: "Medium";
  font-size: 18px;
}
.botWsSvg {
  width: 21px;
}
.botWsDiv {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #dbdfff;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.wsBtn {
  background-color: #eceeff;
  border-radius: 12px;
  border: 0px;
  padding: 6px;
}
.divFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 0px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-family: "Regular";
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-family: "Regular";
  font-size: 18px;
}
.bigDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  border: 2px solid #a3acec;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 20px;
}
.bigDIvNoBorder {
  background-color: #f7f8ff;
  border-radius: 20px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 20px;
}
.bottomDiv {
  height: calc(100% - 75px);
  overflow: hidden;
  display: flex;
  position: relative;
}
.leftDiv {
  width: 720px;
  margin-right: 20px;
  height: calc(100% - 60px);
  overflow: auto;
  position: relative;
}
.rightDiv {
  width: calc(100% - 740px);
  position: sticky;
  right: 0;
  top: 0;
  border: 2px solid #edeef6;
  border-radius: 20px;
  background-color: #f7f8ff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 20px;
}
.rightDiv1 {
  width: 527px;
  border: 2px solid #edeef6;
  border-radius: 20px;
  background-color: #f7f8ff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 20px;
  margin-right: 20px;
}
.highSvg {
  width: 20px;
  margin-right: 12px;
}
.botRight {
  width: 24px;
}
.hightDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  height: 48px;
  border-radius: 18px;
  padding: 0px 15px;
  cursor: pointer;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 15px;
}
.flexCenterHigh {
  display: flex;
  align-items: center;
}
.hightTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
}
.addSvg {
  width: 16px;
  margin-right: 6px;
}
.lgInput {
  margin-right: 10px;
  :deep(.el-input__wrapper) {
    height: 48px;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-family: "Regular";
  }
}
.inputDelete {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.deleteIcon {
  font-size: 18px;
  color: #f02d63;
  cursor: pointer;
}
.rightTopFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 20px;
}
.bigQaP {
  height: calc(100% - 50px);
  overflow: hidden;
}
.qaDIv {
  overflow: auto;
}
.testBtn {
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 16px 0px #a3acec;
  border-radius: 16px;
  padding: 12px 20px;
  font-size: 18px;
  color: #3376ff;
  font-family: "Regular";
  background-color: #fefefe;
  cursor: pointer;
  margin-right: 20px;
}
.testDiv {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fileViewDiv {
  width: calc(100% - 551px);
  height: calc(100% - 4px);
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid #edeef6;
}
</style>

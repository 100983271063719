
import axios from "../server";
export default {
    // 获取发布配置
    getConfig(data: any) {
        return axios({
            url: `/publish/api/config/get`,
            method: "POST",
            data,
        });
    },
    // 查看单独配置
    getOneConfig(data: any) {
        return axios({
            url: `publish/${data.plat}/config/${data.confid}`,
            method: "GET",
            data,
        });
    },
    // 修改配置
    saveConfig(data: any) {
        return axios({
            url: `publish/${data.plat}/config/${data.confid}`,
            method: "PUT",
            data,
        });
    },
    // 新增配置
    addConfig(data: any) {
        return axios({
            url: "publish/api/config",
            method: "POST",
            data,
        });
    },
    // 查看历史
    getHistoryConfig(data: any) {
        return axios({
            url: `publish/api/version/${data.id}`,
            method: "GET",
            data,
        });
    },
    // 切换配置
    changeV(data: any) {
        return axios({
            url: `publish/already/api/config/publish`,
            method: "POST",
            data,
        });
    },
    // 发布
    postPublish(data: any) {
        return axios({
            url: `publish/publish`,
            method: "POST",
            data,
        });
    },
}
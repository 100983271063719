<script setup>
import { ref, onMounted } from "vue";
import api from "@/api";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const spConfig = ref({
  assertionConsumerService: "",
  entityId: "",
});
const xmlType = ref(".xml");
const idpConfig = ref({
  singleService: "",
  entityId: "",
  adloginAddr: "",
});
const emit = defineEmits(["handleSaml", "handleSamlAdd"]);
const props = defineProps({
  showSaml: Boolean,
});
onMounted(() => {
  getConfig();
});
const httprequest = () => {};
// 获取参数
const getConfig = () => {
  api.manage
    .ssoGetConfig({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        spConfig.value = res.data.sp_config;
        idpConfig.value = res.data.idp_config;
      }
    });
};
// 关闭
const handleCancel = () => {
  emit("handleSaml");
};
//同意
const handleUpload = () => {
  api.manage
    .saveXml({
      userId: userId.value,
      adloginAddr: idpConfig.value.adloginAddr,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base103"));
        emit("handleSamlAdd");
      }
    });
};
// 上传
const upload = (file) => {
  api.manage.uploadXml([file], {}).then((res) => {
    if (res.returnCode == 200) {
      message.success(t('base.base88'))
      getConfig();
    }
  });
};
// 下载
const downYData = () => {
  api.log
    .expotrAd({
      userId: userId.value,
    })
    .then((xmlContent) => {
      const blob = new Blob([xmlContent], { type: "application/xml" });
      // 创建一个指向Blob对象的URL
      const url = URL.createObjectURL(blob);
      // 创建一个<a>元素并模拟点击，触发下载
      const a = document.createElement("a");
      a.href = url;
      a.download = "元数据.xml"; // 指定下载文件名
      document.body.appendChild(a);
      a.click();
      // 从DOM中移除<a>元素
      document.body.removeChild(a);
      // 释放URL对象
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.log(error);
    });
};
</script>

<template>
  <div>
    <!-- 部门详情 -->
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="props.showSaml"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div style="display: flex">
            <div class="tit_img">
              <img src="@/assets/manage/samlLogo.svg" alt="" />
            </div>
            {{ $t("manage.baseForm.base53") }}
            <!-- <div class="line">{{ $t("manage.baseForm.base54") }}</div> -->
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="content">
        <el-form :model="idpConfig">
          <el-form-item>
            <div class="form-name">
              <div class="tit">
                <div class="div">{{ $t("manage.baseForm.base57") }}</div>
                <el-upload
                  action="#"
                  :show-file-list="false"
                  :before-upload="upload"
                  :http-request="httprequest"
                  :accept="xmlType"
                >
                  <div class="right">
                    <img
                      src="@/assets/manage/upload.svg"
                      alt=""
                      class="download"
                    />
                    <div>{{ $t("manage.baseForm.base58") }}</div>
                  </div>
                </el-upload>
              </div>
              <div class="green">
                SAML 2.0 Endpint
                <div style="color: #f02d63">*</div>
              </div>
              <el-input
                disabled
                :placeholder="t('login.login59') + 'SAML 2.0 Endpint'"
                v-model="idpConfig.singleService"
                class="comtent"
              ></el-input>
              <div class="green">
                Identity Provider Issuer
                <div style="color: #f02d63">*</div>
              </div>
              <el-input
                disabled
                :placeholder="t('login.login59') + 'Identity Provider Issuer'"
                v-model="idpConfig.entityId"
                class="comtent"
              ></el-input>
              <div class="green">
                {{ t("login.login58") }}
                <div style="color: #f02d63">*</div>
              </div>
              <el-input
                :placeholder="t('login.login59') + t('login.login58')"
                v-model="idpConfig.adloginAddr"
                class="comtent"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <el-form :model="spConfig">
          <el-form-item>
            <div class="form-name">
              <div class="tit">
                <div class="div">{{ $t("manage.baseForm.base55") }}</div>
                <div class="right" @click="downYData">
                  <img
                    src="@/assets/manage/download.svg"
                    alt=""
                    class="download"
                  />
                  <div>{{ $t("manage.baseForm.base56") }}</div>
                </div>
              </div>
              <div class="green">Assertion Consumer Service (ACS) URL</div>
              <el-input
                :placeholder="
                  t('login.login59') + 'Assertion Consumer Service (ACS) URL'
                "
                v-model="spConfig.assertionConsumerService"
                disabled
                class="comtent"
              ></el-input>
              <div class="green">Service Provider (SP) Entity ID</div>
              <el-input
                :placeholder="
                  t('login.login59') + 'Service Provider (SP) Entity ID'
                "
                disabled
                v-model="spConfig.entityId"
                class="comtent"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleCancel" class="cancel">
            {{ $t("manage.baseForm.base5") }}
          </div>
          <div @click="handleUpload" class="confirm">
            {{ $t("manage.baseForm.base59") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 520px;
  border-radius: 30px;
  height: 700px;
  padding: 30px;
  background-color: #f7f8ff;
}

:deep(.el-select__wrapper) {
  width: 450px !important;
  border-radius: 12px !important;
}

:deep(.el-select--large) {
  width: 450px !important;
}

:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 428px !important;
  border-radius: 12px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";

  .tit_img {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .line {
    margin-left: 12px;
    color: #3376ff;
    font-size: 16px;
    margin-top: 4px;
    text-decoration: underline;
  }
}

.close-icon {
  font-size: 20px;
}

.content {
  font-size: 18px;
  color: #8a8a8a;
  height: 550px;
  overflow-x: hidden;
  overflow: auto;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 400;
  }
}

.form-name {
  font-size: 16px;
  color: #3d3d3d;

  .tit {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    .right {
      border-radius: 12px;
      padding: 0px 12px;
      display: flex;
      font-size: 15px;
      color: #3376ff;
      background-color: #eceeff;
      cursor: pointer;
      .download {
        margin-top: 6px;
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .green {
    font-size: 16px;
    color: #8a8a8a;
    margin-bottom: 6px;
    display: flex;
    div {
      margin-left: 6px;
    }
  }

  .comtent {
    margin-bottom: 8px;
    display: flex;

    .log_img {
      width: 25px;
      height: 25px;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .log_name {
      margin-left: 8px;
      color: #8a8a8a;
      height: 25px;
      line-height: 25px;
    }

    .log_item {
      width: 132px;
      height: 42px;
      border-radius: 18px;
      padding: 10px 6px;
      background-color: #fff;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .log_itemActive {
      width: 132px;
      height: 42px;
      border-radius: 18px;
      padding: 10px 6px;
      background-color: #dbdfff;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="baseContent">
    <!-- 搜索 -->
    <div class="flexCenter searchDiv">
      <el-input
        class="baseInput"
        :placeholder="t('base.base1')"
        v-model="listFomr.knowledgePattern"
        @input="searchInput"
        prefix-icon="Search"
      />
      <!-- 角色 -->
      <div class="roleDiv">
        <div class="roleTitle">{{ t("base.base2") }}</div>
        <el-dropdown @command="changeNav">
          <span class="dropText">
            {{ t(navList[listFomr.roles].name) }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in navList"
                :key="item"
                :command="item.value"
              >
                <span class="dropText">{{ t(item.name) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- <div :class="pxBtnType ? 'pxDivActive' : 'pxDiv'" @click="changePx">
        <img :src="pxSvg" alt="" class="px" />
      </div> -->
    </div>
    <!-- 内容 -->
    <div class="flexDiv">
      <el-row
        :gutter="20"
        v-infinite-scroll="loadList"
        :infinite-scroll-distance="5"
        :infinite-scroll-immediate="false"
      >
        <el-col :span="6">
          <div class="itemWidth" @click="openAdd">
            <div class="flexCenter itemTile">
              <div class="addDiv">
                <img :src="addImg" alt="" class="addImg" />
              </div>
              <div class="baseItemTitle">{{ t("base.base3") }}</div>
            </div>
            <div class="addTip3">
              {{ t("base.base4") }}
            </div>
          </div>
        </el-col>
        <el-col :span="6" v-for="item in list" :key="item.knowledge_library_id">
          <div class="itemWidth" @click="goBaseDetails(item)">
            <div class="flexCenter itemTile">
              <div class="flexCenter titleMax">
                <div class="logoDiv">
                  <img
                    :src="baseLogo"
                    alt=""
                    class="baseIconLogo"
                    v-if="item.image_path == null || !item.image_path"
                  />
                  <img
                    v-else
                    alt=""
                    :src="'/openai/chat/v4/image/get/' + item.image_path"
                    class="baseIconLogo"
                  />
                </div>
                <div class="forBaseItemTitle">{{ item.name }}</div>
              </div>
              <div
                class="pinDiv"
                @click.stop="changeMore(1, item)"
                v-if="item.isPin == 0"
              >
                <img :src="pinImg" alt="" class="pinImg" />
              </div>
              <div
                class="pinYesDiv"
                @click.stop="changeMore(2, item)"
                v-if="item.isPin == 1"
              >
                <img :src="pinYesImg" alt="" class="pinImg" />
              </div>
            </div>
            <div class="addTip">
              {{ item.desc }}
            </div>
            <div class="bottomFlexBetween">
              <div class="flexCenter">
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('login.login43') + item.documentNum"
                >
                  <div class="flexCenter">
                    <img :src="fileImg" alt="" class="baseIcon" />
                    <div class="bottomNumberItem">{{ item.documentNum }}</div>
                  </div>
                </el-tooltip>
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('login.login44') + item.conversationNum"
                >
                  <div class="flexCenter">
                    <img :src="talkImg" alt="" class="baseIcon" />
                    <div class="bottomNumberItem">
                      {{ item.conversationNum }}
                    </div>
                  </div>
                </el-tooltip>
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('login.login45') + item.userNum"
                >
                  <div class="flexCenter">
                    <img :src="memberImg" alt="" class="baseIcon" />
                    <div class="bottomNumberItem">{{ item.userNum }}</div>
                  </div>
                </el-tooltip>
              </div>
              <el-dropdown @command="changeMore($event, item)" trigger="click">
                <div class="moreDiv" @click.stop="">
                  <img :src="moreImg" alt="" class="moreIcon" />
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="5">
                      <span class="colorBlue">{{ t("login.login46") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="1" v-if="item.isPin != 1">
                      <span class="colorBlue">{{ t("base.base6") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="2" v-if="item.isPin === 1">
                      <span class="colorBlue">{{ t("base.base7") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="3"
                      v-if="item.knowledge_role_id == 0"
                    >
                      <span class="colorRed">{{ t("base.base8") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="4"
                      v-if="item.knowledge_role_id != 0"
                    >
                      <span class="colorRed">{{ t("base.base9") }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="flexDiv1" v-if="listLoading" v-loading="listLoading"></div>
    <!-- 新增 -->
    <addView
      v-if="addDialog"
      :addType="addType"
      :addLoading="addLoading"
      :editObj="editObj"
      @closeAdd="closeAdd"
      @saveAdd="saveAdd"
      @saveEdit="saveEdit"
    ></addView>
    <!-- 详情 -->
    <indexD
      v-if="detailType"
      :editObj="editObj"
      @closeDetail="detailType = false"
      @openEdit="openEdit"
    ></indexD>
    <dialogView
      v-if="tipDialog"
      @closeTipDialog="closeTipDialog"
      @getList="getList"
    ></dialogView>
  </div>
</template>

<script setup>
import addImg from "@/assets/base/add.svg";
import fileImg from "@/assets/base/file.svg";
import memberImg from "@/assets/base/member.svg";
import talkImg from "@/assets/base/talk.svg";
import moreImg from "@/assets/base/more.svg";
import pinImg from "@/assets/base/pin.svg";
import pinYesImg from "@/assets/base/pinYes.svg";
import pxSvg from "@/assets/base/px.svg";
import addView from "./add.vue";
import baseLogo from "@/assets/base/baselogo.svg";
import dialogView from "./dialog.vue";
import indexD from "./indexD.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
onMounted(() => {
  store.commit("SET_BASEITEM", null);
  getList();
});
const navList = ref([
  {
    name: "base.base10",
    value: 0,
  },
  {
    name: "base.base38",
    value: 1,
  },
  {
    name: "base.base40",
    value: 2,
  },
  {
    name: "base.base41",
    value: 3,
  },
]);
const addType = ref("add"); //默认新增
const pxBtnType = ref(false);
const userId = ref(store.state.userId);
const addLoading = ref(false);
const editObj = ref({}); //编辑接口
const listLoading = ref(false);
const detailType = ref(false);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  knowledgePattern: "",
  pageId: 1,
  perPage: 15,
  roles: 0,
});
const tipDialog = ref(false);
// 选择角色
const changeNav = (e) => {
  listFomr.value.roles = e;
  listFomr.value.pageId = 1;
  getList();
};
// 关闭提示弹窗
const closeTipDialog = () => {
  tipDialog.value = false;
  getList();
};
// 排序
const changePx = () => {
  pxBtnType.value = !pxBtnType.value;
};
const list = ref([]);
const searchInput = () => {
  listFomr.value.pageId = 1;
  getList();
};
const loadList = () => {
  listFomr.value.pageId++;
  getList();
};
// 获取微模型列表
const getList = () => {
  tipDialog.value = false;
  let obj = JSON.parse(JSON.stringify(listFomr.value));
  if (obj.roles == 0) {
    obj.roles = null;
  } else {
    obj.roles = obj.roles - 1;
  }
  listLoading.value = true;
  api.base
    .customLibraryList(obj)
    .then((res) => {
      listLoading.value = false;
      if (res.returnCode == 200) {
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      listLoading.value = false;
    });
};
// 打开编辑
const openEdit = () => {
  detailType.value = false;
  addDialog.value = true;
  addType.value = "edit";
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.base
      .basePin({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base14"));
          searchInput();
        }
      });
  } else if (key == 2) {
    // 取消置顶
    api.base
      .basePinCancel({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          searchInput();
        }
      });
  } else if (key == 3) {
    // 删除微模型
    api.base
      .libraryDelete(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base16"));
          getList();
        }
      });
  } else if (key == 4) {
    // 退出微模型
    api.base
      .libraryExit(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base17"));
          getList();
        }
      });
  } else if (key == 5) {
    // 编辑
    api.base
      .customLibraryDesc({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          editObj.value = res.data;
          detailType.value = true;
        }
      });
  }
};
// 双击进入详情
const goBaseDetails = (item) => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.seleteUserInfo = res.data.userInfo.filter(
          (item) => item.ownerType == 0 && item.ownerName
        );
        store.commit("SET_BASEITEM", res.data);
        store.commit("SET_TOPIC", res.data);
        store.commit("SET_CHATTYPE", "base");
        const checkEmbeddingType = (embeddingOn, embeddingType) => {
          const embedding = res.data.current_user_embedding_type.filter(
            (item) => [embeddingType].includes(item)
          );
          return embeddingOn === 1 && embedding.length !== 1;
        };
        const shouldShowTipDialog =
          checkEmbeddingType(res.data.aliEmbeddingOn, "aliEmbedding") ||
          checkEmbeddingType(res.data.azureEmbeddingOn, "azureEmbedding") ||
          checkEmbeddingType(res.data.baiduEmbeddingOn, "baiduEmbedding");
        // 普通成员直接进
        if (res.data.knowledge_role_id == 2) {
          router.push("/base/details");
          return;
        }
        // 创作者 个人 弹窗
        if (shouldShowTipDialog && res.data.knowledge_role_id == 1) {
          // 判断个人还是部门
          let type = res.data.creator_info.some((item) => item.ownerType == 0);
          if (type) {
            tipDialog.value = true;
            return;
          } else {
            message.warning(t("login.login63"));
            return;
          }
        }
        // 拥有者
        if (shouldShowTipDialog && res.data.knowledge_role_id == 0) {
          tipDialog.value = true;
          return;
        }
        if (!shouldShowTipDialog) {
          router.push("/base/details");
          return;
        }
      }
    });
};
const addDialog = ref(false);
// 关闭新增
const closeAdd = () => {
  addDialog.value = false;
};
// 保存新增
const saveAdd = (form) => {
  addLoading.value = true;
  api.base
    .customLibraryAdd(form)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base18"));
        getList();
        addDialog.value = false;
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
// 编辑
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
    imageName: form.imageName,
    resourcePlatform: form.resourcePlatform,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base307"));
        getList();
        addDialog.value = false;
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
const openAdd = () => {
  addType.value = "add";
  editObj.value = {};
  addDialog.value = true;
};
</script>

<style lang="less" scoped>
.baseContent {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: relative;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 14px;
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
  width: 45px;
}
:deep(.el-dropdown) {
  max-width: calc(100% - 45px);
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}
:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 16px !important;
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 0px #ffffff;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.baseInput {
  width: 236px;
  height: 52px;
  height: 52px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-family: "Bold";
}
.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
  width: calc(100% - 80px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logoDiv {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.addImg {
  width: 15px;
  height: 15px;
}
.baseIconLogo {
  width: 28px;
  height: 28px;
  border-radius: 8px;
}
.searchDiv {
  margin-bottom: 22px;
  position: relative;
}
.itemWidth {
  border-radius: 20px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  height: 162px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
}
.itemWidth:hover {
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #eceeff;
  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #f7f8ff;
  }
  .pinYesDiv {
    background-color: #f7f8ff;
    border-radius: 12px;
  }
  .moreDiv {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    background-color: #f7f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.pinDiv {
  display: none;
}
.pinYesDiv {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreDiv {
  display: none;
}
.itemMargin {
  margin-left: 25px;
  margin-right: 25px;
}
.addTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  /* 限制行数 */
  -webkit-box-orient: vertical;
  word-break: break-all; /* 强制换行 */
  margin-bottom: 18px;
}
.addTip3 {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  height: 75px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
  word-break: break-all; /* 强制换行 */
}
.flexDiv {
  height: calc(100% - 84px);
  padding: 5px 0px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  :deep(.el-row) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.flexDiv1 {
  position: absolute !important;
  width: calc(100% - 60px);
  bottom: 30px;
  height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.itemTile {
  height: 28px;
  margin-bottom: 24px;
  position: relative;
}
.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomNumberItem {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-left: 3px;
  margin-right: 12px;
}
.pinImg {
  width: 16px;
  height: 16px;
}
.titleMax {
  width: 100%;
}
.baseIcon {
  width: 25px;
}
.moreIcon {
  width: 18px;
}
.px {
  width: 13px;
}
.pxDiv {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
}
.pxDivActive {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  background-color: #edeef6;
}
.colorBlue {
  color: #0256ff;
  font-size: 16px;
  font-family: "Regular";
}
.colorRed {
  color: #f02d63;
  font-size: 16px;
  font-family: "Regular";
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
  padding-left: 10px;
}
</style>

<script setup>
import { ref, watch, nextTick, onMounted } from "vue";
import high from "@/assets/bot/high.svg";
import botRight from "@/assets/bot/botRight.svg";
import botBottom from "@/assets/bot/botBottom.svg";
import caseScreen from "./caseScreen.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import helper from "@/assets/bot/helper.svg";
import brain from "@/assets/bot/brain.svg";
import vector from "@/assets/bot/Vector.svg";
import IE from "@/assets/bot/IE.svg";
import { message } from "ant-design-vue";
import uploadIndex from "@/components/upload/index.vue";
import imgAi from "@/assets/base/img.svg";
import leftReson from "@/assets/chat/leftReson.svg";
import bImg from "@/assets/base/addBot.svg";
import imgYes from "@/assets/chat/imgYes.svg";
import rightReson from "@/assets/chat/rightReson.svg";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
  botInfo: Object,
  formType: String,
});
onMounted(() => {
  if (props.formType == "edit") {
    highType.value = true;
  }
});
const userObj = ref({});
const imgList = ref([]);
const imgActive = ref(null);
const resonOne = ref(null);
const formRef = ref(null);
const emit = defineEmits(["closeBtn", "submitBtn", "editBtn"]);
const navActive = ref(0);
const skillActive = ref(0);
const highType = ref(false);
const navList = ref([
  {
    name: t("bot.bot1"),
    url: helper,
  },
  // {
  //     name: t('bot.bot2'),
  //     url: tool
  // },
]);
// 切换选择
const changeNav = (index) => {
  navActive.value = index;
};
const skillList = ref([
  {
    name: t("login.login56"),
    url: brain,
  },
  {
    name: t("base.base29"),
    url: vector,
  },
  {
    name: t("base.base226"),
    url: IE,
  },
]);
const skillNav = (index) => {
  skillActive.value = index;
};
const perLoading = ref(false);
// 一键完善
const oneKeyPerfect = (e) => {
  perLoading.value = true;
  api.bot
    .templatePerfect({
      message: e,
      templateName: props.botInfo.templateName,
      templateValue: props.botInfo.templateValue,
      userId: userId.value,
    })
    .then((res) => {
      perLoading.value = false;
      if (res.returnCode === 200) {
        props.botInfo.templateCall = res.data.templateCall;
        props.botInfo.templateDesc = res.data.templateDesc;
        props.botInfo.templateValue = res.data.templateValue;
        highType.value = true;
        message.success(t("bot.bot18"));
      }
    })
    .catch((err) => {
      perLoading.value = false;
    });
};

// 校验规则
const rules = ref({
  templateName: [{ required: true, message: t("bot.bot19") }],
  templateValue: [{ required: true, message: t("bot.bot20") }],
});
// 提问灵感
const inputValue = ref("");
const dynamicTags = ref(props.botInfo.suggestQuestion || []);
watch(
  () => props.botInfo.suggestQuestion,
  (newVal) => {
    dynamicTags.value = newVal;
  }
);
const inputVisible = ref(false);
const InputRef = ref(null);
const handleClose = (tag) => {
  dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1);
};
const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    if (InputRef.value) {
      InputRef.value.focus();
    }
  });
};
const handleInputConfirm = () => {
  if (inputValue.value) {
    dynamicTags.value.push(inputValue.value);
  }
  inputVisible.value = false;
  inputValue.value = "";
};
// 增加成员弹窗
const showTool = ref(false);
const userList = ref({
  departmentList: [],
  userList: [],
  gropuList: [],
}); //回显
const addMember = () => {
  const result = props.botInfo.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    acc[ownerType].push(item);
    return acc;
  }, {});
  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  showTool.value = true;
};
// 确定保存
const saveUser = (arr) => {
  if (props.formType == "add") {
    props.botInfo.shareUserIds = props.botInfo.shareUserIds.concat(arr);
    // 去重
    const uniqueMap = new Map(
      props.botInfo.shareUserIds.map((item) => [item.id, item])
    );
    props.botInfo.shareUserIds = Array.from(uniqueMap.values());
    showTool.value = false;
  } else {
    props.botInfo.shareUserIds = props.botInfo.shareUserIds.concat(arr);
    // 去重
    const uniqueMap = new Map(
      props.botInfo.shareUserIds.map((item) => [item.id, item])
    );
    props.botInfo.shareUserIds = Array.from(uniqueMap.values());
    let addUserIds = [];
    props.botInfo.shareUserIds.forEach((item) => {
      addUserIds.push({
        ownerId: item.id,
        ownerType: item.ownerType,
        type: item.type,
        color: item.color,
      });
    });
    api.bot
      .templateUserAdd({
        userId: userId.value,
        topicId: props.botInfo.templateId,
        addUserIds: addUserIds,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("bot.bot22"));
          showTool.value = false;
        }
      });
  }
};
const closeDialog = () => {
  formRef.value.resetFields();
  emit("closeBtn");
  imgList.value = [];
};
const closeDialog1 = () => {
  emit("closeBtn");
  imgList.value = [];
};
const submitBtn = (formRef) => {
  props.botInfo.userId = userId.value;
  props.botInfo.suggest = dynamicTags.value;
  formRef.validate((valid) => {
    if (valid) {
      emit("submitBtn", props.botInfo);
      imgList.value = [];
    }
  });
};
// 修改确定
const editBtn = (formRef) => {
  props.botInfo.userId = userId.value;
  props.botInfo.suggest = dynamicTags.value;
  formRef.validate((valid) => {
    if (valid) {
      emit("editBtn", props.botInfo);
    }
  });
};
// 用户移除
const removeMember = (item, index) => {
  console.log(item, index);
  if (props.formType == "add") {
    props.botInfo.shareUserIds.splice(index, 1);
  } else {
    api.bot
      .templateUserDel({
        userId: userId.value,
        templateId: props.botInfo.templateId,
        changeOwnerId: item.id,
        changeOwnerType: item.ownerType,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("bot.bot23"));
          props.botInfo.shareUserIds.splice(index, 1);
        }
      });
  }
};
// 图标事件

const imgLoading = ref(false);
const getImgLoading = ref(false);
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          props.botInfo.image_path = res.data;
          console.log(res.data);
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 向左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  props.botInfo.image_path = item.id;
};
// ai生成图片
const addImg = () => {
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: props.botInfo.templateName,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            props.botInfo.image_path = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = true;
    });
};
</script>

<template>
  <div class="addContent">
    <div class="my-header">
      <div>
        {{ props.formType == "add" ? t("bot.bot25") : t("bot.bot26") }}
      </div>
      <el-icon
        class="el-icon--left"
        :class="'close-icon'"
        @click="closeDialog1"
        style="cursor: pointer"
      >
        <Close />
      </el-icon>
    </div>
    <div class="flexDiv">
      <el-form
        ref="formRef"
        :model="props.botInfo"
        :rules="rules"
        label-position="top"
      >
        <el-form-item>
          <div class="flexBetWeenOne">
            <div
              v-for="(item, index) in navList"
              :key="index"
              :class="navActive == index ? 'itemDivNavActive' : 'itemDivNav'"
              @click="changeNav(index)"
            >
              <img :src="item.url" alt="" class="navImg" />
              <div :class="navActive == index ? 'itemTextActive' : 'itemText'">
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-form-item>
        <!-- 名称 -->
        <el-form-item prop="templateName" :label="$t('bot.bot28')">
          <el-input
            v-model="props.botInfo.templateName"
            :placeholder="$t('bot.bot29')"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item prop="templateValue">
          <div class="settingBox">
            <div class="title"><span>*</span>{{ $t("bot.bot30") }}</div>
            <el-button
              :loading="perLoading"
              class="iconBox"
              @click="oneKeyPerfect(props.botInfo.templateValue)"
            >
              <el-icon class="iconSet">
                <EditPen />
              </el-icon>
              {{ $t("bot.bot31") }}
            </el-button>
          </div>
          <el-input
            v-model="props.botInfo.templateValue"
            maxlength="2000"
            :placeholder="$t('bot.bot32')"
            show-word-limit
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 10 }"
            style="color: #a8abb2"
          />
        </el-form-item>
        <!-- 技能 -->
        <el-form-item :label="$t('bot.bot33')">
          <div class="flexBetWeen">
            <div
              v-for="(item, index) in skillList"
              :key="index"
              class="itemSkillNav"
              @click="skillNav(index)"
            >
              <img :src="item.url" alt="" class="skillImg" />
              <div class="itemSkillText">
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('bot.bot34')">
          <div class="flexCenter wid100">
            <el-tooltip popper-class="toolTip" :content="t('bot.bot101')">
              <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
                <div
                  :class="props.botInfo.image_path ? 'txCenter' : 'step2Img'"
                >
                  <img
                    v-if="props.botInfo.image_path == null"
                    :src="bImg"
                    alt=""
                    class="stepImgIcon"
                  />
                  <img
                    v-if="props.botInfo.image_path != null"
                    :src="
                      '/openai/chat/v4/image/get/' + props.botInfo.image_path
                    "
                    alt=""
                    class="stepImgIcons"
                  />
                </div>
              </uploadIndex>
            </el-tooltip>
            <div class="step2RightImg">
              <div class="pa">
                <img
                  :src="leftReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="leftResonIcon"
                  @click="changeLeftReson(resonOne)"
                />
                <div class="imgFlex" ref="resonOne">
                  <div class="getImgLoading" v-if="getImgLoading">
                    <span class="loader"></span>
                  </div>
                  <div
                    v-for="(item, index) in imgList"
                    :key="item"
                    class="itemImgDiv"
                  >
                    <img
                      @click="changeImg(item, index)"
                      class="itemImg"
                      :src="'/openai/chat/v4/image/get/' + item.id"
                      alt=""
                    />
                    <img
                      :src="imgYes"
                      alt=""
                      v-if="imgActive == index"
                      class="imgYes"
                    />
                  </div>
                </div>
                <img
                  :src="rightReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="rightResonIcon"
                  @click="changeRightReson(resonOne)"
                />
              </div>
              <el-button
                class="stepImgAi"
                @click="addImg"
                :loading="imgLoading"
              >
                <div class="aiImgIcon">
                  <img :src="imgAi" alt="" class="wenshengtuImg" />
                </div>
                <div class="aiImgTitle">{{ t("base.base29") }}</div>
              </el-button>
            </div>
          </div>
        </el-form-item>
        <!-- 权限设置 -->
        <el-form-item :label="$t('base.base32')">
          <el-table :data="props.botInfo.shareUserIds" class="tableSet">
            <el-table-column prop="name" :label="$t('base.base34')">
              <template #default="scope">
                <div class="flexCenter1">
                  <div
                    class="userImg"
                    :style="'background-color:' + scope.row.color"
                  >
                    {{ scope.row.name?.slice(0, 1) }}
                  </div>
                  <el-tooltip popper-class="toolTip" :content="scope.row.name">
                    <div class="tableName nameHidden">{{ scope.row.name }}</div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="type" :label="$t('base.base35')">
              <template #default="scope">
                <div v-if="scope.row.type == 1" class="tableName">
                  {{ t("bot.bot37") }}
                </div>
                <div v-else class="tableName">{{ t("bot.bot38") }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('bot.bot39')">
              <template #default="scope">
                <el-button
                  link
                  type="danger"
                  size="small"
                  v-if="scope.row.type == 1"
                  class="deleteText"
                  @click="removeMember(scope.row, scope.$index)"
                  >{{ t("bot.bot40") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="divTop">
            <!-- 添加新成员 -->
            <div class="infoBtn" @click="addMember">
              {{ $t("bot.bot41") }}
            </div>
          </div>
        </el-form-item>
        <div class="hightDiv" @click="highType = !highType">
          <div class="flexCenterHigh">
            <img :src="high" alt="" class="high" />
            <span class="hightTitle">{{ t("bot.bot104") }}</span>
          </div>
          <img :src="botRight" alt="" class="botRight" v-if="!highType" />
          <img :src="botBottom" class="botRight" alt="" v-else />
        </div>
        <el-collapse-transition>
          <div v-if="highType">
            <!-- 介绍 -->
            <el-form-item prop="templateDesc" :label="$t('bot.bot42')">
              <el-input
                v-model="props.botInfo.templateDesc"
                maxlength="2000"
                :placeholder="$t('bot.bot43')"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                show-word-limit
              />
            </el-form-item>
            <!-- 开场白 -->
            <el-form-item :label="$t('bot.bot44')" prop="templateCall">
              <el-input
                v-model="props.botInfo.templateCall"
                maxlength="2000"
                :placeholder="$t('bot.bot45')"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                show-word-limit
              />
            </el-form-item>
            <!-- 提问灵感 -->
            <el-form-item prop="suggest" class="suggest">
              <div class="inspirationBox">
                <div class="title">{{ $t("bot.bot46") }}</div>
                <div class="inspiration">
                  <el-input
                    v-if="inputVisible"
                    maxlength="30"
                    ref="InputRef"
                    v-model="inputValue"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                  />
                  <el-button
                    :disabled="dynamicTags?.length >= 3"
                    v-else
                    @click="showInput"
                    :class="dynamicTags?.length >= 3 ? 'add-tag2' : 'add-tag'"
                  >
                    + {{ $t("bot.bot47") }}
                  </el-button>
                </div>
              </div>
              <div>
                <el-tag
                  v-for="tag in dynamicTags"
                  :key="tag"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                  class="blockTag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
    </div>
    <div class="dialog-footer" v-if="props.formType == 'add'">
      <div @click="closeDialog(formRef)" class="cancel">
        {{ $t("bot.bot48") }}
      </div>
      <div @click="submitBtn(formRef)" class="confirm">
        {{ $t("bot.bot49") }}
      </div>
    </div>
    <div class="dialog-footer" v-if="props.formType == 'edit'">
      <div @click="closeDialog(formRef)" class="cancel">
        {{ $t("bot.bot48") }}
      </div>
      <div @click="editBtn(formRef)" class="confirm">
        {{ $t("bot.bot49") }}
      </div>
    </div>
  </div>
  <!-- 添加成员 -->
  <div class="mask"></div>
  <caseScreen
    v-if="showTool"
    :userList="userList"
    :userObj="userObj"
    :name="props.botInfo.templateName"
    @closeUser="showTool = false"
    @saveUser="saveUser"
  >
  </caseScreen>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.el-tag) {
  width: 300px;
}

:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 0;
}

:deep(.el-select) {
  width: calc(100% - 10px) !important;
}

:deep(.el-select__wrapper) {
  min-height: 40px !important;
  width: calc(100% - 4px) !important;
}

:deep(.el-collapse-item__header) {
  background-color: #f7f8ff;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-dialog) {
  --el-dialog-width: 610px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-overlay) {
  z-index: 60 !important;
}

.suggest {
  margin-bottom: 40px;
  padding: 10px;

  :deep(.el-tag.is-closable) {
    height: 40px;
    border-radius: 16px;
    background-color: #fff !important;
  }

  :deep(.el-tag__content) {
    margin: 0 6px;
    font-size: 15px;
  }

  :deep(.el-tag.el-tag--primary) {
    --el-tag-text-color: #606266 !important;
  }
}

.inspiration {
  :deep(.el-input__wrapper) {
    margin-top: 4px;
    border-radius: 8px;
    height: 30px;
  }
}

.blockTag {
  display: block;
  position: relative;
  height: 40px;
  line-height: center;
  border-radius: 16px;
  width: 510px;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 6px;
  border: solid 1px #dcdfe6;

  :deep(.el-tag__content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    font-size: 16px;
    margin-top: 6px;
  }

  :deep(.el-tag__close) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.add-tag {
  height: 36px;
  border-radius: 12px;
  border: 0px;
  background-color: #eceeff;
  padding: 6px;
  color: #0256ff;
  font-family: "Medium";
  font-size: 18px;
  cursor: pointer;
}

.pa {
  position: relative;
  height: 100%;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}

.add-tag2 {
  height: 30px;
  background-color: #f7f8ff;
  border-radius: 4px;
  padding: 0px 6px;
  color: #a8abb2;
  font-size: 15px;
  margin-top: 4px;
  cursor: pointer;
}

.flexDiv {
  height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
}

.close-icon {
  font-size: 20px;
}

.tagRight {
  margin-right: 6px;
}

.column2 {
  width: 160px;
}

.tableSet {
  height: 230px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.formIcon {
  margin-right: 4px;
  font-size: 18px;
  margin-top: 8px;
}

// 图标
.flexCenter {
  display: flex;
}
.wid100 {
  width: 100%;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 80px;
  border-radius: 18px;
  margin-left: 10px;
  width: 424px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
}

.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #38aee5;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.itemImgDiv {
  position: relative;
}

.itemImg {
  height: 100%;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 210px;
  margin-right: 10px;
  height: 100%;
}

.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 28px;
    height: 28px;
    border: 4px solid #f6f6f6;
    border-bottom-color: #0256ff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}

.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}

.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}

.infoBtn {
  height: 42px;
  width: 100%;
  color: #fff;
  background-color: #3376ff;
  border-radius: 16px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: "Regular";
}

.divTop {
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.btnSpan {
  margin-left: 10px;
}

// 添加成员
.button-new-tag {
  display: flex;
  justify-content: center;
  color: #0256ff;
  font-size: 15px;
  margin-left: 12px;
  width: 82px;
  background-color: #f7f8ff;
  text-align: center;
  padding: 0px 8px;
}

.inspirationBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.collapseBox {
  background-color: #f7f8ff;
}

.title-Icon {
  font-size: 16px;
  color: #8a8a8a;
  display: flex;
  height: 46px;
  line-height: 46px;

  .header-icon {
    color: #3d3d3d;
    width: 21px;
    height: 21px;
    margin: 12px;
  }
}

// 一键完善
.settingBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;

  span {
    color: red;
    margin-right: 4px;
  }

  .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    background-color: #eceeff;
    border-radius: 12px;
    padding: 6px;
    color: #0256ff;
    font-size: 16px;
    font-family: "Medium";
    margin-top: 24px;
    cursor: pointer;
    border: 0px;
    .iconSet {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      background-color: #dbdfff;
      margin-right: 8px;
    }
  }
}
.flexCenter1 {
  display: flex;
  align-items: center;
}
// 选择模式
.flexBetWeen {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .imageBox {
    width: 80px;
    height: 80px;
    border-radius: 18px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 18px;
    }
  }

  .aiBox {
    width: 433px;
    height: 80px;
    padding: 10px 18px 10px 2px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f3fb;
    border-radius: 8px;

    .leftBtn {
      width: 32px;
      height: 42px;
      margin-top: 7px;
      margin-right: 6px;
      z-index: 100;
      cursor: pointer;
      background-color: #fff;
      // 渐变
      box-shadow: 0 0 10px 10px #fff;

      .icon {
        font-size: 24px;
        margin-top: 12px;
      }

      .icon:hover {
        background-color: #f2f3fb;
        border-radius: 50%;
      }
    }

    .rightBtn {
      margin-top: 6px;
      cursor: pointer;
      border-radius: 12px;
      background-color: #eceeff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      padding: 6px 10px;

      .aiImgIcon {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background-color: #dbdfff;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .aiImgTitle {
        font-size: 18px;
        color: #0256ff;
        font-family: "Medium";
      }
    }
  }
}

.flexBetWeenOne {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

// 选中样式
.itemSkillActive {
  padding: 0 12px 0 0;
  margin-right: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #dbdfff;
  background-color: #eceeff;
  cursor: pointer;
}

.itemSkillNav {
  padding: 0 12px 0 0;
  margin-right: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  margin-bottom:10px
}

.skillImg {
  width: 26px;
  height: 26px;
  display: block;
  margin: 6px 12px 6px 12px;
}

.itemDivNavActive {
  width: 49%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  background-color: #eceeff;
  cursor: pointer;
}

.itemDivNav {
  width: 49%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  box-shadow: #dbdfff 0px 0px 8px 0px;
  cursor: pointer;
}

.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.itemSkillTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.itemSkillText {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.navImg {
  width: 38px;
  height: 38px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-family: "Regular";
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-family: "Regular";
    cursor: pointer;
  }
}
.high {
  width: 20px;
  margin-right: 12px;
}
.botRight {
  width: 24px;
}
.hightTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
}
:deep(.el-form-item__label) {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-top: 24px;
  margin-bottom: 17px;
}
.tableName {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.title {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-top: 24px;
  margin-bottom: 17px;
}
:deep(.el-input__inner) {
  font-family: "Regular";
  font-size: 18px;
}
.deleteText {
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}
.hightDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  height: 48px;
  border-radius: 18px;
  padding: 0px 15px;
  margin-top: 24px;
  cursor: pointer;
}
.flexCenterHigh {
  display: flex;
  align-items: center;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  opacity: 0.2;
}
.addContent {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  padding: 30px;
  border-radius: 30px;
}
:deep(.el-input__inner::placeholder) {
  font-family: "Regular";
  font-size: 18px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-family: "Regular";
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-family: "Regular";
  font-size: 18px;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-family: "Semibold";
  margin-right: 6px;
}
.nameHidden {
  max-width: calc(100% - 36px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<template>
    <div class="billContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <ArrowLeftOutlined class="icon" />
                <div>{{ t('bot.bot55') }}</div>
            </div>
            <div class="right" @click="openHistory">{{ t('bot.bot56') }}</div>
        </div>
        <div v-if="type == 'upFile'" class="upFile">
            <UploadDragger v-if="!loading" class="billLeft" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div>
                    <FilePptOutlined class="upFileIcon"/>
                    <div class="upFileText">{{ t('bot.bot63') }}</div>
                </div>
            </UploadDragger>
            <div v-else class="billLeft flexLoading">
                <loading></loading>
                <div class="loadingNow">{{ t('bot.bot64') }}</div>
            </div>
            <div class="billRight">
                <div class="flexBetween">
                    <div class="imgTopFlex">
                        <div class="dr"></div>
                        <div class="imgTitle">{{ t('bot.bot65') }}</div>
                    </div>
                    <div class="flexCenterRight">
                        <div class="exportText" @click="exportText">{{ t('bot.bot66') }}</div>
                    </div>
                </div>
                <div class="rightItem">
                    <markdownChat v-for="item in rightData" :key="item" :message="item"></markdownChat>
                </div>
            </div>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" :title="$t('bot.bot56')" placement="right" :closable="false" :width="500" :open="historyType"
            @close="historyType = false">
            <div>
                <div v-for="item in historyList" :key="item.id" class="historyDiv">
                    <FilePptOutlined class="historyIcon"/>
                    <div class="historyName">{{ item.fileName }}</div>
                    <el-icon class="down" @click="downFile(item)"><Download /></el-icon>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { UploadDragger, Drawer } from "ant-design-vue";
import { FilePptOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const userId = ref(store.state.userId);
const historyType = ref(false);
const type = ref("upFile");
const historyList = ref([]);
const loading = ref(false);
const rightData = ref([]);
const exportId = ref(null);
onMounted(() => {
    getHistory();
})
const downFile = (item) => {
    const imageUrl = item.contentUrl;
    // 创建一个下载链接
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-image.jpg"; // 设置保存的文件名
    a.style.display = "none";
    // 将链接添加到DOM中
    document.body.appendChild(a);
    // 模拟用户点击链接以触发下载
    a.click();
    // 从DOM中移除链接
    document.body.removeChild(a);
};
const customRequest = () => { }
const exportText = () => {
    api.bot
        .ocrHistory({
            userId: userId.value,
            id: exportId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                downFile(res.data[0]);
            }
        });
}
const getHistory = () => {
    api.bot
        .ocrHistory({
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {

                historyList.value = res.data;
            }
        });
}
// 打开历史记录
const openHistory = () => {
    getHistory();
    historyType.value = true;
}
// 上传前校验
const beforeUpload = (file) => {
    loading.value = true;
    api.bot
        .upFileOcr([file], {}).then((res) => {
            loading.value = false;
            if (res.returnCode == 200) {
                rightData.value = res.data;
                exportId.value = res.fileId[0];
            }
        });
};
// 返回上一页
const back = () => {
    router.go(-1);
};

</script>
<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.billContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 30px;

    .left {
        display: flex;
        color: #8a8a8a;
        cursor: pointer;

        .icon {
            margin-top: 4px;
            margin-right: 6px;
            size: 20px;
        }
    }

    .right {
        cursor: pointer;
        color: #3376ff;
        font-weight: 600;
    }
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
}

.historyIcon {
    font-size: 24px;
    margin-right: 10px;
    width: 25px;
    color: #458ae6;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
    cursor: pointer;
}

.historyName {
    width: calc(100% - 70px);
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
}

.billLeft {
    width: 49%;
    background-color: #edf2ff;
    margin-right: 1%;
    border-radius: 16px;
}

.billRight {
    width: 49%;
    padding: 20px;
    background-color: #edf2ff;
    border-radius: 16px;
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #edf2ff;
    border: 0px;
}

.upFileIcon {
    font-size: 80px;
    color: #458ae6;
    margin-bottom: 40px;
    padding-top: 180px;
}

.upFileText {
    font-size: 20px;
    color: #000000;
    font-family: "Regular";
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
}

.rightItem {
    height: calc(100% - 30px);
    overflow: auto;
    padding: 0px 20px;
}

.imgTopFlex {
    display: flex;
    align-items: center;
    height: 30px;
}

.dr {
    width: 2px;
    height: 22px;
    background-color: #0256ff;
    margin-right: 5px;
}

.imgTitle {
    font-size: 18px;
    color: #000000;
    font-family: "Bold";
}

.flexCenterRight {
    display: flex;
    align-items: center;
    height: 30px;
}

.rightIcon {
    color: #0256ff;
    margin-right: 5px;
}

.exportText {
    color: #0256ff;
    font-family: "Regular";
    font-size: 18px;
    cursor: pointer;
}

.flexLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingNow {
    margin-top: 50px;
    font-family: "Regular";
    font-size: 18px;
    color: #000000;
}

.question_span {
    font-size: 18px;
    color: #000000;
    font-family: "Regular";
}
</style>
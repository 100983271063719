<template>
  <div class="rightDivAbs">
    <div class="flexRightCenter">
      <!-- 归档 chat -->
      <el-button
        class="sc"
        v-if="props.chatType == 'chat'"
        :loading="props.deleteLoading"
        @click="deleteSession(1)"
      >
        <img :src="rightGd" alt="" class="marginRight8 rightGd" />
        <div class="gdText">{{ t("base.base236") }}</div>
      </el-button>
      <!-- 收藏 -->
      <el-button
        class="sc"
        v-if="topicItem.isCollected == 0 || topicItem.isCollected == null"
        @click="likeFn(0)"
      >
        <img :src="scSvg" alt="" class="marginRight8 wid14" />
        <div>{{ t("base.base100") }}</div>
      </el-button>
      <!-- 取消收藏-->
      <el-button
        class="color3 sc"
        v-if="topicItem.isCollected == 1"
        @click="likeFn(1)"
      >
        <img :src="scNoSvg" alt="" class="marginRight8 wid14" />
        <div>{{ t("base.base198") }}</div>
      </el-button>
      <!-- 请求验证话题 base -->
      <el-button
        class="color2 sc"
        @click="openCap"
        v-if="props.chatType == 'base'"
        :disabled="getLoading()"
      >
        <img :src="setCap" alt="" class="marginRight8 wid21" />
        <div>{{ t("base.base238") }}</div>
      </el-button>
      <!-- 撤回验证请求 -->
      <!-- <div class="color3 sc" @click="openCap" v-if="props.chatType == 'base'">
              <img :src="setNoCap" alt="" class="marginRight8 wid21" />
              <div>{{ t('base.base239') }}</div>
            </div> -->
      <!-- 知识库 收藏 清除 -->
      <el-dropdown @command="deleteSession" v-if="props.chatType == 'base'">
        <div class="iconRightCenter">
          <img :src="moreSvg" alt="" class="wid18" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- 归档 -->
            <el-dropdown-item :command="1">
              <div class="dropdownItem">
                <img :src="rightGd" alt="" class="marginRight8 rightGd" />
                <div class="gdText">{{ t("base.base236") }}</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="iconRightCenter" @click="closeRight">
        <img :src="sqRightSvg" alt="" class="wid20" />
      </div>
      <!-- 弹窗验证 -->
      <div v-if="props.capDialog" class="capDialogClass">
        <div class="capTitle">
          <div>{{ t("base.base238") }}</div>
          <img class="crossSvug" :src="crossSvug" alt="" @click="closeCap" />
        </div>
        <div class="labelItem">
          <span class="capLabel">{{ t("base.base240") }}</span>
          <span class="labelTip">{{ t("base.base241") }}</span>
        </div>
        <div>
          <el-select
            v-model="treeData"
            multiple
            :placeholder="t('base.base242')"
          >
            <el-option
              v-for="item in props.chatList"
              :key="item.conversationId"
              :label="item.question"
              :value="item.conversationId"
            >
              <el-tooltip popper-class="toolTip" :content="item.question">
                <span>{{
                  item.question.length > 35
                    ? item.question.slice(0, 35) + "..."
                    : item.question
                }}</span>
              </el-tooltip>
            </el-option>
          </el-select>
        </div>
        <div class="labelItem">
          <span class="capLabel">{{ t("base.base208") }}</span>
        </div>
        <div>
          <el-select
            v-model="peopleSel"
            multiple
            :placeholder="t('base.base209')"
          >
            <el-option
              v-for="item in props.seleteUserInfo"
              :key="item.ownerId"
              :label="item.ownerName"
              :value="item.ownerId"
            >
              <span class="dropText">{{ item.ownerName }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="labelItem">{{ t("base.base210") }}</div>
        <el-input
          :placeholder="t('base.base211')"
          show-word-limit
          class="catText"
          type="textarea"
          v-model="leftNotes"
        />
        <div class="capBtnDiv">
          <el-button class="capCancel" @click="closeCap">{{
            t("base.base45")
          }}</el-button>
          <el-button
            class="capSave"
            :loading="props.requestverifyLoading"
            @click="saveCap"
            >{{ t("base.base212") }}</el-button
          >
        </div>
      </div>
    </div>
    <div class="hrefDiv" ref="aRef" @scroll="loadList">
      <el-anchor
        :container="props.containerRef"
        direction="vertical"
        type="underline"
        :offset="50"
        @click="handleClick"
      >
        <el-anchor-link
          :href="'#talk' + item.conversationId"
          :title="item.question"
          v-for="(item, index) in props.chatList"
          :key="index"
        />
      </el-anchor>
    </div>
  </div>
</template>

<script setup>
import rightGd from "@/assets/chat/rightGd.svg";
import scSvg from "@/assets/chat/sc.svg";
import scNoSvg from "@/assets/chat/noSc.svg";
import setCap from "@/assets/chat/setCap.svg";
import setNoCap from "@/assets/chat/setNoCap.svg";
import moreSvg from "@/assets/chat/more.svg";
import sqRightSvg from "@/assets/chat/sqRight.svg";
import crossSvug from "@/assets/chat/cross.svg";
import { ref, onUpdated, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const peopleSel = ref([]);
const leftNotes = ref("");
const treeData = ref([]);
const props = defineProps([
  "chatType",
  "containerRef",
  "requestverifyLoading",
  "capDialog",
  "chatList",
  "deleteLoading",
  "topicItem",
  "seleteUserInfo",
  'pageId'
]);
const emit = defineEmits([
  "handleClick",
  "deleteSession",
  "likeFn",
  "openCap",
  "closeRight",
  "closeCap",
  "saveCap",
  "loadList",
]);
const loadList = (e) => {
  if (scrollType.value) {
    scrollType.value = false;
    return;
  }
  const scrollTop = e.target.scrollTop;
  if (scrollTop == 0) {
    emit("loadList");
  }
};
const scrollType = ref(true);
onMounted(() => {
  scrollType.value = true;
  scrollBottom();
});
onUpdated(() => {
  scrollBottom();
});
const aRef = ref(null);
const scrollBottom = () => {
  if (props.pageId !=1) return
  nextTick(() => {
    aRef.value.scrollTop = aRef.value.scrollHeight;
  });
};
const handleClick = (e) => {
  emit("handleClick", e);
};
const deleteSession = (e) => {
  emit("deleteSession", e);
};
const likeFn = (e) => {
  emit("likeFn", e);
};
const openCap = () => {
  treeData.value = [];
  peopleSel.value = [];
  leftNotes.value = "";
  emit("openCap");
};
const closeRight = () => {
  emit("closeRight");
};
const closeCap = () => {
  emit("closeCap");
};
const saveCap = () => {
  emit("saveCap", treeData.value, peopleSel.value, leftNotes.value);
};
const getLoading = () => {
  return props.chatList.some((item) => item.loading === true);
};
</script>

<style lang="scss" scoped>
.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 401px;
  height: calc(100% - 60px);
}
.flexRightCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.marginRight8 {
  margin-right: 8px;
}
.rightGd {
  width: 20px;
}
.gdText {
  color: #f02d63;
}
.wid14 {
  width: 14px;
}
.color3 {
  color: #f02d63;
}
.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
  height: 48px;
  margin-left: 0px !important;
}
.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}
.wid21 {
  width: 21px;
}
.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.wid18 {
  width: 18px;
}
.dropdownItem {
  font-size: 18px;
  font-family: "Regular";
  display: flex;
  align-items: center;
  justify-content: center;
}

.wid20 {
  width: 20px;
}
.capDialogClass {
  position: absolute;
  right: 100px;
  bottom: -550px;
  width: 566px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 40;
  padding: 17px;
  cursor: default;
}
.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}
.crossSvug {
  width: 24px;
  cursor: pointer;
}
.labelItem {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}
.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}
.labelTip {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-family: "Regular";
  padding-left: 10px;
}

.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-family: "Regular";
    font-size: 18px;
  }
}
.capBtnDiv {
  text-align: right;
}

.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  padding: 10px 20px;
}

.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 160px);
  overflow: auto;
}
</style>

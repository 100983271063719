<template>
  <div
    :class="{
      historyDialog: props.rightType && props.historyDialog, //俩都展示
      hisOne: !props.rightType && props.historyDialog, //志站是一个
    }"
  >
    <div class="historyTopDiv">
      <div>{{ t("base.base199") }}</div>
      <div @click="closeHistoryClick" class="closeDiv">
        <img :src="closeHistory" class="historyCloseIcon" alt="" />
      </div>
    </div>
    <div class="hisToryItem">
      <!-- 提问 -->
      <div class="hisBetween">
        <div class="hisCenter">
          <div
            class="txImg"
            :style="
              'background-color:' +
              props.historyList.answers[props.historyList.chatActive - 1].color
            "
          >
            {{
              props.historyList.answers[
                props.historyList.chatActive - 1
              ].userName.slice(0, 1)
            }}
          </div>
          <div class="userNmae">
            {{
              props.historyList.answers[props.historyList.chatActive - 1]
                .userName
            }}
            {{ t("base.base232") }}
          </div>
        </div>
        <div class="historyTime">
          {{ getTime(props.historyList.questionCreateTime) }}
        </div>
      </div>
      <!-- 问题 -->
      <div class="hisQ hisCenter">
        {{ props.historyList.question }}
      </div>
      <!-- 回大人 -->
      <div class="hisBetween">
        <div class="hisCenter">
          <div class="txImg" :style="'background-color:#0256ff'">N</div>
          <div class="userNmae">{{ t("base.base233") }}</div>
        </div>
        <div class="historyTime">
          {{ getTime(props.historyList.answerCreatTime) }}
        </div>
      </div>
      <!-- 回答 -->
      <div class="hisQ">
        <div class="fillCenter" v-if="props.historyList.answers.length > 1">
          <img
            :src="props.historyList.chatActive != 1 ? leftFill : leftNoFill"
            alt=""
            class="fillImg"
            @click="changeItemPage(false)"
          />
          <span
            :class="props.historyList.chatActive == 1 ? 'fillColor' : ''"
            class="fillText"
            >{{ props.historyList.chatActive }}</span
          >
          <span
            class="fillText"
            :class="
              props.historyList.chatActive == props.historyList.answers.length
                ? 'fillColor'
                : ''
            "
            >/{{ props.historyList.answers.length }}</span
          >
          <img
            :src="
              props.historyList.chatActive == props.historyList.answers.length
                ? rightNoFill
                : rightFill
            "
            alt=""
            class="fillImg"
            @click="changeItemPage(true)"
          />
        </div>
        <div
          :class="
            props.historyList.answers.length > 1
              ? 'text92 historyItemText'
              : 'historyItemText'
          "
        >
          <messageView
            :message="
              props.historyList.answers[props.historyList.chatActive - 1]
                .answerContent
            "
          ></messageView>
        </div>
      </div>
      <!-- Ai微模型来源 -->
      <div
        class="userNmae marginHis10"
        v-if="
          props.historyList.answers[props.historyList.chatActive - 1]
            .answerResource.fileResource.length != 0
        "
      >
        {{ t("base.base234") }}
      </div>
      <div
        v-for="(items, index) in props.historyList.answers[
          props.historyList.chatActive - 1
        ].answerResource.fileResource"
        :key="index"
        class="hisItemText"
      >
        <div class="dcflex">
          <span class="marginRightHistory">[{{ index + 1 }}]</span>
          <span class="marginRightHistory">{{ items.fileName }}</span>
        </div>
      </div>
      <div
        class="userNmae marginHis10"
        v-if="
          props.historyList.answers[props.historyList.chatActive - 1]
            .answerResource.sessionResource.length != 0
        "
      >
        {{ t("base.base311") }}
      </div>
      <div
        v-for="(items, index) in props.historyList.answers[
          props.historyList.chatActive - 1
        ].answerResource.sessionResource"
        :key="index"
        class="hisItemText"
      >
        <div class="dcflex">
          <span class="marginRightHistory">[{{ index + 1 }}]</span>
          <span class="marginRightHistory">{{ items.sessionName }}</span>
        </div>
      </div>
      <div
        class="userNmae marginHis10"
        v-if="
          props.historyList.answers[props.historyList.chatActive - 1]
            .answerResource.internetResource.length != 0
        "
      >
        {{ t("base.base235") }}
      </div>
      <div
        v-for="(items, index) in props.historyList.answers[
          props.historyList.chatActive - 1
        ].answerResource.internetResource"
        :key="index"
        class="hisItemText"
      >
        <div class="dcflex">
          <span class="marginRightHistory">[{{ index + 1 }}]</span>
          <span class="marginRightHistory">{{ items.internetUrl }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import closeHistory from "@/assets/chat/closeHistory.svg";
import { getTime } from "@/utils/utils";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import messageView from "@/components/message/index.vue";
const props = defineProps(["rightType", "historyDialog", "historyList"]);
const emit = defineEmits(["closeHistoryClick", "changeItemPage"]);
const closeHistoryClick = () => {
  emit("closeHistoryClick");
};
const changeItemPage = (type) => {
  emit("changeItemPage", type);
};
</script>

<style lang="scss" scoped>
.historyTopDiv {
  font-size: 20px;
  height: 29px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.historyDialog {
  position: absolute;
  right: 444px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}
.hisOne {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}
.closeDiv {
  cursor: pointer;
}
.historyCloseIcon {
  width: 14px;
  cursor: pointer;
}
.hisToryItem {
  height: calc(100% - 60px);
  margin-top: 15px;
  overflow: auto;
}
.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hisCenter {
  display: flex;
  align-items: center;
}
.txImg {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-family: "Regular";
}
.userNmae {
  color: #3d3d3d;
  font-family: "Medium";
  font-size: 18px;
}
.historyTime {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 16px;
}
.hisQ {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}

.hisItemText {
  font-size: 17px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-top: 2px;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}

.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fillColor {
  color: #d9d9d9;
}
.text92 {
  text-indent: 92px;
}
.historyItemText {
  color: #000;
  font-family: "Regular";
  font-size: 18px;
}
.marginHis10 {
  margin: 10px 0px;
}
.dcflex {
  word-wrap: break-word;
}
.marginRightHistory {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>

export default {
    CN: {
        team: '团队',
        department: '用户与部门',
        group: '群组管理',
        role: '角色管理',
        resoure: '资源管理',
        knowledge: '知识',
        tipic: '话题管理',
        micromodel: '知识库管理',
        bot: '智能体管理',
        train: '训练中心',
        deploy: '部署',
        accredit: '平台授权',
        base: '基础配置',
        log: '平台日志',
        qincheng: '沁诚信息',
        userForm: {
            userName: '用户名',
            isActive: '状态',
            email: '邮箱',
            departmentName: '部门名称',
            operation: '操作',
            address: '地址',
            phone: '手机号',
            roleName: '角色名称',
            sex: '性别',
            supervisorName: '直属上级',
            DepartmentHead: '部门负责人',

            // 表头
            user1: '用户',
            user2: '部门',
            user3: '部门名称修改成功',
            user4: '部门删除成功',
            user5: '部门修改成功',
            user6: '部门新增成功',
            user7: '子部门新增成功',
            user8: '请输入用户名或邮箱',
            user9: '搜索部门名',
            user10: '部门导出',
            user11: '新建部门',
            user12: '修改名称',
            user13: '删除部门',
            user14: '部门用户数',
            user15: '部门直属用户',
            user16: '操作',
            user17: '详情',
            user18: '新增子部门',
            user19: '编辑',
            user20: '删除',
            user21: '部门详情',
            user22: '部门名称',
            user23: '上级部门',
            user24: '关闭详情',
            user25: '编辑部门',
            user26: '请输入部门名称',
            user27: '请选择上级部门',
            user28: '请输入子部门名称',
            user29: '部门新增',
            user30: '部门编辑',
            user31: '子部门名称',
            user32: '请输入子部门名称',
            user33: '部门名称',
            user34: '请输入部门名称',
            user35: '放弃',
            user36: '提交',
            user37: '取消',
            user38: '确定',
            user39: '添加',
            user40: '请输入用户名',
            user41: '请输入正确的邮箱地址',
            user42: '请输入邮箱地址',
            user43: '请输入手机号',
            user44: '手机号格式不正确',
            user45: '请输入地址',
            user46: '请选择性别',
            user47: '请选择是否发送邮件',
            user48: '请选择角色',
            user49: '请选择部门',
            user50: '新增用户',
            user51: '编辑用户',
            user52: '启用',
            user53: '禁用',
            user54: '男',
            user55: '女',
            user56: '通过邮箱将登录信息发送给用户',
            user57: '密码',
            user58: '自动创建密码',
            user59: '请输入新密码',
            user60: '群组',
            user61: '角色',
            user62: '用户详情',
            user63: '编辑用户',
            user64: '部门名称编辑',
            user65: '重置密码',
            user66: '密码',
            user67: '密码不能小于6位',
            user68: '密码重置成功',
            user69: '你已成功为此用户重置密码',
            user70: '全部',
            user71: '展示全部用户',
            user72: '仅展示部门直属',
            user73: '请选择要禁用的用户',
            user74: '确定要禁用这些用户吗？',
            user75: '提示',
            user76: '禁用成功',
            user77: '修改成功',
            user78: '新增成功',
            user79: '禁用失败',
            user80: '启用成功',
            user81: '总用户',
            user82: '启用用户',
            user83: '批量禁用',
            user84: '批量新增',
            user85: '添加用户',
            user86: '账号状态',
            user87: '禁用用户',
            user88: '表格显示',
            user89: '添加成员',
            user90: '搜索群组名或群组成员',
            user91: '文件类型仅支持xlsx版本,请使用规定模板进行上传。',
            user92: '请选择文件后再上传',
            user93: '上传成功',
            user94: '单击或拖动文件到此区域进行上传',
            user95: '仅支持单个文件上传',
            user96: '下载模板',
            user97: '上传文件',
            user98: '添加群组',
            user99: '选择群组',
            user100: '密码不能为空',
        },
        groupForm: {
            group1: '移除成功',
            group2: '请选择要移除的群组用户',
            group3: "确定要移除这些群组用户吗？",
            group4: '已取消移除',
            group5: '确定要解散该群组吗？',
            group6: '解散成功',
            group7: '添加成功',
            group8: '搜索群组名称',
            group9: '群组详情',
            group10: '编辑群组',
            group11: '解散群组',
            group12: '新建群组',
            group13: "成员人数",
            group14: '批量移除',
            group15: '添加成员',
            group16: '群组名称',
            group17: '群组描述',
            group18: '群组成员',
            group19: '请输入群组名称',
            group20: '请输入群组描述',
            group21: '请选择群组成员',
            group22: '修改成功',
            group23: '群组新增',
            group24: "群组编辑",
            group25: "移除",
        },
        roleForm: {
            role1: '用户名',
            role2: '角色名',
            role3: '请选择要禁用的角色',
            role4: '确定要禁用这些角色吗？',
            role5: '提示',
            role6: '确定',
            role7: '取消',
            role8: '禁用成功',
            role9: '超级管理员和系统管理员不能被删除',
            role10: '删除成功',
            role11: '启用成功',
            role12: '用户添加成功',
            role13: '请输入搜索信息',
            role14: '导出角色详情',
            role15: '批量禁用',
            role16: '创建角色',
            role17: '角色名称',
            role18: '成员',
            role19: '作用域',
            role20: '权限范围',
            role21: '状态',
            role22: '启用',
            role23: '禁用',
            role24: '详情',
            role25: '添加成员',
            role26: '编辑角色',
            role27: '禁用角色',
            role28: '启用角色',
            role29: '删除角色',
            role30: '角色名称不能为空',
            role31: '角色描述不能为空',
            role32: '修改成功',
            role33: '新增成功',
            role34: '新建角色',
            role35: '编辑角色',
            role36: '角色名称',
            role37: '角色简介',
            role38: '请输入角色简介',
            role39: '编辑成员',
            role40: '用户名/手机号/邮箱',
            role41: '部门名称',
            role42: '配置作用域',
            role43: '请选择',
            role44: '配置权限范围',
            role45: '角色详情',
            role46: '成员',
            role47: '部门',
            role48: '关闭详情',
            role49: '编辑角色',
        },
        resoureForm: {
            resoure1: '部门',
            resoure2: '白名单',
            resoure3: '编辑成功',
            resoure4: '新增成功',
            resoure5: '群组添加成功',
            resoure6: '删除成功',
            resoure7: '禁用成功',
            resoure8: '启用成功',
            resoure9: '请选择要禁用的数据',
            resoure10: '确定要禁用这些白名单吗？',
            resoure11: '提示',
            resoure12: '确定',
            resoure13: '取消',
            resoure14: '禁用成功',
            resoure15: '禁用失败',
            resoure16: '请选择要删除的数据',
            resoure17: '确定要删除这些白名单吗？',
            resoure18: '删除成功',
            resoure19: '删除失败',
            resoure20: '资源配额优先级规则：白名单配额 > 部门配额',
            resoure21: '批量禁用',
            resoure22: '批量删除',
            resoure23: '添加白名单',
            resoure24: '白名单名称',
            resoure25: '成员',
            resoure26: '详情',
            resoure27: '操作',
            resoure28: '添加群组',
            resoure29: '配置资源',
            resoure30: '编辑',
            resoure31: '删除',
            resoure32: '禁用',
            resoure33: '启用',
            resoure34: '全部',
            resoure35: '阿里',
            resoure36: 'baidu',
            resoure37: 'Azure',
            resoure38: '请选择要禁用的资源',
            resoure39: '确定要禁用这些资源吗？',
            resoure40: '提示',
            resoure41: '确定',
            resoure42: '取消',
            resoure43: '禁用成功',
            resoure44: '禁用成功',
            resoure45: '启用成功',
            resoure46: '配额编辑成功',
            resoure47: '该资源平台禁用成功',
            resoure48: '该资源平台启用成功',
            resoure49: '资源平台',
            resoure50: '启用当前资源平台',
            resoure51: '禁用当前资源平台',
            resoure52: '批量禁用',
            resoure53: '完成编辑',
            resoure54: '编辑配额',
            resoure55: '模型类型',
            resoure56: '资源平台',
            resoure57: '其他',
            resoure58: '功能类型',
            resoure59: '状态',
            resoure60: '启用',
            resoure61: '禁用',
            resoure62: '无限',
            resoure63: '继承配额',
            resoure64: '用户日配额',
            resoure65: '幅',
            resoure66: '个',
            resoure67: '令牌',
            resoure68: '白名单名称不能为空',
            resoure69: '白名单描述不能为空',
            resoure70: '群组不能为空',
            resoure71: '资源配置不能为空',
            resoure72: '请先保存当前编辑',
            resoure73: '新建',
            resoure74: '白名单',
            resoure75: '白名单名称',
            resoure76: '白名单描述',
            resoure77: '所属群组',
            resoure78: '配置资源',
            resoure79: '编辑配额',
            resoure80: '完成编辑',
            resoure81: '资源名称',
            resoure82: '资源类型',
            resoure83: '用户日配额',
            resoure84: '白名单详情',
            resoure85: '成员',
            resoure86: '群组名',
            resoure87: '操作',
            resoure88: '移除',
            resoure89: '配置资源',
            resoure90: '关闭详情',
            resoure91: '编辑资源组',
            resoure92: '编辑白名单',
        },
        topicForm: {
            topic1: '标题关键词',
            topic2: '归档成功',
            topic3: '删除成功',
            topic4: '请选择要归档的项',
            topic5: '请选择要删除的项',
            topic6: '恢复成功',
            topic7: '请选择要恢复的项',
            topic8: '请输入搜索信息',
            topic9: '起止时间',
            topic10: '至',
            topic11: '开始时间',
            topic12: '结束时间',
            topic13: '批量归档',
            topic14: '批量删除',
            topic15: '提问者',
            topic16: '标题',
            topic17: '问答数',
            topic18: '话题类型',
            topic19: '创建时间',
            topic20: '最近更新',
            topic21: '操作',
            topic22: '归档',
            topic23: '删除',
            topic24: '回收站',
            topic25: '批量恢复',
            topic26: '恢复',
            topic27: '返回',
        },
        botForm: {
            bot1: '用户名',
            bot2: '智能体名称',
            bot3: '归档成功',
            bot4: '删除成功',
            bot5: '请选择要归档的智能体',
            bot6: '归档成功',
            bot7: '请选择要删除的智能体',
            bot8: '恢复成功',
            bot9: '请选择要恢复的项',
            bot10: '拥有者',
            bot11: '成员数',
            bot12: '智能体类型',
            bot13: '智能助手',
            bot14: '话题数',
            bot15: '创建时间',
            bot16: '更新时间',
            bot17: '操作',
            bot18: '归档',
            bot19: '删除',
            bot20: '回收站',
            bot21: '恢复',
            bot22: '返回',
        },
        micromodelForm: {
            model1: '用户名',
            model2: '知识库名称',
            model3: '拥有者',
            model4: '成员数',
            model5: '知识库名称',
            model6: '知识库名称',
            model7: '知识库名称',
            model8: '知识库名称',
            model9: '文档数/权威',
            model11: '话题数/权威/热门',
            model12: '热门',
            model13: '创建时间',
            model14: '更新时间',
            model15: '操作',
        },
        logForm: {
            log1: '导出失败',
            log2: '用户名/邮箱',
            log3: '至',
            log4: '开始日期',
            log5: '结束日期',
            log6: '全部操作',
            log7: '全部模块',
            log8: '目前仅支持审计30日内平台日志',
            log9: '导出',
            log10: '操作时间',
            log11: '用户名',
            log12: '邮箱',
            log13: 'IP地址',
            log14: '部门',
            log15: '操作类型',
            log16: '所属模块',
            log17: '操作内容',
            log18: '操作结果',
            log19: '表格控制',
            log20: '角色',
            log21: '最多添加4个筛选条件',
            log22: '成功',
            log23: '失败',
            log24: '筛选条件',
            log25: '选择筛选条件',
            log26: '请选择',
            log27: '添加筛选项',
            log28: '取消',
            log29: '确定',
            log30: '请输入用户名或邮箱',
        },
        accrditForm: {
            accrdit1: '上传成功',
            accrdit2: '订阅时长',
            accrdit3: '已使用时长（天）/总订阅时长（天）',
            accrdit4: '联系NextAI官方订阅续期',
            accrdit5: '用户席位',
            accrdit6: '已使用席位/总用户席位',
            accrdit7: '产品名',
            accrdit8: '正式版',
            accrdit9: '版本信息',
            accrdit10: '稳定版',
            accrdit11: '席位信息',
            accrdit12: '剩余',
            accrdit13: '订阅时长',
            accrdit14: '激活码',
            accrdit15: '激活状态',
            accrdit16: '已激活',
            accrdit17: '未激活',
            accrdit18: '激活日期',
            accrdit19: '失效日期',
            accrdit20: '访问产品官网',
            accrdit21: '席位',
            accrdit22: '天',
        },
        baseForm: {
            base1: '请输入资源组名',
            base2: '新建资源组',
            base3: '资源组名称',
            base4: '资源组名称',
            base5: '放弃',
            base6: '创建',
            base7: '功能类型不能为空',
            base8: '配置名称不能为空',
            base9: '模型名称不能为空',
            base10: '模型类型不能为空',
            base11: '终结点不能为空',
            base12: '模型版本不能为空',
            base13: 'api密钥不能为空',
            base14: '终结点不能为空',
            base15: '连接字符串不能为空',
            base16: '账号密钥不能为空',
            base17: '账号终结点不能为空',
            base18: '密钥不能为空',
            base19: '终结点不能为空',
            base20: '接入密钥ID不能为空',
            base21: '接入关键密钥不能为空',
            base22: '接入密钥不能为空',
            base23: '终结点不能为空',
            base24: '添加成功',
            base25: '资源配置',
            base26: '新建',
            base27: '修改',
            base28: '资源平台',
            base29: '配置名称',
            base30: '功能类型',
            base31: '请选择功能类型',
            base32: '模型类型',
            base33: '请选择模型类型',
            base34: '模型名称',
            base35: '终结点',
            base36: '模型版本',
            base37: 'api密钥',
            base38: '连接字符串',
            base39: '账号密钥',
            base40: '账号终结点',
            base41: '接入密钥',
            base42: '密钥',
            base43: '接入密钥ID',
            base44: '接入关键密钥',
            base45: '取消',
            base46: '确定',
            base47: '百度平台账单配置',
            base48: '阿里云平台账单配置',
            base49: '平台账单配置',
            base50: '开始时间',
            base51: '确认删除资源组',
            base52: '一旦删除资源组，该资源组所属的所有资源配置将被一并抹除，确认删除当前资源组吗？',
            base53: 'SAML认证',
            base54: '查看帮助文档',
            base55: '资源提供方映射信息',
            base56: '下载元数据',
            base57: '认证提供方映射信息',
            base58: '上传元数据',
            base59: '验证并保存',
            base60: '无法删除资源组',
            base61: '为了防止误操作，只有资源配置为空的资源组方可被删除，您需要手动前往清空资源组的资源配置吗？',
            base62: '前往清空',
            base63: '修改成功',
            base64: '新增成功',
            base65: '默认设置成功',
            base66: '删除成功',
            base67: '资源组',
            base68: '资源组仅用于分配各项模型资源及用量统计，资源组之间的应用范围不可相互重叠',
            base69: '新建资源组',
            base70: '先设定资源组，再给资源组配置资源',
            base71: '应用范围',
            base72: '内存配置',
            base73: '为了保证您的使用体验，我们建议您开放1-2GB Redis内存',
            base74: '确定修改',
            base75: 'Redis 内存配置',
            base76: '个人话题归档提醒时长',
            base77: '系统仅留存自最新一次对话时间起30日内的个人话题数据（知识库话题除外），我们建议您开放至少3天提醒时长，提醒用户在话题归档前保留重要数据',
            base78: '天',
            base79: '资源平台账单配置',
            base80: '仪表盘调取对应资源平台账单API所必须的参数，已部署的资源平台显示绿色圆点',
            base81: 'SSO账号登录',
            base82: '跨平台账号认证方式，使用您习惯的企业通行证登录NextAI客户端，验证成功的认证方式显示绿色圆点',
            base83: '请输入名称',
            base84: '应用范围',
            base85: '勾选本资源组需要应用的部门/子部门',
            base86: '资源配置',
            base87: '添加资源配置',
            base88: '模型',
            base89: '默认',
            base90: '设为默认',
            base91: '确认修改',
            base92: '修改AccessKey、SecretKey是对资源组的重大修改，您需要重新配置所有调用该配置组合的资源，确认修改吗？',
            base93: '资源ID',
            base94: '请输入资源ID',
            base95: '我们在仪表盘中将根据该资源ID追踪您的成本消耗',
        }
    },
    HK: {
        team: '團隊',
        department: '用戶與部門',
        group: '群組管理',
        role: '角色管理',
        resoure: '資源管理',
        knowledge: '知識',
        tipic: '話題管理',
        micromodel: '知識庫管理',
        bot: '智慧體管理',
        train: '訓練中心',
        deploy: '部署',
        accredit: '平臺授權',
        base: '基礎配置',
        log: '平臺日誌',
        qincheng: '沁誠信息',
        userForm: {
            userName: '用戶名',
            isActive: '狀態',
            email: '郵箱',
            departmentName: '部門名稱',
            operation: '操作',
            address: '地址',
            phone: '手機號',
            roleName: '角色名稱',
            sex: '性別',
            supervisorName: '直屬上級',
            DepartmentHead: '部門負責人',

            // 表頭
            user1: '用戶',
            user2: '部門',
            user3: '部門名稱修改成功',
            user4: '部門刪除成功',
            user5: '部門修改成功',
            user6: '部門新增成功',
            user7: '子部門新增成功',
            user8: '請輸入用戶名或郵箱',
            user9: '搜索部門名',
            user10: '部門匯出',
            user11: '新建部門',
            user12: '修改名稱',
            user13: '刪除部門',
            user14: '部門用戶數',
            user15: '部門直屬用戶',
            user16: '操作',
            user17: '詳情',
            user18: '新增子部門',
            user19: '編輯',
            user20: '刪除',
            user21: '部門詳情',
            user22: '部門名稱',
            user23: '上級部門',
            user24: '關閉詳情',
            user25: '編輯部門',
            user26: '請輸入部門名稱',
            user27: '請選擇上級部門',
            user28: '請輸入子部門名稱',
            user29: '部門新增',
            user30: '部門編輯',
            user31: '子部門名稱',
            user32: '請輸入子部門名稱',
            user33: '部門名稱',
            user34: '請輸入部門名稱',
            user35: '放棄',
            user36: '提交',
            user37: '取消',
            user38: '確定',
            user39: '添加',
            user40: '請輸入用戶名',
            user41: '請輸入正確的郵箱位址',
            user42: '請輸入郵箱位址',
            user43: '請輸入手機號',
            user44: '手機號格式不正確',
            user45: '請輸入位址',
            user46: '請選擇性別',
            user47: '請選擇是否發送郵件',
            user48: '請選擇角色',
            user49: '請選擇部門',
            user50: '新增用戶',
            user51: '編輯用戶',
            user52: '啟用',
            user53: '禁用',
            user54: '男',
            user55: '女',
            user56: '通過郵箱將登錄資訊發送給使用者',
            user57: '密碼',
            user58: '自動創建密碼',
            user59: '請輸入新密碼',
            user60: '群組',
            user61: '角色',
            user62: '用戶詳情',
            user63: '編輯用戶',
            user64: '部門名稱編輯',
            user65: '重置密碼',
            user66: '密碼',
            user67: '密碼不能小於6位元',
            user68: '密碼重置成功',
            user69: '你已成功為此使用者重置密碼',
            user70: '全部',
            user71: '展示全部使用者',
            user72: '僅展示部門直屬',
            user73: '請選擇要禁用的用戶',
            user74: '確定要禁用這些用戶嗎？',
            user75: '提示',
            user76: '禁用成功',
            user77: '修改成功',
            user78: '新增成功',
            user79: '禁用失敗',
            user80: '啟用成功',
            user81: '總用戶',
            user82: '啟用用戶',
            user83: '批量禁用',
            user84: '批量新增',
            user85: '添加用戶',
            user86: '帳號狀態',
            user87: '禁用用戶',
            user88: '表格顯示',
            user89: '添加成員',
            user90: '搜索群組名或群組成員',
            user91: '檔案類型僅支持xlsx版本,請使用規定範本進行上傳。',
            user92: '請選擇檔後再上傳',
            user93: '上傳成功',
            user94: '按一下或拖動檔到此區域進行上傳',
            user95: '僅支援單個檔上傳',
            user96: '下載範本',
            user97: '上傳文件',
            user98: '添加群組',
            user99: '選擇群組',
        },
        groupForm: {
            group1: '移除成功',
            group2: '請選擇要移除的群組用戶',
            group3: "確定要移除這些群組用戶嗎？",
            group4: '已取消移除',
            group5: '確定要解散該群組嗎？',
            group6: '解散成功',
            group7: '添加成功',
            group8: '搜索群組名稱',
            group9: '群組詳情',
            group10: '編輯群組',
            group11: '解散群組',
            group12: '新建群組',
            group13: "成員人數",
            group14: '批量移除',
            group15: '添加成員',
            group16: '群組名稱',
            group17: '群組描述',
            group18: '群組成員',
            group19: '請輸入群組名稱',
            group20: '請輸入群組描述',
            group21: '請選擇群組成員',
            group22: '修改成功',
            group23: '群組新增',
            group24: "群組編輯",
            group25: "移除",
        },
        roleForm: {
            role1: '用戶名',
            role2: '角色名',
            role3: '請選擇要禁用的角色',
            role4: '確定要禁用這些角色嗎？',
            role5: '提示',
            role6: '確定',
            role7: '取消',
            role8: '禁用成功',
            role9: '超級管理員和系統管理員不能被刪除',
            role10: '刪除成功',
            role11: '啟用成功',
            role12: '用戶添加成功',
            role13: '請輸入搜索資訊',
            role14: '匯出角色詳情',
            role15: '批量禁用',
            role16: '創建角色',
            role17: '角色名稱',
            role18: '成員',
            role19: '作用域',
            role20: '許可權範圍',
            role21: '狀態',
            role22: '啟用',
            role23: '禁用',
            role24: '詳情',
            role25: '添加成員',
            role26: '編輯角色',
            role27: '禁用角色',
            role28: '啟用角色',
            role29: '刪除角色',
            role30: '角色名稱不能為空',
            role31: '角色描述不能為空',
            role32: '修改成功',
            role33: '新增成功',
            role34: '新建角色',
            role35: '編輯角色',
            role36: '角色名稱',
            role37: '角色簡介',
            role38: '請輸入角色簡介',
            role39: '編輯成員',
            role40: '用戶名/手機號/郵箱',
            role41: '部門名稱',
            role42: '配置作用域',
            role43: '請選擇',
            role44: '配置許可權範圍',
            role45: '角色詳情',
            role46: '成員',
            role47: '部門',
            role48: '關閉詳情',
            role49: '編輯角色',
        },
        resoureForm: {
            resoure1: '部門',
            resoure2: '白名單',
            resoure3: '編輯成功',
            resoure4: '新增成功',
            resoure5: '群組添加成功',
            resoure6: '刪除成功',
            resoure7: '禁用成功',
            resoure8: '啟用成功',
            resoure9: '請選擇要禁用的資料',
            resoure10: '確定要禁用這些白名單嗎？',
            resoure11: '提示',
            resoure12: '確定',
            resoure13: '取消',
            resoure14: '禁用成功',
            resoure15: '禁用失敗',
            resoure16: '請選擇要刪除的資料',
            resoure17: '確定要刪除這些白名單嗎？',
            resoure18: '刪除成功',
            resoure19: '刪除失敗',
            resoure20: '資源配額優先順序規則：白名單配額 > 部門配額',
            resoure21: '批量禁用',
            resoure22: '批量刪除',
            resoure23: '添加白名單',
            resoure24: '白名單名稱',
            resoure25: '成員',
            resoure26: '詳情',
            resoure27: '操作',
            resoure28: '添加群組',
            resoure29: '配置資源',
            resoure30: '編輯',
            resoure31: '刪除',
            resoure32: '禁用',
            resoure33: '啟用',
            resoure34: '全部',
            resoure35: '阿裡',
            resoure36: 'baidu',
            resoure37: 'Azure',
            resoure38: '請選擇要禁用的資源',
            resoure39: '確定要禁用這些資源嗎？',
            resoure40: '提示',
            resoure41: '確定',
            resoure42: '取消',
            resoure43: '禁用成功',
            resoure44: '禁用成功',
            resoure45: '啟用成功',
            resoure46: '配額編輯成功',
            resoure47: '該資源平臺禁用成功',
            resoure48: '該資源平臺啟用成功',
            resoure49: '資源平臺',
            resoure50: '啟用當前資源平臺',
            resoure51: '禁用當前資源平臺',
            resoure52: '批量禁用',
            resoure53: '完成編輯',
            resoure54: '編輯配額',
            resoure55: '模型類型',
            resoure56: '資源平臺',
            resoure57: '其他',
            resoure58: '功能類型',
            resoure59: '狀態',
            resoure60: '啟用',
            resoure61: '禁用',
            resoure62: '無限',
            resoure63: '繼承配額',
            resoure64: '用戶日配額',
            resoure65: '幅',
            resoure66: '個',
            resoure67: '權杖',
            resoure68: '白名單名稱不能為空',
            resoure69: '白名單描述不能為空',
            resoure70: '群組不能為空',
            resoure71: '資源配置不能為空',
            resoure72: '請先保存當前編輯',
            resoure73: '新建',
            resoure74: '白名單',
            resoure75: '白名單名稱',
            resoure76: '白名單描述',
            resoure77: '所屬群組',
            resoure78: '配置資源',
            resoure79: '編輯配額',
            resoure80: '完成編輯',
            resoure81: '資源名稱',
            resoure82: '資源類型',
            resoure83: '用戶日配額',
            resoure84: '白名單詳情',
            resoure85: '成員',
            resoure86: '群組名',
            resoure87: '操作',
            resoure88: '移除',
            resoure89: '配置資源',
            resoure90: '關閉詳情',
            resoure91: '編輯資源組',
            resoure92: '編輯白名單',
        },
        topicForm: {
            topic1: '標題關鍵字',
            topic2: '歸檔成功',
            topic3: '刪除成功',
            topic4: '請選擇要歸檔的項',
            topic5: '請選擇要刪除的項',
            topic6: '恢復成功',
            topic7: '請選擇要恢復的項',
            topic8: '請輸入搜索資訊',
            topic9: '起止時間',
            topic10: '至',
            topic11: '開始時間',
            topic12: '結束時間',
            topic13: '批量歸檔',
            topic14: '批量刪除',
            topic15: '提問者',
            topic16: '標題',
            topic17: '問答數',
            topic18: '話題類型',
            topic19: '創建時間',
            topic20: '最近更新',
            topic21: '操作',
            topic22: '歸檔',
            topic23: '刪除',
            topic24: '回收站',
            topic25: '批量恢復',
            topic26: '恢復',
            topic27: '返回',
        },
        botForm: {
            bot1: '用戶名',
            bot2: '智能體名稱',
            bot3: '歸檔成功',
            bot4: '刪除成功',
            bot5: '請選擇要歸檔的智能體',
            bot6: '歸檔成功',
            bot7: '請選擇要刪除的智能體',
            bot8: '恢復成功',
            bot9: '請選擇要恢復的項',
            bot10: '擁有者',
            bot11: '成員數',
            bot12: '智能體類型',
            bot13: '智能助手',
            bot14: '話題數',
            bot15: '創建時間',
            bot16: '更新時間',
            bot17: '操作',
            bot18: '歸檔',
            bot19: '刪除',
            bot20: '回收站',
            bot21: '恢復',
            bot22: '返回',
        },
        micromodelForm: {
            model1: '用戶名',
            model2: '知識庫名稱',
            model3: '擁有者',
            model4: '成員數',
            model5: '知識庫名稱',
            model6: '知識庫名稱',
            model7: '知識庫名稱',
            model8: '知識庫名稱',
            model9: '文檔數/權威',
            model11: '話題數/權威/熱門',
            model12: '熱門',
            model13: '創建時間',
            model14: '更新時間',
            model15: '操作',
        },
        logForm: {
            log1: '匯出失敗',
            log2: '用戶名/郵箱',
            log3: '至',
            log4: '開始日期',
            log5: '結束日期',
            log6: '全部操作',
            log7: '全部模組',
            log8: '目前僅支援審計30日內平臺日誌',
            log9: '匯出',
            log10: '操作時間',
            log11: '用戶名',
            log12: '郵箱',
            log13: 'IP地址',
            log14: '部門',
            log15: '操作類型',
            log16: '所屬模組',
            log17: '操作內容',
            log18: '操作結果',
            log19: '表格控制',
            log20: '角色',
            log21: '最多添加4個篩選條件',
            log22: '成功',
            log23: '失敗',
            log24: '篩選條件',
            log25: '選擇篩選條件',
            log26: '請選擇',
            log27: '添加篩選項',
            log28: '取消',
            log29: '確定',
            log30: '請輸入用戶名或郵箱',
        },
        accrditForm: {
            accrdit1: '上傳成功',
            accrdit2: '訂閱時長',
            accrdit3: '已使用時長（天）/總訂閱時長（天）',
            accrdit4: '聯繫NextAI官方訂閱續期',
            accrdit5: '用戶席位',
            accrdit6: '已使用席位/總用戶席位',
            accrdit7: '產品名',
            accrdit8: '正式版',
            accrdit9: '版本資訊',
            accrdit10: '穩定版',
            accrdit11: '席位信息',
            accrdit12: '剩餘',
            accrdit13: '訂閱時長',
            accrdit14: '啟動碼',
            accrdit15: '啟動狀態',
            accrdit16: '已啟動',
            accrdit17: '未啟動',
            accrdit18: '啟動日期',
            accrdit19: '失效日期',
            accrdit20: '訪問產品官網',
            accrdit21: '席位',
            accrdit22: '天',

        },
        baseForm: {
            base1: '請輸入資源組名',
            base2: '新建資源組',
            base3: '資源組名稱',
            base4: '資源組名稱',
            base5: '放棄',
            base6: '創建',
            base7: '功能類型不能為空',
            base8: '配置名稱不能為空',
            base9: '模型名稱不能為空',
            base10: '模型類型不能為空',
            base11: '終結點不能為空',
            base12: '模型版本不能為空',
            base13: 'api金鑰不能為空',
            base14: '終結點不能為空',
            base15: '連接字串不能為空',
            base16: '帳號金鑰不能為空',
            base17: '帳號終結點不能為空',
            base18: '金鑰不能為空',
            base19: '終結點不能為空',
            base20: '接入金鑰ID不能為空',
            base21: '接入關鍵金鑰不能為空',
            base22: '接入金鑰不能為空',
            base23: '終結點不能為空',
            base24: '添加成功',
            base25: '資源配置',
            base26: '新建',
            base27: '修改',
            base28: '資源平臺',
            base29: '配置名稱',
            base30: '功能類型',
            base31: '請選擇功能類型',
            base32: '模型類型',
            base33: '請選擇模型類型',
            base34: '模型名稱',
            base35: '終結點',
            base36: '模型版本',
            base37: 'api金鑰',
            base38: '連接字串',
            base39: '帳號金鑰',
            base40: '帳號終結點',
            base41: '接入金鑰',
            base42: '金鑰',
            base43: '接入金鑰ID',
            base44: '接入關鍵金鑰',
            base45: '取消',
            base46: '確定',
            base47: '百度平臺帳單配置',
            base48: '阿裡雲平臺帳單配置',
            base49: '平臺帳單配置',
            base50: '開始時間',
            base51: '確認刪除資源組',
            base52: '一旦刪除資源組，該資源組所屬的所有資源配置將被一併抹除，確認刪除當前資源組嗎？',
            base53: 'SAML認證',
            base54: '查看幫助文檔',
            base55: '資源提供方映射資訊',
            base56: '下載中繼資料',
            base57: '認證提供方映射資訊',
            base58: '上傳中繼資料',
            base59: '驗證並保存',
            base60: '無法刪除資源組',
            base61: '為了防止誤操作，只有資源配置為空的資源組方可被刪除，您需要手動前往清空資源組的資源配置嗎？',
            base62: '前往清空',
            base63: '修改成功',
            base64: '新增成功',
            base65: '默認設置成功',
            base66: '刪除成功',
            base67: '資源組',
            base68: '資源組僅用於分配各項模型資源及用量統計，資源組之間的應用範圍不可相互重疊',
            base69: '新建資源組',
            base70: '先設定資源組，再給資源組配置資源',
            base71: '應用範圍',
            base72: '記憶體配置',
            base73: '為了保證您的使用體驗，我們建議您開放1-2GB Redis記憶體',
            base74: '確定修改',
            base75: 'Redis 記憶體配置',
            base76: '個人話題歸檔提醒時長',
            base77: '系統僅留存自最新一次對話時間起30日內的個人話題資料（知識庫話題除外），我們建議您開放至少3天提醒時長，提醒使用者在話題歸檔前保留重要資料',
            base78: '天',
            base79: '資源平臺帳單配置',
            base80: '儀錶盤調取對應資源平臺帳單API所必須的參數，已部署的資源平臺顯示綠色圓點',
            base81: 'SSO帳號登錄',
            base82: '跨平臺帳號認證方式，使用您習慣的企業通行證登錄NextAI用戶端，驗證成功的認證方式顯示綠色圓點',
            base83: '請輸入名稱',
            base84: '應用範圍',
            base85: '勾選本資源組需要應用的部門/子部門',
            base86: '資源配置',
            base87: '添加資源配置',
            base88: '模型',
            base89: '默認',
            base90: '設為默認',
            base91: '確認修改',
            base92: '修改AccessKey、SecretKey是對資源組的重大修改，您需要重新配置所有調用該配置組合的資源，確認修改嗎？',
            base93: '資源ID',
            base94: '請輸入資源ID',
            base95: '我們在儀錶盤中將根據該資源ID追蹤您的成本消耗',


        }
    },
    US: {
        team: 'Team',
        department: 'Users and Departments',
        group: 'Group Management',
        role: 'Role Management',
        resoure: 'Resource Management',
        knowledge: 'Knowledge',
        tipic: 'Topic Management',
        micromodel: 'Knowledge Base Management',
        bot: 'Agent Management',
        train: 'Training Center',
        deploy: 'Deployment',
        accredit: 'Platform Authorization',
        base: 'Basic Configuration',
        log: 'Platform Logs',
        userForm: {
            userName: 'Username',
            isActive: 'Status',
            email: 'Email',
            departmentName: 'Department Name',
            operation: 'Operation',
            address: 'Address',
            phone: 'Phone Number',
            roleName: 'Role Name',
            sex: 'Gender',
            supervisorName: 'Supervisor',
            DepartmentHead: 'Department Head',
            // Table Headers 
            user1: 'User',
            user2: 'Department',
            user3: 'Department name modified successfully',
            user4: 'Department deleted successfully',
            user5: 'Department modified successfully',
            user6: 'Department added successfully',
            user7: 'Sub-department added successfully',
            user8: 'Please enter username or email',
            user9: 'Search for department name',
            user10: 'Export Department',
            user11: 'Create New Department',
            user12: 'Modify Name',
            user13: 'Delete Department',
            user14: 'Department User Count',
            user15: 'Direct Department Users',
            user16: 'Operation',
            user17: 'Details',
            user18: 'Add Sub-department',
            user19: 'Edit',
            user20: 'Delete',
            user21: 'Department Details',
            user22: 'Department Name',
            user23: 'Parent Department',
            user24: 'Close Details',
            user25: 'Edit Department',
            user26: 'Please enter department name',
            user27: 'Please select parent department',
            user28: 'Please enter sub-department name',
            user29: 'Add Department',
            user30: 'Edit Department',
            user31: 'Sub-department Name',
            user32: 'Please enter sub-department name',
            user33: 'Department Name',
            user34: 'Please enter department name',
            user35: 'Abort',
            user36: 'Submit',
            user37: 'Cancel',
            user38: 'Confirm',
            user39: 'Add',
            user40: 'Please enter username',
            user41: 'Please enter a valid email address',
            user42: 'Please enter email address',
            user43: 'Please enter phone number',
            user44: 'Phone number format is incorrect',
            user45: 'Please enter address',
            user46: 'Please select gender',
            user47: 'Choose whether to send an email',
            user48: 'Please select role',
            user49: 'Please select department',
            user50: 'Add New User',
            user51: 'Edit User',
            user52: 'Enable',
            user53: 'Disable',
            user54: 'Male',
            user55: 'Female',
            user56: 'Send login information to the user via email',
            user57: 'Password',
            user58: 'Auto-create password',
            user59: 'Please enter new password',
            user60: 'Group',
            user61: 'Role',
            user62: 'User Details',
            user63: 'Edit User',
            user64: 'Edit Department Name',
            user65: 'Reset Password',
            user66: 'Password',
            user67: 'Password must be at least 6 characters',
            user68: 'Password reset successful',
            user69: 'You have successfully reset the password for this user',
            user70: 'All',
            user71: 'Display All Users',
            user72: 'Display Only Direct Department Reports',
            user73: 'Select users to disable',
            user74: 'Are you sure you want to disable these users?',
            user75: 'Prompt',
            user76: 'Disabled successfully',
            user77: 'Modified successfully',
            user78: 'Added successfully',
            user79: 'Disable failed',
            user80: 'Enabled successfully',
            user81: 'Total Users',
            user82: 'Enabled Users',
            user83: 'Batch Disable',
            user84: 'Batch Add',
            user85: 'Add User',
            user86: 'Account Status',
            user87: 'Disabled Users',
            user88: 'Table Display',
            user89: 'Add Member',
            user90: 'Search for group name or group members',
            user91: 'File type only supports xlsx version, please use the specified template for upload.',
            user92: 'Please select a file before uploading',
            user93: 'Upload successful',
            user94: 'Click or drag files to this area to upload',
            user95: 'Only supports single file upload',
            user96: 'Download Template',
            user97: 'Upload File',
            user98: 'Add Group',
            user99: 'Select Group'
        },
        groupForm: {
            group1: 'Removed Successfully',
            group2: 'Please Select Group Members to Remove',
            group3: 'Are You Sure You Want to Remove These Group Members?',
            group4: 'Removal Canceled',
            group5: 'Are You Sure You Want to Disband This Group?',
            group6: 'Disbanded Successfully',
            group7: 'Added Successfully',
            group8: 'Search Group Name',
            group9: 'Group Details',
            group10: 'Edit Group',
            group11: 'Disband Group',
            group12: 'Create Group',
            group13: 'Number of Members',
            group14: 'Batch Remove',
            group15: 'Add Member',
            group16: 'Group Name',
            group17: 'Group Description',
            group18: 'Group Members',
            group19: 'Please Enter Group Name',
            group20: 'Please Enter Group Description',
            group21: 'Please Select Group Members',
            group22: 'Updated Successfully',
            group23: 'Group Created',
            group24: 'Edit Group',
            group25: "Remove",
        },
        roleForm: {
            role1: 'Username',
            role2: 'Role Name',
            role3: 'Please select roles to disable',
            role4: 'Are you sure you want to disable these roles?',
            role5: 'Prompt',
            role6: 'Confirm',
            role7: 'Cancel',
            role8: 'Disabled successfully',
            role9: 'Super administrators and system administrators cannot be deleted',
            role10: 'Deleted successfully',
            role11: 'Enabled successfully',
            role12: 'User added successfully',
            role13: 'Please enter search information',
            role14: 'Export role details',
            role15: 'Batch disable',
            role16: 'Create role',
            role17: 'Role Name',
            role18: 'Members',
            role19: 'Scope',
            role20: 'Permission Range',
            role21: 'Status',
            role22: 'Enable',
            role23: 'Disable',
            role24: 'Details',
            role25: 'Add Members',
            role26: 'Edit Role',
            role27: 'Disable Role',
            role28: 'Enable Role',
            role29: 'Delete Role',
            role30: 'Role name cannot be empty',
            role31: 'Role description cannot be empty',
            role32: 'Modified successfully',
            role33: 'Added successfully',
            role34: 'New Role',
            role35: 'Edit Role',
            role36: 'Role Name',
            role37: 'Role Brief',
            role38: 'Please enter role brief',
            role39: 'Edit Members',
            role40: 'Username/Phone Number/Email',
            role41: 'Department Name',
            role42: 'Configure Scope',
            role43: 'Please Select',
            role44: 'Configure Permission Range',
            role45: 'Role Details',
            role46: 'Members',
            role47: 'Department',
            role48: 'Close Details',
            role49: 'Edit Role'

        },
        resoureForm: {
            resoure1: 'Department',
            resoure2: 'Whitelist',
            resoure3: 'Edit successful',
            resoure4: 'Add successful',
            resoure5: 'Group added successfully',
            resoure6: 'Delete successful',
            resoure7: 'Disable successful',
            resoure8: 'Enable successful',
            resoure9: 'Please select the data to disable',
            resoure10: 'Are you sure you want to disable these whitelists?',
            resoure11: 'Prompt',
            resoure12: 'Confirm',
            resoure13: 'Cancel',
            resoure14: 'Disabled successfully',
            resoure15: 'Disable failed',
            resoure16: 'Please select the data to delete',
            resoure17: 'Are you sure you want to delete these whitelists?',
            resoure18: 'Delete successful',
            resoure19: 'Delete failed',
            resoure20: 'Resource quota priority rule: Whitelist quota > Department quota',
            resoure21: 'Batch disable',
            resoure22: 'Batch delete',
            resoure23: 'Add whitelist',
            resoure24: 'Whitelist name',
            resoure25: 'Members',
            resoure26: 'Details',
            resoure27: 'Operation',
            resoure28: 'Add group',
            resoure29: 'Configure resources',
            resoure30: 'Edit',
            resoure31: 'Delete',
            resoure32: 'Disable',
            resoure33: 'Enable',
            resoure34: 'All',
            resoure35: 'Alibaba',
            resoure36: 'Baidu',
            resoure37: 'Azure',
            resoure38: 'Please select the resources to disable',
            resoure39: 'Are you sure you want to disable these resources?',
            resoure40: 'Prompt',
            resoure41: 'Confirm',
            resoure42: 'Cancel',
            resoure43: 'Disabled successfully',
            resoure44: 'Disabled successfully',
            resoure45: 'Enabled successfully',
            resoure46: 'Quota edit successful',
            resoure47: 'The resource platform has been disabled successfully',
            resoure48: 'The resource platform has been enabled successfully',
            resoure49: 'Resource platform',
            resoure50: 'Enable current resource platform',
            resoure51: 'Disable current resource platform',
            resoure52: 'Batch disable',
            resoure53: 'Complete editing',
            resoure54: 'Edit quota',
            resoure55: 'Model type',
            resoure56: 'Resource platform',
            resoure57: 'Other',
            resoure58: 'Function type',
            resoure59: 'Status',
            resoure60: 'Enable',
            resoure61: 'Disable',
            resoure62: 'Unlimited',
            resoure63: 'Inherit quota',
            resoure64: 'User daily quota',
            resoure65: 'width',
            resoure66: 'Items',
            resoure67: 'Tokens',
            resoure68: 'Whitelist name cannot be empty',
            resoure69: 'Whitelist description cannot be empty',
            resoure70: 'Group cannot be empty',
            resoure71: 'Resource allocation cannot be empty',
            resoure72: 'Please save the current edit first',
            resoure73: 'New',
            resoure74: 'Whitelist',
            resoure75: 'Whitelist name',
            resoure76: 'Whitelist description',
            resoure77: 'Associated group',
            resoure78: 'Configure resources',
            resoure79: 'Edit quota',
            resoure80: 'Complete editing',
            resoure81: 'Resource name',
            resoure82: 'Resource type',
            resoure83: 'User daily quota',
            resoure84: 'Whitelist details',
            resoure85: 'Members',
            resoure86: 'Group name',
            resoure87: 'Operation',
            resoure88: 'Remove',
            resoure89: 'Configure resources',
            resoure90: 'Close details',
            resoure91: 'Edit resource group',
            resoure92: 'Edit the whitelist',
        },
        topicForm: {
            topic1: 'Title Keywords',
            topic2: 'Archived successfully',
            topic3: 'Deleted successfully',
            topic4: 'Please select items to archive',
            topic5: 'Please select items to delete',
            topic6: 'Restored successfully',
            topic7: 'Please select items to restore',
            topic8: 'Please enter search information',
            topic9: 'Start and End Time',
            topic10: 'To',
            topic11: 'Start Time',
            topic12: 'End Time',
            topic13: 'Batch Archive',
            topic14: 'Batch Delete',
            topic15: 'Asker',
            topic16: 'Title',
            topic17: 'Number of Q&A',
            topic18: 'Topic Type',
            topic19: 'Creation Time',
            topic20: 'Last Updated',
            topic21: 'Operation',
            topic22: 'Archive',
            topic23: 'Delete',
            topic24: 'Recycle Bin',
            topic25: 'Batch Restore',
            topic26: 'Restore',
            topic27: 'Return'
        },
        botForm: {
            bot1: 'Username',
            bot2: 'Intelligent Entity Name',
            bot3: 'Archiving successful',
            bot4: 'Deletion successful',
            bot5: 'Please select the intelligent entity to archive',
            bot6: 'Archive successful',
            bot7: 'Please select the intelligent entity to delete',
            bot8: 'Recovery successful',
            bot9: 'Please select the item to recover',
            bot10: 'Owner',
            bot11: 'Number of Members',
            bot12: 'Type of Intelligent Entity',
            bot13: 'Intelligent Assistant',
            bot14: 'Number of Topics',
            bot15: 'Creation Time',
            bot16: 'Update Time',
            bot17: 'Operation',
            bot18: 'Archive',
            bot19: 'Delete',
            bot20: 'Recycle Bin',
            bot21: 'Recover',
            bot22: 'Return'

        },
        micromodelForm: {
            model1: 'Username',
            model2: 'Knowledge Base Name',
            model3: 'Owner',
            model4: 'Number of Members',
            model5: 'Knowledge Base Name',
            model6: 'Knowledge Base Name',
            model7: 'Knowledge Base Name',
            model8: 'Knowledge Base Name',
            model9: 'Document Count/Authority',
            model11: 'Topic Count/Authority/Popularity',
            model12: 'Popular',
            model13: 'Creation Time',
            model14: 'Update Time',
            model15: 'Operation'

        },
        logForm: {
            log1: 'Export failed',
            log2: 'Username/Email',
            log3: 'To',
            log4: 'Start Date',
            log5: 'End Date',
            log6: 'All Operations',
            log7: 'All Modules',
            log8: 'Currently only supports auditing platform logs within the last 30 days',
            log9: 'Export',
            log10: 'Operation Time',
            log11: 'Username',
            log12: 'Email',
            log13: 'IP Address',
            log14: 'Department',
            log15: 'Operation Type',
            log16: 'Module',
            log17: 'Operation Content',
            log18: 'Operation Result',
            log19: 'Table Control',
            log20: 'Role',
            log21: 'Up to 4 filter conditions can be added',
            log22: 'Success',
            log23: 'Failure',
            log24: 'Filter Conditions',
            log25: 'Select Filter Conditions',
            log26: 'Please Select',
            log27: 'Add Filter Item',
            log28: 'Cancel',
            log29: 'Confirm',
            log30: 'Please enter username or email'

        },
        accrditForm: {
            aaccrdit1: 'Upload successful',
            accrdit2: 'Subscription duration',
            accrdit3: 'Used duration (days) / Total subscription duration (days)',
            accrdit4: 'Contact NextAI official for subscription renewal',
            accrdit5: 'User seats',
            accrdit6: 'Used seats / Total user seats',
            accrdit7: 'Product name',
            accrdit8: 'Official version',
            accrdit9: 'Version information',
            accrdit10: 'Stable version',
            accrdit11: 'Seat information',
            accrdit12: 'Remaining',
            accrdit13: 'Subscription duration',
            accrdit14: 'Activation code',
            accrdit15: 'Activation status',
            accrdit16: 'Activated',
            accrdit17: 'Not activated',
            accrdit18: 'Activation date',
            accrdit19: 'Expiration date',
            accrdit20: 'Visit the official product website',
            accrdit21: 'Seat',
            accrdit22: 'Day',
        },
        baseForm: {
            base1: 'Please enter the resource group',
            base2: 'Create new resource group',
            base3: 'Resource group name',
            base4: 'Resource group name',
            base5: 'Abort',
            base6: 'Create',
            base7: 'Function type cannot be empty',
            base8: 'Configuration name cannot be empty',
            base9: 'Model name cannot be empty',
            base10: 'Model type cannot be empty',
            base11: 'Endpoint cannot be empty',
            base12: 'Model version cannot be empty',
            base13: 'API key cannot be empty',
            base14: 'Endpoint cannot be empty',
            base15: 'Connection string cannot be empty',
            base16: 'Account key cannot be empty',
            base17: 'Account endpoint cannot be empty',
            base18: 'Key cannot be empty',
            base19: 'Endpoint cannot be empty',
            base20: 'Access key ID cannot be empty',
            base21: 'Access secret key cannot be empty',
            base22: 'Access key cannot be empty',
            base23: 'Endpoint cannot be empty',
            base24: 'Added successfully',
            base25: 'Resource configuration',
            base26: 'New',
            base27: 'Modify',
            base28: 'Resource platform',
            base29: 'Configuration name',
            base30: 'Function type',
            base31: 'Please select a function type',
            base32: 'Model type',
            base33: 'Please select a model type',
            base34: 'Model name',
            base35: 'Endpoint',
            base36: 'Model version',
            base37: 'API key',
            base38: 'Connection string',
            base39: 'Account key',
            base40: 'Account endpoint',
            base41: 'Access key',
            base42: 'Key',
            base43: 'Access key ID',
            base44: 'Access secret key',
            base45: 'Cancel',
            base46: 'Confirm',
            base47: 'Baidu platform billing configuration',
            base48: 'Alibaba Cloud platform billing configuration',
            base49: 'Platform billing configuration',
            base50: 'Start time',
            base51: 'Confirm deletion of resource group',
            base52: 'Once the resource group is deleted, all resource configurations associated with it will be erased. Are you sure you want to delete the current resource group?',
            base53: 'SAML authentication',
            base54: 'View help documentation',
            base55: 'Resource provider mapping information',
            base56: 'Download metadata',
            base57: 'Authentication provider mapping information',
            base58: 'Upload metadata',
            base59: 'Verify and save',
            base60: 'Unable to delete resource group',
            base61: 'To prevent accidental operations, only resource groups with empty resource configurations can be deleted. Do you need to manually clear the resource configurations of the resource group?',
            base62: 'Go to clear',
            base63: 'Modified successfully',
            base64: 'Added successfully',
            base65: 'Default settings successful',
            base66: 'Deleted successfully',
            base67: 'Resource group',
            base68: 'Resource groups are only used for allocating model resources and usage statistics. The application scope of resource groups must not overlap.',
            base69: 'Create new resource group',
            base70: 'Set up the resource group first, then configure resources for the resource group',
            base71: 'Application scope',
            base72: 'Memory configuration',
            base73: 'To ensure your user experience, we recommend allocating 1-2 GB of Redis memory',
            base74: 'Confirm modification',
            base75: 'Redis memory configuration',
            base76: 'Personal topic archive reminder duration',
            base77: 'The system only retains personal topic data for 30 days from the latest conversation time (excluding knowledge base topics). We recommend setting a reminder duration of at least 3 days to remind users to save important data before topic archiving.',
            base78: 'Days',
            base79: 'Resource platform billing configuration',
            base80: 'Dashboard must retrieve the corresponding resource platform billing API parameters, deployed resource platforms are displayed with a green dot',
            base81: 'SSO account login',
            base82: 'Cross-platform account authentication method, use your familiar enterprise pass to log in to the NextAI client, and the successful authentication method is displayed with a green dot',
            base83: 'Please enter a name',
            base84: 'Application scope',
            base85: 'Select the departments/sub-departments that this resource group needs to apply to',
            base86: 'Resource configuration',
            base87: 'Add resource configuration',
            base88: 'Model',
            base89: 'Default',
            base90: 'Set as default',

            base91: 'Confirm the changes',
            base92: 'If you modify an AccessKey pair or SecretKey pair for a resource group, do you need to reconfigure all the resources that call the configuration combination?',
            base93: 'Resource ID',
            base94: 'Please enter the resource ID',
            base95: 'We will track your cost consumption based on this resource ID in the dashboard',
        }
    },
}
import axios from "../server";
export default {
    // 获取默认
    modelGet(data: any) {
        return axios({
            url: "/personal-page/default-model/get",
            method: "POST",
            data,
        });
    },
    // 字典
    modelDict(data: any) {
        return axios({
            url: "/chat/v4/model-dict/get",
            method: "GET",
            data,
        });
    },
    // 获取最近聊天
    // 查看所有模板主题
    getTopic(data: any) {
        return axios({
            url: `/chat-topic/history`,
            method: "GET",
            data,
        });
    },
    // 获取对应聊天记录
    getQueryList(data: any) {
        return axios({
            url: "/chat/session/session/query",
            method: "POST",
            data,
        });
    },
    // pdf
    pdfFind(data: any) {
        return axios({
            url: "memory-chat/pdf/url/find",
            method: "POST",
            data,
        });
    },
    // excel
    excelFind(data: any) {
        return axios({
            url: "memory-chat/excel/view",
            method: "POST",
            data,
        });
    },
    // word
    wordFind(data: any) {
        return axios({
            url: "memory-chat/word-url/find",
            method: "POST",
            data,
        });
    },
    // txt html
    txtFind(data: any) {
        return axios({
            url: "memory-chat/txt/download",
            method: "POST",
            data,
        });
    },
    // 微模型会话点赞 以及取消点赞
    baseUp(data: any) {
        return axios({
            url: "knowledge/session/conversation/operation/vote-up",
            method: "POST",
            data,
        });
    },
    // 微模型点踩
    baseDown(data: any) {
        return axios({
            url: "/knowledge/session/conversation/operation/vote-down",
            method: "POST",
            data,
        });
    },
    // 聊天点赞
    chatUp(data: any) {
        return axios({
            url: "chat/session/conversation/operation/vote-up",
            method: "POST",
            data,
        });
    },
    // 聊天点踩
    chatDown(data: any) {
        return axios({
            url: "/chat/session/conversation/operation/vote-down",
            method: "POST",
            data,
        });
    },
    // 聊天收藏
    chatLike(data: any) {
        return axios({
            url: "/chat/session/conversation/operation/collect",
            method: "POST",
            data,
        });
    },
    // 知识库收藏
    baseLike(data: any) {
        return axios({
            url: "/knowledge/session/conversation/operation/collect",
            method: "POST",
            data,
        });
    },
    // 微模型翻译
    baseTranslate(data: any) {
        return axios({
            url: "/knowledge/session/conversation/translate",
            method: "POST",
            data,
        });
    },
    // 聊天翻译
    chatTranslate(data: any) {
        return axios({
            url: "chat/session/conversation/translate",
            method: "POST",
            data,
        });
    },
    //新的对话
    addChat(data: any) {
        return axios({
            url: "/chat-topic/history",
            method: "POST",
            data,
        });
    },
    //模板查询
    getTemplate(data: any) {
        return axios({
            url: "memory-chat/template/query",
            method: "POST",
            data,
        });
    },
    // 聊天信息描述
    chatDesc(data: any) {
        return axios({
            url: "chat/session/conversation/desc",
            method: "POST",
            data,
        });
    },
    // 聊天信息描述base
    baseDesc(data: any) {
        return axios({
            url: "/knowledge/session/conversation/desc",
            method: "POST",
            data,
        });
    },
    // pdf
    pdfFindChat(data: any) {
        return axios({
            url: "memory-chat/pdf/url/find",
            method: "POST",
            data,
        });
    },
    // excel
    excelFindChat(data: any) {
        return axios({
            url: "memory-chat/excel/view",
            method: "POST",
            data,
        });
    },
    // word
    wordFindChat(data: any) {
        return axios({
            url: "memory-chat/word-url/find",
            method: "POST",
            data,
        });
    },
    // txt html
    txtFindChat(data: any) {
        return axios({
            url: "memory-chat/txt/download",
            method: "POST",
            data,
        });
    },
    // 对话文件查询
    chatFileQuery(data: any) {
        return axios({
            url: "memory-chat/file/query",
            method: "POST",
            data,
        });
    },
    // chat文件轮询
    getProcess(data: any) {
        return axios({
            url: "memory-chat/file/task/view",
            method: "POST",
            data,
        });
    },
    // 文件上传对话
    chatUpFile(data: any, id: any) {
        return axios({
            url: "memory-chat/file/upload",
            method: "POST",
            data,
            headers: {
                apiId: JSON.stringify(id),
                apiType: 'file'
            },
        });
    },
    // 文件删除
    chatFileDelete(data: any) {
        return axios({
            url: "memory-chat/file/delete",
            method: "POST",
            data,
        });
    },
    // 微模型 文件 文件夹 会话删除
    baseFileDelete(data: any) {
        return axios({
            url: "knowledge/session/session/file/delete",
            method: "POST",
            data,
        });
    },
    // 会话删除
    sessionDelete(data: any) {
        return axios({
            url: "management/session/management/delete",
            method: "POST",
            data,
        });
    },
    // 随机10条
    randomMessage() {
        return axios({
            url: "personal-page/random-question/get",
            method: "GET",
        });
    },
    // chat 单删
    chatConversationDelete(data: any) {
        return axios({
            url: "chat/session/conversation/delete",
            method: "POST",
            data,
        });
    },
    // base 单删
    baseConversationDelete(data: any) {
        return axios({
            url: "knowledge/session/conversation/delete",
            method: "POST",
            data,
        });
    },
    // base 话题重命名
    baseUpdataName(data: any) {
        return axios({
            url: "custom-library/session/update",
            method: "POST",
            data,
        });
    },
    //编辑名字
    putLeftList(data: any) {
        return axios({
            url: "/chat-topic/history",
            method: "PUT",
            data,
        });
    },
    // 微模型添加文件
    attachmentAdd(data: any) {
        return axios({
            url: "custom-library/session/attachment/add",
            method: "POST",
            data,
        });
    },
    // 对话收藏
    baseCollect(data: any) {
        return axios({
            url: `/knowledge/session/session/operation/collect`,
            method: "POST",
            data,
        });
    },
    // 对话收藏
    chatCollect(data: any) {
        return axios({
            url: `chat/session/session/operation/collect`,
            method: "POST",
            data,
        });
    },
    // Azure账单查询
    azureBill(data: any) {
        return axios({
            url: `token-management/azure/billing/summary`,
            method: "POST",
            data,
        });
    },
    // 阿里账单查询
    aliBill(data: any) {
        return axios({
            url: `token-management/ali/billing/summary`,
            method: "POST",
            data,
        });
    },
    baiduBill(data: any) {
        return axios({
            url: `token-management/baidu/billing/summary`,
            method: "POST",
            data,
        });
    },
    tokenBill(data: any) {
        return axios({
            url: `token-management/individual/token`,
            method: "POST",
            data,
        });
    },
    // 结束上下文 baseClear
    baseClear(data: any) {
        return axios({
            url: `chat/v4/clear/know`,
            method: "POST",
            data,
        });
    },
    // 结束上下文 chatClear
    chatClear(data: any) {
        return axios({
            url: `/memory-chat/clear`,
            method: "POST",
            data,
        });
    },
    // 获取chat模型列表
    chatModel(data: any) {
        return axios({
            url: `chat/v4/filter-model-dict/get`,
            method: "POST",
            data,
        });
    },
    // 获取文生图模型列表
    imgModel(data: any) {
        return axios({
            url: `chat/v4/picture-model-dict/get`,
            method: "POST",
            data,
        });
    },
    // 文生图一键优化提示词
    imgOptimize(data: any) {
        return axios({
            url: `image-generate/optimize`,
            method: "POST",
            data,
        });
    },
    // 获取文生图下拉
    imgListGet(data: any) {
        return axios({
            url: `image-generate/option-list/get`,
            method: "POST",
            data,
        });
    },
    // 首页资源
    indexDictGet(data: any) {
        return axios({
            url: `configuration-set-future/filter-resource-dict/get`,
            method: "POST",
            data,
        });
    },
    // 查询话题详情
    chatDescQuery(data: any) {
        return axios({
            url: `/chat/session/session/one/query`,
            method: "POST",
            data,
        });
    },
    // chat聊天
    chatRegenerate(data: any) {
        return axios({
            url: `chat/v4/normal/regenerate`,
            method: "POST",
            data,
        });
    },
    // base聊天
    baseRegenerate(data: any) {
        return axios({
            url: `chat/v4/knowledge/regenerate`,
            method: "POST",
            data,
        });
    },
    // 一键更新
    baseUpRegenerate(data: any) {
        return axios({
            url: `chat/v4/knowledge/chat`,
            method: "POST",
            data,
        });
    },
    // o1聊天
    o1Chat(data: any) {
        return axios({
            url: `/chat/v4/memory/chat`,
            method: "POST",
            data,
        });
    },
}
<script setup>
import { ref } from 'vue'
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
    show: Boolean,
});
const emit = defineEmits(['handleClose','handleSave']);
const form = ref({
    groupName: '',
    userId: userId.value
});
const formRef = ref(null)
const rules = ref({
    groupName: [
        {
            required: true,
            message: t('manage.baseForm.base1'),
        },
        { min: 1, max: 20, message: t("login.login68"), trigger: "blur" },
    ],
})
const handleClose = () => {
    formRef.value.resetFields();
    emit('handleClose');
}
const handleSave = async () => {
    await formRef.value.validate((valid) => {
        if (valid) {
            emit('handleSave', form.value);
            form.value = {}
        }
    });    
};
</script>
<template>
    <div>
        <el-dialog :z-index="100" destroy-on-close v-model="props.show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div style="display: flex;"> <img class="tit_img" src="@/assets/manage/groupItem.svg" alt="">
                        <div>{{ $t('manage.baseForm.base2') }}</div>
                    </div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                <el-form-item prop="groupName" :label="$t('manage.baseForm.base3')">
                    <el-input v-model="form.groupName" :placeholder="$t('manage.baseForm.base4')"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose(formRef)" class="cancel">{{$t('manage.baseForm.base5')}}</div>
                    <div @click="handleSave(formRef)" class="confirm">{{$t('manage.baseForm.base6')}}</div>
                </div>
            </template>
        </el-dialog>
    </div>

</template>

<style scoped lang="scss">
:deep(.el-dialog) {
    --el-dialog-width: 460px;
    border-radius: 30px;
    padding: 20px;
}
:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 380px !important;
    border-radius: 12px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";

    .tit_img {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 6px;
    }

}
.close-icon {
    font-size: 20px;
}
.form-name {
    font-size: 16px;
    color: #3D3D3D;
    .tit {
        font-weight: 600;
    }
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>
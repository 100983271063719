<template>
  <iframe
    v-if="props.flag == 1"
    style="width: 100%; height: 100%"
    :src="offUrl + encodeURIComponent(props.pptUrl)"
    frameborder="0"
  ></iframe>
  <VueOfficePptx v-else :src="props.pptUrl" @rendered="renderedHandler" />
</template>

<script setup>
//引入VueOfficeDocx组件
import VueOfficePptx from "@vue-office/pptx";
import { ref } from "vue";
const props = defineProps(["pptUrl", "flag"]);
const offUrl = ref("https://view.officeapps.live.com/op/embed.aspx?src=");
const renderedHandler = () => {
  console.log("渲染完成");
};
</script>

<style lang="scss" scoped></style>

export default {
    CN: {
        base1: '搜索知识库',
        base2: '角色',
        base3: '新建知识库',
        base4: '新建一个空白的AI知识库容器；我们推荐您基于特定的部门、群组、项目或专题构建专属AI知识库。',
        base5: '编辑',
        base6: '置顶',
        base7: '取消置顶',
        base8: '归档知识库',
        base9: '退出知识库',
        base10: '全部',
        base11: '文本模式',
        base12: '表格模式',
        base13: '综合模式',
        base14: '置顶成功',
        base15: '取消成功',
        base16: '删除成功',
        base17: '退出成功',
        base18: '创建成功',
        base19: '新建AI知识库',
        base20: '编辑AI知识库',
        base21: '名称',
        base22: '知识库名称不能为空',
        base23: '描述',
        base24: '输入知识库主题、内容或作用的简要描述',
        base25: '知识来源',
        base26: '资源平台',
        base27: '您可以选择一个或多个平台的资源分析处理您上传的文档',
        base28: '图标',
        base29: 'AI图像生成',
        base30: '默认智能助手',
        base31: '请选默认智能助手',
        base32: '成员',
        base33: '添加成员',
        base34: '用户/部门/群组',
        base35: '权限',
        base36: '操作',
        base37: '主',
        base38: '拥有者',
        base39: '创',
        base40: '创作者',
        base41: '普通成员',
        base42: '移除',
        base43: '设为普通用户',
        base44: '设为创作者',
        base45: '取消',
        base46: '创建',
        base47: '保存',
        base48: '本地文档',
        base49: '上传PDF, TXT, MD, DOC, DOCX, XLS, XLSX',
        base50: '添加成功',
        base51: '移除成功',
        base52: '您至少选择一个资源平台',
        base53: '详情',
        base54: '在',
        base55: '中搜索',
        base56: '上传',
        base57: '上传文件',
        base58: '上传文件夹',
        base59: '新建',
        base60: '历史',
        base61: '上级目录',
        base62: '下载文件',
        base63: '设为过期',
        base64: '取消过期',
        base65: '取消权威',
        base66: '设为权威',
        base67: '删除文件',
        base68: '删除文件夹',
        base69: '将您需要引用的文件/文件夹/话题拖放到此处,知识库可以针对引用回答得更加精准',
        base70: '针对知识库提问',
        base71: '时间',
        base72: '全部',
        base73: '提问人',
        base74: '新建文件夹',
        base75: '文件夹名称',
        base76: '文件夹名称不能为空',
        base77: '您选择的下列文件等待上传:',
        base78: '您选择的下列文件夹等待上传:',
        base79: '待上传',
        base80: '收起',
        base81: '展开所有',
        base82: '下列文件不符合系统上传规则:',
        base83: '上传历史',
        base84: '所有类型',
        base85: '文档大小',
        base86: '最近上传',
        base87: '不限状态',
        base88: '上传成功',
        base89: '上传失败',
        base90: '清除',
        base91: '清除全部记录',
        base92: '已完成上传',
        base93: '正在上传',
        base94: '已上传:',
        base95: '取消未上传项',
        base96: '全部暂停',
        base97: '全部开始',
        base98: '权威话题',
        base99: '热门话题',
        base100: '收藏话题',
        base101: '所有话题',
        base102: '粘贴成功',
        base103: '保存成功',
        base104: '清除成功',
        base105: '设置成功',
        base106: '文件名重复',
        base107: '当前暂无上传中任务...',
        base108: '新建文件夹成功',
        base109: '权限冲突',
        base110: '系统检测到您的资源权限发生变化,不符合该知识库当前的资源平台配置,您可以选择使用您当前有权限的平台重新分析处理知识库中的文档数据,或者将该知识库的拥有者权限转让他人。',
        base111: '系统检测到您的资源权限发生变化,不符合该知识库当前的资源平台配置,您可以选择将您的权限转变为普通成员,或者退出该知识库。',
        base112: '您可以选择一个或多个平台的资源分析处理您上传的文档',
        base113: '放弃',
        base114: '退出知识库',
        base115: '转为普通成员',
        base116: '重新分析',
        base117: '转移权限',
        base118: '设置过期时间',
        base119: '设置权威文件过期时间',
        base120: '过期时间',
        base121: '请选择设置过期时间',
        base122: '确定',
        base123: '添加用户',
        base124: '请输入部门名称',
        base125: '请输入群组名称',
        base126: '用户名',
        base127: '部门',
        base128: '添加',
        base129: '群组',
        base130: '搜索话题',
        base131: '我可验证',
        base132: '请求验证',
        base133: '我可验证的话题',
        base134: '请求验证的话题',
        base135: '收藏夹',
        base136: '原问答',
        base137: '上次验证',
        base138: '第1步',
        base139: '调整回答来源',
        base140: '若根据原回答搜索到的参考来源得出的回答不正确/不完善,您可以按需调整回答来源',
        base141: '调整来源',
        base142: '在当前知识库内选择您认为适合的来源文档',
        base143: '上传新文档',
        base144: '调整互联网来源',
        base145: '第2步',
        base146: '调整回答文本',
        base147: '完成回答来源调整后,你可以尝试"一键刷新"回答,对不满意的地方还可点击"编辑"自行调整新回答；点击"回到最初"则可消除编辑修改,恢复到未经编辑的回答版本',
        base148: '一键更新',
        base149: '编辑',
        base150: '存储',
        base151: '回到最初',
        base152: '第3步',
        base153: '设置时效',
        base154: '为了保证过期内容不再作为有效知识,你可以为你的验证内容设置时效,验证失效一周前,系统将向你确认是否更新验证或延长时效；默认验证时效为长期',
        base155: '请选择有效期',
        base156: '上个问答',
        base157: '下个问答',
        base158: '进入预览',
        base159: '发布验证',
        base160: '确认发布验证',
        base161: '感谢您的辛苦审阅,您的验证内容会立即推送至当前AI知识库的权威话题,并加入后续相关提问的参考来源,确认发布当前验证内容吗？',
        base162: '发布',
        base163: '发生冲突',
        base164: '非常抱歉,当前申请已由他人审阅并发布验证,请核对与您的验证内容是否一致,并解决可能的冲突后再次发布。若选择放弃解决,系统将不会保存您的验证内容。',
        base165: '放弃解决',
        base166: '解决冲突',
        base167: '跳转问答确认',
        base168: '您在当前问答页面尚有未存储的调整内容,是否存储当前内容并跳转到新问答页面？',
        base169: '放弃更改并跳转',
        base170: '存储并跳转新问答',
        base171: '否决',
        base172: '一个月',
        base173: '一季度',
        base174: '半年',
        base175: '一年',
        base176: '长期',
        base177: '自选',
        base178: '回答来源至少一个',
        base179: '回答文本不能为空',
        base180: '否决成功',
        base181: '发布成功',
        base182: '服务器连接失败',
        base183: '服务器连接超时',
        base184: '当前对话记录超过限制,请结束对话或新建对话。',
        base185: '预览',
        base186: '你好！我是AI，有什么我可以帮助你的吗？',
        base187: '返回编辑',
        base188: '冲突提醒',
        base189: '您当前审阅的问答已有他人完成审阅,可能产生冲突,若多个验证者同时发布验证,发布顺序靠后的需要解决与发布顺序靠前的验证内容冲突,您确认要继续验证该话题吗？',
        base190: '继续验证',
        base191: '全面算法',
        base192: '深度算法',
        base193: '请求验证',
        base194: '拷贝',
        base195: '重新生成',
        base196: '翻译',
        base197: '收藏',
        base198: '取消收藏',
        base199: '历史记录',
        base200: '对话信息',
        base201: '归档',
        base202: '精准算法',
        base203: '点赞',
        base204: '取消点赞',
        base205: '点踩',
        base206: '取消点踩',
        base207: '请求验证话题',
        base208: '邀请验证人',
        base209: '请选择邀请验证人',
        base210: '验证留言',
        base211: '(选填) 向验证人简短描述你的验证需求',
        base212: '提交',
        base213: '成功',
        base214: '请求验证成功',
        base215: '暂无历史记录',
        base216: '拷贝成功',
        base217: '拷贝失败',
        base218: '翻译成功',
        base219: '取消收藏成功',
        base220: '收藏成功',
        base221: '取消点赞成功',
        base222: '点赞成功',
        base223: '取消点踩成功',
        base224: '点踩成功',
        base225: '继续询问针对知识库知识的任何问题',
        base226: '联网搜索',
        base227: '字数过多或文件为空',
        base228: '页数过多',
        base229: '请输入信息',
        base230: '哪些方面让你觉得不满意？',
        base231: '(可选) 描述更多你的使用体验',
        base232: '提问',
        base233: 'NextAI 回答',
        base234: '文件来源',
        base235: '互联网来源',
        base236: '归档话题',
        base237: '请求重新验证',
        base238: '请求验证话题',
        base239: '撤回验证请求',
        base240: '选择验证范围',
        base241: '（一个请求至多验证六组问答）',
        base242: '请选择验证范围',
        base243: '信息虚假',
        base244: '资料太少',
        base245: '深度不足',
        base246: '其他',
        base247: '归档成功',
        base248: '回收站',
        base249: '请选择GPT类型',
        base250: '最多选择6条进行验证',
        base251: '返回',
        base252: '选择文件',
        base253: '将您需要引用的文件/文件夹拖放到此处,知识库可以针对引用回答得更加精准',
        base254: '清晰度',
        base255: '比例',
        base256: '风格',
        base257: '高清',
        base258: '标清',
        base259: '生动',
        base260: '自然',
        base261: "发送消息、输入 {'@'} 或 / 选择插件",
        base318: "发送消息",
        base262: 'AI知识库',
        base263: 'AI智能体',
        base264: '文件夹',
        base265: '请输入问题',
        base266: '正在询问AI知识库',
        base267: '正在对话AI智能体',
        base268: '请选择',
        base269: '上传人',
        base270: '查看文件内容',
        base271: '查看切片内容',
        base272: '重命名',
        base273: '恢复话题',
        base274: '请输入新的话题名称',
        base275: '重命名成功',
        base276: '点击查看来源',
        base277: '找到',
        base278: '个来源',
        base279: '个互联网来源',
        base280: '个来源',
        base281: '文件大小超过20MB',
        base282: '文件名称过长,上传失败！',
        base283: '不支持的文件类型',
        base284: '不支持的图片类型',
        base285: '利用人工智能深度学习的团队知识库',
        base286: '探索新知识,验证新话题',
        base287: '提示词模板,可以执行多重能力工作流',
        base288: "<strong>拥有者</strong>权限:查看知识库详情，编辑知识库，归档知识库，查看文件/夹，新建文件夹，上传文件/夹，删除文件/夹，验证话题/问答，针对知识库提问；<br/><strong>创作者</strong>权限:查看知识库详情，查看文件/夹，新建文件夹，上传文件/夹，验证话题/问答，针对知识库提问；<br/>普通成员权限:查看知识库详情，查看文件/夹，针对知识库提问。",
        base289: '最近更新',
        base290: '权威时限',
        base291: '权威文件',
        base292: '已过期',
        base293: '当前申请已有他人进入审阅过程,可能产生冲突,若多个验证者同时发布验证,发布顺序靠后的需要解决与发布顺序靠前者验证内容的冲突,您确认要继续进入验证吗？',
        base294: '进入验证',
        base295: '文档仓库',
        base296: '话题广场',
        base297: '提示',
        base298: '已被验证当前验证请求已完成审阅发布,您无需验证',
        base299: '好的',
        base300: '最多支持上传三个文件',
        base301: '最多支持上传三个图片',
        base302: '点击上传知识库头像',
        base303: '剪切',
        base304: '粘贴',
        base305: '你可以在AI智能体中自主定制你专属的智能助手,并将其设置为AI知识库的默认智能助手,当你没有主动选择时,NextAI会将「知识检索官」配置为AI知识库的默认智能助手。',
        base306: '无',
        base307: '编辑成功',
        base308: '正在上传中',
        base309: '话题',
        base310: '正在输出信息,请稍后',
        base311: '知识库会话来源',
        base312: '剪切成功',
        base313: '转让',
        base314: '分析成功',
        base315: '成功',
        base316: '转让成功',
        base317: '验证',
        base319: '已引用权威话题',
        base320: '参考来源',
        base321: '不可查看',
        base322: '移动到最上层',
        base323: '部门视图',
        base324: '群组视图',
        base325: '将团队SharePoint的数据库和文档导入AI知识库中',
        base326: '同步知识范围',
        base327: '配置SharePoint知识来源',
        base328: '前往Microsoft账号验证页面，验证你的Microsoft企业账号，一选择你需要同步的知识范围。',
        base329: '验证Microsoft账号权限',
        base330: '选择同步知识范围',
        base331: '选择您希望同步知识的范围',
        base332: 'SharePoint',
        base333: '我是您的聊天助手,现在可以和我聊天了!',
        base334: '输入SharePoint站点',
        base335: '输入SharePoint站点，并点击验证按钮验证你的Microsoft企业账户',
        base336: '请输入站点地址',
        base337: '上一步',
        base338: 'AI知识库详情',
        base339: '同步知识库',
        base340: '关闭',
        base341: '同步中...',
    },
    HK: {
        base1: '搜索知識庫',
        base2: '角色',
        base3: '新建知識庫',
        base4: '新建一个空白的AI知识库容器; 我们推荐您基于特定的部门、群组、项目或专题构建专属AI知识库。 ',
        base5: '編輯',
        base6: '置頂',
        base7: '取消置頂',
        base8: '归档知識庫',
        base9: '退出知識庫',
        base10: '全部',
        base11: '文字模式',
        base12: '表格模式',
        base13: '綜合模式',
        base14: '置頂成功',
        base15: '取消成功',
        base16: '刪除成功',
        base17: '退出成功',
        base18: '創建成功',
        base19: '新建AI Knowledge Store',
        base20: '編輯AI知識庫',
        base21: '名稱',
        base22: '知識庫名稱不能為空',
        base23: '描述',
        base24: '输入知识库主题、内容或作用的简要描述',
        base25: '知識來源',
        base26: '資源平臺',
        base27: '您可以选择一个或多个平台的资源分析处理您上传的文档',
        base28: '圖示',
        base29: 'AI圖像生成',
        base30: '默認智能助手',
        base31: '請選默認智能助手',
        base32: '成員',
        base33: '添加成員',
        base34: '用户/部门/群组',
        base35: '許可權',
        base36: '操作',
        base37: '主',
        base38: '擁有者',
        base39: '創',
        base40: '创建者',
        base41: '普通成員',
        base42: '移除',
        base43: '設為普通用戶',
        base44: '設為創作者',
        base45: '取消',
        base46: '創建',
        base47: '保存',
        base48: '本地文檔',
        base49: '上层PDF, TXT, MD, DOC, DOCX, XLS, XLSX',
        base50: '添加成功',
        base51: '移除成功',
        base52: '您至少选择一个资源平台',
        base53: '詳情',
        base54: '在',
        base55: '中搜索',
        base56: '上傳',
        base57: '上傳文件',
        base58: '上傳資料夾',
        base59: '新建',
        base60: '歷史',
        base61: '上級目标錄',
        base62: '下載檔案',
        base63: '設為過期',
        base64: '取消期',
        base65: '取消權威',
        base66: '設定為權威',
        base67: '刪除檔',
        base68: '刪除資料夾',
        base69: '将您需要引用的文件/文件夹/话题拖放到此处,知识库可以针对引用回答得更加精准',
        base70: '針對知識庫提問',
        base71: '时间',
        base72: '全部',
        base73: '提問人',
        base74: '新建資料夾',
        base75: '資料夾名稱',
        base76: '文件夹名称不能为空',
        base77: '您选择的下列文件等待上传:',
        base78: '您选择的下列文件夹等待上传:',
        base79: '待上傳',
        base80: '收起',
        base81: '展開所有',
        base82: '下列文件不符合系统上传规则:',
        base83: '上傳歷史',
        base84: '所有類型',
        base85: '文檔大小',
        base86: '最近上傳',
        base87: '不限狀態',
        base88: '上傳成功',
        base89: '上傳失敗',
        base90: '清除',
        base91: '清除全部記錄',
        base92: '已完成上傳',
        base93: '正在上傳',
        base94: '已上傳:',
        base95: '取消未上傳項',
        base96: '全部暫停',
        base97: '全部开始',
        base98: '權威話題',
        base99: '熱門話題',
        base100: '收藏話題',
        base101: '所有話題',
        base102: '粘貼成功',
        base103: '保存成功',
        base104: '清除成功',
        base105: '設置成功',
        base106: '檔案名重複',
        base107: '当前暂无上传中任务...',
        base108: '新建資料夾成功',
        base109: '許可權衝突',
        base110: '系统检测到您的资源权限发生变化,不符合该知识库当前的资源平台配置,您可以选择使用您当前有权限的平台重新分析处理知识库中的文档数据,或者将该知识库的所有者权限转让他人。 ',
        base111: '系统检测到您的资源权限发生变化,不符合该知识库当前的资源平台配置,您可以选择将您的权限转变为普通成员,或者退出该知识库。 ',
        base112: '您可以选择一个或多个平台的资源分析处理您上传的文档',
        base113: '放棄',
        base114: '退出知識庫',
        base115: '轉為普通成員',
        base116: '重新分析',
        base117: '轉移許可權',
        base118: '安装超时',
        base119: '設定權威文件過期時間',
        base120: '过去的时间',
        base121: '请选择设置过期时间',
        base122: '確定',
        base123: '添加用戶',
        base124: '请输入部门名称',
        base125: '請輸入群組名稱',
        base126: '用戶名',
        base127: '部門',
        base128: '添加',
        base129: '群組',
        base130: '搜索話題',
        base131: '我可驗證',
        base132: '求驗證',
        base133: '我可验证的话题',
        base134: '請求驗證的話題',
        base135: '收藏夾',
        base136: '原問答',
        base137: '上次驗證',
        base138: '第1步',
        base139: '調整回答來源',
        base140: '若根据原回答搜索到的参考来源得出的回答不正确/不完善,您可以按需调整回答来源',
        base141: '调整来源',
        base142: '在当前知识库内选择您认为适合的来来源文件',
        base143: '上傳新文檔',
        base144: '調整互聯網來源',
        base145: '第2步',
        base146: '調整回答文本',
        base147: '完成回答来源调整后,你可以尝试"一键刷新"回答,对不满意的地方还可点击"编辑"自行调整新回答; 点击"回到最初"则可消除编辑修改,恢复到未经编辑的回答版本',
        base148: '一鍵更新',
        base149: '編輯',
        base150: '存儲',
        base151: '回到最初',
        base152: '第3步',
        base153: '設置時效',
        base154: '为了保证过期内容不再作为有效知识,你可以为你的验证内容设置时效,验证失效一周前,系统将向你确认是否更新验证或延长时效; 默认验证时效为长期',
        base155: '请选择有效期',
        base156: '上個問答',
        base157: '下個問答',
        base158: '进入預覽',
        base159: '發佈驗證',
        base160: '確認發佈驗證',
        base161: '感谢您的辛苦审阅,您的验证内容会立即推送至当前AI知识库的权威话题,并加入后续相关提问的参考来源,确认发布当前验证内容吗？ ',
        base162: '發佈',
        base163: '發生衝突',
        base164: '非常抱歉,当前申请已由他人审阅并发布验证,请核对与您的验证内容是否一致,并解决可能的冲突后再次发布。 若选择放弃解决,系统将不会保存您的验证内容。 ',
        base165: '放尽解決',
        base166: '解決衝突',
        base167: '跳轉問答確認',
        base168: '您在当前问答页面尚有未存储的调整内容,是否存储当前内容并跳转到新问答页面？ ',
        base169: '放棄更改並跳轉',
        base170: '存儲並跳轉新問答',
        base171: '否決',
        base172: '一个月',
        base173: '一季度',
        base174: '半年',
        base175: '一年',
        base176: '長期',
        base177: '自選',
        base178: '回答来源至少一个',
        base179: '回答文本不能為空',
        base180: '否決成功',
        base181: '發佈成功',
        base182: '伺服器連接失敗',
        base183: '伺服器連接逾時',
        base184: '当前对话记录超过限制,请结束对话或新建对话。 ',
        base185: '預覽',
        base186: '你好！ 我是AI，有什麼我可以説明你的嗎？',
        base187: '返回編輯',
        base188: '衝突提醒',
        base189: '您当前审阅的问答已有他人完成审阅,可能产生冲突,若多个验证者同时发布验证,发布顺序靠后的需要解决与发布顺序靠前的验证内容冲突,您确认要继续验证该话题吗？ ',
        base190: '繼續驗證',
        base191: '全面算法',
        base192: '深度算法',
        base193: '請求驗證',
        base194: '拷貝',
        base195: '重新生成',
        base196: '翻譯',
        base197: '收藏',
        base198: '取消收藏',
        base199: '歷史記錄',
        base200: '对话信息',
        base201: '歸檔',
        base202: '精確算法',
        base203: '點贊',
        base204: '取消點贊',
        base205: '點踩',
        base206: '取消點踩',
        base207: '請求驗證話題',
        base208: '邀請驗證人',
        base209: '请选择邀请验证人',
        base210: '留言',
        base211: '（选填） 向验证人简短描述你的验证需求',
        base212: '提交',
        base213: '成功',
        base214: '請求驗證成功',
        base215: '暫無歷史記錄',
        base216: '拷貝成功',
        base217: '拷貝失敗',
        base218: '翻譯成功',
        base219: '取消收藏成功',
        base220: '收藏成功',
        base221: '取消點贊成功',
        base222: '點贊成功',
        base223: '取消點踩成功',
        base224: '點踩成功',
        base225: '继续询问针对知识库知识的任何问题',
        base226: '聯网搜索',
        base227: '字數過多或檔為空',
        base228: '頁數過多',
        base229: '請輸入資訊',
        base230: '哪些方面让你觉得不满意？ ',
        base231: '（可选） 描述更多你的使用体验',
        base232: '提問',
        Base233: 'Nextai 回答',
        base234: '檔案來源',
        base235: '互聯網來源',
        base236: '歸檔話題',
        base237: '請求重新驗證',
        base238: '請求驗證話題',
        base239: '撤回驗證請求',
        base240: '选择验证范围',
        base241: '（一个请求至多验证六组问答）',
        base242: '请选择验证范围',
        base243: '資訊虛假',
        base244: '資料太少',
        base245: '深度不足',
        base246: '其他',
        base247: '歸檔成功',
        base248: '回收站',
        base249: '請選擇GPT型',
        base250: '最多选择6条进行验证',
        base251: '返回',
        base252: '選擇檔',
        base253: '将您需要引用的文件/文件夹拖放到此处,知识库可以针对引用回答得更加精准',
        base254: '清晰度',
        base255: '比例',
        base256: '風格',
        base257: '高清',
        base258: '標清',
        base259: '生動',
        base260: '自然',
        base261: "发送消息、输入 {'@'} 或 / 选择插件",
        base318: "发送消息",
        base262: 'AI知识識庫',
        base263: 'AI智能體',
        base264: '資料夾',
        base265: '請輸入問題',
        base266: '正在詢問AI知識庫',
        base267: '正在對話AI智慧體',
        base268: '请选择',
        base269: '上傳人',
        base270: '查看檔內容',
        base271: '查看切片內容',
        base272: '重命名',
        base273: '恢復話題',
        base274: '请输入新的话题名称',
        base275: '重命名成功',
        base276: '點擊查看來源',
        base277: '找到',
        base278: '個來源',
        base279: '個互聯網來源',
        base280: '个来源',
        base281: '文件大小超過20MB',
        base282: '檔案名稱過長,上傳失敗！ ',
        base283: '不支持的档案类型',
        base284: '不支持的图片类型',
        base285: '利用人工智能深度学习的团队知识库',
        base286: '探索新知识,验证新话题',
        base287: '提示詞範本,可以執行多重能力工作流',
        base288: "<strong>擁有者</strong>許可權：查看知識庫詳情，編輯知識庫，歸檔知識庫，查看檔/夾，新建資料夾，上傳檔/夾，刪除檔/夾，驗證話題/問答，針對知識庫提問; <br/><strong>創作者</strong>許可權：查看知識庫詳情，查看檔/夾，新建資料夾，上傳檔/夾，驗證話題/問答，針對知識庫提問; <br/>普通成員許可權：查看知識庫詳情，查看檔/夾，針對知識庫提問。",
        base289: '最近更新',
        base290: '權威限',
        base291: '權威文件',
        base292: '已過期',
        base293: '当前申请已有他人进入审阅过程,可能产生冲突,若多个验证者同时发布验证,发布顺序靠后的需要解决与发布顺序靠前者验证内容的冲突,您确认要继续进入验证吗？ ',
        base294: '入驗證',
        base295: '文檔倉庫',
        base296: '話題廣場',
        base297: '提示',
        base298: '已被验证当前验证请求已完成审阅发布,您无需验证',
        base299: '好的',
        base300: '最多支援上傳三個檔',
        base301: '最多支持上传三个图片',
        base302: '點擊上傳知識庫頭像',
        base303: '剪切',
        base304: '粘貼',
        base305: '你可以在AI智能体中自主定制你专属的智能助手,并将其设置为AI知识库的默认智能助手,当你没有主动选择时,NextAI会将「知识检索官」配置为AI知识库的预设智能助手。 ',
        base306: '無',
        base307: '編輯成功',
        base308: '正在上傳中',
        base309: '話題',
        base310: '正在输出信息,请稍后',
        base311: '知識庫會話來源',
        base312: '剪切成功',
        base313: '轉讓',
        base314: '分析成功',
        base315: '成功',
        base316: '轉讓成功',
        base317: '驗證',
        base319: '已引用權威話題',
        base320: '參考來源',
        base321: '不可查看',
        base322: '移動到最上層',
        base323: '部門檢視',
        base324: '群組檢視',
        base325: "將團隊SharePoint的資料庫和文檔導入AI知識庫中",
        base326: "同步知識範圍",
        base327: "配置SharePoint知識來源",
        base328: "前往Microsoft帳號驗證頁面，驗證你的Microsoft企業帳號，一選擇你需要同步的知識範圍。",
        base329: "驗證Microsoft賬號許可權",
        base330: "選擇同步知識範圍",
        base331: "選擇您希望同步知識的範圍",
        base332: 'SharePoint',
        base333: '我是您的聊天助手，現在可以和我聊天了！',
        base334: '輸入SharePoint網站',
        base335: '輸入SharePoint網站，並點擊驗證按鈕驗證你的Microsoft企業帳戶',
        base336: '請輸入網站位址',
        base337: '上一步',
        base338: 'AI知識庫詳情',
        base339: '同步知識庫',
        base340: '關閉',
        base341: '同步中...',
    },
    US: {
        base1: 'Search Knowledge Base',
        base2: 'Roles',
        base3: 'Create Knowledge Base',
        base4: 'Create a new blank AI knowledge base container; we recommend you to build a dedicated AI knowledge base based on specific departments, groups, projects, or topics.',
        base5: 'Edit',
        base6: 'Pin',
        base7: 'Unpin',
        base8: 'Knowledge Repository',
        base9: 'Exit Knowledge Base',
        base10: 'All',
        base11: 'Text Mode',
        base12: 'Table Mode',
        base13: 'Comprehensive Mode',
        base14: 'Pinned Successfully',
        base15: 'Unpinned Successfully',
        base16: 'Deleted Successfully',
        base17: 'Exited Successfully',
        base18: 'Created Successfully',
        base19: 'New AI Knowledge Base',
        base20: 'Edit AI Knowledge Base',
        base21: 'Name',
        base22: 'Knowledge base name cannot be empty',
        base23: 'Description',
        base24: 'Enter a brief description of the knowledge base topic, content, or purpose',
        base25: 'Knowledge Source',
        base26: 'Resource Platform',
        base27: 'You can choose one or multiple platforms to analyze and process the documents you upload',
        base28: 'Icon',
        base29: 'AI Image Generation',
        base30: 'Default Intelligent Assistant',
        base31: 'Please select the default intelligent assistant',
        base32: 'Members',
        base33: 'Add Members',
        base34: 'User/Department/Group',
        base35: 'Permissions',
        base36: 'Actions',
        base37: 'Primary',
        base38: 'Owner',
        base39: 'Create',
        base40: 'Creator',
        base41: 'Regular Member',
        base42: 'Remove',
        base43: 'Set as Regular User',
        base44: 'Set as Creator',
        base45: 'Cancel',
        base46: 'Create',
        base47: 'Save',
        base48: 'Local Documents',
        base49: 'Upload PDF, TXT, MD, DOC, DOCX, XLS, XLSX',
        base50: 'Added Successfully',
        base51: 'Removed Successfully',
        base52: 'You must select at least one resource platform',
        base53: 'Detail',
        base54: 'In',
        base55: 'Search',
        base56: 'Upload',
        base57: 'Upload File',
        base58: 'Upload Folder',
        base59: 'New',
        base60: 'History',
        base61: 'Parent Directory',
        base62: 'Download File',
        base63: 'Set as Expired',
        base64: 'Unexpire',
        base65: 'Removal of authority',
        base66: 'Set as authority',
        base67: 'Delete File',
        base68: 'Delete Folder',
        base69: 'Drag and drop the files/folders/topics you need to reference here, the knowledge base can provide more accurate answers based on references',
        base70: 'Knowledge base inquiries',
        base71: 'Time',
        base72: 'All',
        base73: 'Asker',
        base74: 'New Folder',
        base75: 'Folder Name',
        base76: 'Folder name cannot be empty',
        base77: 'The following files you selected are waiting to be uploaded:',
        base78: 'The following folders you selected are waiting to be uploaded:',
        base79: 'Pending Upload',
        base80: 'Collapse',
        base81: 'Expand All',
        base82: 'The following files do not comply with the system upload rules:',
        base83: 'Upload History',
        base84: 'All Types',
        base85: 'Document Size',
        base86: 'Recently Uploaded',
        base87: 'Any Status',
        base88: 'Successful',
        base89: 'Failed',
        base90: 'Clear',
        base91: 'Clear All Records',
        base92: 'Upload Completed',
        base93: 'Uploading',
        base94: 'Uploaded:',
        base95: 'Cancel Unuploaded Items',
        base96: 'Pause All',
        base97: 'Start All',
        base98: 'Authoritative',
        base99: 'Popular',
        base100: 'Favorite',
        base101: 'All',
        base102: 'Pasted Successfully',
        base103: 'Saved Successfully',
        base104: 'Cleared Successfully',
        base105: 'Settings Saved Successfully',
        base106: 'Duplicate Filename',
        base107: 'No upload tasks at present...',
        base108: 'New Folder Created Successfully',
        base109: 'Permission Conflict',
        base110: 'The system has detected a change in your resource permissions, which does not meet the current configuration of the knowledge base\'s resource platform. You can choose to re-analyze and process the documents in the knowledge base using the platforms you currently have permission for, or transfer the ownership rights of this knowledge base to someone else.',
        base111: 'The system has detected a change in your resource permissions, which does not meet the current configuration of the knowledge base\'s resource platform. You can choose to change your permissions to a regular member, or exit this knowledge base.',
        base112: 'You can choose one or multiple platforms to analyze and process the documents you upload',
        base113: 'Abandon',
        base114: 'Exit',
        base115: 'Convert to ordinary member',
        base116: 'Re-analyze',
        base117: 'Transfer Rights',
        base118: 'Set Expiry Time',
        base119: 'Set the expiration time for authoritative documents.',
        base120: 'Expiry Time',
        base121: 'Please select the expiry time',
        base122: 'Confirm',
        base123: 'Add User',
        base124: 'Please enter the department name',
        base125: 'Please enter the group name',
        base126: 'Username',
        base127: 'Department',
        base128: 'Add',
        base129: 'Group',
        base130: 'Search Topics',
        base131: 'Verify',
        base132: 'Request Verification',
        base133: 'Topics I Can Verify',
        base134: 'Topics Requesting Verification',
        base135: 'Favorites',
        base136: 'Original Q&A',
        base137: 'Last Verification',
        base138: 'Step 1',
        base139: 'Adjust Answer Sources',
        base140: 'If the answer derived from the original reference sources is incorrect or incomplete, you can adjust the answer sources as needed',
        base141: 'Adjust Sources',
        base142: 'Select the source documents you think are suitable within the current knowledge base',
        base143: 'Upload New Document',
        base144: 'Adjust Internet Sources',
        base145: 'Step 2',
        base146: 'Adjust Answer Text',
        base147: 'After completing the adjustment of the answer source, you can try "One-click Refresh" to update the answer. If you are not satisfied with the changes, you can click "Edit" to make your own adjustments to the new answer; clicking "Return to Original" will undo the edits and restore the answer to its unedited version',
        base148: 'One-click Update',
        base149: 'Edit',
        base150: 'Save',
        base151: 'Return to Original',
        base152: 'Step 3',
        base153: 'Set Validity Period',
        base154: 'To ensure that expired content is no longer valid knowledge, you can set a validity period for your verified content. One week before the verification expires, the system will ask you to confirm whether to update the verification or extend the validity period; the default verification validity is permanent',
        base155: 'Please select the validity period',
        base156: 'Previous Q&A',
        base157: 'Next Q&A',
        base158: 'Enter Preview',
        base159: 'Publish Verification',
        base160: 'Confirm Publishing Verification',
        base161: 'Thank you for your hard work in reviewing. Your verified content will be immediately pushed to the current AI knowledge base\'s authoritative topics and added to the reference sources for subsequent related questions. Are you sure you want to publish the current verified content?',
        base162: 'Publish',
        base163: 'Conflict Occurred',
        base164: 'We are very sorry, but the current application has been reviewed and verified by another person. Please check if it is consistent with your verification content and resolve any potential conflicts before publishing again. If you choose to give up resolving, the system will not save your verification content.',
        base165: 'Give Up Resolving',
        base166: 'Resolve Conflict',
        base167: 'Jump to Q&A Confirmation',
        base168: 'You have unsaved adjustments on the current Q&A page. Do you want to save the currentcontent and jump to a new Q&A page?',
        base169: 'Give Up Changes and Jump',
        base170: 'Save and Jump to New Q&A',
        base171: 'Reject',
        base172: 'One Month',
        base173: 'One Quarter',
        base174: 'Half Year',
        base175: 'One Year',
        base176: 'Permanent',
        base177: 'Custom',
        base178: 'At least one answer source is required',
        base179: 'Answer text cannot be empty',
        base180: 'Rejected Successfully',
        base181: 'Published Successfully',
        base182: 'Server Connection Failed',
        base183: 'Server Connection Timeout',
        base184: 'The current conversation record exceeds the limit, please end the conversation or start a new one.',
        base185: 'Preview',
        base186: "Hello! I'm AI, is there anything I can help you with?",
        base187: 'Return to Edit',
        base188: 'Conflict Alert',
        base189: 'The Q&A you are currently reviewing has been completed by another reviewer, which may cause a conflict. If multiple verifiers publish verifications at the same time, the one who publishes later needs to resolve the conflict with the content published earlier. Are you sure you want to continue verifying this topic?',
        base190: 'Continue Verification',
        base191: 'Comprehensive Algorithm',
        base192: 'Depth Algorithm',
        base193: 'Request Verification',
        base194: 'Copy',
        base195: 'Regenerate',
        base196: 'Translate',
        base197: 'Favorite',
        base198: 'Unfavorite',
        base199: 'History Record',
        base200: 'Conversation Information',
        base201: 'Archive',
        base202: 'Precision Algorithm',
        base203: 'Like',
        base204: 'Unlike',
        base205: 'Dislike',
        base206: 'Undo Dislike',
        base207: 'Request Verification',
        base208: 'Invite Verifier',
        base209: 'Please select an invite verifier',
        base210: 'Verification Message',
        base211: '( Optional ) Briefly describe your verification requirements to the verifier',
        base212: 'Submit',
        base213: 'Success',
        base214: 'Verification Request Successful',
        base215: 'No Historical Records',
        base216: 'Copied Successfully',
        base217: 'Copy Failed',
        base218: 'Translation Successful',
        base219: 'Unfavorited Successfully',
        base220: 'Favorited Successfully',
        base221: 'Unliked Successfully',
        base222: 'Liked Successfully',
        base223: 'Undo Dislike Successfully',
        base224: 'Disliked Successfully',
        base225: 'Continue to ask any questions about the knowledge base',
        base226: 'Internet',
        base227: 'Too many characters or empty file',
        base228: 'Too many pages',
        base229: 'Please enter information',
        base230: 'What aspects are unsatisfactory to you?',
        base231: '( Optional ) Describe more about your user experience',
        base232: 'Ask a Question',
        base233: 'NextAI Answer',
        base234: 'File source',
        base235: 'Internet Source',
        base236: 'Archive Topic',
        base237: 'Request Re-verification',
        base238: 'Request Verification',
        base239: 'Withdraw Verification Request',
        base240: 'Select Verification Scope',
        base241: '(A request can verify up to six groups of Q&As at most)',
        base242: 'Please select the verification scope',
        base243: 'False Information',
        base244: 'Insufficient Material',
        base245: 'Lack of Depth',
        base246: 'Other',
        base247: 'Archived Successfully',
        base248: 'Recycle Bin',
        base249: 'Please select GPT type',
        base250: 'Select up to 6 for verification',
        base251: 'Return',
        base252: 'Select File',
        base253: 'Drag and drop the files/folders you need to reference here, the knowledge base can provide more accurate answers based on references',
        base254: 'Clarity',
        base255: 'Ratio',
        base256: 'Style',
        base257: 'HD',
        base258: 'SD',
        base259: 'Vivid',
        base260: 'Natural',
        base261: "Send a message, type {'@'} or / to select a plugin",
        base318: "Send a message",
        base262: 'AI Knowledge Base',
        base263: 'AI Intelligence',
        base264: 'Folder',
        base265: 'Please enter a question',
        base266: 'Inquiring AI Knowledge Base',
        base267: 'Dialog with AI Intelligence',
        base268: 'Please Select',
        base269: 'Name',
        base270: 'View File Content',
        base271: 'View text',
        base272: 'Rename',
        base273: 'Restore Topic',
        base274: 'Please enter the new topic name',
        base275: 'Renamed Successfully',
        base276: 'Click to View Source',
        base277: 'Found',
        base278: 'Sources',
        base279: 'Internet Sources',
        base280: 'Sources',
        base281: 'File size exceeds 20MB',
        base282: 'File name is too long, upload failed!',
        base283: 'Unsupported file type',
        base284: 'Unsupported image type',
        base285: 'Team knowledge base using artificial intelligence and deep learning',
        base286: 'Explore new knowledge, verify new topics',
        base287: 'Prompt word templates can perform multiple capability workflows',
        base288: "<strong>Owner permissions</strong>: View knowledge base details, edit knowledge base, pigeonhole knowledge base, view files/folders, create new folders, upload files/folders, delete files/folders, verify topics/Q&A, and ask questions about the knowledge base. <br/><strong>Creator</strong> permissions: view knowledge base details, view files/folders, create new folders, upload files/folders, verify topics/Q&A, and ask questions about the knowledge base. <br/>Normal member permissions: View knowledge base details, view files/folders, and ask questions about the knowledge base.",
        base289: 'Recently Updated',
        base290: 'Authoritative Time Limit',
        base291: 'Authoritative Document',
        base292: 'Expired',
        base293: 'The current application already has others in the review process, which may cause a conflict. If multiple verifiers publish verifications at the same time, the one who publishes later needs to resolve the conflict with the content published earlier. Are you sure you want to continue with the verification?',
        base294: 'Enter Verification',
        base295: 'Document',
        base296: 'Topic',
        base297: 'Prompt',
        base298: 'Already verified that the current verification request has been reviewed and published, you do not need to verify',
        base299: 'Okay',
        base300: 'Support up to three file uploads',
        base301: 'Support up to three image uploads',
        base302: 'Click to upload knowledge base avatar',
        base303: 'Cut',
        base304: 'Paste',
        base305: 'You can customize your own intelligent assistant in the AI intelligence and set it as the default intelligent assistant for the AI knowledge base. When you do not actively choose, NextAI will configure "Knowledge Retrieval Officer" as the default intelligent assistant for the AI knowledge base.',
        base306: 'None',
        base307: 'Edited Successfully',
        base308: 'Uploading',
        base309: 'Topic',
        base310: 'Outputting information, please wait',
        base311: 'Knowledge base session source',
        base312: 'The cut is successful',
        base313: 'Transfer',
        base314: 'Analysis successful',
        base315: 'Success',
        base316: 'Transfer successful',
        base317: 'verify',
        base319: 'The authoritative topic has been cited.',
        base320: 'Reference source',
        base321: 'Not viewable',
        base322: 'Move to the top',
        base323: 'Department view',
        base324: 'Group view',
        base325: "Import the team's SharePoint database and documents into the AI knowledge base",
        base326: 'Synchronous knowledge scope',
        base327: 'Configure SharePoint knowledge sources',
        base328: 'Go to the Microsoft Account Verification page, verify your Microsoft business account, and select the knowledge areas you need to sync. ',
        base329: 'Verify Microsoft account permissions',
        base330: 'Select the scope of knowledge to be synchronized',
        base331: 'Select the range of knowledge you want to synchronize',
        base332: 'SharePoint',
        base333: "I'm your chat assistant and now you can chat with me!",
        base334: 'Enter SharePoint site',
        base335: 'Enter the SharePoint site and click the Verify button to verify your Microsoft business account',
        base336: 'Please enter site address',
        base337: 'Previous',
        base338: 'AI Knowledge Base Details',
        base339: 'Sync Knowledge Base',
        base340: 'Close',
        base341: 'In synchronization....',
    },
};

<template>
  <div class="baseInner">
    <!-- tab切换 -->
    <div class="tabDiv">
      <!-- tab切换 -->
      <div class="flexCenter">
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="{
            navItem: navActive != index,
            navItemActive: navActive == index,
          }"
          @click="changeNav(index)"
        >
          {{ t(item) }}
        </div>
      </div>
      <div class="flexCenter">
        <!-- 搜索 -->
        <div class="topicSearch" v-if="navActive == 1">
          <el-input
            :placeholder="
              t('base.base54') + baseItem.knowledge_name + t('base.base55')
            "
            v-model="sessionNamePattern"
            prefix-icon="Search"
            class="topicInput"
            @input="searchList"
          />
          <div class="roleDiv">
            <div class="roleTitle">{{ t("base.base71") }}</div>
            <el-dropdown @command="changeTime">
              <span class="dropText">
                {{ t(timeList[timeValue].label) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in timeList"
                    :key="item.value"
                    :command="item.value"
                  >
                    <span class="dropText">{{ t(item.label) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv">
            <div class="roleTitle">{{ t("base.base73") }}</div>
            <el-dropdown @command="searchTopicList">
              <span class="dropText">
                {{
                  sessionUserNamePattern == 0
                    ? t("base.base10")
                    : sessionUserNamePattern
                }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu class="maxMenu">
                  <el-dropdown-item :command="0">
                    <span class="dropText">{{ t("base.base10") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-for="(item, index) in baseItem.seleteUserInfo"
                    :key="index"
                    :command="item.ownerName"
                  >
                    <span class="dropText">{{ item.ownerName }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- <div :class="pxBtnType ? 'pxDivActive' : 'pxDiv'" @click="changePx">
          <img :src="pxSvg" alt="" class="px" />
        </div> -->
        </div>
        <!-- 发布 -->
        <publishView @openDetail="openDetail" type="base"></publishView>
      </div>
    </div>
    <div class="flex">
      <!-- 左侧 -->
      <div :class="navActive == 0 ? 'leftInner' : 'leftInner1'">
        <div ref="leftDiv" class="leftDiv">
          <!-- tab切换 -->
          <div ref="topNav" style="position: relative">
            <!-- 搜索 -->
            <el-input
              class="searchInput"
              prefix-icon="Search"
              v-if="navActive == 0"
              v-model="searchFileName"
              @input="searchInput"
              :placeholder="
                t('base.base54') + baseItem.knowledge_name + t('base.base55')
              "
            ></el-input>
            <!-- 上传 新增文件夹 历史 -->
            <div
              class="upDiv"
              v-if="navActive == 0 && baseItem.knowledge_role_id != 2"
            >
              <template v-if="baseItem.resourcetype == 0">
                <el-dropdown @command="upFile">
                  <div class="upFileBtn">
                    <img :src="upFiles" alt="" class="upFiles" />
                    <div>{{ t("base.base56") }}</div>
                    <img :src="fileIcon" alt="" class="wid11" />
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <uploadIndex
                        type="base"
                        @beforeAvatarUpload="beforeAvatarUpload"
                      >
                        <el-dropdown-item :command="1">
                          <span class="dropText">{{ t("base.base57") }}</span>
                        </el-dropdown-item>
                      </uploadIndex>
                      <upFloderIndex @upFloder="upFloder">
                        <el-dropdown-item :command="2">
                          <span class="dropText">{{ t("base.base58") }}</span>
                        </el-dropdown-item>
                      </upFloderIndex>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <div class="addFiles" @click="openCreateFloader">
                  <img :src="addFiles" alt="" class="width30" />
                  <div>{{ t("base.base59") }}</div>
                </div>
              </template>
              <template v-if="baseItem.resourcetype == 1 && !nowSyncType">
                <el-tooltip popper-class="toolTip" :content="t('base.base339')">
                  <el-button
                    class="tbDiv"
                    @click="goSync"
                    :loading="syncLoading"
                  >
                    <img :src="baseTb" alt="" class="baseTb" />
                    <div class="syncText">{{ t("base.base339") }}</div>
                  </el-button>
                </el-tooltip>
              </template>
              <template v-if="baseItem.resourcetype == 1 && nowSyncType">
                <el-tooltip popper-class="toolTip" :content="t('base.base341')">
                  <div class="nowSyncTypeDiv">
                    <el-icon class="is-loading marginRight6">
                      <Loading />
                    </el-icon>
                    <div class="syncLoadingText">{{ t("base.base341") }}</div>
                    <div class="syncProgress">
                      <el-progress :percentage="syncProgress" color="#11D7B2" />
                    </div>
                  </div>
                </el-tooltip>
              </template>
              <div class="addFiles" @click="openHistory">
                <img :src="upLog" alt="" class="width30" />
                <div>{{ t("base.base60") }}</div>
              </div>
            </div>
            <!-- 根目录 -->
            <div
              class="fileUpDiv"
              :draggable="true"
              v-if="fileUpType"
              @dragover.prevent="dragover"
              @drop="dropTop"
            >
              <div class="fileUpInnerDiv">
                <img :src="fileUpSvg" class="fileUpSvg" alt="" />
                <span>{{ t("base.base322") }}</span>
              </div>
            </div>
          </div>
          <div
            :style="'height:' + fileListHeight + 'px'"
            class="fileListDiv"
            v-if="navActive == 0 && fileList.length != 0"
          >
            <!-- 文件列表 -->
            <div
              :style="'height:' + fileListHeight + 'px'"
              class="fileListDiv"
              v-loading="leftFileLoading"
              @click="rightType = false"
              @contextmenu.prevent.stop="openRight($event, {})"
            >
              <el-tree
                ref="treeRef"
                class="VueDraggable"
                :data="fileList"
                node-key="id"
                draggable
                :allow-drop="allowDrop"
                :highlight-current="true"
                :filter-node-method="treeFilter"
                @keydown.prevent="copy($event)"
                @node-click="handleNodeClick"
                @node-drag-start="dragStart"
                @node-drop="dropEnd"
                @node-contextmenu="openRight"
                @node-drag-end="nodeDragEnd"
              >
                <template #default="{ data }">
                  <div class="fileItemDiv">
                    <div class="fileBetween">
                      <div class="flexCenter treeDiv">
                        <img
                          :src="getBaseFileIcon(data)"
                          alt=""
                          class="fileIcon"
                        />

                        <el-tooltip
                          popper-class="toolTip"
                          :content="data.fileName || data.folderName"
                        >
                          <div
                            :class="{
                              fileTitle:
                                data.expireFlag == 1 && data.importantFlag == 1,
                              fileTitle1:
                                data.expireFlag == 1 || data.importantFlag == 1,
                              fileTitle2:
                                data.expireFlag != 1 && data.importantFlag != 1,
                            }"
                          >
                            {{ data.fileName || data.folderName }}
                          </div>
                        </el-tooltip>
                        <el-tooltip
                          popper-class="toolTip"
                          :content="t('base.base291')"
                          v-if="data.importantFlag == 1"
                        >
                          <img :src="fileYear" alt="" class="fileYear" />
                        </el-tooltip>
                      </div>
                      <el-dropdown
                        @command="changeFileMore($event, data)"
                        v-if="
                          data.folderName && baseItem.knowledge_role_id == 0
                        "
                      >
                        <div class="fileMoreImgDiv">
                          <img :src="fileMore" alt="" class="fileMoreImg" />
                        </div>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item :command="5">
                              <span class="dropText redColor">{{
                                t("base.base68")
                              }}</span>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                      <el-dropdown
                        @command="changeFileMore($event, data)"
                        v-if="baseItem.knowledge_role_id != 2 && data.fileName"
                      >
                        <div class="fileMoreImgDiv">
                          <img :src="fileMore" alt="" class="fileMoreImg" />
                        </div>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item :command="1" v-if="data.fileName">
                              <span class="dropText blueColor">{{
                                t("base.base62")
                              }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="3"
                              v-if="data.fileName && data.importantFlag == 1"
                            >
                              <span class="dropText blueColor">{{
                                t("base.base65")
                              }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="7"
                              v-if="
                                data.fileName &&
                                (data.importantFlag == 0 ||
                                  data.importantFlag == null)
                              "
                            >
                              <span class="dropText blueColor">{{
                                t("base.base66")
                              }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="4"
                              v-if="
                                data.fileName && baseItem.knowledge_role_id == 0
                              "
                            >
                              <span class="dropText redColor">{{
                                t("base.base67")
                              }}</span>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
          <!-- 无数据 -->
          <div
            :style="'height:' + fileListHeight + 'px'"
            class="fileListDiv nodataCenter"
            v-if="navActive == 0 && fileList.length == 0"
          >
            <el-empty />
          </div>
          <!-- 话题广场列表 -->
          <div
            class="talkDiv"
            v-if="navActive == 1"
            :style="'height:' + fileListHeight + 'px'"
          >
            <div
              v-for="(item, index) in topicList"
              :key="index"
              :class="{
                topMenuActive: topicActive == index,
              }"
              class="topMenu"
              @click="changeTopic(item, index)"
            >
              <img :src="item.url" alt="" class="wid24 marginRight13" />
              <div>{{ t(item.name) }}</div>
            </div>
          </div>
          <!-- 针对微模型提问 -->
          <div ref="topBtn" class="drapBottomDiv">
            <div @dragover.prevent="dragover" @drop="bottomDrop">
              <div class="drapFlex" v-if="bottomFileList.length == 0">
                <img :src="drapBottom" alt="" class="drapBottom" />
                <div class="drapBottomText">
                  {{ t("base.base69") }}
                </div>
              </div>
              <div v-else class="bottomMax">
                <div
                  v-for="(item, index) in bottomFileList"
                  :key="item.id"
                  class="bottomFileItem"
                >
                  <div class="fileBetween">
                    <div class="flexCenter bottomTreeDiv">
                      <img
                        :src="getBaseFileIcon(item)"
                        alt=""
                        class="fileIcon"
                      />
                      <el-tooltip
                        popper-class="toolTip"
                        :content="item.fileName || item.folderName"
                        v-if="item.fileName || item.folderName"
                      >
                        <div
                          :class="{
                            fileTitle:
                              item.expireFlag == 1 && item.importantFlag == 1,
                            fileTitle1:
                              item.expireFlag == 1 || item.importantFlag == 1,
                            fileTitle2:
                              item.expireFlag != 1 && item.importantFlag != 1,
                          }"
                        >
                          {{ item.fileName || item.folderName }}
                        </div>
                      </el-tooltip>
                      <el-tooltip
                        popper-class="toolTip"
                        :content="item.sessionName"
                        v-if="item.sessionName"
                      >
                        <div class="fileTitle2">
                          {{ item.sessionName }}
                        </div>
                      </el-tooltip>
                      <el-tooltip
                        popper-class="toolTip"
                        :content="t('base.base291')"
                        v-if="item.importantFlag == 1"
                      >
                        <img :src="fileYear" alt="" class="fileYear" />
                      </el-tooltip>
                    </div>
                    <img
                      :src="closeFileBottom"
                      alt=""
                      class="closeFileBottom"
                      @click="deleteBottomFile(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <el-button class="qaBtn" @click="goChat">{{
              t("base.base70")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 话题广场 -->
      <div class="rightTopic" v-if="navActive == 1">
        <!-- 话题 -->
        <div class="baseItemDiv" v-loading="topicLoading">
          <!-- 换指定的数据即可 -->
          <div class="VueDraggable overAuto" @scroll="scrollLoad">
            <lookView
              :type="'base'"
              :list="rightList"
              :deleteType="false"
              :like="topicActive == 2 ? true : false"
              :draggable="true"
              v-if="rightList.length != 0"
              @goLookView="goLookView"
              @reshList="reshList"
              @topicDragStart="topicDragStart"
            ></lookView>
            <div class="noDataDiv" v-else>
              <img :src="noData" class="noData" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 预览文件 -->
      <fileView
        ref="fileViewRef"
        class="rightInner"
        :fileItem="fileItem"
        :chunkResource="[]"
        :chunkDialog="chunkDialog"
        :qaType="false"
        type="base"
        @closeFileView="closeFileView"
        @downFile="downFile"
        @deleteFile="deleteFile"
        @getFileList="getFileList"
        v-if="navActive == 0 && fileItem.id"
      >
      </fileView>
      <!-- 无文件展示 -->
      <div
        class="noDataFile"
        v-if="navActive == 0 && Object.keys(fileItem).length == 0"
      >
        <img :src="noFileImg" alt="" class="noFileImg" />
      </div>
    </div>
    <!-- 新建文件夹 -->
    <div v-if="floderDialog" class="dialog">
      <div class="dialogBetWeen">
        <div class="dialogTitle">{{ t("base.base74") }}</div>
        <img
          @click="closeAddFolder"
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
        />
      </div>
      <div class="dialogTip">
        {{ t("base.base75") }} <span style="color: #f02d63">*</span>
      </div>
      <el-input
        maxlength="100"
        :placeholder="t('base.base76')"
        v-model="floderName"
        show-word-limit
        class="dialogInput"
      ></el-input>
      <div class="dialogEnd">
        <el-button @click="closeAddFolder" class="dialogCancelBtn">{{
          t("base.base45")
        }}</el-button>
        <el-button
          @click="saveFloader"
          class="dialogSaveBtn"
          :loading="folderLoading"
        >
          {{ t("base.base46") }}
        </el-button>
      </div>
    </div>
    <div class="mask" v-if="floderDialog"></div>
    <!-- 上传文件 -->
    <div v-if="upFileDialog" class="upFileDialog">
      <div class="upFileTitleDiv dialogBetWeen">
        <div class="upFileTitle">
          {{ upTypeActive == 1 ? t("base.base57") : t("base.base58") }}
        </div>
        <img
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
          @click="closeUpDialog"
        />
      </div>
      <div class="upFileBody">
        <div class="upFileTip" v-if="upFileList.length != 0">
          {{ upTypeActive == 1 ? t("base.base77") : t("base.base78") }}
        </div>
        <div class="upFileItemDiv" v-if="upFloderName">
          <div class="upFileLeftDiv">
            <img
              :src="zkr"
              alt=""
              v-if="collType"
              class="zk"
              @click="collType = !collType"
            />
            <img
              :src="zkb"
              alt=""
              v-if="!collType"
              class="zk"
              @click="collType = !collType"
            />
            <img :src="fileFolder" alt="" class="fileIcon" />
            <div class="upListItemTitle">{{ upFloderName }}</div>
          </div>
          <div class="upFileItemTip">{{ t("base.base79") }}</div>
        </div>
        <el-collapse-transition>
          <div v-show="(upFloderName && !collType) || !upFloderName">
            <div v-for="(item, index) in upFileList.slice(0, 10)" :key="index">
              <div class="upFileItemDiv">
                <div class="upFileLeftDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div class="upListItemTitle">{{ item.name }}</div>
                </div>
                <div class="upFileItemTip">{{ t("base.base79") }}</div>
              </div>
            </div>
            <div v-if="isExpanded">
              <div v-for="(item, index) in upFileList.slice(10)" :key="index">
                <div class="upFileItemDiv">
                  <div class="upFileLeftDiv">
                    <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                    <div class="upListItemTitle">{{ item.name }}</div>
                  </div>
                  <div class="upFileItemTip">{{ t("base.base79") }}</div>
                </div>
              </div>
            </div>
            <div
              class="zkText"
              @click="isExpanded = !isExpanded"
              v-if="upFileList.length > 10"
            >
              {{ isExpanded ? t("base.base80") : t("base.base81") }}
            </div>
          </div>
        </el-collapse-transition>
        <div class="upFileTip" v-if="errorFileList.length != 0">
          {{ t("base.base82") }}
        </div>
        <div>
          <div v-for="(item, index) in errorFileList" :key="index">
            <div class="upFileItemDiv">
              <div class="upFileLeftDiv">
                <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                <div class="upListItemTitle">{{ item.name }}</div>
              </div>
              <div class="upFileItemTip">{{ item.type }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialogEnd">
        <el-button class="dialogCancelBtn" @click="closeUpDialog">{{
          t("base.base45")
        }}</el-button>
        <el-button
          class="dialogSaveBtn"
          v-if="upFileList.length != 0"
          @click="saveDialog"
          >{{ t("base.base56") }}</el-button
        >
      </div>
    </div>
    <!-- 历史记录 -->
    <div v-if="historyDialog" class="historyDialog">
      <div class="dialogBetWeen">
        <div class="upFileTitle">{{ t("base.base83") }}</div>
        <img
          :src="closeFileViewImg"
          alt=""
          class="closeFileViewImg"
          @click="historyDialog = false"
        />
      </div>
      <div class="historyDiv" ref="historyBody">
        <el-table
          :data="historyTableData"
          border
          :height="tableHistory"
          v-loading="historyLoading"
        >
          <el-table-column
            prop="fileName"
            :label="t('base.base84')"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div class="tableFileNameDiv">
                <img :src="getBaseFileIcon(row)" alt="" class="fileIcon" />
                <div class="tableText tableMore">{{ row.fileName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="fileSize"
            :label="t('base.base85')"
            align="center"
          >
            <template #default="{ row }">
              <div class="tableText">
                {{ formatFileSize(Number(row.fileSize)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" :label="t('base.base86')"
            ><template #default="{ row }">
              <div class="tableText">
                {{ getTime(row.finishTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="finishStatus"
            :label="t('base.base87')"
            align="center"
          >
            <template #default="{ row }">
              <div :class="row.finishStatus == 1 ? 'hisSuccess' : 'hisError'">
                {{
                  row.finishStatus == 1 ? t("base.base88") : t("base.base89")
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="t('base.base36')"
            align="center"
          >
            <template #default="{ row }">
              <el-button
                class="tableClearBtn"
                @click="clearItemHistory(row.taskId)"
                >{{ t("base.base90") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialogEnd">
        <el-button class="dialogSaveBtn" @click="clearItemHistory('all')">{{
          t("base.base91")
        }}</el-button>
      </div>
    </div>
    <div class="maskFile" v-if="upFileDialog || historyDialog"></div>
    <!-- 上传进度条 -->
    <div class="processDialog" v-if="processDialog">
      <div class="processBetWeen">
        <div class="processTop">
          <img :src="ok" alt="" class="img36" v-if="processList.length == 0" />
          <div class="processTopTitle">
            {{
              processList.length == 0
                ? t("base.base92")
                : t("base.base93") + processList.length
            }}
          </div>
        </div>
        <div>
          <img
            :src="down"
            alt=""
            class="img36"
            v-if="!processType"
            @click="processType = !processType"
          />
          <img
            :src="up"
            alt=""
            class="img36"
            v-else
            @click="processType = !processType"
          />
          <img
            v-if="processList.length == 0"
            :src="closeSvg"
            alt=""
            class="img36"
            @click="processDialog = false"
          />
        </div>
      </div>
      <el-collapse-transition>
        <div v-if="!processType">
          <!-- 总进度条 -->
          <!-- 成功 -->
          <!-- <div class="processItemDiv">
            已完成上传2个文件夹，共12个文件，失败1个文件
          </div> -->
          <!-- 进度中 -->
          <div class="processItemDiv" v-if="processList.length != 0">
            <div :style="'width:' + getContSum + '%'" class="processItem"></div>
            <div class="zindex">{{ t("base.base94") }}{{ getContSum }}%</div>
            <div class="zindex">
              <el-button
                link
                class="cancelBtn"
                :disabled="cancelType"
                @click="postFile({}, 'allDelete')"
                >{{ t("base.base95") }}</el-button
              >
              <!-- <el-button
                link
                class="stopBtn"
                :disabled="pauseType"
                v-if="!startType"
                @click="postFile({}, 'allPause')"
                >{{ t('base.base96') }}</el-button
              >
              <el-button
                link
                class="stopBtn"
                :disabled="startType"
                v-if="startType && !pauseType"
                @click="postFile({}, 'allStart')"
                >{{ t('base.base97') }}</el-button
              > -->
            </div>
          </div>
          <!-- 文件列表 -->
          <!-- <div class="upFileItemDiv">
            <div class="upFileLeftDiv">
              <img
                :src="zkr"
                alt=""
                v-if="processCollType"
                class="zk"
                @click="processCollType = !processCollType"
              />
              <img
                :src="zkb"
                alt=""
                v-if="!processCollType"
                class="zk"
                @click="processCollType = !processCollType"
              />
              <img :src="fileFolder" alt="" class="fileIcon" />
              <div class="upListItemTitle">法律文书</div>
            </div>
            <div class="upFileItemTip">
              <el-progress :percentage="50" />
            </div>
          </div> -->
          <el-collapse-transition>
            <div v-show="!processCollType" class="fileListItemUp">
              <div v-for="(item, index) in processList" :key="index">
                <div class="upFileItemDiv">
                  <div class="upFileLeftDiv">
                    <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                    <div class="upListItemTitle">{{ item.fileName }}</div>
                  </div>
                  <div class="upFileItemTip">
                    <el-progress :percentage="Math.floor(item.process * 100)" />
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </el-collapse-transition>
    </div>
    <!-- 过期 -->
    <timeView
      v-if="timeDialog"
      :loading="timeLoading"
      @closeTime="closeTime"
      @saveTime="saveTime"
    ></timeView>
    <!-- 编辑 -->
    <addView
      v-if="addDialog"
      :addType="addType"
      :addLoading="addLoading"
      :editObj="editObj"
      @closeAdd="closeAdd"
      @saveEdit="saveEdit"
    ></addView>
    <!-- 针对话题弹窗 -->
    <newChat
      v-if="chatDialog"
      type="base"
      @closeChat="closeChat"
      :bottomFileList="bottomFileList"
    ></newChat>
    <!-- 右键菜单 -->
    <div
      v-if="rightType"
      class="menuDiv"
      :style="'left:' + leftPos + 'px;top:' + topPos + 'px'"
    >
      <div class="menuItem" @click="copyClose" v-if="copyType">
        {{ t("base.base303") }}
      </div>
      <div
        class="menuItem"
        @click="rightCopy"
        v-if="Object.keys(moveItem).length != 0"
      >
        {{ t("base.base304") }}
      </div>
    </div>
    <!-- 详情 -->
    <indexD
      v-if="detailType"
      :editObj="editObj"
      @closeDetail="detailType = false"
      @openEdit="openEdit"
    ></indexD>
    <spView v-if="spDialog" @closeSp="closeSp" :type="2"></spView>
  </div>
</template>

<script setup>
import upFiles from "@/assets/base/upFiles.svg";
import drapBottom from "@/assets/base/drapBottom.svg";
import addFiles from "@/assets/base/addFiles.svg";
import baseTb from "@/assets/base/baseTb.svg";
import pxSvg from "@/assets/base/px.svg";
import upLog from "@/assets/base/upLog.svg";
import fileIcon from "@/assets/base/fileIcon.svg";
import fileFolder from "@/assets/base/fileFolder.svg";
import fileYear from "@/assets/base/fileYear.svg";
import fileMore from "@/assets/base/fileMore.svg";
import topic1 from "@/assets/base/topic1.svg";
import topic2 from "@/assets/base/topic2.svg";
import topic3 from "@/assets/base/topic3.svg";
import topic4 from "@/assets/base/topic4.svg";
import fileUpSvg from "@/assets/base/fileUp.svg";
import topic5 from "@/assets/base/topic5.svg";
import down from "@/assets/upload/down.svg";
import up from "@/assets/upload/up.svg";
import ok from "@/assets/upload/ok.svg";
import closeSvg from "@/assets/upload/close.svg";
import closeFileBottom from "@/assets/base/closeFileBottom.svg";
import closeFileViewImg from "@/assets/base/closeFileView.svg";
import noFileImg from "@/assets/base/noFile.svg";
import uploadIndex from "@/components/upload/index.vue";
import upFloderIndex from "@/components/upFloder/index.vue";
import fileView from "@/components/fileView/index.vue";
import lookView from "@/components/look/index.vue";
import zkb from "@/assets/base/zkb.svg";
import zkr from "@/assets/base/zkr.svg";
import api from "@/api/index";
import indexD from "./indexD.vue";
import spView from "./spView.vue";
import { getBaseFileIcon, formatFileSize } from "@/utils/file";
import { ref, onMounted, nextTick, computed } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { getTime } from "@/utils/utils";
import timeView from "./time.vue";
import addView from "./add.vue";
import noData from "@/assets/nodata.svg";
import newChat from "@/components/newChat/index.vue";
import publishView from "@/components/publish/index.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const baseItem = ref(store.state.baseItem);
const fileList = ref([]);
const fileItem = ref({});
const navList = ref(["base.base295", "base.base296"]);
const topicActive = ref(0); //话题广场选择
const rootId = ref(null); //root文件夹目录
const sessionNamePattern = ref("");
const topicList = ref([
  {
    name: "base.base98",
    url: topic1,
  },
  {
    name: "base.base99",
    url: topic2,
  },
  {
    name: "base.base100",
    url: topic3,
  },
  {
    name: "base.base101",
    url: topic5,
  },
]);
const navActive = ref(0); //nav菜单选项
const userId = ref(store.state.userId); //userId
const chunkDialog = ref(false); //文件预览是否展示
const floderDialog = ref(false); //新建文件夹
const floderName = ref(""); //新建文件夹名称
const fileActive = ref(null); //选择的那个文件
const topNav = ref(null); //ref计算使用
const topBtn = ref(null); //ref计算使用
const leftDiv = ref(null);
const fileListHeight = ref(null);
const bottomFileList = ref([]); //底部拖动
const pxBtnType = ref(false); //排序
const upFileList = ref([]); //要上传的文件列表
const upFileDialog = ref(false); //上传框状态
const upTypeActive = ref(null); //判断上传文件还是文件夹
const errorFileList = ref([]); //不符合条件的文件
const upFloderName = ref(""); //上传文件夹的名称
const collType = ref(false); //文件夹展开 上传
const isExpanded = ref(false); //默认不展开
const timmer = ref(null); //轮询使用
const historyDialog = ref(false); //历史上传dialog
const historyBody = ref(null); //获取histable高度
const tableHistory = ref(null);
const historyTableData = ref([]); //历史数据
const historyLoading = ref(false); //历史记录loading
const processCollType = ref(false); //上传文件夹展开与关闭
const processDialog = ref(false); //上传进度
const processType = ref(false); //上传进度展开收起
const searchLoading = ref(false); //搜索loading
const searchFileList = ref([]); //搜索数组
const leftFileLoading = ref(false); //左侧列表loading
const folderLoading = ref(false); //创建文件夹loading
const processList = ref([]); //进度
const pauseType = ref(false); //暂停
const startType = ref(false); //开始
const cancelType = ref(false); //取消
const timeDialog = ref(false); //过期弹窗
const timeLoading = ref(false); //过期loading
const timeObj = ref({});
const rightList = ref([]);
const topicLoading = ref(false);
const addType = ref(""); //打开编辑
const editObj = ref({}); //编辑信息存储
const addDialog = ref(false); //编辑弹窗
const addLoading = ref(false); //编辑保存
const chatDialog = ref(false); //针对微模型对话
const moveItem = ref({});
const moveType = ref(false);
const rightType = ref(false); //右键弹出展示
const leftPos = ref(); //位置
const topPos = ref(); //位置
const copyItem = ref({}); //右键内容
const copyType = ref(false); //是否展示复制
const topicPageId = ref(1);
const fileViewRef = ref();
const sessionUserNamePattern = ref("");
const startDate = ref("");
const endDate = ref("");
const timeValue = ref(0);
const detailType = ref(false);
const searchFileName = ref("");
const treeRef = ref(null);
const fileUpType = ref(false); //是否展示移动最上方
const topType = ref(false);
const timeList = ref([
  {
    label: "base.base10",
    value: 0,
  },
  {
    label: "base.base172",
    value: 1,
  },
  {
    label: "base.base173",
    value: 2,
  },
  {
    label: "base.base174",
    value: 3,
  },
  {
    label: "base.base175",
    value: 4,
  },
]);
const getContSum = computed(() => {
  // 获取所有值 / 个数 *100
  let totalProcess =
    processList.value.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.process;
    }, 0) / processList.value.length;
  totalProcess = totalProcess >= 1 ? 100 : totalProcess * 100;
  return Math.floor(totalProcess);
});
onMounted(() => {
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_TOPIC", baseItem.value);
  if (baseItem.value.resourcetype == 1) {
    pollingSyncFn();
    getSyncProcess();
  } else {
    pollingFn();
    task();
  }
  getFileList();
  window.addEventListener("resize", () => {
    if (historyDialog.value) {
      tableHistory.value = historyBody.value.offsetHeight;
    }
    getFileHeight();
  });
  getFileHeight();
});
// 离开结束所有任务
onBeforeRouteLeave((to, from, next) => {
  clearInterval(syncTime.value);
  syncTime.value = null;
  clearInterval(timmer.value);
  timmer.value = null;
  // 解绑resize
  window.removeEventListener("resize", () => {});
  next();
});
// 同步
const syncLoading = ref(false);
// 开始同步
const goSync = () => {
  syncLoading.value = true;
  api.base
    .asyncBase({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      syncLoading.value = false;
      if (res.returnCode == 200) {
        getSyncProcess();
      } else {
        spDialog.value = true;
      }
    })
    .catch((err) => {
      syncLoading.value = false;
    });
};
// 获取进度
const nowSyncType = ref(false);
const syncProgress = ref(10);
const syncTime = ref(null);
const spDialog = ref(false);
const getSyncProcess = () => {
  api.base
    .getProcess({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.progress != null) {
          nowSyncType.value = true;
          syncProgress.value = res.data.progress;
          pollingSyncFn();
        } else {
          nowSyncType.value = false;
          clearInterval(syncTime.value);
          syncTime.value = null;
        }
      }
    })
    .catch((err) => {
      clearInterval(syncTime.value);
      syncTime.value = null;
    });
};
// 轮询
const pollingSyncFn = () => {
  clearInterval(syncTime.value);
  syncTime.value = null;
  syncTime.value = setInterval(() => {
    setTimeout(() => {
      getSyncProcess();
    }, 0);
  }, 2000);
};
// 关闭sp
const closeSp = () => {
  spDialog.value = false;
};
// ctrl x
const copy = (event) => {
  // 剪切
  if (
    event.keyCode == 88 &&
    (event.ctrlKey || event.metaKey) &&
    fileItem.value.fileName
  ) {
    message.success(t("base.base312"));
  }
  // 粘贴
  if (
    event.keyCode == 86 &&
    (event.ctrlKey || event.metaKey) &&
    fileActive.value
  ) {
    moveListPublic([fileItem.value.id], fileActive.value, true);
  }
};
// 筛选tree
const treeFilter = (value, data) => {
  if (!value) return true;
  if (data.fileName) {
    return data.fileName.includes(value);
  } else {
    return data.folderName.includes(value);
  }
};
const searchInput = () => {
  treeRef.value.filter(searchFileName.value);
};
// 禁止拖拽的目标
const allowDrop = (a, b, type) => {
  if (type == "inner") {
    return true;
  } else {
    return false;
  }
};
// 拖动开始
const dragStart = (item, event) => {
  fileUpType.value = true;
  event.dataTransfer.setData("text/plain", JSON.stringify(item.data));
};
// 移动未完成
const nodeDragEnd = () => {
  fileUpType.value = false;
};
// 移动到最顶层
const dropTop = (event) => {
  topType.value = true;
  const itemData = event.dataTransfer.getData("text/plain");
  const item = JSON.parse(itemData || "{}");
  moveListPublic([item.id], rootId.value, false);
};
// 拖动结束进行粘贴
const dropEnd = (a, b, c, d) => {
  if (topType.value) return;
  if (b.data.fileName) {
    moveListPublic([a.data.id], b.data.folder, false);
  } else {
    moveListPublic([a.data.id], b.data.id, false);
  }
};
const topicDragStart = (event, item) => {
  event.dataTransfer.setData("text/plain", JSON.stringify(item));
};
const moveListPublic = (ids, id, type) => {
  if (ids[0] != undefined) {
    api.base
      .companyFileMove(
        {
          userId: userId.value,
          fileIds: ids,
          folderId: id ? id : rootId.value,
        },
        baseItem.value.knowledge_library_id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          fileUpType.value = false;
          topType.value = false;
          getFileList();
          moveType.value = false;
          moveType.value = false;
          rightType.value = false;
          copyItem.value = {};
          moveItem.value = {};
          fileActive.value = null;
          if (type) {
            message.success(t("base.base102"));
          }
        }
      });
  }
};
const openRight = (event, item) => {
  leftPos.value = event.pageX;
  topPos.value = event.pageY;
  rightType.value = true;
  if (Object.keys(item).length != 0) {
    if (!item.folderName) {
      copyType.value = true;
      copyItem.value = item;
    } else {
      fileActive.value = item.id;
    }
  } else {
    copyType.value = false;
    // 只展示粘贴
  }
};
// 剪切
const copyClose = () => {
  copyType.value = false;
  moveItem.value = copyItem.value;
  rightType.value = false;
};
// 粘贴
const rightCopy = () => {
  moveListPublic(
    [moveItem.value.id],
    fileActive.value ? fileActive.value : rootId.value,
    true
  );
};
// 去对话历史记录
const goLookView = (item) => {
  item.knowledge_library_id = baseItem.value.knowledge_library_id;
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_TOPIC", item);
  if (item.isValid == 1) {
    router.push({
      name: "chat",
      query: {
        type: "look",
      },
    });
  } else {
    router.push({
      name: "chat",
      query: {
        type: item.isOwner == 1 ? "chat" : "look",
      },
    });
  }
};
// 编辑保存
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
    imageName: form.imageName,
    resourcePlatform: form.resourcePlatform,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base103"));
        addDialog.value = false;
        updataEdit();
      }
    })
    .catch((err) => {
      addLoading.value = true;
    });
};
// 详情更新
const updataEdit = () => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: editObj.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.seleteUserInfo = res.data.userInfo.filter(
          (item) => item.ownerType == 0 && item.ownerName
        );
        store.commit("SET_BASEITEM", res.data);
      }
    });
};
// 打开编辑
const openEdit = () => {
  detailType.value = false;
  addDialog.value = true;
  addType.value = "edit";
};
// 打开编辑
const openDetail = () => {
  // 编辑
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        editObj.value = res.data;
        detailType.value = true;
      }
    });
};
// 关闭编辑
const closeAdd = () => {
  addType.value = "";
  editObj.value = {};
  addDialog.value = false;
};
// 清除所有历史
const clearItemHistory = (id) => {
  historyLoading.value = true;
  api.base
    .clearHistory(
      { userId: userId.value, taskId: id },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        openHistory();
        message.success(t("base.base104"));
      }
    });
};
// 打开历史
const openHistory = () => {
  historyLoading.value = true;
  api.base
    .getHistoryFiles(
      {
        endTime: null,
        filenamePattern: "",
        pageId: 1,
        perPage: 10000,
        startTime: null,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        historyLoading.value = false;
        historyDialog.value = true;
        historyTableData.value = res.data;
        nextTick(() => {
          tableHistory.value = historyBody.value.offsetHeight;
        });
      }
    });
};
// 删除底部
const deleteBottomFile = (index) => {
  bottomFileList.value.splice(index, 1);
  getFileHeight();
};
// 下载文件
const downFile = (item) => {
  api.base
    .wordView(
      {
        fileId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      const imageUrl = res.data.url;
      const link = document.createElement("a");
      link.href = imageUrl;
      link.setAttribute("download", "文件");
      link.click();
    });
};
// 删除文件
const deleteFile = () => {
  deleteFileFn(fileItem.value);
};
const dragover = (event) => {
  event.preventDefault();
};
// 移动到底部
const bottomDrop = (event) => {
  topType.value = true;
  fileUpType.value = false;
  const itemData = event.dataTransfer.getData("text/plain");
  const item = JSON.parse(itemData || "{}");
  bottomFileList.value.push(item);
  onAdd();
  getFileList();
};
const onAdd = () => {
  setBottomFileList();
  setTimeout(() => {
    getFileHeight();
  }, 100);
};
// 去重底部
const setBottomFileList = () => {
  let arr = JSON.parse(JSON.stringify(bottomFileList.value));
  // 筛选出包含id属性的元素
  let idArr = arr.filter((item) => item.id !== undefined);
  idArr = Array.from(new Map(idArr.map((item) => [item.id, item])).values());
  // 筛选出包含sessionId属性的元素
  let sessionIdArr = arr.filter((item) => item.sessionId !== undefined);
  sessionIdArr = Array.from(
    new Map(sessionIdArr.map((item) => [item.sessionId, item])).values()
  );
  bottomFileList.value = idArr.concat(sessionIdArr);
};
// 动态计算高度
const getFileHeight = () => {
  nextTick(() => {
    if (leftDiv.value && topNav.value && topBtn.value) {
      fileListHeight.value =
        leftDiv.value.offsetHeight -
        (topNav.value.offsetHeight + topBtn.value.offsetHeight);
    }
  });
};
// 跳转chat
const goChat = () => {
  let fileList = [];
  let folderList = [];
  let topicList = [];
  bottomFileList.value.forEach((item) => {
    if (item.fileName) {
      fileList.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  store.commit("SET_BASEDRAP", { fileList, folderList, topicList });
  store.commit("SET_CHATTYPE", "base");
  chatDialog.value = true;
};
const closeChat = () => {
  chatDialog.value = false;
};
// 创建文件夹
const openCreateFloader = () => {
  floderName.value = "";
  floderDialog.value = true;
};
// 切换话题
const changeTopic = (item, index) => {
  topicActive.value = index;
  startDate.value = "";
  endDate.value = "";
  topicPageId.value = 1;
  rightList.value = [];
  sessionNamePattern.value = "";
  getTopic();
};
// 获取文件
const getFileList = () => {
  leftFileLoading.value = true;
  api.base
    .queryFileList({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      leftFileLoading.value = false;
      if (res.returnCode == 200) {
        rootId.value = res.data[0].id;
        fileList.value = res.data[0].children;
        topType.value = false;
      }
    })
    .catch((err) => {
      leftFileLoading.value = false;
    });
};
// 远程搜索
const selectSearch = (value) => {
  searchLoading.value = true;
  api.base
    .queryFolderFileCompanyApi(
      {
        userId: userId.value,
        folderNamePattern: value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      searchLoading.value = false;
      if (res.returnCode == 200) {
        if (res.data.filesData.length != 0) {
          searchFileList.value = res.data.filesData;
        }
      }
    })
    .catch((err) => {
      searchLoading.value = false;
    });
};
// 关闭新增文件夹
const closeAddFolder = () => {
  floderDialog.value = false;
  floderName.value = "";
};
// 打开预览
const handleNodeClick = (data) => {
  // 进入文件夹
  if (data.folderName) {
    fileActive.value = data.id;
  } else {
    fileItem.value = data;
    chunkDialog.value = true;
  }
};
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base
    .importantSet({
      userId: userId.value,
      fileId: timeObj.value.id,
      expireTime: time ? time : null,
      libraryId: baseItem.value.knowledge_library_id,
      operateType: "add",
    })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success(t("base.base105"));
        getFileList();
        fileViewRef.value.getFileDesc();
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const closeTime = () => {
  timeDialog.value = false;
};
// 操作文件
const changeFileMore = (e, item) => {
  // 下载
  if (e == 1) {
    downFile(item);
  }
  // 取消验证
  if (e == 3) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: item.id,
        expireTime: null,
        libraryId: baseItem.value.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileList();
          fileViewRef.value.getFileDesc();
        }
      });
  }
  // 删除文件夹
  if (e == 5) {
    deleteFolderFn(item);
  }
  // 删除文件
  if (e == 4) {
    deleteFileFn(item);
  }
  // 验证
  if (e == 7) {
    timeObj.value = item;
    timeDialog.value = true;
  }
};
// 删除文件
const deleteFileFn = (item) => {
  console.log(item);
  console.log(fileItem.value);
  api.base
    .deleteFile(
      {
        idArray: [item.id],
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (fileItem.value && item.id == fileItem.value.id) {
          fileItem.value = {};
          fileActive.value = null;
        }
        message.success(t("base.base16"));
        getFileList();
      }
    });
};
// 删除文件夹
const deleteFolderFn = (item) => {
  api.base
    .deleteFolder(
      {
        folderId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        fileActive.value = null;
        message.success(t("base.base16"));
        getFileList();
      }
    });
};
// 排序
const changePx = () => {
  pxBtnType.value = !pxBtnType.value;
};
const searchTopicList = (e) => {
  if (e == 0) {
    sessionUserNamePattern.value = "";
  } else {
    sessionUserNamePattern.value = e;
  }
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
const reshList = () => {
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
// nav
const changeNav = (index) => {
  navActive.value = index;
  topicPageId.value = 1;
  if (index == 1) {
    startDate.value = "";
    endDate.value = "";
    chunkDialog.value = false;
    sessionNamePattern.value = "";
    rightList.value = [];
    getTopic();
  } else {
    fileActive.value = null;
    fileItem.value = {};
    getFileList();
  }
  getFileHeight();
};
// 下拉加载
const scrollLoad = (e) => {
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;
  const clientHeight = e.target.clientHeight;
  if (scrollTop + clientHeight >= scrollHeight - 1) {
    load();
  }
};
const load = () => {
  topicPageId.value++;
  getTopic();
};
const searchList = () => {
  topicPageId.value = 1;
  getTopic();
};
// 获取日期
const getDateFromToday = (type) => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth(); // 月份是从0开始的，所以这里得到的是0-11

  switch (type) {
    case 1: // 一个月前
      month -= 1;
      break;
    case 2: // 一个季度前
      month -= 3;
      break;
    case 3: // 半年前
      month -= 6;
      break;
    case 4: // 一年前
      year -= 1;
      break;
    default:
      return today; // 如果输入不是1-4之间的数字，返回今天
  }

  // 如果月份小于0，调整年份和月份
  if (month < 0) {
    year += Math.ceil(month / 12); // 计算需要回退多少年
    month = 12 + month; // 计算新的月份
  }

  // 创建新的日期对象
  const date = new Date(year, month);

  // 返回格式化的日期字符串
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};
const changeTime = (e) => {
  timeValue.value = e;
  if (e == 0) {
    startDate.value = "";
    endDate.value = "";
    topicPageId.value = 1;
    rightList.value = [];
    getTopic();
    return;
  }
  startDate.value = getDateFromToday(e);
  // 创建一个新的Date对象，它将被初始化为当前的日期和时间
  const now = new Date();
  // 获取年份
  const year = now.getFullYear();
  // 获取月份，月份是从0开始的，所以需要加1
  const month = now.getMonth() + 1;
  // 获取日
  const day = now.getDate();
  endDate.value = year + "-" + month + "-" + day;
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
// 获取话题广场
const getTopic = () => {
  let arr = ["verifyQuery", "hotQuery", "likeQuery", "allQuery"];
  topicLoading.value = true;
  api.base[arr[topicActive.value]](
    {
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
      pageId: topicPageId.value,
      perPage: 10,
      sessionNamePattern: sessionNamePattern.value,
      sessionUserNamePattern: sessionUserNamePattern.value,
      startDate: startDate.value,
      endDate: endDate.value,
    },
    baseItem.value.knowledge_library_id
  )
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (topicPageId.value == 1) {
          rightList.value = res.data;
        } else {
          rightList.value = rightList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 上传选择
const upFile = (index) => {
  upTypeActive.value = index;
};
// 关闭上传文件选项
const closeUpDialog = () => {
  upFileList.value = [];
  errorFileList.value = [];
  isExpanded.value = false;
  collType.value = false;
  upFloderName.value = "";
  upFileDialog.value = false;
};
// 保存上传
const saveDialog = () => {
  if (upTypeActive.value == 1) {
    let id = fileActive.value ? fileActive.value : rootId.value;
    upFileFn(upFileList.value, id);
  } else {
    // 新建文件夹
    api.base
      .createFolderCompanyApi(
        {
          userId: userId.value,
          folderName: upFloderName.value,
          parentId: fileActive.value ? fileActive.value : rootId.value,
        },
        baseItem.value.knowledge_library_id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          handleNodeClick({ folderName: upFloderName.value, id: res.data });
          upFileFn(upFileList.value, res.data);
        }
      });
  }
};
// 上传文件
const beforeAvatarUpload = (fileList, err) => {
  upFileList.value = fileList;
  errorFileList.value = err;
  upFileDialog.value = true;
};
// 上传文件fuction
const upFileFn = (fileList, id) => {
  console.log(id, "diidididididi");
  upFileDialog.value = false;
  api.base
    .uploadFileCompanyApi(
      fileList,
      { folderId: id },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pollingFn();
        task();
        if (res.failedFileList.length != 0) {
          const textFile = res.failedFileList.toString();
          message.warning(textFile + t("base.base106"));
        }
      }
    })
    .catch((err) => {
      message.warning(t("base.base89"));
    });
};
// 轮询
const pollingFn = () => {
  clearInterval(timmer.value);
  timmer.value = null;
  timmer.value = setInterval(() => {
    setTimeout(() => {
      task();
    }, 0);
  }, 2000);
};
// 轮询
const task = () => {
  api.base
    .taskView({ userId: userId.value }, baseItem.value.knowledge_library_id)
    .then((res) => {
      if (res.returnCode == 200) {
        //刷新当前文件夹
        getFileList();
        if (res.data.length == 0) {
          clearInterval(timmer.value);
          timmer.value = null;
          processList.value = [];
        } else {
          processDialog.value = true;
          processList.value = res.data;
          // 判断是否有可暂停的
          let pauseList = processList.value.filter(
            (item) => item.waitableFlag == "1"
          );
          // 有暂停的
          if (pauseList.length != 0) {
            pauseType.value = false;
          } else {
            // 没有 不可点击
            pauseType.value = true;
          }
          // 判断是否有开始的
          let startList = processList.value.filter(
            (item) => item.processStatusFlag == "suspend"
          );
          if (startList.length != 0) {
            startType.value = false;
          } else {
            startType.value = true;
          }
          // 判断是否有取消的
          let cancelList = processList.value.filter(
            (item) =>
              item.processStatusFlag == "ready" ||
              item.processStatusFlag == "suspend"
          );
          if (cancelList.length != 0) {
            cancelType.value = false;
          } else {
            cancelType.value = true;
          }
        }
      }
    });
};
// 判断取消 暂停 开始
const postFile = (item, type) => {
  if (processList.value.length == 0) {
    message.warning(t("base.base107"));
    return;
  }
  if (type == "pause") {
    fileTypeChange(item, "uploadSuspend");
  } else if (type == "start") {
    fileTypeChange(item, "uploadContinue");
  } else if (type == "delete") {
    fileTypeChange(item, "uploadDelete");
  } else if (type == "allPause") {
    fileTypeChange({}, "uploadAllSuspend");
  } else if (type == "allStart") {
    fileTypeChange({}, "uploadAllStart");
  } else if (type == "allDelete") {
    fileTypeChange({}, "uploadAllDelete");
  }
};
// 公共类
const fileTypeChange = (item, name) => {
  api.base[name](
    { userId: userId.value, taskId: item ? item.taskId : "" },
    baseItem.value.knowledge_library_id
  ).then((res) => {
    if (res.returnCode == 200) {
      clearInterval(timmer.value);
      timmer.value = null;
      pollingFn();
      task();
    }
  });
};
// 保存文件夹名称
const saveFloader = () => {
  if (floderName.value == "") {
    message.warning(t("base.base76"));
    return;
  }
  folderLoading.value = true;
  api.base
    .createFolderCompanyApi(
      {
        userId: userId.value,
        folderName: floderName.value,
        parentId: fileActive.value ? fileActive.value : rootId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      folderLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base108"));
        floderDialog.value = false;
        getFileList();
      }
    })
    .catch((err) => {
      folderLoading.value = false;
    });
};
// 上传文件夹
const upFloder = (fileList, name, errorList) => {
  upFileList.value = fileList;
  errorFileList.value = errorList;
  upFloderName.value = name;
  upFileDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  fileItem.value = {};
  fileActive.value = null;
};
</script>

<style lang="scss" scoped>
.baseInner {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  position: relative;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 30px 0px 0px 30px;
}
.flex {
  display: flex;
  height: calc(100% - 76px);
}
.leftInner {
  width: 402px;
  height: calc(100% - 30px);
  padding-bottom: 30px;
}
.leftInner1 {
  width: 270px;
  height: calc(100% - 30px);
  padding-bottom: 30px;
}
.rightTopic {
  width: calc(100% - 422px);
  height: calc(100% - 64px);
  margin-left: 30px;
  padding: 30px 59px;
  border-top: 2px solid #dbdfff;
  border-left: 2px solid #dbdfff;
  border-radius: 30px 0px 0px 0px;
}
.noDataFile {
  width: calc(100% - 436px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  border-top: 2px solid #dbdfff;
  border-left: 2px solid #dbdfff;
  border-radius: 30px 0px 0px 0px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.navItem {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Regular";
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #d9d9d9;
  cursor: pointer;
}
.navItemActive {
  font-size: 20px;
  color: #0256ff;
  font-family: "Regular";
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #0256ff;
  cursor: pointer;
}
.searchInput {
  :deep(.el-input__wrapper) {
    height: 48px;
    border: 1px solid #edeef6;
    margin-bottom: 15px;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
}
.topicInput {
  width: 390px;
  :deep(.el-input__wrapper) {
    height: 44px;
    border: 1px solid #edeef6;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
}
.topicSearch {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: 128px;
}
.upFileBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 8px 13px;
  font-size: 18px;
  font-family: "Regular";
  border: 2px solid #edeef6;
  color: #0256ff;
  cursor: pointer;
}
.addFiles {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 18px;
  font-family: "Regular";
  color: #8a8a8a;
  padding: 8px 20px;
  border: 2px solid #edeef6;
  cursor: pointer;
}
.upDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  padding-bottom: 15px;
}
.width30 {
  margin-right: 6px;
  width: 24px;
}
.upFiles {
  width: 18px;
  margin-right: 9px;
}
.wid11 {
  width: 24px;
  margin-left: 6px;
}
.qaBtn {
  width: 100%;
  height: 49px;
  border-radius: 16px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  background-color: #0256ff;
  border: 0px;
}
.fileListDiv {
  overflow: auto;
  position: relative;
}
.talkDiv {
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: calc(100% - 64px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle1 {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle2 {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileYear {
  width: 18px;
  margin-left: 10px;
}
.fileMoreImg {
  width: 4px;
}
.treeDiv {
  width: calc(100% - 52px);
}
.bottomTreeDiv {
  width: calc(100% - 26px);
  margin-right: 10px;
}
.wid24 {
  width: 24px;
}
.rightInner {
  width: calc(100% - 436px);
  height: calc(100% - 4px);
  margin-left: 30px;
  overflow: hidden;
  border-left: 2px solid #dbdfff;
  border-top: 2px solid #dbdfff;
  border-radius: 30px 0px 0px 0px;
}
.topMenu {
  height: 32px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: "Medium";
  cursor: pointer;
  padding: 17px 88px 15px 49px;
}
.topMenuActive {
  background-color: #f7f8ff;
  color: #0256ff;
}
.marginRight13 {
  margin-right: 13px;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 14px;
  :deep(.el-dropdown) {
    max-width: calc(100% - 70px);
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Regular";
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
  width: 70px;
}
.baseItemDiv {
  height: 100%;
}
.nodataCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileItemDiv {
  height: 26px;
  cursor: pointer;
  width: calc(100% - 24px);
}
.bottomFileItem {
  height: 26px;
  padding: 13px 0px 13px 30px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #eceeff;
  margin-bottom: 8px;
}
.fileMoreImgDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileMoreImgDiv:hover {
  background-color: #dbdfff;
}
.noFileImg {
  width: 360px;
}
.leftDiv {
  width: 100%;
  height: 100%;
  position: relative;
}
.drapBottom {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.drapBottomText {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 强制换行 */
}
.drapFlex {
  display: flex;
  margin-bottom: 15px;
}
.drapBottomDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 12px;
}
.VueDraggable {
  width: 100%;
  height: 100%;
}
.overAuto {
  overflow: auto;
}
.closeFileBottom {
  width: 16px;
}
.bottomMax {
  max-height: 232px;
  overflow: auto;
}
.dialog {
  position: fixed;
  width: 540px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  box-shadow: 0px 0px 15px 0px #dcdcdc;
}
.dialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-bottom: 24px;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 17px;
}
.dialogInput {
  margin-bottom: 24px;
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px;
    height: 48px;
    font-size: 18px;
    font-family: "Regular";
  }
}
.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-family: "Regular";
  font-size: 18px;
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
.szText {
  color: #0256ff;
  font-family: "Regular";
  font-size: 18px;
  cursor: pointer;
}
.px {
  width: 13px;
}
.pxDiv {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
}
.pxDivActive {
  width: 34px;
  height: 34px;
  border: 1px solid #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  background-color: #edeef6;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0;
}
.maskFile {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.5;
}
.upFileDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 600px;
  max-height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.upFileTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.upFileTitleDiv {
  height: 36px;
  padding-bottom: 17px;
  border-bottom: 2px solid #edeef6;
  margin-bottom: 17px;
}
.upFileTip {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
}
.upListItemTitle {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 43px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemTip {
  color: #3376ff;
  font-size: 18px;
  font-family: "Regular";
  text-align: right;
  width: 40%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemDiv {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.upFileLeftDiv {
  width: 60%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileBody {
  max-height: 500px;
  overflow: auto;
}
.zk {
  width: 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.5s ease;
}
.zkText {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
  margin: 10px 0px;
  text-align: center;
  cursor: pointer;
}
.historyDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 820px;
  height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.historyDiv {
  height: calc(100% - 106px);
  margin: 20px 0px;
}
.hisSuccess {
  color: #14ae5c;
  font-family: "Semibold";
  font-size: 18px;
}
.hisError {
  color: #f02d63;
  font-family: "Semibold";
  font-size: 18px;
}
.tableClearBtn {
  height: 31px;
  background-color: #f7f8ff;
  border: 0px;
  border-radius: 8px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
.tableText {
  color: #3d3d3d;
  font-family: "Regular";
  font-size: 18px;
}
.tableMore {
  width: calc(100% - 28px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.tableFileNameDiv {
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.processDialog {
  position: absolute;
  right: 20px;
  bottom: 10px;
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  width: 660px;
  z-index: 100;
  max-height: 424px;
}
.img36 {
  width: 36px;
  cursor: pointer;
}
.processTop {
  display: flex;
  align-items: center;
  width: calc(100% - 72px);
}
.processTopTitle {
  margin-left: 12px;
  color: #3d3d3d;
  font-size: 26px;
  font-family: "Medium";
  max-width: calc(100% - 48px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.processBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.processItemDiv {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  padding: 0px 30px;
  font-size: 18px;
  color: #000000;
  font-family: "Regular";
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.processItem {
  position: absolute;
  left: 0px;
  height: 100%;
  background-color: #dbdfff;
}
.cancelBtn {
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
}
.stopBtn {
  font-size: 18px;
  color: #3376ff;
  font-family: "Regular";
  cursor: pointer;
}
.zindex {
  z-index: 20;
}
.fileListItemUp {
  max-height: 264px;
  overflow: auto;
}
.noData {
  width: 300px;
}
.noDataDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuDiv {
  position: fixed;
  width: 80px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px #a6a6a6;
}
.menuItem {
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-family: "Regular";
  caret: #3d3d3d;
  cursor: pointer;
}
.menuItem:hover {
  background-color: rgba(196, 205, 234);
  cursor: pointer;
}
.maxMenu {
  max-height: 265px;
  overflow: auto;
}
.radioText {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    background-color: transparent;
    border: 1px solid #3376ff;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3376ff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #8a8a8a;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner) {
    border: 1px solid #8a8a8a;
    width: 18px;
    height: 18px;
  }
  :deep(el-radio__label) {
    font-size: 18px;
    font-family: "Regular";
    color: #3d3d3d;
  }
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
}
:deep(.el-tree-node__content) {
  height: 45px;
  border-radius: 6px;
}
.redColor {
  color: #f02d63;
}
.blueColor {
  color: #0256ff;
}
:deep(.el-loading-mask) {
  z-index: 300 !important;
}
.tabDiv {
  height: 52px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  padding-right: 30px;
}
.fileUpSvg {
  width: 20px;
  margin-right: 6px;
}
.fileUpDiv {
  height: 52px;
  border-radius: 8px;
  background-color: #eceeffc7;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: calc(100% - 12px);
  bottom: 10px;
}
.fileUpInnerDiv {
  border: 1px solid #a3acec;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #767bfa;
  font-family: "Medium";
}
.baseTb {
  width: 20px;
  margin-right: 8px;
}
.tbDiv {
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 44px;
  border-radius: 16px;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  justify-content: center;
  margin-right: 12px;
}
.nowSyncTypeDiv {
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 40px;
  border-radius: 16px;
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
  justify-content: center;
  margin-right: 12px;
}
.syncProgress {
  width: 50%;
  margin-left: 5px;
}
:deep(.el-progress__text) {
  font-size: 16px !important;
  font-family: "Medium";
}
.marginRight6 {
  margin-right: 6px;
}
:deep(.el-progress-bar__outer) {
  height: 9px !important;
}
.syncText {
  max-width: calc(100% - 28px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.syncLoadingText {
  max-width: 100px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import {
  message,
  Button,
  UploadDragger,
  Select,
  SelectOption,
  Modal,
  Drawer,
} from "ant-design-vue";
import { FileWordOutlined, ArrowLeftOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
onMounted(() => {
  clearItem();
  getLang();
});
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const fileList = ref([]);
const type = ref("upFile");
const historyType = ref(false);
const userId = ref(store.state.userId);
const langList = ref([]);
const lang = ref(null);
const fileName = ref("");
const fileId = ref(null);
const historyList = ref([]);
const chunkItem = ref({});
const chunkType = ref(false);
const offUrl = ref(`https://view.officeapps.live.com/op/embed.aspx?src=`);
// 关闭
const closeChunk = () => {
  chunkItem.value = {};
  chunkType.value = false;
};
// 打开文件预览
const openChunk = (item) => {
  chunkItem.value = item;
  chunkType.value = true;
};
// 打开文档历史记录
const openHistory = () => {
  historyType.value = true;
  api.bot
    .documentQuery({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        historyList.value = res.data;
      }
    });
};
const itemDown = (item) => {
  fetchWordFile(item);
};
const response = ref(null);
const blob = ref(null);
const fetchWordFile = (item) => {
  downloadFile(item.fileUrl, item.fileName);
};
// 下载
const downloadFile = (url, fileName) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  // 触发点击
  document.body.appendChild(a);
  a.click();
  // 清理
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
// 下载
const downFile = () => {
  api.bot
    .documentQuery({ userId: userId.value, fileId: fileId.value })
    .then((res) => {
      if (res.returnCode == 200) {
        itemDown(res.data);
      }
    });
};
// 预览
const viewFile = () => {
  api.bot
    .documentQuery({ userId: userId.value, fileId: fileId.value })
    .then((res) => {
      if (res.returnCode == 200) {
        openChunk(res.data);
      }
    });
};
// 上传新文档 清空所有
const clearItem = () => {
  type.value = "upFile";
  fileList.value = [];
  lang.value = null;
  fileName.value = "";
  fileId.value = null;
};
// 获取字典
const getLang = () => {
  api.bot.getLanguage({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      langList.value = Object.entries(res.data).map(([value, key]) => ({
        value: value,
        key: key,
      }));
    }
  });
};
// 切换语言
const changeLang = (e) => {
  lang.value = e;
};
const getByteLength = (str) => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(str);
  return encoded.length;
};
// 上传钩子
const beforeUpload = (file) => {
  fileName.value = file.name;
  // 文件类型
  file.fileType = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .replace(" ", "");
  let validFileTypes = ["docx"];
  if (!validFileTypes.includes(file.fileType)) {
    message.warning(t("bot.bot89"));
    return false;
  }
  // 大小限制
  if (file.size > 20971520 && !this.$isBeat) {
    message.warning(t("bot.bot90"));
    return false;
  }
  // 名称超长
  let fileNameLen = String(file.name);
  if (getByteLength(fileNameLen) > 255) {
    message.warning(t("bot.bot91"));
    return false;
  }
  fileList.value = [file];
  type.value = "finish";
};
// 开始翻译
const upFile = () => {
  api.bot
    .fileTranslate(fileList.value, {
      language: lang.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        type.value = "qaLoading";
        if (res.data.length != 0) {
          poill(res.data);
          fileId.value = res.data[0];
        } else {
          type.value = "ok";
        }
      }
    });
};
// 轮询
const poill = (data) => {
  api.bot
    .statusView({
      userId: userId.value,
      fileId: data[0],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.status == "finish") {
          type.value = "ok";
        } else {
          setTimeout(() => {
            poill(data);
          }, 1000);
        }
      }
    });
};
const customRequest = () => {};
// 返回上一页
const back = () => {
  router.go(-1);
};
</script>
<template>
  <div class="wordContent">
    <div class="btnRight">
      <div class="left" @click="back">
        <ArrowLeftOutlined class="icon" />
        <div>{{ t("bot.bot55") }}</div>
      </div>
      <div class="right" @click="openHistory">{{ t("bot.bot56") }}</div>
    </div>
    <!-- 上传 -->
    <div v-if="type == 'upFile'">
      <UploadDragger
        class="upFile"
        name="file"
        :multiple="false"
        :showUploadList="false"
        accept=".docx"
        :customRequest="customRequest"
        action="#"
        :before-upload="beforeUpload"
      >
        <div class="iconCenter">
          <div>
            <FileWordOutlined class="word iconItem" />
            <div class="iconText">docx</div>
          </div>
        </div>
        <div class="bottomText">{{ t("bot.bot92") }}</div>
      </UploadDragger>
    </div>
    <!-- 翻译 -->
    <div class="upFile" v-if="type == 'finish'">
      <div class="loadingFiles">
        <FileWordOutlined class="word iconItem" />
        <div>
          <Select
            class="selWidth"
            @change="changeLang"
            :placeholder="$t('bot.bot93')"
          >
            <SelectOption
              v-for="(item, index) in langList"
              :key="index"
              :value="item.key"
            >
              {{ item.value }}
            </SelectOption>
          </Select>
        </div>
        <div class="qaBtn" @click="upFile">{{ t("bot.bot94") }}</div>
      </div>
    </div>
    <!-- 翻译中 -->
    <div class="upFile" v-if="type == 'qaLoading'">
      <div class="loadingFiles">
        <loading class="loading"></loading>
        <div class="qaText">{{ t("bot.bot95") }}</div>
        <div class="qaTip">{{ t("bot.bot96") }}</div>
      </div>
    </div>
    <!-- 翻译成功 -->
    <div class="upFile" v-if="type == 'ok'">
      <div class="loadingFiles">
        <FileWordOutlined class="word iconItem" />
        <div class="iconText">{{ fileName }}</div>
        <div class="iconText1">{{ t("bot.bot97") }}</div>
        <div class="okDiv">
          <div class="okBtn" @click="downFile">{{ t("bot.bot98") }}</div>
          <div class="okBtn" @click="viewFile">{{ t("bot.bot99") }}</div>
          <div class="okBtn" @click="clearItem">{{ t("bot.bot100") }}</div>
        </div>
      </div>
    </div>
    <!-- 历史 -->
    <Drawer
      v-if="historyType"
      :title="$t('bot.bot56')"
      placement="right"
      :closable="false"
      :width="500"
      :open="historyType"
      @close="historyType = false"
    >
      <div>
        <div v-for="item in historyList" :key="item.fileId" class="historyDiv">
          <FileWordOutlined class="historyIcon" />
          <div class="historyName" @click="openChunk(item)">
            {{ item.fileName }}
          </div>
          <el-icon class="down" @click="itemDown(item)">
            <Download />
          </el-icon>
        </div>
      </div>
    </Drawer>
    <!-- chunk -->
    <el-dialog
      :z-index="100"
      :close-on-click-modal="false"
      :title="$t('base.base185')"
      v-model="chunkType"
    >
      <div class="dialogClass">
        <iframe
          :src="offUrl + encodeURIComponent(chunkItem.fileUrl)"
          class="chunkItem"
          frameborder="0"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
  width: 1000px !important;
  height: 560px !important;
  margin: 40px auto 0 !important;
}

:deep(.ant-upload.ant-upload-drag) {
  background-color: #edf2ff;
}

:deep(.ant-select-selector) {
  height: 48px !important;
  border-radius: 16px !important;
}

:deep(.ant-select-selection-placeholder) {
  line-height: 46px !important;
}

:deep(.ant-select-selection-item) {
  line-height: 46px !important;
}

.wordContent {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px 30px 30px 30px;
  min-width: 1430px;
  min-height: 711px;
  width: 100%;
  height: 100%;
}

.upFile {
  width: 1000px;
  height: 560px;
  margin: 0 auto;
  margin-top: 40px;
}

.btnRight {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 30px;

  .left {
    display: flex;
    color: #8a8a8a;
    cursor: pointer;

    .icon {
      margin-top: 4px;
      margin-right: 6px;
      size: 20px;
    }
  }

  .right {
    cursor: pointer;
    color: #3376ff;
    font-weight: 600;
  }
}

.iconItem {
  display: block;
  margin-bottom: 40px;
  font-size: 120px;
}

.pdf {
  color: #eb5451;
}

.word {
  color: #458ae6;
}

.ppt {
  color: #e9643d;
}

.excel {
  color: #1fb97b;
}

.iconCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 180px;
}

.iconText {
  font-size: 20px;
  color: #000000;
  font-family: "Regular";
  margin: 20px 0px;
}

.iconText1 {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Regular";
}

.bottomText {
  font-size: 28px;
  color: #000000;
  font-family: "Regular";
}

.loadingFile {
  width: 100%;
  background-color: #edf2ff;
  height: 100%;
  border-radius: 8px;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingFiles {
  width: 100%;
  background-color: #edf2ff;
  height: 100%;
  border-radius: 8px;
  border: 1px dashed #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loadingText {
  font-size: 26px;
  margin-top: 10px;
  font-family: "Regular";
  color: #000000;
}

.selWidth {
  width: 250px;
  margin: 0px 10px;
}

.qaBtn {
  width: 250px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 20px;
  background-color: #767bfa;
  border-radius: 16px;
  margin-top: 30px;
  cursor: pointer;
}

.qaText {
  font-size: 28px;
  color: #000000;
  font-family: "Regular";
  margin-top: 50px;
}

.qaTip {
  font-size: 23px;
  width: 100%;
  color: #c4c4c4;
  margin-top: 20px;
  text-align: center;
}

.okTip {
  font-size: 1.857143rem;
  font-family: "Regular";
  color: #7d7d7d;
}

.okDiv {
  display: flex;
  justify-content: space-around;
  width: 75%;
  margin-top: 60px;

  .okBtn {
    padding: 10px 20px;
    border-radius: 16px;
    color: #ffffff;
    background-color: #767bfa;
    font-size: 20px;
    font-family: "Regular";
    cursor: pointer;
    box-shadow: 2px 4px 4px 0px #d0d0d0;
  }
}

.historyDiv {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
}

.historyIcon {
  font-size: 25px;
  color: #458ae6;
  display: block;
  margin-right: 10px;
  width: 25px;
}

.down {
  position: absolute;
  right: 0;
  font-size: 24px;
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.historyName {
  width: calc(100% - 70px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.ant-modal) {
  width: 400px !important;
}

:deep(.ant-modal-body) {
  height: 500px !important;
}

:deep(.ant-modal-header) {
  border: 0;
}

.chunkItem {
  width: 100%;
  height: 100%;
}
.dialogClass {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

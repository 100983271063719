<template>
  <div>
    <!-- 文件 -->
    <div v-if="inputFileList.length != 0" class="pa">
      <img
        :src="leftReson"
        alt=""
        class="leftResonIcon"
        v-if="inputFileList.length >= 3"
        @click="changeLeftReson(fileListRef)"
      />
      <div ref="fileListRef" class="listFlex">
        <div
          class="fileItem"
          v-for="(item, index) in inputFileList"
          :key="index"
          @click="openFile(item)"
        >
          <template v-if="!ifImg(item)">
            <img :src="getFileIcon(item)" alt="" class="fileImg" />
            <div class="fileRightDiv">
              <el-tooltip
                popper-class="toolTip"
                :content="item.name || item.fileName"
              >
                <div class="fileNmae">{{ item.name || item.fileName }}</div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  getFileType(item) + ' ' + formatFileSize(item.fileSize)
                "
              >
                <div class="fileSize">
                  {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
                </div>
              </el-tooltip>
            </div>
            <img
              :src="closePng"
              @click.stop="deleteFile(item)"
              alt=""
              class="closeIcon"
            />
          </template>
          <template v-if="ifImg(item)">
            <el-popover placement="top" popper-class="fileImgPopover">
              <template #reference>
                <img :src="getImgUrl(item)" alt="" class="fileImg" />
              </template>
              <img :src="getImgUrl(item)" alt="" class="hoverImg" />
            </el-popover>
            <div class="fileRightDiv">
              <el-tooltip popper-class="toolTip" :content="item.name">
                <div class="fileNmae">{{ item.name }}</div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  getFileType(item) + ' ' + formatFileSize(item.fileSize)
                "
              >
                <div class="fileSize">
                  {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
                </div>
              </el-tooltip>
            </div>
            <img
              :src="closePng"
              alt=""
              class="closeIcon"
              @click.stop="deleteFile(item)"
            />
          </template>
          <div class="absDiv" v-if="item.isFinish == 0">
            <span class="loader"></span>
            <div class="loadingText">{{ t("login.login42") }}</div>
          </div>
        </div>
      </div>
      <img
        :src="rightReson"
        alt=""
        class="rightResonIcon"
        v-if="inputFileList.length >= 3"
        @click="changeRightReson(fileListRef)"
      />
    </div>
    <!-- tag标签 -->
    <div class="tagCenter" v-if="tagActive == null">
      <div
        v-for="(item, index) in tagDiv"
        :key="index"
        class="tagItemDiv"
        @click="changeTag(item.value)"
      >
        <img class="tagItemImg" :src="item.img" alt="" />
        <span class="tagItemTitle">{{ t(item.label) }}</span>
      </div>
    </div>
    <!-- 结束上下文 -->
    <div
      :class="getEndClass"
      @mouseenter="clearType = true"
      @mouseleave="clearType = false"
      @click="clearTalk"
    >
      <img :src="endTalk" v-if="!clearType" class="clearBtnSvg" alt="" />
      <div link v-else class="blueClear">
        <img :src="clearBtn1Svg" alt="" class="clearBtnSvg1" />
        {{ t("login.login51") }}
      </div>
    </div>
    <!-- 选择展示的 -->
    <topTitle
      v-if="tagActive != null"
      :tagActive="tagActive"
      :tagList="tagList"
      :wsLoading="wsLoading"
      @changeBaseId="changeBaseId"
      @closeTag="closeTag"
      @wsMessage="wsMessage"
    ></topTitle>
    <div :class="tagActive == null ? 'inputDivHistory' : 'inputDivHistoryTag'">
      <el-mention
        v-model="question"
        :options="tagDiv"
        :prefix="['@', '/']"
        :placeholder="t('base.base261')"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 3 }"
        id="chatMessage"
        :disabled="props.debugtemplateId == null"
        @input="changeInput"
        @select="selInput"
        @keydown="messageSendListen"
        @paste="onPasteUpload($event)"
      >
        <template #label="{ item }">
          <div class="tjDiv">
            <img class="tagItemImg" :src="item.img" alt="" />
            <div>{{ t(item.label) }}</div>
          </div>
        </template>
      </el-mention>
      <!-- 下方选项 -->
      <div class="flexEnd">
        <!-- 对话 -->
        <div class="flexCenter">
          <uploadIndex
            type="file"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="tagActive == null"
          >
            <img :src="chatInputFile" class="chatInputFile" alt="" />
          </uploadIndex>
          <uploadIndex
            type="img"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="
              tagActive == null &&
              (props.model == 'GPT-4o-mini' || props.model == 'GPT-4o')
            "
          >
            <img :src="chatInputImg" class="chatInputFile" alt="" />
          </uploadIndex>
          <el-switch v-model="isInternetOn" v-if="tagActive != 2" />
          <div class="inputText" v-if="tagActive != 2">
            {{ t("base.base226") }}
          </div>
          <img :src="lineSvg" alt="" class="zwf" v-if="tagActive == 0" />
          <el-dropdown
            placement="top"
            v-if="tagActive == 0"
            @command="changeMenu"
          >
            <div class="selDiv">
              <div class="selInner">
                <span>{{ t(sdList[strategy].name) }}</span>
                <img :src="upSvg" alt="" class="selIcon" />
              </div>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  :command="item.value"
                  v-for="item in sdList"
                  :key="item.value"
                >
                  <el-tooltip popper-class="toolTip" :content="t(item.tip)">
                    <div class="dropText">{{ t(item.name) }}</div>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- 文生图选项 -->
        <div class="flexCenter" v-if="tagActive == 2">
          <imgSelView
            :model="props.model"
            @changeInput="changeInput"
            @changebl="changebl"
            @changeqxd="changeqxd"
            @changeStyle="changeStyle"
          ></imgSelView>
        </div>
        <img :src="lineSvg" alt="" class="zwf" />
        <img
          :src="sendSvg"
          v-if="question.length == 0"
          alt=""
          class="sendImg"
        />
        <img :src="sendYes" v-else class="sendImg" @click="sendMessage" />
      </div>
    </div>
  </div>
</template>

<script setup>
import endTalk from "@/assets/publish/endTalk.svg";
import clearBtn1Svg from "@/assets/chat/clearBtn1.svg";
import topTitle from "@/components/chat/topTitle.vue";
import nImg from "@/assets/index/n.svg";
import sendSvg from "@/assets/chat/send.svg";
import inputBase from "@/assets/input/inputBase.svg";
import iImg from "@/assets/index/i.svg";
import inputBot from "@/assets/input/inputImg.svg";
import oImg from "@/assets/index/o.svg";
import inputImg from "@/assets/input/inputBot.svg";
import chatInputFile from "@/assets/chat/chatInputFile.svg";
import chatInputImg from "@/assets/chat/chatInputImg.svg";
import uploadIndex from "@/components/upload/index.vue";
import upSvg from "@/assets/chat/up.svg";
import lineSvg from "@/assets/chat/line.svg";
import imgSelView from "@/components/chat/imgSel.vue";
import sendYes from "@/assets/chat/sendYes.svg";
import rightReson from "@/assets/chat/rightReson.svg";
import leftReson from "@/assets/chat/leftReson.svg";
import closePng from "@/assets/index/close.svg";
import { getFileIcon, formatFileSize } from "@/utils/file";
import { onBeforeRouteLeave } from "vue-router";
import api from "@/api";
import { ref, nextTick, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();
const props = defineProps(["model", "debugtemplateId"]);
const emit = defineEmits([
  "getDivHeight",
  "sendMessage",
  "changeTag",
  "openFileView",
  "changeBaseId",
  "clearTalk",
]);
const isInternetOn = ref(false); //联网搜索
const userId = ref(store.state.userId);
const tagActive = ref(null); //选择知识库 智能体
const wsLoading = ref(false); //完善loading
const knowledgeId = ref(null); //选择tag的知识库id
const question = ref(""); //input框
const timer = ref(null);
const fileListRef = ref(null);
const clearType = ref(false);
const botItem = ref(JSON.parse(JSON.stringify(store.state.botItem)));
const sdList = ref([
  {
    name: "base.base202",
    tip: "login.login73",
    value: 0,
  },
  {
    name: "base.base191",
    tip: "login.login74",
    value: 1,
  },
  {
    name: "base.base192",
    tip: "login.login75",
    value: 2,
  },
]); //算法列表
const strategy = ref(0); //默认算法
const tagList = ref([
  {
    label: "base.base262",
    img: nImg,
    icon: inputBase,
    value: 0,
  },
  {
    label: "base.base263",
    img: oImg,
    icon: inputImg,
    value: 1,
  },
  {
    label: "base.base29",
    img: iImg,
    icon: inputBot,
    value: 2,
  },
]);
const tagDiv = ref([
  {
    label: "base.base262",
    img: nImg,
    icon: inputBase,
    value: 0,
  },
  {
    label: "base.base29",
    img: iImg,
    icon: inputBot,
    value: 2,
  },
]); //@的值
const inputFileList = ref([]); //文件列表
const imageStyle = ref();
const imageQuality = ref();
const imageSize = ref();
onMounted(() => {
  getTask();
});
onBeforeRouteLeave((to, from, next) => {
  clearInterval(processTime.value);
  processTime.value = null;
  next()
});
const changeStyle = (e) => {
  imageStyle.value = e;
};
const changeqxd = (e) => {
  imageQuality.value = e;
};
const changebl = (e) => {
  imageSize.value = e;
};
//清楚上下文
const clearTalk = () => {
  emit("clearTalk");
};
const getEndClass = computed(() => {
  const tagActiveIsNull = tagActive.value === null;
  const clearTypeIsTrue = clearType.value;
  const inputFileListIsEmpty = inputFileList.value.length === 0;
  if (tagActiveIsNull) {
    return clearTypeIsTrue
      ? inputFileListIsEmpty
        ? "clearBtnDiv5"
        : "clearBtnDiv6"
      : inputFileListIsEmpty
      ? "clearBtnDiv"
      : "clearBtnDiv2";
  } else {
    return clearTypeIsTrue ? "clearBtnDiv7" : "clearBtnDiv8";
  }
});
// file转图片
const getImgUrl = (file) => {
  if (file.fileContentPath) {
    return file.fileContentPath;
  } else {
    return URL.createObjectURL(file);
  }
};
// 删除文件
const deleteFile = (item) => {
  api.chat
    .chatFileDelete({
      userId: userId.value,
      fileId: item.fileId || item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base16"));
        getChatFile();
      }
    });
};
// 获取文件类型
const getFileType = (item) => {
  if (item.fileType && item.fileType == 2) {
    return t("login.login40");
  }
  let name = item.fileName || item.name;
  let fileType = name.substring(name.lastIndexOf(".") + 1);
  fileType = fileType.toLowerCase().replace(" ", "");
  return fileType;
};
// 判断是不是图片
const ifImg = (file) => {
  if (file.name) {
    const type = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileType = type.toLowerCase().replace(" ", "");
    if (fileType == "png") {
      return true;
    } else if (fileType == "jpg") {
      return true;
    } else if (fileType == "gif") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// 预览文件
const openFile = (item) => {
  if (item.isFinish == 0) {
    message.warning(t("base.base308"));
    return;
  }
  if (
    item.fileType == "png" ||
    item.fileType == "jpg" ||
    item.fileType == "gif" ||
    item.fileType == 1
  )
    return;
  emit("openFileView", item);
};
const saveMessage = (message) => {
  let postObj = {
    model: props.model,
    message: message,
    topicId: props.debugtemplateId,
    knowledgeId: knowledgeId.value,
    userId: userId.value,
    isInternetOn: isInternetOn.value,
    regenerateFlag: 0,
    conversationId: null,
    internetResource: null,
    strategy: strategy.value,
    imageFlag: tagActive.value == 2 ? 1 : 0,
    indebug: true,
  };
  emit("sendMessage", postObj);
};
defineExpose({
  saveMessage,
});
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 切换tag
const changeTag = (index) => {
  tagActive.value = index;
  question.value = "";
  inputFileList.value = [];
  emit("changeTag", index);
  changeInput();
};
// 选择提及
const selInput = (e) => {
  tagActive.value = e.value;
  question.value = "";
  inputFileList.value = [];
  emit("changeTag", e.value);
  changeInput();
};
// 关闭tag
const closeTag = () => {
  tagActive.value = null;
  inputFileList.value = [];
  knowledgeId.value = null;
  getChatFile();
  emit("changeBaseId", null);
  changeInput();
};
// 微模型选择
const changeBaseId = (id) => {
  knowledgeId.value = id;
  emit("changeBaseId", id);
};
// 一键完善
const wsMessage = () => {
  wsLoading.value = true;
  api.chat
    .imgOptimize({
      userId: userId.value,
      message: question.value,
    })
    .then((res) => {
      wsLoading.value = false;
      if (res.returnCode == 200) {
        question.value = res.data;
        changeInput();
      }
    })
    .then((res) => {
      wsLoading.value = false;
    });
};
// 调用父组件-input高度
const changeInput = () => {
  setTimeout(() => {
    emit("getDivHeight");
  }, 100);
};
// 换行
const messageSendListen = (event) => {
  if (event.keyCode === 13) {
    if (!event.altKey) {
      event.preventDefault();
      sendMessage(); // 发送文本
    } else {
      let textarea = document.getElementById("chatMessage");
      let start = textarea.selectionStart;
      let end = textarea.selectionEnd;
      let before = textarea.value.substring(0, start);
      let after = textarea.value.substring(end);
      question.value = before + "\n" + after;
      changeInput();
      nextTick(() => {
        textarea.scrollTop = textarea.scrollHeight;
      });
    }
  }
};
// 粘贴文件
const onPasteUpload = (event) => {
  if (tagActive.value == null) {
    let items = event.clipboardData && event.clipboardData.items;
    let file = null;
    let fileList = [];
    const fileTypeList = [
      "txt",
      "pptx",
      "pdf",
      "docx",
      "xlsx",
      "html",
      "jpg",
      "png",
      "gif",
    ];
    if (items && items.length) {
      for (let key in items) {
        if (items[key].kind == "file") {
          file = items[key].getAsFile();
          file.fileType = file.name
            .substring(file.name.lastIndexOf(".") + 1)
            .toLowerCase()
            .replace(" ", "");
          if (!fileTypeList.includes(file.fileType)) {
            message.warning(file.name + t("base.base283"));
            return;
          }
          // 大小限制
          if (file.size > 20971520) {
            message.warning(file.name + t("base.base281"));
            return;
          }
          // 名称超长
          let fileNameLen = String(file.name);
          if (fileNameLen.length > 100) {
            message.warning(file.name + t("base.base282"));
            return;
          }
          fileList.push(file);
          clearTimeout(timer.value);
          timer.value = setTimeout(() => {
            beforeAvatarUpload(fileList);
          }, 100);
        }
      }
    }
  }
};
// 上传
const beforeAvatarUpload = (fileList) => {
  let inFileList = JSON.parse(JSON.stringify(inputFileList.value));
  inFileList = inFileList.concat(fileList);
  let file = inFileList.filter(
    (item) => !["jpg", "png", "gif"].includes(item.fileType)
  );
  let imgNumberList = inFileList.filter((item) =>
    ["jpg", "png", "gif"].includes(item.fileType)
  );
  if (file.length > 3) {
    return message.warning(t("base.base300"));
  }
  if (imgNumberList.length > 3) {
    return message.warning(t("base.base301"));
  }
  //   上传轮询
  api.chat
    .chatUpFile(fileList, {
      topicId: botItem.value.id || botItem.value.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.failedFileList.length != 0) {
          res.failedFileList.forEach((item) => {
            message.warning(item[0] + item[1]);
          });
          getChatFile();
        } else {
          getChatFile();
        }
        getTask();
      }
    });
};
const processTime = ref(null);
const getTask = () => {
  api.chat
    .getProcess({
      userId: userId.value,
      topicId: botItem.value.id || botItem.value.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length == 0) {
          getChatFile();
          clearInterval(processTime.value);
          processTime.value = null;
        } else {
          clearInterval(processTime.value);
          processTime.value = null;
          getChatFile();
          processTime.value = setInterval(() => {
            setTimeout(() => {
              getTask();
            }, 0);
          }, 2000);
        }
      }
    });
};
// 回显文件
const getChatFile = () => {
  api.chat
    .chatFileQuery({
      userId: userId.value,
      topicId: botItem.value.id || botItem.value.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputFileList.value = res.data;
        emit("getDivHeight");
      }
    });
};
// 发送消息
const sendMessage = () => {
  let postObj = {
    model: props.model,
    message: question.value,
    topicId: props.debugtemplateId,
    knowledgeId: knowledgeId.value,
    userId: userId.value,
    isInternetOn: isInternetOn.value,
    regenerateFlag: 0,
    conversationId: null,
    internetResource: null,
    strategy: strategy.value,
    imageFlag: tagActive.value == 2 ? 1 : 0,
    imageSize: imageSize.value,
    pictureModelType: null,
    imageQuality: imageQuality.value,
    imageStyle: imageStyle.value,
    indebug: true,
  };
  emit("sendMessage", postObj);
  question.value = "";
};
// 切换算法
const changeMenu = (index) => {
  strategy.value = index;
};
</script>

<style lang="scss" scoped>
.inputDivHistory {
  min-height: 84px;
  border: 2px solid #d9d9d9;
  border-radius: 18px;
  padding: 15px;
  position: relative;
  background-color: #feffff;
}
.inputDivHistoryTag {
  min-height: 86px;
  border: 2px solid #d9d9d9;
  border-radius: 0px 0px 18px 18px;
  padding: 15px;
  position: relative;
  background-color: #feffff;
}
.tagItemDiv {
  border-radius: 16px;
  border: 2px solid #d9d9d9;
  padding: 7px 11px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  cursor: pointer;
  background-color: #feffff;
}
.tagItemTitle {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Medium";
}
.tagItemImg {
  margin-right: 10px;
  width: 20px;
}
.tagCenter {
  display: flex;
  align-items: center;
  height: 48px;
  margin-bottom: 12px;
}
.tagItemImg {
  margin-right: 10px;
  width: 20px;
}
.tjDiv {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
:deep(.el-textarea) {
  height: 100%;
  .el-textarea__inner {
    border: 0px;
    resize: none;
    outline: none;
    width: 100%;
    overflow: auto;
    font-size: 18px;
    font-family: "Regular";
    min-height: 43px !important;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 0;
    padding: 0px;
  }
  .el-textarea__inner:disabled {
    background-color: #fff;
  }
  .el-textarea__inner::placeholder {
    color: #d9d9d9;
    font-size: 18px;
    font-family: "Regular";
  }
}
.flexEnd {
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}
.chatInputFile {
  width: 20px;
  margin-right: 14px;
}
.inputText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-left: 6px;
}
.zwf {
  margin: 0px 12px;
  width: 5px;
  height: 25px;
}
.selIcon {
  margin-left: 5px;
  width: 14px;
}
.selInner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceeff;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
  padding: 0px 4px;
}
.selDiv {
  height: 30px;
  border: 1px solid #dbdfff;
  border-radius: 10px;
  padding: 2px;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
}
.sendImg {
  width: 30px;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.listFlex {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.fileItem {
  width: 188px;
  height: 52px;
  background-color: #feffff;
  border-radius: 20px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
}
.fileImg {
  width: 40px;
}
.fileNmae {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileSize {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  width: 15px;
  z-index: 20;
}
.hoverImg {
  max-width: 300px;
  max-height: 300px;
}
.fileRightDiv {
  margin-left: 13px;
  width: calc(100% - 41px);
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.absDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8a8a8a;
  opacity: 0.9;
  border-radius: 20px;
  flex-direction: column;
  z-index: 10;
}
.loadingText {
  color: #ffffff;
  font-family: "Regular";
  font-size: 14px;
  margin-top: 5px;
}
:deep(.el-tooltip__trigger) {
  font-size: 16px;
  font-family: "Regular";
}
.clearBtnSvg {
  width: 24px;
  cursor: pointer;
}
.blueClear {
  background-color: #3376ff;
  border-radius: 16px;
  padding: 12px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Regular";
  display: flex;
  align-items: center;
  cursor: pointer;
}
.clearBtnSvg1 {
  width: 24px;
  margin-right: 6px;
}
.clearBtnDiv5 {
  position: absolute;
  top: 0px;
  right: 10px;
}
.clearBtnDiv6 {
  position: absolute;
  top: 88px;
  right: 10px;
}
.clearBtnDiv {
  position: absolute;
  top: 12px;
  right: 10px;
}
.clearBtnDiv2 {
  position: absolute;
  top: 100px;
  right: 10px;
}
.clearBtnDiv7 {
  position: absolute;
  top: -53px;
  right: 10px;
}
.clearBtnDiv8 {
  position: absolute;
  top: -30px;
  right: 10px;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #8a8a8a;
  background-color: #ffffff;
  border-radius: 16px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 16px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #3d3d3d;
  left: 2px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
}
</style>

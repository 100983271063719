export default {
    CN: {
        index1: '配置微信服务号',
        index2: '前往微信公众平台,按照微信公众号（服务号）配置流程配置并发布智能体,公众号即可利用NextAI智能体回复用户信息。',
        index3: '请输入',
        index4: '放弃',
        index5: '保存',
        index6: '配置企业微信应用',
        index7: '前往企业微信管理后台,按照企业微信应用配置流程配置并发布智能体,企微应用即可利用NextAI智能体回复用户信息。',
        index8: '发送消息URL',
        index9: '请输入Token',
        index10: '请输入EncodingAESKey',
        index11: '企业微信应用',
        index12: '微信服务号',
        index13: '发布',
        index14: '选择发布平台',
        index15: '未授权',
        index16: '已授权',
        index17: '修改配置',
        index18: '配置',
        index19: '选择LLM模型',
        index20: '发布更新',
        index21: '发布历史',
        index22: '发布智能体',
        index23: '发布知识库',
        index24: '当前',
        index25: '还原',
        index26: '确认还原',
        index27: '确认还原到该版本吗？',
        index28: 'SharePoint全局配置',
        index29: '系统将用SharePoint全局配置更新所有知识来源为SharePoint的资源库,若不添加全局配置,所有SharePoint知识库将仅支持拥有者手动触发更新。',
        index30: 'SharePoint全局配置',
        index31: '配置信息',
        index32: 'Sharepoint 版本',
        index33: '同步信息',
        index34: '请输入TenantId',
        index35: '请输入ClientId',
        index50: '请输入ClientSecret',
        index36: '同步频率',
        index37: '同步时间',
        index38: '定期同步租户下SharePoint来源知识库',
        index39: '国内版',
        index40: '国际版',
        index41: '每天',
        index42: '每周',
        index43: '星期一',
        index44: '星期二',
        index45: '星期三',
        index46: '星期四',
        index47: '星期五',
        index48: '星期六',
        index49: '星期日',
        index51: '选择回复模式',
        index52: '前往微信公众平台,按照微信公众号（服务号）配置流程配置并发布知识库,公众号即可利用NextAI知识库回复用户信息。',
        index53: '前往企业微信管理后台,按照企业微信应用配置流程配置并发布知识库,企微应用即可利用NextAI知识库回复用户信息。',
    },
    HK: {
        index1: '配置微信服務號',
        index2: '前往微信公眾平臺,按照微信公眾號（服務號）配置流程配置併發佈智慧體,公眾號即可利用NextAI智慧體回復用戶資訊。 ',
        index3: '請輸入',
        index4: '放棄',
        index5: '保存',
        index6: '配置企業微信應用',
        index7: '前往企業微信管理後台，按照企業微信應用配置流程配置併發佈智慧體，企微應用即可利用NextAI智慧體回復用戶資訊。',
        index8: '發送消息URL',
        index9: '請輸入Token',
        index10: '請輸入EncodingAESKey',
        index11: '企業微信應用',
        index12: '微信服務號',
        index13: '發佈',
        index14: '選擇發佈平臺',
        index15: '未授權',
        index16: '已授權',
        index17: '修改配置',
        index18: '配置',
        index19: '選擇LLM模型',
        index20: '發佈更新',
        index21: '發佈歷史',
        index22: '發佈智慧體',
        index23: '發佈知識庫',
        index24: '當前',
        index25: '還原',
        index26: '確認還原',
        index27: '確認還原到該版本嗎？',
        index30: 'SharePoint全域配置',
        index31: '配置資訊',
        index32: 'Sharepoint 版本',
        index33: '同步資訊',
        index34: '請輸入TenantId',
        index35: '請輸入ClientId',
        index50: '請輸入ClientSecret',
        index36: '同步頻率',
        index37: '同步時間',
        index38: '定期同步租戶下SharePoint來源知識庫',
        index39: '國內版',
        index40: '國際版',
        index41: '每天',
        index42: '每周',
        index43: '星期一',
        index44: '星期二',
        index45: '星期三',
        index46: '星期四',
        index47: '星期五',
        index48: '星期六',
        index49: '星期日',
        index51: '選擇回覆模式',
        index52: '前往微信公眾平臺，按照微信公眾號（服務號）配置流程配置併發佈知識庫，公眾號即可利用NextAI知識庫回復用戶資訊。 ',
        index53: '前往企業微信管理後台，按照企業微信應用配置流程配置併發佈知識庫，企微應用即可利用NextAI知識庫回復用戶資訊。',
    },
    US: {
        index1: 'Configure WeChat Service Account',
        index2: 'Go to the WeChat Official Accounts Platform, configure and publish the agent according to the configuration process of the WeChat official account (service account), and the Official Account can use the NextAI agent to reply to user information.',
        index3: 'Please enter ',
        index4: 'give up',
        index5: 'save',
        index6: 'Configure WeCom application',
        index7: 'Go to the WeCom admin background, configure and publish the agent according to the WeCom application configuration process, and the WeCom application can use the NextAI agent to reply to user information.',
        index8: 'Send message URL',
        index9: 'Please enter Token',
        index10: 'Please enter EncodingAESKey',
        index11: 'WeCom application',
        index12: 'WeChat service account',
        index13: 'Publish',
        index14: 'Select a publishing platform',
        index15: 'Unauthorized',
        index16: 'Authorized',
        index17: 'Modify configuration',
        index18: 'Configuration',
        index19: 'Select LLM model',
        index20: 'Release update',
        index21: 'Release History',
        index22: 'Publishing agent',
        index23: 'Publishing knowledge base',
        index24: 'current',
        index25: 'revert',
        index26: 'Confirm restoration',
        index27: 'Do you confirm the restoration to this version?',
        index30: 'SharePoint Global Configuration',
        index31: 'Configuration information',
        index32: 'Sharepoint version',
        index33: 'Synchronization information',
        index34: 'Please enter TenantId',
        index35: 'Please enter ClientId',
        index50: 'Please enter ClientSecret',
        index36: 'Synchronization frequency',
        index37: 'Synchronization time',
        index38: 'Regularly synchronize the SharePoint source knowledge base under the tenant',
        index39: 'Domestic Edition',
        index40: 'International Edition',
        index41: 'everyday',
        index42: 'Weekly',
        index43: 'Monday',
        index44: 'Tuesday',
        index45: 'Wednesday',
        index46: 'Thursday',
        index47: 'Friday',
        index48: 'Saturday',
        index49: 'Sunday',
        index51: 'Select reply mode.',
        index52: 'Go to the WeChat Official Accounts Platform, configure and publish the knowledge base according to the configuration process of the WeChat official account (service account), and the official account can use the NextAI knowledge base to reply to user information. ',
        index53: 'Go to the WeCom admin background, configure and publish the knowledge base according to the WeCom application configuration process, and the WeCom application can use the NextAI knowledge base to reply to user information.',
    },
};
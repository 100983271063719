<script setup>
import { ref, reactive, onMounted, watch, onUnmounted } from "vue";
import { Search, ArrowDown } from "@element-plus/icons-vue";
import addDialog from "./components/addBot.vue";
import botTool from "./components/botTool.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import pinImg from "@/assets/base/pin.svg";
import pinYesImg from "@/assets/base/pinYes.svg";
import detailBot from "./components/detailBot.vue";
import { useRouter, useRoute } from "vue-router";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const listLoading = ref(false);
const detailType = ref(false);
const botIsOwner = ref(0);
onMounted(() => {
  getList();
});
// 列表查询
const navActive = ref(0);
const navList = ref([
  {
    name: t("bot.bot1"),
    url: require("@/assets/bot/rabitLine.svg"),
  },
  {
    name: t("bot.bot2"),
    url: require("@/assets/bot/intellent.svg"),
  },
]);
const showList = ref(true);
const showTool = ref(false);
const changeNav = (index) => {
  navActive.value = index;
  if (index == 0) {
    showList.value = true;
    showTool.value = false;
  } else if (index == 1) {
    showList.value = false;
    showTool.value = true;
  }
};
// 分页
const currentPage = ref(1);
const pageSize = ref(30);
// 列表参数
const listFomr = ref({
  userId: userId.value,
  templatePattern: "",
  pageId: currentPage.value,
  perPage: pageSize.value,
});
watch(
  () => listFomr.value.templatePattern,
  (newVal) => {
    listLoading.value = true;
    listFomr.value.templatePattern = newVal;
    listFomr.value.pageId = 1;
    list.value = [];
    getList();
  }
);
const list = ref([]);
// 获取微模型列表
const getList = () => {
  listLoading.value = true;
  api.bot
    .templateQuery(listFomr.value)
    .then((res) => {
      if (res.returnCode == 200) {
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
        listLoading.value = false;
      }
    })
    .catch((err) => {
      listLoading.value = false;
    });
};
const loadList = () => {
  listFomr.value.pageId++;
  getList();
};
// 新建
const formType = ref("add");
const botInfo = ref({});
const showDialog = ref(false);
// 新建智能体
const openAdd = () => {
  formType.value = "add";
  showDialog.value = true;
  botInfo.value = {
    shareUserIds: [
      {
        userId: userId.value,
        id: userId.value,
        type: 0, //0 拥有者 1 创作者 2 普通用户
        userName: store.state.userName,
        name: store.state.userName,
        ownerType: 0, //0个人 1部门 2群组
        color: store.state.color,
      },
    ],
    suggest: [],
    imageName: "",
  };
};

// 确定新增
const submitBtn = (form) => {
  form.userId = userId.value;
  showDialog.value = false;
  api.bot.templateAdd(form).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("bot.bot4"));
      form.indebug = true
      form.id = res.data.debugtemplateId||res.data.templateId
      form.templateId = res.data.debugtemplateId||res.data.templateId
      store.commit("SET_BOT", form);
      router.push({
        name: "botDetail",
      });
    }
  });
};
// 智能体修改
const templateId = ref(null);
const goBaseDetails = (item) => {
  api.chat
    .addChat({
      userId: userId.value,
      topicName: item.templateName,
      templateId: item.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        store.commit("SET_CHATTYPE", "chat");
        store.commit("SET_TOPIC", {
          sessionName: item.templateName,
          sessionId: res.data.table_id,
          sessionType: 2,
        });
        router.push("/chat");
      }
    });
};
// 关闭弹窗
const closeBtn = () => {
  showDialog.value = false;
  getList();
};
// 修改确定
const editBtn = (form) => {
  api.bot.templateEdit(form).then((res) => {
    if (res.returnCode == 200) {
      showDialog.value = false;
      message.success(t("bot.bot5"));
      getList();
    }
  });
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.bot
      .templateTop({
        userId: userId.value,
        templateId: item.templateId,
        isFavourite: 1,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("bot.bot6"));
          listFomr.value.pageId = 1;
          getList();
        }
      });
  } else if (key == 2) {
    // 取消置顶
    api.bot
      .templateTop({
        userId: userId.value,
        templateId: item.templateId,
        isFavourite: 0,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("bot.bot7"));
          listFomr.value.pageId = 1;
          getList();
        }
      });
  } else if (key == 3) {
    // 删除智能体
    api.bot
      .templateDel({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        message.success(t("bot.bot8"));
        getList();
      });
  } else if (key == 4) {
    // 编排
    templateId.value = item.templateId;
    api.bot
      .templateDetail({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          botInfo.value = res.data;
          botInfo.value.templateId = templateId.value;
          botInfo.value.suggest = botInfo.value.suggestQuestion;
          store.commit("SET_BOT", botInfo.value);
          router.push({
            name: "botDetail",
          });
        }
      });
  } else if (key == 5) {
    //智能体详情
    templateId.value = item.templateId;
    api.bot
      .templateDetail({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          botIsOwner.value = item.isOwner;
          botInfo.value = res.data;
          botInfo.value.templateId = templateId.value;
          detailType.value = true;
        }
      });
  }
};
// 关闭详情
const closeDetail = () => {
  detailType.value = false;
};
// 关闭详情打开编辑
const openEdit = () => {
  detailType.value = false;
  botInfo.value.templateId = botInfo.value.id;
  formType.value = "edit";
  showDialog.value = true;
};
</script>

<template>
  <div class="container">
    <!-- 搜索框 -->
    <div
      style="display: flex; justify-content: space-between; position: relative"
    >
      <div style="display: flex">
        <el-input
          v-model="listFomr.templatePattern"
          class="baseInput"
          :placeholder="t('bot.bot3')"
          :prefix-icon="Search"
          v-if="showList"
        />
        <div
          :class="navActive == index ? 'navActiveItem formItem' : 'formItem'"
          v-for="(item, index) in navList"
          :key="index"
          @click="changeNav(index)"
        >
          <img v-if="item.url" :src="item.url" alt="" class="iconImg" />
          <div v-if="item.name" class="spanText">{{ item.name }}</div>
        </div>
      </div>
      <!-- <div class="botCode">
        {{ $t('bot.code') }}
      </div> -->
    </div>
    <!-- 内容 -->
    <div
      class="box"
      v-infinite-scroll="loadList"
      :infinite-scroll-distance="5"
      :infinite-scroll-immediate="false"
    >
      <div class="itemWidth" @click="openAdd" v-if="showList">
        <div class="flexCenter itemTile">
          <div class="addDiv">
            <img src="@/assets/base/add.svg" alt="" class="addImg" />
          </div>
          <div class="baseItemTitle">{{ t("bot.bot9") }}</div>
        </div>
        <div class="addTip other">{{ t("bot.bot10") }}</div>
      </div>
      <!-- 展示智能工具 -->
      <botTool v-if="showTool"></botTool>
      <!--展示智能助手-->
      <template v-if="showList">
        <div
          class="itemWidth"
          v-for="(item, index) in list"
          :key="index"
          @click="goBaseDetails(item)"
        >
          <div class="flexCenter itemTile">
            <div class="flexCenter titleMax">
              <div class="logoDiv">
                <img
                  src="@/assets/bot/rabit.svg"
                  alt=""
                  class="baseIconLogo"
                  v-if="item.image_path == null || !item.image_path"
                />
                <img
                  :src="'/openai/chat/v4/image/get/' + item.image_path"
                  alt=""
                  class="baseIconLogo"
                  v-else
                />
              </div>
              <div class="forBaseItemTitle">{{ item.templateName }}</div>
            </div>
            <div
              class="pinDiv"
              @click.stop="changeMore(1, item)"
              v-if="item.isPin == 0"
            >
              <img :src="pinImg" alt="" class="pinImg" />
            </div>
            <div
              class="pinYesDiv"
              @click.stop="changeMore(2, item)"
              v-if="item.isPin == 1"
            >
              <img :src="pinYesImg" alt="" class="pinImg" />
            </div>
          </div>
          <div class="addTip">
            {{ item.templateDesc }}
          </div>
          <!-- 数量 -->
          <div class="bottomFlexBetween">
            <div class="flexCenter">
              <el-tooltip
                popper-class="toolTip"
                :content="t('login.login44') + item.sessionNum"
              >
                <div class="flexCenter">
                  <img src="@/assets/base/talk.svg" alt="" class="baseIcon" />
                  <div class="bottomNumberItem">{{ item.sessionNum }}</div>
                </div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="t('login.login45') + item.userResults"
              >
                <div class="flexCenter">
                  <img src="@/assets/base/member.svg" alt="" class="baseIcon" />
                  <div class="bottomNumberItem">{{ item.userResults }}</div>
                </div>
              </el-tooltip>
            </div>
            <!-- 功能区 -->
            <el-dropdown @command="changeMore($event, item)" trigger="click">
              <div class="moreDiv" @click.stop="">
                <img src="@/assets/base/more.svg" alt="" class="moreIcon" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- 编辑 -->
                  <el-dropdown-item :command="4" v-if="item.isOwner == 1">
                    <span class="dropText colorBlue">{{ t("bot.bot14") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item :command="5">
                    <span class="dropText colorBlue">
                      {{ t("bot.bot105") }}
                    </span>
                  </el-dropdown-item>
                  <!-- 置顶 -->
                  <el-dropdown-item :command="1" v-if="item.isPin === 0">
                    <span class="dropText colorBlue">{{ t("bot.bot11") }}</span>
                  </el-dropdown-item>
                  <!-- 取消置顶 -->
                  <el-dropdown-item :command="2" v-if="item.isPin === 1">
                    <span class="dropText colorRed">{{ t("bot.bot12") }}</span>
                  </el-dropdown-item>
                  <!-- 删除 -->
                  <el-dropdown-item :command="3" v-if="item.isOwner == 1">
                    <span class="dropText colorRed">{{ t("bot.bot13") }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </template>
    </div>
    <div class="flexDiv1" v-if="listLoading" v-loading="listLoading"></div>
  </div>
  <!-- 新建智能体弹窗 -->
  <addDialog
    v-if="showDialog"
    :botInfo="botInfo"
    :formType="formType"
    @closeBtn="closeBtn"
    @submitBtn="submitBtn"
    @editBtn="editBtn"
  ></addDialog>
  <!-- 详情 -->
  <detailBot
    v-if="detailType"
    :botInfo="botInfo"
    :botIsOwner="botIsOwner"
    @closeDetail="closeDetail"
    @openEdit="openEdit"
  ></detailBot>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.el-loading-spinner) {
  width: 100%;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

:deep(.el-loading-mask) {
  z-index: 3 !important;
}

.flexDiv1 {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: 100%;
  height: 100%;
  position: relative;
  .baseInput {
    width: 236px;
    height: 52px;
    font-size: 17px;
    color: #8a8a8a;
    font-family: "Regular";
  }

  .navActiveItem {
    border: 2px solid #dbdfff;
    background-color: #eceeff;
    color: #0256ff;
  }

  .formItem {
    display: flex;
    border-radius: 16px;
    border: 2px solid #edeef6;
    height: 52px;
    padding: 0 20px;
    margin-left: 14px;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconImg {
      width: 26px;
      height: 23px;
      margin-right: 6px;
    }

    .spanText {
      padding-top: -24px;
    }
  }
}

.sort {
  padding: 12px !important;

  img {
    width: 25px;
    height: 25px;
  }
}

.formItem:hover {
  border: 2px solid #dbdfff;
  background-color: #eceeff;
  color: #0256ff;
}

.box {
  width: 100%;
  height: calc(100% - 74px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-top: 22px;
  overflow: auto;
}

.itemWidth {
  border-radius: 20px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  width: 23%;
  height: 206px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.pinYesDiv {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWidth:hover {
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #eceeff;

  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #f7f8ff;
  }

  .pinYesDiv {
    background-color: #f7f8ff;
    border-radius: 12px;
  }

  .moreDiv {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    background-color: #f7f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.itemTile {
  height: 28px;
  margin-bottom: 24px;
  position: relative;
}

.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.addImg {
  width: 15px;
  height: 15px;
}

.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-family: "Bold";
}

.addTip {
  font-size: 18px;
  color: #8a8a8a;
  text-align: justify;
  font-family: "Regular";
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  word-break: break-all; /* 强制换行 */
  -webkit-box-orient: vertical;
}

.other {
  -webkit-line-clamp: 3; /* 限制行数 */
  word-break: break-all; /* 强制换行 */
  -webkit-box-orient: vertical;
  height: 75px;
}

.titleMax {
  width: 100%;
}

.logoDiv {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.baseIconLogo {
  width: 28px;
  height: 28px;
  border-radius: 8px;
}

.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
  width: calc(100% - 80px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pinDiv {
  display: none;
}

.pinImg {
  width: 16px;
  height: 16px;
}

.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.baseIcon {
  width: 25px;
}

.bottomNumberItem {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-left: 3px;
  margin-right: 12px;
}

.moreDiv {
  display: none;
}

.moreIcon {
  width: 18px;
}

.botCode {
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  border-radius: 16px;
  background-color: #0256ff;
  text-align: center;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
}
.dropText {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
}
.colorBlue {
  color: #0256ff;
}
.colorRed {
  color: #f02d63;
}
</style>

<script setup>
import { ref, watch, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import helper from "@/assets/bot/helper.svg";
import brain from "@/assets/bot/brain.svg";
import vector from "@/assets/bot/Vector.svg";
import IE from "@/assets/bot/IE.svg";
import { message } from "ant-design-vue";
import bImg from "@/assets/base/addBot.svg";
const { t } = useI18n();
const props = defineProps({
  botInfo: Object,
  botIsOwner: Number,
});
const formRef = ref(null);
const emit = defineEmits(["closeDetail", "openEdit"]);
const navActive = ref(0);
const skillActive = ref(0);
const navList = ref([
  {
    name: t("bot.bot1"),
    url: helper,
  },
  // {
  //     name: t('bot.bot2'),
  //     url: tool
  // },
]);
// 切换选择
const changeNav = (index) => {
  navActive.value = index;
};
const skillList = ref([
  {
    name: t("login.login56"),
    url: brain,
  },
  {
    name: t("base.base29"),
    url: vector,
  },
  {
    name: t("base.base226"),
    url: IE,
  },
]);
const skillNav = (index) => {
  skillActive.value = index;
};
// 校验规则
const rules = ref({
  templateName: [{ required: true, message: t("bot.bot19") }],
  templateValue: [{ required: true, message: t("bot.bot20") }],
});
// 提问灵感
const dynamicTags = ref(props.botInfo.suggestQuestion || []);
watch(
  () => props.botInfo.suggestQuestion,
  (newVal) => {
    dynamicTags.value = newVal;
  }
);
const closeDialog = () => {
  formRef.value.resetFields();
  emit("closeDetail");
};
// 修改确定
const openEdit = () => {
  emit("openEdit");
};
</script>

<template>
  <div class="addContent">
    <div class="my-header">
      <div>
        {{ t("bot.bot106") }}
      </div>
      <el-icon
        class="el-icon--left"
        :class="'close-icon'"
        @click="closeDialog"
        style="cursor: pointer"
      >
        <Close />
      </el-icon>
    </div>
    <div class="flexDiv">
      <el-form
        ref="formRef"
        :model="props.botInfo"
        :rules="rules"
        label-position="top"
      >
        <el-form-item>
          <div class="flexBetWeenOne">
            <div
              v-for="(item, index) in navList"
              :key="index"
              :class="navActive == index ? 'itemDivNavActive' : 'itemDivNav'"
              @click="changeNav(index)"
            >
              <img :src="item.url" alt="" class="navImg" />
              <div :class="navActive == index ? 'itemTextActive' : 'itemText'">
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-form-item>
        <!-- 名称 -->
        <el-form-item prop="templateName" :label="$t('bot.bot28')">
          {{ props.botInfo.templateName }}
        </el-form-item>
        <!-- 技能 -->
        <el-form-item :label="$t('bot.bot33')">
          <div class="flexBetWeen">
            <div
              v-for="(item, index) in skillList"
              :key="index"
              class="itemSkillNav"
              @click="skillNav(index)"
            >
              <img :src="item.url" alt="" class="skillImg" />
              <div class="itemSkillText">
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('bot.bot34')">
          <div :class="props.botInfo.image_path ? 'txCenter' : 'step2Img'">
            <img
              v-if="props.botInfo.image_path == null"
              :src="bImg"
              alt=""
              class="stepImgIcon"
            />
            <img
              v-if="props.botInfo.image_path != null"
              :src="'/openai/chat/v4/image/get/' + props.botInfo.image_path"
              alt=""
              class="stepImgIcons"
            />
          </div>
        </el-form-item>
        <!-- 权限设置 -->
        <el-form-item :label="$t('base.base32')">
          <el-table :data="props.botInfo.shareUserIds" class="tableSet">
            <el-table-column prop="name" :label="$t('base.base34')">
              <template #default="scope">
                <div class="flexCenter1">
                  <div
                    class="userImg"
                    :style="'background-color:' + scope.row.color"
                  >
                    {{ scope.row.name?.slice(0, 1) }}
                  </div>
                  <el-tooltip popper-class="toolTip" :content="scope.row.name">
                    <div class="tableName nameHidden">{{ scope.row.name }}</div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="type" :label="$t('base.base35')">
              <template #default="scope">
                <div v-if="scope.row.type == 1" class="tableName">
                  {{ t("bot.bot37") }}
                </div>
                <div v-else class="tableName">{{ t("bot.bot38") }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-footer">
      <div @click="closeDialog(formRef)" class="cancel">
        {{ t("bot.bot48") }}
      </div>
      <div
        @click="openEdit(formRef)"
        class="confirm"
        v-if="props.botIsOwner == 1"
      >
        {{ t("base.base5") }}
      </div>
    </div>
  </div>
  <!-- 添加成员 -->
  <div class="mask"></div>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.el-tag) {
  width: 300px;
}

:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 0;
}

:deep(.el-select) {
  width: calc(100% - 10px) !important;
}

:deep(.el-select__wrapper) {
  min-height: 40px !important;
  width: calc(100% - 4px) !important;
}

:deep(.el-collapse-item__header) {
  background-color: #f7f8ff;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-dialog) {
  --el-dialog-width: 610px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-overlay) {
  z-index: 60 !important;
}
.flexDiv {
  height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
}

.close-icon {
  font-size: 20px;
}
.tableSet {
  height: 230px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #38aee5;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.flexCenter1 {
  display: flex;
  align-items: center;
}
// 选择模式
.flexBetWeen {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flexBetWeenOne {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.itemSkillNav {
  padding: 0 12px 0 0;
  margin-right: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  margin-bottom:10px
}

.skillImg {
  width: 26px;
  height: 26px;
  display: block;
  margin: 6px 12px 6px 12px;
}

.itemDivNavActive {
  width: 49%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  background-color: #eceeff;
  cursor: pointer;
}

.itemDivNav {
  width: 49%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  box-shadow: #dbdfff 0px 0px 8px 0px;
  cursor: pointer;
}

.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.itemSkillText {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.navImg {
  width: 38px;
  height: 38px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-family: "Regular";
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-family: "Regular";
    cursor: pointer;
  }
}
:deep(.el-form-item__label) {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-top: 24px;
  margin-bottom: 17px;
}
.tableName {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.title {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-top: 24px;
  margin-bottom: 17px;
}
:deep(.el-input__inner) {
  font-family: "Regular";
  font-size: 18px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  opacity: 0.2;
}
.addContent {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  padding: 30px;
  border-radius: 30px;
}
:deep(.el-input__inner::placeholder) {
  font-family: "Regular";
  font-size: 18px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-family: "Regular";
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-family: "Regular";
  font-size: 18px;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-family: "Semibold";
  margin-right: 6px;
}
.nameHidden {
  max-width: calc(100% - 36px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

export default {
    CN: {
        title: '知识共享',
        search: '搜索话题',
        searchTit: '搜索',
        authority: '权威话题',
        hot: '热门话题',
        collect: '收藏夹',
        verify: '我可验证',
        apply: '申请验证',
        rightTitle: '我可验证的话题',
        rightApply: '申请验证的话题',
        more: '更多',
        toVerify: '验证',
        input1:'请输入正确内容',
        input2:'正在更新中...',
    },
    HK: {
        title: '知識共享',
        search: '搜索話題',
        searchTit: '搜索',
        authority: '權威話題',
        hot: '熱門話題',
        collect: '收藏夾',
        verify: '我可驗證',
        apply: '申請驗證',
        rightTitle: '我可驗證嘅話題',
        rightApply: '申請驗證嘅話題',
        more: '更多',
        toVerify: '驗證',
        input1:'請輸入正確內容',
        input2:'正在更新中...',
    },
    US: {
        title: 'Creative Commons',
        search: 'Search Topic',
        searchTit: 'Search',
        authority: 'Authority Topics',
        hot: 'Hot Topics',
        collect: 'Favorites',
        verify: 'I can verify this',
        apply: 'Apply for verification',
        rightTitle: 'Topics I can verify',
        rightApply: 'Topics to apply for validation',
        more: 'More',
        toVerify: 'Verify',
        input1:'Please enter the correct information',
        input2:'Updating...',
        
    },
};
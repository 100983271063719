// 节流
export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): (...args: Parameters<T>) => void {
    let inThrottle: number | null = null;
    return function (...args: Parameters<T>) {
        if (!inThrottle) {
            func(...args);
            inThrottle = window.setTimeout(() => {
                inThrottle = null;
            }, limit);
        }
    };
}
// 防抖
export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeout: any
    return function (...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            func(...args);
        }, delay);
    };
}
// 返回年月日
export function getTime(t: any) {
    if (t == null) return
    let newtime = t.replace("GMT", " ");
    let date = new Date(newtime);
    let year = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let newm = m < 10 ? "0" + m : m;
    let newday = d < 10 ? "0" + d : d;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let formattedHours = hours < 10 ? '0' + hours : hours.toString();
    let formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    let formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();
    let returntime =
        year +
        "-" +
        newm +
        "-" +
        newday +
        " " +
        formattedHours +
        ":" +
        formattedMinutes +
        ":" +
        formattedSeconds;
    return returntime;
}
export function getYear(t: any) {
    if (t == null) return
    let newtime = t.replace("GMT", " ");
    let date = new Date(newtime);
    let year = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let newm = m < 10 ? "0" + m : m;
    let newday = d < 10 ? "0" + d : d;
    let returntime =
        year +
        "-" +
        newm +
        "-" +
        newday
    return returntime;
}
<template>
  <div class="baseDialog">
    <div class="flexCenter">
      <div
        v-for="(item, index) in navList"
        :key="index"
        :class="{
          navItem: navActive != index,
          navItemActive: navActive == index,
        }"
        @click.stop="changeNav(index)"
      >
        {{ t(item) }}
      </div>
      <div class="baseChange" @click="changeTopic" v-if="navActive == 1">
        <img class="basechangeSvg" :src="basechangeSvg" alt="" />
        {{ topicActive ? t("login.login87") : t("login.login88") }}
      </div>
    </div>
    <el-input
      class="searchInput"
      prefix-icon="Search"
      v-model="searchFileName"
      @input="searchInput"
      :placeholder="t('manage.roleForm.role13')"
    ></el-input>
    <!-- 文件 -->
    <div
      class="treeDiv"
      v-loading="props.leftFileLoading"
      v-if="props.baseList.length != 0 && navActive == 0"
    >
      <el-tree
        ref="treeRef"
        class="VueDraggable"
        :data="props.baseList"
        node-key="id"
        draggable
        :allow-drop="allowDrop"
        :highlight-current="true"
        :filter-node-method="treeFilter"
        @node-click="handleNodeClick"
        @node-drag-start="dragStart"
        @node-drag-end="nodeDragEnd"
      >
        <template #default="{ data }">
          <div class="fileItemDiv">
            <div class="fileBetween">
              <div class="flexCenter treeDiv1">
                <img :src="getBaseFileIcon(data)" alt="" class="fileIcon" />
                <el-tooltip
                  popper-class="toolTip"
                  :content="data.fileName || data.folderName"
                >
                  <div class="fileTitle2">
                    {{ data.fileName || data.folderName }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('base.base291')"
                  v-if="data.importantFlag == 1"
                >
                  <img :src="fileYear" alt="" class="fileYear" />
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
    <!-- 话题 -->
    <div v-loading="props.leftFileLoading">
      <div
        class="treeDiv"
        v-if="navActive == 1 && props.rightList.length != 0"
        v-infinite-scroll="loadList"
        :infinite-scroll-distance="5"
        :infinite-scroll-immediate="false"
      >
        <div
          v-for="item in props.rightList"
          :key="item"
          :draggable="true"
          @click="openTalk(item)"
          @dragstart="topicDragStart($event, item)"
          @dragend="nodeDragEnd"
          @dragover.prevent="dragover"
        >
          <div class="topicItemDiv">
            <div class="flexCenter">
              <img class="wid22" :src="iconPng" alt="" />
              <el-tooltip popper-class="toolTip" :content="item.sessionName">
                <div class="topItemName wid72">{{ item.sessionName }}</div>
              </el-tooltip>
              <img
                :src="topYes"
                v-if="item.isValid == 1"
                alt=""
                class="topYes"
              />
            </div>
            <div class="flexBetWeen">
              <div class="flexCenter wid25">
                <div
                  class="itemTx"
                  v-if="item.userName"
                  :style="'background-color:' + item.color"
                >
                  {{ item.userName.slice(0, 1) }}
                </div>
                <el-tooltip
                  popper-class="toolTip"
                  :content="item.userName"
                  v-if="item.userName"
                >
                  <div class="itemFont20s wid72">
                    {{ item.userName }}
                  </div>
                </el-tooltip>
              </div>
              <img
                :src="hotPng"
                v-if="item.result != null && item.result != 0"
                alt=""
                class="topYes"
              />
            </div>
          </div>
          <div class="topicHr"></div>
        </div>
      </div>
    </div>
    <!-- 无数据 -->
    <div
      class="nodataCenter"
      v-if="
        (navActive == 0 && props.baseList.length == 0) ||
        (navActive == 1 && props.rightList.length == 0)
      "
    >
      <el-empty />
    </div>
  </div>
</template>

<script setup>
import hotPng from "@/assets/index/hot.svg";
import iconPng from "@/assets/header/icon.svg";
import fileYear from "@/assets/base/fileYear.svg";
import basechangeSvg from "@/assets/chat/basechange.svg";
import topYes from "@/assets/index/yes.svg";
import { getBaseFileIcon } from "@/utils/file";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { ref } from "vue";
const props = defineProps(["baseList", "leftFileLoading", "rightList"]);
const emit = defineEmits([
  "getTopic",
  "loadList",
  "changeTopic",
  "changeNav",
  "openFileView",
  "openTalk",
  "closeBaseDialog",
  "dragStart",
  "nodeDragEnd",
  "topicDragStart",
  "dragover",
]);
const navList = ref(["base.base295", "base.base296"]);
const navActive = ref(0); //选用的nav
const topicActive = ref(true); //话题切换
const searchFileName = ref(""); //知识库文件搜索
const treeRef = ref(null); //ref tree
const topicDragStart = (event, item) => {
  emit("topicDragStart", event, item);
};
// 拖动开始
const dragStart = (item, event) => {
  emit("dragStart", item, event);
};
// 拖动结束
const nodeDragEnd = () => {
  emit("nodeDragEnd");
};
// 打开话题
const openTalk = (item) => {
  emit("openTalk", item);
};
// 打开文件预览
const handleNodeClick = (data) => {
  if (!data.folderName) {
    emit("openFileView", data, []);
  }
};
// 筛选tree
const treeFilter = (value, data) => {
  if (!value) return true;
  if (data.fileName) {
    return data.fileName.includes(value);
  } else {
    return data.folderName.includes(value);
  }
};
// 禁止拖拽的目标
const allowDrop = (a, b, type) => {
  if (type == "inner") {
    return true;
  } else {
    return false;
  }
};
// 切换话题
const changeTopic = () => {
  searchFileName.value = "";
  topicActive.value = !topicActive.value;
  emit("changeTopic", topicActive.value);
};
// 更多话题
const loadList = () => {
  emit("loadList", topicActive.value);
};
// 切换知识库nav
const changeNav = (index) => {
  searchFileName.value = "";
  navActive.value = index;
  emit("changeNav", index, topicActive.value);
};
// 初始化
const oneList = () => {
  changeNav(navActive.value);
};
defineExpose({ oneList });
// 知识库文件搜索
const searchInput = () => {
  if (navActive.value == 0) {
    treeRef.value.filter(searchFileName.value);
  } else {
    emit("getTopic", topicActive.value, searchFileName.value);
  }
};
</script>

<style lang="scss" scoped>
.baseDialog {
  position: absolute;
  right: 48px;
  bottom: 103px;
  width: 301px;
  border: 2px solid #d9d9d9;
  height: 487px;
  border-radius: 18px;
  background-color: #fefefe;
  z-index: 10;
  padding: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.navItem {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Regular";
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #d9d9d9;
  cursor: pointer;
}
.navItemActive {
  font-size: 20px;
  color: #0256ff;
  font-family: "Regular";
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #0256ff;
  cursor: pointer;
}
.basechangeSvg {
  width: 17px;
  margin-right: 3px;
}
.baseChange {
  background-color: #f7f8ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px 9px;
  font-size: 15px;
  color: #3376ff;
  font-family: "Regular";
  cursor: pointer;
}
.searchInput {
  :deep(.el-input__wrapper) {
    height: 48px;
    border: 1px solid #edeef6;
    margin: 10px 0px;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
}
.treeDiv {
  height: 384px;
  overflow: auto;
}
.treeDiv1 {
  width: calc(100% - 52px);
}
.VueDraggable {
  width: 100%;
  height: 100%;
}
.fileItemDiv {
  height: 26px;
  cursor: pointer;
  width: calc(100% - 24px);
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle2 {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileYear {
  width: 18px;
  margin-left: 10px;
}
.nodataCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 384px;
}
:deep(.el-tree-node__content) {
  height: 45px;
  border-radius: 6px;
}
.wid22 {
  width: 22px;
}
.itemTx {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 16px;
  color: #f7f8ff;
  text-align: center;
  font-family: "Regular";
  line-height: 22px;
}
.topItemName {
  margin-left: 10px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Semibold";
  max-width: calc(100% - 47px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topicItemDiv {
  height: 62px;
  border-radius: 16px;
  padding: 14px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 6px;
  cursor: pointer;
}
.topicItemDiv:hover {
  background-color: #eceeff;
}
.topicHr {
  width: 100%;
  height: 2px;
  background-color: #edeef6;
}
.topYes {
  width: 18px;
  margin-left: 7px;
}
.itemFont20s {
  font-size: 18px;
  font-family: "Regular";
  color: #8a8a8a;
  margin-right: 15px;
  max-width: 185px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wid25 {
  width: calc(100% - 25px);
}
.wid72 {
  width: calc(100% - 72px);
}
</style>

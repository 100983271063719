<template>
  <div class="fileItem" @click="openTalkBig">
    <img class="icon" :src="talkReson" alt="" />
    <div class="flexDiv">
      <div class="flexCenter">
        <el-tooltip
          popper-class="toolTip"
          :content="props.talkItem.sessionName"
        >
          <div class="fileName">
            {{ props.talkItem.sessionName }}
          </div>
        </el-tooltip>
        <img
          class="wid20"
          :src="time"
          alt=""
          v-if="props.talkItem.isValid == 1"
        />
        <div class="lookDiv" @click.stop="openTalk" v-if="props.deleType">
          <img class="lookImg" :src="look" alt="" />
        </div>
      </div>
      <div
        :class="
          props.talkItem.allConversationNum > 0 ? 'flexBetween' : 'flexEnd'
        "
      >
        <div class="fileTip" v-if="props.talkItem.allConversationNum > 0">
          <img :src="number" class="wid18" alt="" />
          <div>
            {{ props.talkItem.allConversationNum }}
          </div>
        </div>
        <!-- <div class="zwf" v-if="props.talkItem.selectedConversationNum">
          {{ props.talkItem.selectedConversationNum }}
        </div> -->
        <div class="lookDiv" @click.stop="deleteTalk" v-if="props.deleType">
          <img class="lookImg" :src="yc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import talkReson from "@/assets/reson/talkReson.svg";
import time from "@/assets/reson/time.svg";
import number from "@/assets/reson/number.svg";
import look from "@/assets/share/look.svg";
import yc from "@/assets/share/yc.svg";
const props = defineProps(["talkItem", "deleType"]);
const emit = defineEmits(["deleteTalk", "openTalk"]);
const openTalkBig = () => {
  if (props.deleType) return;
  emit("openTalk", props.talkItem);
};
const openTalk = () => {
  emit("openTalk", props.talkItem);
};
const deleteTalk = () => {
  emit("deleteTalk", props.talkItem);
};
</script>

<style lang="scss" scoped>
.fileItem {
  display: flex;
  align-items: center;
  background-color: #fefefe;
  border-radius: 20px;
  padding: 11px 23px 10px 25px;
  height: 57px;
  width: 212px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px #dbdfff7d;
}
.icon {
  width: 40px;
  margin-right: 16px;
}
.fileName {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Semibold";
  width: calc(100% - 59px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid20 {
  width: 20px;
  margin: 0px 0px 0px 11px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
  display: flex;
  align-items: center;
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-family: "Semibold";
  text-align: center;
  line-height: 18px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 165px;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 165px;
}
.flexCenter {
  display: flex;
  align-items: center;
  width: 165px;
}
.flexDiv {
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.wid18 {
  margin-right: 7px;
  width: 18px;
}
.lookDiv {
  background-color: #edeef6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.lookImg {
  width: 12px;
}
</style>

<template>
  <!-- 开场白 -->
  <div class="messageView call">
    {{
      props.botItem.templateCall
        ? props.botItem.templateCall
        : props.templateCall
        ? props.templateCall
        : t("base.base186")
    }}
  </div>
  <!-- 灵感 -->
  <div>
    <div v-for="(idea, index) in props.botItem.suggest" :key="index">
      <div class="ideaName" @click="saveMessage(idea.value)">
        {{ idea.value }}
      </div>
    </div>
  </div>
  <template v-for="item in props.chatList" :key="item">
    <!-- 问题 -->
    <div class="qDiv">
      <div class="qMessage">{{ item.question }}</div>
    </div>
    <!-- 粘贴 缺少循环 -->
    <div class="flexCenterEnd">
      <el-tooltip popper-class="toolTip" :content="t('base.base194')">
        <div class="lineIcon" @click="copyFn(item.question)">
          <img :src="copySvg" alt="" class="wid36" />
        </div>
      </el-tooltip>
    </div>
    <div
      v-if="!item.resonType && item.answers[item.chatActive - 1].answerContent"
      class="resonDiv"
      @click="getReson(item)"
    >
      <img :src="searchSvg" alt="" class="wid19" />
      <div class="resonText">{{ t("base.base276") }}</div>
    </div>
    <chatFile
      v-if="item.resonType"
      :item="item"
      :resonDialog="resonDialog"
      @openFileView="openFileView"
      @openTalk="openTalk"
      @openReson="openReson"
    ></chatFile>
    <!-- 回答 -->
    <messageView
      v-if="item.answers[item.chatActive - 1].answerContent"
      :message="item.answers[item.chatActive - 1].answerContent"
      class="messageView"
    ></messageView>
    <span class="loader messageView" v-else></span>
    <div class="bottomFlex" v-if="item.conversationId">
      <div class="fillCenter" v-if="item.answers.length > 1">
        <el-button
          link
          @click="changeItemPage(false, item)"
          :disabled="getLoading()"
        >
          <img
            :src="item.chatActive != 1 ? leftFill : leftNoFill"
            alt=""
            class="fillImg"
          />
        </el-button>
        <span
          :class="item.chatActive == 1 ? 'fillColor' : ''"
          class="fillText"
          >{{ item.chatActive }}</span
        >
        <span
          class="fillText"
          :class="item.chatActive == item.answers.length ? 'fillColor' : ''"
          >/{{ item.answers.length }}</span
        >
        <el-button
          link
          @click="changeItemPage(true, item)"
          :disabled="getLoading()"
        >
          <img
            :src="
              item.chatActive == item.answers.length ? rightNoFill : rightFill
            "
            alt=""
            class="fillImg"
          />
        </el-button>
      </div>
      <!-- 回答拷贝 -->
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base194')"
        placement="top"
      >
        <el-button
          class="iconMargin"
          @click="copyFn(item.answers[item.chatActive - 1].answerContent)"
        >
          <img :src="copySvg" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <!-- 重新生成 -->
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base195')"
        placement="top"
      >
        <el-button class="iconMargin" @click="releteTalk(item)">
          <img :src="relete" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <!-- 翻译 -->
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base196')"
        placement="top"
      >
        <el-button class="iconMargin" @click="itemQa(item)">
          <img :src="cToa" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <!-- 详细信息 -->
      <el-tooltip popper-class="toolTip" placement="top">
        <template #content>
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <el-button class="iconMargin" @mouseover="getDesc(item)">
          <img :src="gantan" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
    </div>
  </template>
</template>

<script setup>
import searchSvg from "@/assets/chat/search.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import copySvg from "@/assets/chat/copy.svg";
import relete from "@/assets/chat/relete.svg";
import cToa from "@/assets/chat/cToa.svg";
import gantan from "@/assets/chat/gantan.png";
import messageView from "@/components/message/index.vue";
import chatFile from "./chatFile.vue";
import { getTime, throttle } from "@/utils/utils";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import api from "@/api";
import { useStore } from "vuex";
const store = useStore();
const { t } = useI18n();
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();
const props = defineProps([
  "botItem",
  "chatList",
  "debugtemplateId",
  "templateCall",
]);
const emit = defineEmits([
  "saveMessage",
  "releteTalk",
  "openFileView",
  "openTalk",
]);
const descObj = ref({});
const getLoading = () => {
  return props.chatList.some((item) => item.loading === true);
};
const userId = ref(store.state.userId);
const resonDialog = ref(false); //来源展示框
// 获取来源数据
const getReson = (item) => {
  if (item.loading) {
    message.warning(t("login.login65"));
    return;
  }
  api.base
    .chatHistory({
      userId: userId.value,
      conversationId: item.conversationId,
      topicId: props.debugtemplateId,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        item.resonList = res.data;
        item.resonType = true;
        if (resonDialog.value) {
          resonDialog.value = false;
        }
      }
    });
};
const openReson = () => {
  resonDialog.value = true;
};
// 打开文件预览
const openFileView = (item, items) => {
  emit("openFileView", item, items);
};
// 打开会话
const openTalk = (item) => {
  emit("openTalk", item);
};
// 加减页数
const changeItemPage = (type, item) => {
  if (type && item.chatActive < item.answers.length) {
    item.chatActive++;
  }
  if (!type && item.chatActive > 1) {
    item.chatActive--;
  }
};
const saveMessage = (message) => {
  emit("saveMessage", message);
};
// 重新生成
const releteTalk = (item) => {
  emit("releteTalk", item);
};
// 翻译
const itemQa = (item) => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  let text = JSON.parse(
    JSON.stringify(item.answers[item.chatActive - 1].answerContent)
  );
  item.answers[item.chatActive - 1].answerContent = "";
  api.chat
    .chatTranslate({
      userId: userId.value,
      conversationId: item.conversationId,
      answerId: item.answers[item.chatActive - 1].answerId,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base218"));
        item.answers[item.chatActive - 1].answerContent = res.data;
      } else {
        item.answers[item.chatActive - 1].answerContent = text;
      }
    });
};
// 获取信息
// 获取详情
const getDesc = throttle((item) => {
  api.chat
    .chatDesc({
      answerId: item.answers[item.chatActive - 1].answerId,
      conversationId: item.conversationId,
      userId: userId.value,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        descObj.value = res.data;
      }
    });
}, 1000);
// 复制
const copyFn = async (item) => {
  await toClipboard(item); // str 为需要复制的文字
  message.success(t("base.base216"));
};
</script>

<style lang="scss" scoped>
.messageView {
  margin-bottom: 48px;
}
.call {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.ideaName {
  padding: 8px 12px;
  border: #edeef6 2px solid;
  background-color: #fefefe;
  border-radius: 12px;
  color: #3d3d3d;
  font-size: 18px;
  margin-bottom: 12px;
  display: inline-block;
  font-family: "Regular";
  cursor: pointer;
}

.qMessage {
  display: flex;
  background-color: #fefefe;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.qDiv {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid36 {
  width: 36px;
}
.flexCenterEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.iconMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  background-color: transparent;
  margin-left: 0px !important;
}
.iconMargin:hover {
  background-color: #edeef6;
  border-radius: 12px;
}
.bottomFlex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillImg {
  width: 24px;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fillColor {
  color: #d9d9d9;
}
.resonDiv {
  display: flex;
  background-color: #feffff;
  border-radius: 30px;
  height: 48px;
  padding: 0px 15px;
  align-items: center;
  margin-bottom: 24px;
  max-width: 235px;
  cursor: pointer;
}
.wid19 {
  width: 19px;
}
.resonText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin-left: 10px;
  margin-right: 25px;
}
</style>

<template>
  <div class="flexCenter">
    <template v-if="props.type != 'base'">
      <el-select v-model="model" class="chatSelete">
        <el-option
          v-for="item in modelList"
          :key="item"
          :label="item"
          :value="item"
        >
          <span class="selText">{{ item }}</span>
        </el-option>
      </el-select>
      <div class="line"></div>
    </template>
    <div
      class="svgDiv"
      @click.stop="openLog"
      v-if="
        props.type != 'base' ||
        (props.type == 'base' && baseItem.knowledge_role_id == 0)
      "
    >
      <img :src="logSvg" alt="" class="logSvg" />
    </div>
    <div class="svgDiv" @click="openDetail">
      <img :src="detailSvg" alt="" class="logSvg" />
    </div>
    <div
      class="line"
      v-if="
        props.type != 'base' ||
        (props.type == 'base' && baseItem.knowledge_role_id == 0)
      "
    ></div>
    <el-tooltip
      popper-class="toolTip"
      v-if="props.type != 'base'"
      :content="t('bot.bot107')"
    >
      <div class="saveTopBtn" @click="saveEdit">
        {{ t("base.base212") }}
      </div>
    </el-tooltip>
    <el-tooltip popper-class="toolTip" :content="t('bot.bot108')">
      <div
        class="pubLishBtn"
        @click.stop="pushClick"
        v-if="props.type != 'base'"
      >
        {{ t("publish.index13") }}
      </div>
    </el-tooltip>
    <div
      class="pubLishBtn"
      @click.stop="pushClick"
      v-if="props.type == 'base' && baseItem.knowledge_role_id == 0"
    >
      {{ t("publish.index13") }}
    </div>
    <!-- 发布弹窗 -->
    <div class="pushDialog" v-if="pushType">
      <div class="pushTitle">{{ t("publish.index14") }}</div>
      <div>
        <el-checkbox-group v-model="pushActive">
          <div
            v-for="(item, index) in pushList"
            :key="index"
            class="flexBetween"
          >
            <div class="flexCenter">
              <el-checkbox :value="item.value">
                <img :src="item.url" alt="" class="wxImg" />
                <div class="wxName">{{ t(item.name) }}</div>
                <div :class="item.status == 1 ? 'wxStatus' : 'wxStatusNo'">
                  {{
                    item.status == 0
                      ? t("publish.index15")
                      : t("publish.index16")
                  }}
                </div>
              </el-checkbox>
            </div>
            <el-button link class="pzBtn" @click="openDialog(item)">
              {{
                item.status == 1 ? t("publish.index17") : t("publish.index18")
              }}
            </el-button>
          </div>
        </el-checkbox-group>
      </div>
      <!-- 知识库选用模型 -->
      <template v-if="props.type == 'base'">
        <div class="pushTitle">{{ t("publish.index19") }}</div>
        <div class="chatSelBorder">
          <el-select v-model="model" class="chatSelete">
            <el-option
              v-for="item in modelList"
              :key="item"
              :label="item"
              :value="item"
            >
              <span class="selText">{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="pushTitle">{{ t("publish.index51") }}</div>
        <div class="chatSelBorder">
          <el-select v-model="strategy" class="chatSelete">
            <el-option
              v-for="item in sdList"
              :key="item"
              :label="t(item.name)"
              :value="item.value"
            >
              <el-tooltip popper-class="toolTip" :content="t(item.tip)">
                <span class="selText">{{ t(item.name) }}</span>
              </el-tooltip>
            </el-option>
          </el-select>
        </div>
        <div class="pushTitle">{{ t("base.base30") }}</div>
        <div class="chatSelBorder">
          <div class="basePromt">
            <img class="baseTx" :src="nextAiPng" alt="" />
            {{ baseItem.prompt_value.promptName }}
          </div>
        </div>
      </template>
      <!-- 发布 -->
      <div class="flexRight">
        <el-button class="saveBtn" @click="goPush">{{
          t("publish.index20")
        }}</el-button>
      </div>
    </div>
    <!-- 历史记录 -->
    <div class="historyDialog" v-if="historyType">
      <div class="histouryTitle">{{ t("publish.index21") }}</div>
      <div class="scrollHistory" v-if="historyList.length != 0">
        <div
          v-for="(item, index) in historyList"
          :key="index"
          class="hisToryItem"
        >
          <!-- 根据页面判断 -->
          <div class="titleBetWeen">
            <div class="flexCenter">
              <div class="historyName">
                {{
                  props.type == "base"
                    ? t("publish.index23")
                    : t("publish.index22")
                }}
              </div>
              <div class="nowDiv" v-if="item.isCurrent">
                {{ t("publish.index24") }}
              </div>
            </div>
            <div class="historyTime">{{ getYear(item.createdAt) }}</div>
          </div>
          <!-- 内容 -->
          <div class="smillDiv">
            <div>
              <img
                v-for="items in item.platforms"
                :key="items"
                :src="getImg(items)"
                alt=""
                class="wxImg"
              />
            </div>
            <div class="contentBetween">
              <div class="flexCenter">
                <div class="txDiv" :style="'background-color:' + item.color">
                  {{ item.userName.slice(0, 1) }}
                </div>
                <div class="userNmae">{{ item.userName }}</div>
              </div>
              <div
                class="jtText"
                @click="restClick(item)"
                v-if="!item.isCurrent"
              >
                <div>{{ t("publish.index25") }}</div>
                <img :src="jtSvg" alt="" class="jtSvg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty />
      </div>
    </div>
  </div>
  <!-- 企业微信配置 -->
  <qwDialog
    v-if="qywxType"
    @closeQw="closeQw"
    @save="save"
    :type="props.type"
    :configInfo="configInfo"
  ></qwDialog>
  <!-- 微信配置 -->
  <wxDialog
    v-if="wxType"
    @closeWx="closeWx"
    :type="props.type"
    :configInfo="configInfo"
    @save="save"
  ></wxDialog>
  <!-- 还原 -->
  <tipView
    v-if="restType"
    :title="t('publish.index26')"
    :tip="t('publish.index27')"
    :cancel="t('publish.index4')"
    :ok="t('publish.index25')"
    @cancel="closeRest"
    @ok="saveRest"
  ></tipView>
</template>

<script setup>
import nextAiPng from "@/assets/header/nextAi.svg";
import logSvg from "@/assets/publish/log.svg";
import detailSvg from "@/assets/publish/detail.svg";
import vxSvg from "@/assets/publish/vx.svg";
import qwSvg from "@/assets/publish/qw.svg";
import qwbgSvg from "@/assets/publish/qwbg.svg";
import wxbgSvg from "@/assets/publish/wxbg.svg";
import jtSvg from "@/assets/publish/jt.svg";
import qwDialog from "./qwDialog.vue";
import wxDialog from "./wxDialog.vue";
import tipView from "@/components/tipDialog/index.vue";
import { getYear } from "@/utils/utils";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
const { t } = useI18n();
const store = useStore();
const pushType = ref(false); //发布弹窗
const pushList = ref([
  {
    url: qwSvg,
    name: "publish.index11",
    status: 0, //授权 1没授权
    value: 2,
  },
  {
    url: vxSvg,
    name: "publish.index12",
    status: 0, //授权 1没授权
    value: 1,
  },
]);
const pushActive = ref([]);
const model = ref("");
const userId = ref(store.state.userId);
const baseItem = ref(store.state.baseItem);
const botItem = ref(store.state.botItem);
const modelList = ref([]); //模型列表
const qywxType = ref(false);
const wxType = ref(false);
const historyType = ref(false);
const restType = ref(false);
const configInfo = ref();
const historyList = ref([]);
const vObj = ref({});
const sdList = ref([
  {
    name: "base.base202",
    tip: "login.login73",
    value: 0,
  },
  {
    name: "base.base191",
    tip: "login.login74",
    value: 1,
  },
  {
    name: "base.base192",
    tip: "login.login75",
    value: 2,
  },
]);
const strategy = ref(0);
const emit = defineEmits(["openDetail", "changeModel", "saveEdit"]);
const props = defineProps(["type"]);
onMounted(() => {
  getModel();
  document.addEventListener("click", handleOutsideClick);
});
watch(
  () => model.value,
  (newValue, oldValue) => {
    emit("changeModel", model.value);
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = store.state.baseItem;
  }
);
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (
    pushType.value &&
    !event.target.closest(".pushDialog") &&
    !wxType.value &&
    !qywxType.value
  ) {
    pushType.value = false;
  }
  if (
    historyType.value &&
    !event.target.closest(".historyDialog") &&
    !wxType.value &&
    !qywxType.value
  ) {
    historyType.value = false;
  }
};
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
});
const saveEdit = () => {
  emit("saveEdit");
};
// 获取图像imglist
const getImgModel = () => {
  api.chat
    .imgModel({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        model.value = res.data[0];
        modelList.value = res.data;
      }
    });
};
// 发布
const goPush = () => {
  api.publish
    .postPublish({
      id:
        props.type == "bot"
          ? botItem.value.templateId
          : baseItem.value.knowledge_library_id, //知识库/智能体id
      platforms: pushActive.value,
      userId: userId.value,
      type: props.type == "base" ? 1 : 2, // 发布类型,1知识库，2智能体
      model: model.value,
      strategy: props.type == "base" ? strategy.value : null,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base181"));
        pushActive.value = [];
        strategy.value = 0;
      }
    });
};
// 返回版本头像
const getImg = (item) => {
  if (item == 2) {
    return qwbgSvg;
  } else {
    return wxbgSvg;
  }
};
// 关闭还原
const closeRest = () => {
  restType.value = false;
};
// 保存还原
const saveRest = () => {
  api.publish
    .changeV({
      id:
        props.type == "bot"
          ? botItem.value.templateId
          : baseItem.value.knowledge_library_id, //知识库/智能体id
      type: props.type == "base" ? 1 : 2,
      publishid: vObj.value.publishid,
      userId: userId.value,
    })
    .then((res) => {
      message.success(t("base.base213"));
      restType.value = false;
      openLog();
    });
};
// 打开还原弹窗
const restClick = (item) => {
  vObj.value = item;
  restType.value = true;
};
// 打开历史
const openLog = () => {
  api.publish
    .getHistoryConfig({
      id:
        props.type == "bot"
          ? botItem.value.templateId
          : baseItem.value.knowledge_library_id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        historyList.value = res.data;
        historyType.value = true;
      }
    });
};
// 保存
const save = (item, number) => {
  // 判断配置过没
  // 发布类型,1知识库，2智能体
  if (item.confid) {
    api.publish
      .saveConfig({
        plat: number,
        confid: item.confid,
        userId: userId.value,
        ...item,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base103"));
          if (number == 1) {
            closeWx();
          } else {
            closeQw();
          }
          pushClick();
        }
      });
  } else {
    api.publish
      .addConfig({
        userId: userId.value,
        config: item,
        platformtype: number,
        type: props.type == "base" ? 1 : 2,
        id:
          props.type == "bot"
            ? botItem.value.templateId
            : baseItem.value.knowledge_library_id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base103"));
          if (number == 1) {
            closeWx();
          } else {
            closeQw();
          }
          pushClick();
        }
      });
  }
};
// 关闭弹窗
const closeQw = () => {
  qywxType.value = false;
};
const closeWx = () => {
  wxType.value = false;
};
// 打开配置dialog
const openDialog = (item) => {
  configInfo.value = {};
  // 判断是否配置过
  if (item.status == 0) {
    // 判断打开那个
    if (item.value == 2) {
      qywxType.value = true;
    } else {
      wxType.value = true;
    }
  } else {
    // plat 1微信  2企微
    api.publish
      .getOneConfig({
        plat: item.value == 2 ? 2 : 1,
        confid: item.confid,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          configInfo.value = res.data;
          // 判断打开那个
          if (item.value == 2) {
            qywxType.value = true;
          } else {
            wxType.value = true;
          }
        }
      });
  }
};
// 获取模型列表
const getModel = (id) => {
  api.chat
    .chatModel({
      userId: userId.value,
      knowledgeId:
        props.type == "base"
          ? baseItem.value.knowledge_library_id
          : id
          ? id
          : null,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        modelList.value = res.data;
        getDefauleModel();
      }
    });
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      let type = modelList.value.some((item) => item == res.data);
      if (type) {
        model.value = res.data;
      } else {
        model.value = "";
      }
    }
  });
};
defineExpose({
  getImgModel,
  getModel,
});
// 点击发布弹出
const pushClick = () => {
  api.publish
    .getConfig({
      id:
        props.type == "bot"
          ? botItem.value.templateId
          : baseItem.value.knowledge_library_id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (props.type == "base") {
          getModel();
        }
        for (const item of res.data) {
          if (item.platformtype === 2) {
            pushList.value[0] = {
              ...pushList.value[0],
              ...item,
            };
            pushList.value[0].status = 1;
          }
          if (item.platformtype === 1) {
            pushList.value[1] = {
              ...pushList.value[1],
              ...item,
            };
            pushList.value[1].status = 1;
          }
        }
        pushType.value = true;
      }
    });
};
// 编辑
const openDetail = () => {
  emit("openDetail");
};
</script>

<style lang="scss" scoped>
.logSvg {
  width: 25px;
}
.svgDiv {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  margin-right: 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
  position: relative;
}
.line {
  width: 2px;
  height: 36px;
  background-color: #edeef6;
  margin-right: 12px;
}
.pubLishBtn {
  border-radius: 16px;
  height: 25px;
  background-color: #0256ff;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  padding: 12px 20px;
  cursor: pointer;
}
.saveTopBtn {
  border-radius: 16px;
  height: 21px;
  background-color: #fefefe;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  padding: 12px 20px;
  cursor: pointer;
  border: 2px solid #edeef6;
  margin-right: 12px;
}
.pushDialog {
  position: absolute;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  padding: 12px;
  width: 480px;
  right: 0;
  top: 55px;
  background-color: #ffffff;
  z-index: 20;
}
.historyDialog {
  position: absolute;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  padding: 12px;
  width: 397px;
  right: 115px;
  top: 55px;
  background-color: #ffffff;
  z-index: 20;
  max-height: 580px;
}
.pushTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
  padding: 12px 0px 12px 9px;
  border-bottom: 1px solid #edeef6;
}
.wxImg {
  width: 31px;
  margin-right: 5px;
}
.wxName {
  font-size: 16px;
  color: #3d3d3d;
  font-family: "Medium";
  width: 140px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wxStatus {
  font-size: 16px;
  color: #14ae5c;
  font-family: "Medium";
  background-color: #14ae5c1a;
  border-radius: 4px;
  padding: 1px 5px;
  height: 24px;
  line-height: 24px;
}
.wxStatusNo {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
  background-color: #edeef6;
  border-radius: 4px;
  padding: 1px 5px;
  height: 24px;
  line-height: 24px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 6px;
  border-bottom: 1px solid #edeef6;
}
.pzBtn {
  font-size: 16px;
  color: #3376ff;
  font-family: "Medium";
}
.chatSelete {
  width: 127px;
  margin-right: 12px;
}
.selText {
  font-size: 16px;
  color: #3d3d3d;
  font-family: "Regular";
}
:deep(.el-select__wrapper) {
  height: 48px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  border-radius: 16px;
  border: 2px solid #edeef6;
}
:deep(.el-select__caret) {
  font-size: 20px;
  color: #8a8a8a;
}
.chatSelBorder {
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid #edeef6;
}
.saveBtn {
  height: 49px;
  border-radius: 16px;
  background-color: #0256ff;
  color: #fefefe;
  font-size: 18px;
  font-family: "Regular";
  padding: 12px 20px;
  margin-top: 20px;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}
:deep(.el-checkbox__label) {
  display: flex;
  align-items: center;
}
.histouryTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
}
.hisToryItem {
  border-radius: 16px;
  background-color: #f7f8ff;
  padding: 12px;
  margin-top: 12px;
}
.scrollHistory {
  max-height: 531px;
  overflow: auto;
}
.historyName {
  font-size: 16px;
  color: #a3acec;
  font-family: "Medium";
}
.historyTime {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
}
.nowDiv {
  background-color: #ffc3002e;
  border-radius: 4px;
  color: #ffc300;
  font-size: 16px;
  font-family: "Medium";
  padding: 1px 5px;
  height: 24px;
  line-height: 24px;
  margin-left: 12px;
}
.titleBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jtSvg {
  width: 10px;
  margin-left: 6px;
}
.smillDiv {
  background-color: #eff0ff;
  border-radius: 16px;
  margin-top: 10px;
  padding: 11px 18px 14px 17px;
}
.txDiv {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  font-size: 16px;
  font-family: "Regular";
  color: #ffffff;
  margin-right: 11px;
}
.userNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
}
.jtText {
  background-color: #f7f8ff;
  border-radius: 8px;
  height: 31px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  cursor: pointer;
  padding: 3px 9px;
}
.contentBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.baseTx {
  width: 28px;
  margin-right: 13px;
}
.basePromt {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: "Regular";
  background-color: #edeef6;
  border-radius: 16px;
  padding: 12px;
  color: #8a8a8a;
  justify-content: center;
}
</style>

<template>
    <div class="imgageContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <ArrowLeftOutlined class="icon" />
                <div>{{ t('bot.bot55') }}</div>
            </div>
            <div class="right" @click="getHistory">{{ t('bot.bot56') }}</div>
        </div>
        <!-- 上传 -->
        <div v-if="type == 'upFile'">
            <UploadDragger class="upFile" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div class="iconCenter">
                    <FileImageOutlined class="word iconItem" />
                </div>
                <div class="imageFileText">{{ t('bot.bot84') }}</div>
            </UploadDragger>
        </div>
        <div v-if="type == 'loading'" class="upFile">
            <div class="loadingAbs">
                <div v-loading="loading" class="loading"></div>
                <div class="loadingNow">{{ t('bot.bot85') }}</div>
            </div>
        </div>
        <!-- 成功 -->
        <div v-if="type == 'success'" class="upFile">
            <div class="imgRow">
                <div class="imgLeft">
                    <div class="imgTopFlex">
                        <div class="dr"></div>
                        <div class="imgTitle">{{ t('bot.bot86') }}</div>
                    </div>
                    <div class="leftCenter">
                        <img :src="detail.image_url" class="leftImg" alt="" />
                    </div>
                    <div class="leftBottom">
                        <div class="flexBetween">
                            <div class="imgTopFlex">
                                <div class="dr"></div>
                                <div class="imgTitle">{{ t('bot.bot87') }}</div>
                            </div>
                            <div class="flexCenterRight">
                                <FileWordOutlined class="rightIcon"/>
                                <div class="exportText" @click="exportText">{{ t('bot.bot66') }}</div>
                            </div>
                        </div>
                        <div class="leftBottomText">
                            <markdownChat :message="detail.word"></markdownChat>
                        </div>
                    </div>
                </div>
                <div class="imgRight">
                    <div class="flexBetween">
                        <div class="imgTopFlex">
                            <div class="dr"></div>
                            <div class="imgTitle">{{ t('bot.bot88') }}</div>
                        </div>
                        <div class="flexCenterRight">
                            <FileWordOutlined class="rightIcon"/>
                            <div class="exportText" @click="exportText">{{ t('bot.bot66') }}</div>
                        </div>
                    </div>
                    <div class="leftBottomText">
                        <markdownChat :message="detail.describe"></markdownChat>
                    </div>
                </div>
            </div>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" :title="$t('bot.bot56')" placement="right" :closable="false" :width="300" :open="historyType"
            @close="historyType = false">
            <div>
                <div v-for="item in historyData" :key="item.id" class="historyDiv">
                    <img :src="item.imageUrl" alt="" style="width: 100%; cursor: pointer" @click="openDetails(item)" />
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { UploadDragger, Drawer } from "ant-design-vue";
import { FileImageOutlined, ArrowLeftOutlined,FileWordOutlined } from '@ant-design/icons-vue';
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const type = ref("upFile");
const historyType = ref(false);
const userId = ref(store.state.userId);
const fileList = ref([]);
const detail = ref({});
const historyData = ref([]);
const loading = ref(false);
const exportText = () => {
    const imageUrl = detail.value.file_url;
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-image.jpg"; // 设置保存的文件名
    a.style.display = "none";
    // 将链接添加到DOM中
    document.body.appendChild(a);
    // 模拟用户点击链接以触发下载
    a.click();
    // 从DOM中移除链接
    document.body.removeChild(a);
};

const getHistory = () => {
    api.bot
        .imageHistory({ userId: userId.value }).then((res) => {
            if (res.returnCode == 200) {
                historyType.value = true;
                historyData.value = res.data;
            }
        });
};
const beforeUpload = (file) => {
    fileList.value = [file];
    upFile();
};
const upFile = () => {
    type.value = "loading"
    loading.value = true;
    api.bot
        .imgToTxt(fileList.value, {}).then((res) => {
            console.log(res);
            type.value = "success";
            if (res.returnCode == 200) {
                detail.value = res.data;
                loading.value = false;
            }
        });
};
const openDetails = (item) => {
    detail.value = item;
    detail.value.image_url = item.imageUrl;
    detail.value.word = item.contentMessage;
    detail.value.describe = item.describeMessage;
    detail.value.file_url = item.fileUrl;
    type.value = "success";
    historyType.value = false;
}
const customRequest = () => { };
// 返回上一页
const back = () => {
    router.go(-1);
};
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.imgageContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 20px 30px 30px 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 30px;

    .left {
        display: flex;
        color: #8a8a8a;
        cursor: pointer;

        .icon {
            margin-top: 4px;
            margin-right: 6px;
            size: 20px;
        }
    }

    .right {
        cursor: pointer;
        color: #3376ff;
        font-weight: 600;
    }
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
    border-radius: 16px;
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #edf2ff;
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
    width: 1000px !important;
    height: 560px !important;
    margin: 40px auto 0 !important;
}

.iconCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
}

.iconItem {
    display: block;
    margin-bottom: 40px;
    font-size: 120px;
}

.word {
    color: #458ae6;
}

.imageFileText {
    font-size: 28px;
    color: #000000;
    font-family: "Regular";
    margin: 10px 0px;
}

.loadingAbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 240px;
    border-radius: 16px;
    background-color: #edf2ff;
    height: 690px;

    .loading {
        margin-right: 60px;
    }
}

.imgRow {
    width: 1460px;
    height: 690px;
    border-radius: 16px;
    display: flex;
}

.imgLeft {
    height: 100%;
    padding: 20px;
    background-color: #edf2ff;
    width: 49%;
    margin-right: 1%;
    border-radius: 16px;
}

.imgRight {
    height: 100%;
    padding: 20px;
    background-color: #edf2ff;
    width: 49%;
    border-radius: 16px;
}

.dr {
    width: 2px;
    height: 22px;
    background-color: #0256ff;
    margin-right: 5px;
}

.imgTitle {
    font-size: 18px;
    color: #000000;
    font-family: "Bold";
}

.imgTopFlex {
    display: flex;
    align-items: center;
    height: 30px;
}

.leftBottom {
    height: 30%;
}

.leftCenter {
    height: calc(70% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexCenterRight {
    display: flex;
    align-items: center;
    height: 30px;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
}

.leftBottomText {
    height: calc(100% - 30px);
    overflow: auto;
    padding: 0px 20px;
    font-size: 20px;
    color: #000;
}

.rightIcon {
    color: #0256ff;
    font-size: 20px;
}

.exportText {
    color: #0256ff;
    font-family: "Regular";
    font-size: 18px;
    cursor: pointer;
}

.leftImg {
    max-height: 80%;
    max-width: 80%;
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.historyIcon {
    font-size: 24px;
    margin-right: 10px;
    width: 25px;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
}

.historyName {
    width: calc(100% - 70px);
}

.question_span {
    font-size: 18px;
    color: #000000;
    font-family: "Regular";
}

.imgLoading {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingNow {
    font-size: 20px;
    color: #3376ff;
    margin-top: 80px;
    font-family: "Regular";
}
</style>
import axios from "../server";
export default {
    // 拿到公司名称
    getCompanyName() {
        return axios({
            url: "/login/company/get",
            method: "GET",
        });
    },
    // 获得免责公告
    getDisclaimer() {
        return axios({
            url: "/login/Disclaimer/get",
            method: "GET",
        });
    },
    // 登录
    goLogin(data: any) {
        return axios({
            url: "/login/login",
            method: "POST",
            data,
        });
    },
    //忘记密码
    postforgotPasswd(data: any) {
        return axios({
            url: "/user/forgot_passwd",
            method: "POST",
            data,
        });
    },
    // 通过激活码激活
    activation(data: any) {
        return axios({
            url: "/login/activate",
            method: "POST",
            data,
        });
    },
    // 获取头像
    getAvatar(data: any) {
        return axios({
            url: "/personal-page/get/avatar",
            method: "POST",
            data,
        });
    },
    // 获取部门
    loginGetDepartMent(data: any) {
        return axios({
            url: "login/department-info/get",
            method: "GET",
            data,
        });
    },
    // 获取禁用列表用户
    loginGetUser(data: any) {
        return axios({
            url: "login/user-info/get",
            method: "POST",
            data,
        });
    },
    // 禁用用户
    loginDelete(data: any) {
        return axios({
            url: "login/user/delete",
            method: "POST",
            data,
        });
    },
}
export default {
    CN: {
        bot1: '智能助手',
        bot2: '智能工具',
        bot3: '搜索智能助手',
        bot4: '新增成功',
        bot5: '修改成功',
        bot6: '置顶成功',
        bot7: '取消成功',
        bot8: '删除成功',
        bot9: '新建智能体',
        bot10: '新建一个空白的AI智能体；智能体能够根据你的设定和介绍执行特定指令，帮助你自动执行任务。',
        bot11: '置顶智能体',
        bot12: '取消置顶',
        bot13: '删除智能体',
        bot14: '编排智能体',
        bot15: 'AI微模型',
        bot16: '图像生成',
        bot17: '联网检索',
        bot18: '一键完善成功',
        bot19: '名称不能为空',
        bot20: '设定不能为空',
        bot21: '权限成员不能为空',
        bot22: '添加成功',
        bot23: '用户删除成功',
        bot24: '智能体名称不能为空',
        bot25: '新建智能体',
        bot26: '修改智能体',
        bot27: '智能体类型',
        bot28: '名称',
        bot29: '请输入智能助手名称',
        bot30: '设定',
        bot31: '一键完善',
        bot32: '输入智能体主题、内容或作用的简要描述',
        bot33: '技能',
        bot34: '图标',
        bot35: '权限设置',
        bot36: '身份',
        bot37: '普通用户',
        bot38: '拥有者',
        bot39: '操作',
        bot40: '移除',
        bot41: '添加成员',
        bot42: '介绍',
        bot43: '简短介绍您的智能体，例如：',
        bot44: '开场白',
        bot45: '智能体的开场白。例如：',
        bot46: '提问灵感',
        bot47: '添加',
        bot48: '取消',
        bot49: '确定',
        bot50: '请输入部门名称',
        bot51: '请输入群组名称',
        bot52: '用户名',
        bot53: '角色',
        bot54: '群组名',
        bot55: '返回',
        bot56: '历史记录',
        bot57: "点击上传/拖拽本地音频文件到这里",
        bot58: '大小：音频≤50M，时长0.5小时内',
        bot59: '格式：.WAV,.MP3,.M4A,.OPUS/OGG,FLAC,WMA,AAC,ALAW in WAV container,MULAW in WAV container,AMR,WebM,SPEEX',
        bot60: '正在提取文字...',
        bot61: '音频内容',
        bot62: '不支持该文件类型上传。',
        bot63: '点击上传/拖拽PDF到这里',
        bot64: '正在解析中...',
        bot65: "识别结果",
        bot66: "导出为文档",
        bot67: '文档翻译',
        bot68: '跨越语言障碍，让信息自由流通',
        bot69: '多语种翻译',
        bot70: '多种格式支持',
        bot71: '智能音频',
        bot72: '快速识别音/视频，一键转换文字，精准支持多种语言',
        bot73: '语音识别',
        bot74: '多语种支持',
        bot75: '图片转文档',
        bot76: '单张图片上传，快速识别出结果',
        bot77: '图片识别',
        bot78: '文档导出',
        bot79: 'PDF文字提取',
        bot80: '支持PDF识别，快速识别出结果',
        bot81: 'PDF识别',
        bot82: 'PDF文字提取',
        bot83: '立即体验',
        bot84: '点击上传/拖拽图片到这里',
        bot85: '正在解析图片中...',
        bot86: '图片',
        bot87: '图片识别',
        bot88: 'AI润色预览',
        bot89: '不支持该文件类型上传。',
        bot90: '上传文件不能超过20MB',
        bot91: '文件名称过长，上传失败！',
        bot92: '点击或拖拽添加',
        bot93: '请选择目标语言',
        bot94: '开始翻译',
        bot95: '文档翻译中',
        bot96: '若离开页面，翻译不会取消，可到翻译历史中查看',
        bot97: '文档翻译成功',
        bot98: '文档下载',
        bot99: '在线预览',
        bot100: '上传新文档',
        bot101: '点击上传智能体头像',
        bot102: '部门',
        bot104: '高级',
        bot105: '智能体详情',
        bot106: 'AI智能助手详情',
        bot107: '点击「提交」按钮后，你的智能体编排会提交到NextAI Platform，你可以在完成提交后进行发布',
        bot108: '在发布之前，请先将你的智能体编排提交到NextAI Plaform',
        bot109: '编排',
        bot110: '设定和逻辑介绍',
        bot111: 'AI一键完善',
        bot112: '高级设置',
        bot113: '点击「调试」按钮后，你可以在右侧「调试预览」模块测试你的智能体编排',
        bot114: '调试',
        bot115: '调试预览',
        bot116: '提交成功'
    },
    HK: {
        bot1: '智能助手',
        bot2: '智慧工具',
        bot3: '搜索智能助手',
        bot4: '新增成功',
        bot5: '修改成功',
        bot6: '置頂成功',
        bot7: '取消成功',
        bot8: '刪除成功',
        bot9: '新建智能體',
        bot10: '新建一個空白的AI智慧體；智慧體能夠根據你的設定和介紹執行特定指令，説明你自動執行任務。',
        bot11: '置頂智能體',
        bot12: '取消置頂',
        bot13: '刪除智能體',
        bot14: '編排智慧體',
        bot15: 'AI知識庫 ',
        bot16: '圖像生成',
        bot17: '聯網檢索',
        bot18: '一鍵完善成功',
        bot19: '名稱不能為空',
        bot20: '設定不能為空',
        bot21: '許可權成員不能為空',
        bot22: '添加成功',
        bot23: '用戶刪除成功',
        bot24: '智慧體名稱不能為空',
        bot25: '新建智能體',
        bot26: '修改智能體',
        bot27: '智能體類型',
        bot28: '名稱',
        bot29: '請輸入智慧助手名稱',
        bot30: '設定',
        bot31: '一鍵完善',
        bot32: '輸入智慧體主題、內容或作用的簡要描述',
        bot33: '技能',
        bot34: '圖示',
        bot35: '許可權設置',
        bot36: '身份',
        bot37: '普通用戶',
        bot38: '擁有者',
        bot39: '操作',
        bot40: '移除',
        bot41: '添加成員',
        bot42: '介紹',
        bot43: '簡短介紹您的智慧體，例如：',
        bot44: '開場白',
        bot45: '智能體的開場白。例如：',
        bot46: '提問靈感',
        bot47: '添加',
        bot48: '取消',
        bot49: '確定',
        bot50: '請輸入部門名稱',
        bot51: '請輸入群組名稱',
        bot52: '用戶名',
        bot53: '部門',
        bot54: '群組名',
        bot55: '返回',
        bot56: '歷史記錄',
        bot57: "點擊上傳/拖拽本地音訊檔到這裡",
        bot58: '大小：音訊≤50M，時長0.5小時內',
        bot59: '格式：.WAV,.MP3,.M4A,.OPUS/OGG,FLAC,WMA,AAC,ALAW in WAV container,MULAW in WAV container,AMR,WebM,SPEEX',
        bot60: '正在提取文字...',
        bot61: '音訊內容',
        bot62: '不支持該檔案類型上傳。',
        bot63: '點擊上傳/拖拽PDF到這裡',
        bot64: '正在解析中...',
        bot65: "識別結果",
        bot66: "匯出為文檔",
        bot67: '文檔翻譯',
        bot68: '跨越語言障礙，讓資訊自由流通',
        bot69: '多語種翻譯',
        bot70: '多種格式支持',
        bot71: '智能音訊',
        bot72: '快速識別音/視頻，一鍵轉換文字，精准支援多種語言',
        bot73: '語音辨識',
        bot74: '多語種支持',
        bot75: '圖片轉文檔',
        bot76: '單張圖片上傳，快速識別出結果',
        bot77: '圖片識別',
        bot78: '文檔匯出',
        bot79: 'PDF文字提取',
        bot80: '支持PDF識別，快速識別出結果',
        bot81: 'PDF識別',
        bot82: 'PDF文字提取',
        bot83: '立即體驗',
        bot84: '點擊上傳/拖拽圖片到這裡',
        bot85: '正在解析圖片中...',
        bot86: '圖片',
        bot87: '圖片識別',
        bot88: 'AI潤色預覽',
        bot89: '不支持該檔案類型上傳。',
        bot90: '上傳檔不能超過20MB',
        bot91: '檔案名稱過長，上傳失敗！',
        bot92: '點擊或拖拽添加',
        bot93: '請選擇目的語言',
        bot94: '開始翻譯',
        bot95: '文檔翻譯中',
        bot96: '若離開頁面，翻譯不會取消，可到翻譯歷史中查看',
        bot97: '文檔翻譯成功',
        bot98: '文檔下載',
        bot99: '線上預覽',
        bot100: '上傳新文檔',
        bot101: '點擊上傳智能體頭像',
        bot102: '部門',
        bot104: '高級',
        bot105: '智慧體詳情',
        bot106: 'AI智慧助手詳情',
        bot107: '點擊「提交」按鈕后，你的智慧體編排會提交到NextAI Platform，你可以在完成提交後進行發佈',
        bot108: '在發佈之前，請先將你的智慧體編排提交到NextAI Plaform',
        bot109: '編排',
        bot110: '設定和邏輯介紹',
        bot111: 'AI一鍵完善',
        bot112: "高級設置",
        bot113: '點擊「調試」按鈕后，你可以在右側「調試預覽」模塊測試你的智慧體編排',
        bot114: '調試',
        bot115: '調試預覽',
        bot116: '提交成功'
    },
    US: {
        bot1: 'AI Assistant',
        bot2: 'AI Tool',
        bot3: 'Search Assistant',
        bot4: 'Added Successfully',
        bot5: 'Modified Successfully',
        bot6: 'Pinned Successfully',
        bot7: 'Unpinned Successfully',
        bot8: 'Deleted Successfully',
        bot9: 'Create AI Agent',
        bot10: 'Create a blank AI agent; the agent can execute specific instructions based on your settings and description, helping you automate tasks.',
        bot11: 'Pin AI Agent',
        bot12: 'Unpin AI Agent',
        bot13: 'Delete AI Agent',
        bot14: 'Orchestrate agents',
        bot15: 'AI Knowledge Base',
        bot16: 'Image Generation',
        bot17: 'Web Search',
        bot18: 'One-Click Enhancement Successful',
        bot19: 'Name cannot be empty',
        bot20: 'The setting cannot be empty',
        bot21: 'Permission members cannot be empty',
        bot22: 'AI Agent Modified Successfully',
        bot23: 'User Deleted Successfully',
        bot24: 'AI Agent name cannot be empty',
        bot25: 'Create AI Agent',
        bot26: 'Edit AI Agent',
        bot27: 'Agent Type',
        bot28: 'Name',
        bot29: 'Please enter the AI assistant name',
        bot30: 'Settings',
        bot31: 'One-Click Enhancement',
        bot32: 'Enter a brief description of the AI agent’s theme, content, or purpose',
        bot33: 'Skills',
        bot34: 'Icon',
        bot35: 'Permission Settings',
        bot36: 'Role',
        bot37: 'Member',
        bot38: 'Owner',
        bot39: 'Actions',
        bot40: 'Remove',
        bot41: 'Add Member',
        bot42: 'Description',
        bot43: 'Briefly introduce your AI agent, for example:',
        bot44: 'Greeting',
        bot45: 'AI Agent greeting, for example:',
        bot46: 'Prompt Inspiration',
        bot47: 'Add',
        bot48: 'Cancel',
        bot49: 'Confirm',
        bot50: 'Please enter the department name',
        bot51: 'Please enter the group name',
        bot52: 'Username',
        bot53: 'Department',
        bot54: 'Group Name',
        bot55: 'Back',
        bot56: 'History',
        bot57: 'Click to upload or drag and drop local audio files here',
        bot58: 'Size: Audio ≤ 50MB, duration within 0.5 hours',
        bot59: 'Formats: .WAV, .MP3, .M4A, .OPUS/OGG, FLAC, WMA, AAC, ALAW in WAV container, MULAW in WAV container, AMR, WebM, SPEEX',
        bot60: 'Extracting text...',
        bot61: 'Audio Content',
        bot62: 'This file type is not supported for upload.',
        bot63: 'Click to upload or drag and drop a PDF here',
        bot64: 'Parsing...',
        bot65: 'Recognition Results',
        bot66: 'Export as Document',
        bot67: 'Document Translation',
        bot68: 'Break language barriers and enable seamless information flow',
        bot69: 'Multilingual Translation',
        bot70: 'Supports Multiple Formats',
        bot71: 'Intelligent Audio',
        bot72: 'Quickly transcribe audio/video into text with precise multilingual support',
        bot73: 'Speech Recognition',
        bot74: 'Multilingual Support',
        bot75: 'Image to Document',
        bot76: 'Upload a single image for fast recognition results',
        bot77: 'Image Recognition',
        bot78: 'Document Export',
        bot79: 'PDF Text Extraction',
        bot80: 'Supports PDF recognition with fast results',
        bot81: 'PDF Recognition',
        bot82: 'PDF Text Extraction',
        bot83: 'Try Now',
        bot84: 'Click to upload or drag and drop an image here',
        bot85: 'Parsing image...',
        bot86: 'Image',
        bot87: 'Image Recognition',
        bot88: 'AI Polishing Preview',
        bot89: 'This file type is not supported for upload.',
        bot90: 'Uploaded file cannot exceed 20MB',
        bot91: 'File name too long, upload failed!',
        bot92: 'Click or drag to add',
        bot93: 'Please select the target language',
        bot94: 'Start Translation',
        bot95: 'Document Translating',
        bot96: 'If you leave the page, the translation will not be canceled. You can view it in the translation history.',
        bot97: 'Document Translated Successfully',
        bot98: 'Download Document',
        bot99: 'Online Preview',
        bot100: 'Upload New Document',
        bot101: "Click to upload intelligent entity avatar",
        bot102: "Department",
        bot104: 'Senior',
        bot105: 'Agent details',
        bot106: 'Details of the AI intelligent assistant',
        bot107: "After clicking the 'submit' button, your agent orchestration will be submitted to NextAI Platform, and you can publish it after completing the submission",
        bot108: 'Before publishing, please submit your agent orchestration to NextAI Plaform',
        bot109: 'orchestration',
        bot110: 'Introduction to settings and logic',
        bot111: 'AI one-click improvement',
        bot112: 'advanced settings',
        bot113: 'After clicking the "Debug" button, you can test your agent orchestration in the "Debug Preview" module on the right',
        bot114: 'debug',
        bot115: 'debug preview',
        bot116: 'Submitted successfully'
    },
};

<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["handleCloseUser", "handleAddUser", "addUserBtn"]);
const props = defineProps({
  showAdd: Boolean,
  uploadInfo: Object,
  formType: String,
});
const show = ref(props.showAdd);
watch(
  () => props.showAdd,
  (newVal) => {
    show.value = newVal;
  }
);
onMounted(() => {
  getRole();
  getDepart();
  getGroup();
});
// 部门树形列表
const departmentList = ref([]);
const defaultProps = ref({
  children: "children",
  label: "departmentName",
  id: "departmentId",
});
const defaultProps2 = ref({
  children: "children",
  label: "roleName",
  id: "id",
});
const defaultProps3 = ref({
  children: "children",
  label: "groupName",
  id: "groupId",
});
const getDepart = () => {
  api.manage
    .getDepartmentList({ userId: userId.value, pageId: 0 })
    .then((res) => {
      if (res.returnCode == 200) {
        departmentList.value = res.data;
      }
    });
};
// 角色树形列表
const roleList = ref([]);
const getRole = () => {
  api.manage.allRole({ userId: userId.value, pageId: 0 }).then((res) => {
    if (res.returnCode == 200) {
      roleList.value = res.data;
    }
  });
};
// 群组下拉
const groupList = ref([]);
const getGroup = () => {
  api.manage.queryGroupList({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      groupList.value = res.data;
    }
  });
};
const autoAdd = ref(true);
const newPassword = ref("");
const isEmailInfo = ref(true);
// 监听是否自动创建密码
watch(
  () => autoAdd.value,
  (newVal) => {
    if (newVal) {
      newPassword.value = "";
      isEmailInfo.value = true;
    } else {
      isEmailInfo.value = false;
    }
  }
);
const sex = ref(0);
//保存编辑
const handleSave = (formRef) => {
  formRef.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      props.uploadInfo.newUserId = props.uploadInfo.userId;
      emit("handleAddUser", props.uploadInfo);
    }
  });
};
//新增用户校验
const rules = ref({
  userName: [
    {
      required: true,
      message: t("manage.userForm.user40"),
    },
    { min: 1, max: 50, message: t("manage.userForm.user40") },
  ],
  email: [
    {
      type: "email",
      message: t("manage.userForm.user41"),
    },
    {
      required: true,
      message: t("manage.userForm.user42"),
    },
  ],
  sex: [{ required: true, message: t("manage.userForm.user46") }],
  isEmailInfo: [{ required: true, message: t("manage.userForm.user47") }],
  roleId: [{ required: true, message: t("manage.userForm.user48") }],
  departmentId: [{ required: true, message: t("manage.userForm.user49") }],
});

// 新增取消
const handleCancel = () => {
  formRef.value.resetFields();
  autoAdd.value = true;
  isEmailInfo.value = true;
  newPassword.value = "";
  show.value = !show.value;
  emit("handleCloseUser");
  newPassword.value = "";
};
const formRef = ref(null);
// 新增确定
const handleConfirm = async (formRef) => {
  props.uploadInfo.sex = sex.value;
  props.uploadInfo.isEmailInfo = isEmailInfo.value;
  if (autoAdd.value == false) {
    if (newPassword.value == "") {
      message.warning(t("manage.userForm.user100"));
      return;
    } else {
      props.uploadInfo.newPassword = newPassword.value;
    }
  }
  props.uploadInfo.userId = userId.value;
  await formRef.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      emit("addUserBtn", props.uploadInfo);
      newPassword.value = "";
    }
  });
};
</script>

<template>
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>
            {{
              props.formType == "add"
                ? t("manage.userForm.user50")
                : t("manage.userForm.user51")
            }}
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider />
      <div class="content">
        <div
          style="display: flex; justify-content: space-between"
          v-if="props.formType == 'edit'"
        >
          <div style="display: flex">
            <div
              class="avatar"
              :style="'background-color:' + props.uploadInfo?.color"
            >
              {{ props.uploadInfo?.userName[0] }}
            </div>
            <!-- 启用 -->
            <div class="tag" v-if="props.uploadInfo.isActive == true">
              <div class="tag-icon">
                <img src="@/assets/manage/dui.svg" alt="" />
              </div>
              <div class="tag-text">{{ t("manage.userForm.user52") }}</div>
            </div>
            <!-- 禁用 -->
            <div class="tag_cuo" v-else>
              <div class="tag-icon">
                <img src="@/assets/manage/cuo.svg" alt="" />
              </div>
              <div class="tag-text">{{ t("manage.userForm.user53") }}</div>
            </div>
          </div>
        </div>
        <el-form
          :model="props.uploadInfo"
          class="form"
          :rules="rules"
          ref="formRef"
          label-position="top"
        >
          <el-form-item prop="userName" :label="$t('manage.userForm.userName')">
            <el-input
              class="comtent"
              oninput="(value)=>value.replace(/\s/g,'')"
              v-model.trim="props.uploadInfo.userName"
              :placeholder="$t('manage.userForm.user8')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sex" :label="$t('manage.userForm.sex')">
            <el-radio-group v-model="props.uploadInfo.sex">
              <el-radio :value="0" class="isActive">{{
                t("manage.userForm.user54")
              }}</el-radio>
              <el-radio :value="1">{{ t("manage.userForm.user55") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="email" :label="$t('manage.userForm.email')">
            <el-input
              class="comtent"
              v-model="props.uploadInfo.email"
              :placeholder="$t('manage.userForm.user42')"
            ></el-input>
            <el-checkbox
              v-if="props.formType == 'add'"
              v-model="isEmailInfo"
              :label="$t('manage.userForm.user56')"
              size="large"
            />
          </el-form-item>
          <el-form-item prop="phone" :label="$t('manage.userForm.phone')">
            <el-input
              class="comtent"
              v-model="props.uploadInfo.phone"
              :placeholder="$t('manage.userForm.user43')"
            />
          </el-form-item>
          <el-form-item
            prop="address"
            :label="$t('manage.userForm.address')"
            v-if="props.formType == 'add'"
          >
            <el-input
              class="comtent"
              v-model="props.uploadInfo.address"
              :placeholder="$t('manage.userForm.user45')"
            />
          </el-form-item>
          <el-form-item
            prop="newPassword"
            :label="$t('manage.userForm.user57')"
            v-if="props.formType == 'add'"
          >
            <div class="form-name">
              <el-checkbox
                v-model="autoAdd"
                :label="$t('manage.userForm.user58')"
                size="large"
              />
              <div v-if="!autoAdd">
                <el-input
                  v-model="newPassword"
                  :placeholder="$t('manage.userForm.user59')"
                  show-password
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="departmentId"
            :label="$t('manage.userForm.user2')"
          >
            <el-tree-select
              v-model="props.uploadInfo.departmentId"
              :data="departmentList"
              check-strictly
              :render-after-expand="false"
              :props="defaultProps"
              :placeholder="$t('manage.userForm.user49')"
              node-key="departmentId"
            >
              <template #default="{ data: { departmentName } }">
                {{ departmentName }}
              </template>
            </el-tree-select>
          </el-form-item>
          <el-form-item prop="groups" :label="$t('manage.userForm.user60')">
            <el-tree-select
              clearable
              multiple
              v-model="props.uploadInfo.groupIds"
              :data="groupList"
              check-on-click-node
              :props="defaultProps3"
              node-key="groupId"
            >
              <template #default="{ data: { groupName } }">
                <span style="float: left">{{ groupName }}</span>
              </template>
            </el-tree-select>
          </el-form-item>
          <el-form-item prop="roleId" :label="$t('manage.userForm.user61')">
            <el-tree-select
              clearable
              v-model="props.uploadInfo.roleId"
              :data="roleList"
              check-on-click-node
              :props="defaultProps2"
              node-key="id"
            >
              <template #default="{ data: { roleName } }">
                <span style="float: left">{{ roleName }}</span>
              </template>
            </el-tree-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType == 'edit'">
          <el-button @click="handleCancel(formRef)" class="cancel">{{
            t("manage.userForm.user35")
          }}</el-button>
          <el-button
            @click="handleSave(formRef)"
            :disabled="$hasPermission('10007') == false"
            class="confirm"
            >{{ t("manage.userForm.user36") }}</el-button
          >
        </div>
        <div class="dialog-footer" v-if="props.formType == 'add'">
          <el-button @click="handleCancel(formRef)" class="cancel">{{
            t("manage.userForm.user37")
          }}</el-button>
          <el-button @click="handleConfirm(formRef)" class="confirm">{{
            t("manage.userForm.user38")
          }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-tag.is-closable) {
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  background-color: #eceeff !important;
  color: #3d3d3d !important;
}

:deep(.el-tag .el-tag__close:hover) {
  background-color: #3d3d3d !important;
}

:deep(.el-tag .el-tag__close) {
  background-color: #a3acec !important;
  width: 12px !important;
  height: 12px !important;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-form-item__label) {
  margin-top: 10px;
}

:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 500px !important;
}

:deep(.el-select__wrapper) {
  min-height: 42px !important;
  border-radius: 16px !important;
  width: 520px !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.close-icon {
  font-size: 30px;
}

.passWord {
  color: #f02d63;
  font-size: 12px;
}

.content {
  height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
  font-size: 16px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 22px;
    margin: auto 8px auto 12px;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 6px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 34px;
    font-family: "Semibold";
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #b8e6cd;
    color: #14ae5c;
    padding: 0px 4px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 16px;
      height: 16px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .tag_cuo {
    display: flex;
    justify-content: space-between;
    background-color: #fbd4df;
    color: #f02d63;
    padding: 0px 4px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 16px;
      height: 16px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .reset {
    color: #0256ff;
    margin: auto 10px;
  }
}

.form {
  margin-top: 20px;
}

:deep(.close_icon) {
  font-size: 30px !important;
  color: #a3acec;
  margin-left: 6px;

  :hover {
    color: #3d3d3d;
  }
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 9px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 16px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 2px;
    font-weight: 600;
  }

  .comtent {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.isActive {
  margin-right: 6px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>

<script setup>
import tableUser from "../components/userTable.vue";
import departmentView from "../components/departmentDetail.vue";
import departmentAdd from "../components/departmentAdd.vue";
import userMember from "../components/userMember.vue";
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { debounce } from "@/utils/utils";
onMounted(() => {
  getTableList();
});
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const loading = ref(true);
const loading2 = ref(false);
// 导航条切换
const activeTab = ref("dashboard");
const tabs = [
  { name: "dashboard", label: t("manage.userForm.user1"), contentKey: "User" },
  { name: "unit", label: t("manage.userForm.user2"), contentKey: "Department" },
];
function setActiveTab(name) {
  activeTab.value = name;
}

const userTable = ref(""); // 用户input
const user = ref(""); // 部门input
watch(
  () => userTable.value,
  (val) => {
    listForm.departmentName = val;
    getTableList();
  }
);
// 部门组收缩
const isAsideVisible = ref(true);
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value;
};
// 部门树形列表点击
const handleNodeClick = (data) => {
  tableId.value = data.departmentId;
  tableName.value = data.departmentName;
};
// 打开部门名称修改
const showName = ref(false);
const memberInfo = ref({});
const closeMember = () => {
  showName.value = false;
  getTableList();
};
const submitMember = (data) => {
  showName.value = false;
  api.manage
    .updateDepartmentName({
      userId: userId.value,
      departmentId: data.departmentId,
      departmentName: data.departmentName,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.userForm.user3"));
        getTableList();
        user.value = "";
      }
    });
};
// 更多操作
const changeMore = (key, data) => {
  if (key == 1) {
    showName.value = true;
    memberInfo.value = JSON.parse(JSON.stringify(data));
  } else if (key == 2) {
    deleteBtn(data);
  }
};
// 删除部门
const deleteBtn = (data) => {
  api.manage
    .deleteDepartment({
      userId: userId.value,
      departmentIds: [data.departmentId],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.userForm.user4"));
        getTableList();
      }
    });
};
// 左侧部门菜单
const defaultProps = ref({
  children: "children",
  label: "label",
});
const listForm = ref({
  userId: userId,
  departmentName: "",
});
// 部门组列表
const tableName = ref(""); //部门名称
const tableId = ref(null); //部门ID
const data = ref([]);
watch(
  () => listForm.value.departmentName,
  (val) => {
    listForm.value.departmentName = val;
    getTableList();
  }
);
const getTableList = debounce(() => {
  loading2.value = true;
  api.manage.getGroupList(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      data.value = res.data;
      tableId.value = res.data[0]?.departmentId; //默认选中第一个部门ID
      tableName.value = res.data[0]?.departmentName; //默认选中第一个部门名称
      loading.value = false;
      loading2.value = false;
    }
  });
}, 1000);
// 部门详情打开
const dataDetail = ref({});
const departmentInfo = ref({});
const showDepartment = ref(false);
const uploadDepartment = ref(false);
watch(
  () => showDepartment.value,
  (val) => {
    showDepartment.value = val;
  }
);
const handleDetail = (data) => {
  dataDetail.value = data;
  showDepartment.value = true;
};
// 关闭部门详情
const handleCancel = () => {
  showDepartment.value = false;
};
// 打开修改部门
const formType = ref("add");
const handleAdd = () => {
  showDepartment.value = false;
  departmentInfo.value = JSON.parse(JSON.stringify(dataDetail.value))
  formType.value = "edit";
  uploadDepartment.value = true;
};
// 关闭修改部门
const handleClose = () => {
  uploadDepartment.value = false;
  getTableList();
};
// 提交修改部门
const editSave = (data) => {
  uploadDepartment.value = false;
  data.userId = userId.value;
  api.manage.editDepartment(data).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.userForm.user5"));
      getTableList();
    }
  });
};
const exportDepartment = () => {
  const imageUrl = "/openai/user-management/department/export2?userId="+userId.value;
  const a = document.createElement("a");
  a.href = imageUrl;
  a.download = "download.xlsx";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
// 新增部门
const openDepartment = () => {
  formType.value = "add";
  departmentInfo.value = {};
  uploadDepartment.value = true;
};
const handleSave = (data) => {
  uploadDepartment.value = false;
  api.manage.addDepartment(data).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.userForm.user6"));
      getTableList();
    }
  });
};
// 新增子部门
const newMemeberId = ref(null);
const addDepartment = (row) => {
  newMemeberId.value = row.departmentId;
  formType.value = "addSmall";
  departmentInfo.value = row;
  uploadDepartment.value = true;
};
const addSave = (data) => {
  data.parentId = newMemeberId.value;
  data.userId = userId.value;
  uploadDepartment.value = false;
  api.manage.addDepartment(data).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.userForm.user7"));
      getTableList();
    }
  });
};
// 更多操作
const changeMoreTree = (key, item) => {
  if (key === 1) {
    formType.value = "edit";
    departmentInfo.value = item;
    uploadDepartment.value = true;
  } else if (key === 2) {
    deleteBtn(item);
  }
};
</script>

<template>
  <div class="main">
    <div style="position: relative">
      <div class="tabs">
        <div
          class="tab"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab === tab.name }"
          @click="setActiveTab(tab.name)"
        >
          {{ tab.label }}
        </div>
        <div v-if="activeTab === 'dashboard'" class="dashboard">
          <!-- 用户 -->
          <el-input
            v-model="user"
            size="large"
            class="searchInput"
            :placeholder="$t('manage.userForm.user8')"
          >
            <template #prefix>
              <el-icon class="el-input__icon">
                <search />
              </el-icon>
            </template>
          </el-input>
        </div>
        <div v-if="activeTab === 'unit'" class="unit">
          <!-- 部门 -->
          <div class="search">
            <div>
              <el-input
                v-model="listForm.departmentName"
                size="large"
                class="searchInput"
                :placeholder="$t('manage.userForm.user9')"
              >
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <search />
                  </el-icon>
                </template>
              </el-input>
            </div>
            <div style="display: flex">
              <div class="toolBox" @click="exportDepartment" v-if="tableId">
                {{ t("manage.userForm.user10") }}
              </div>
              <el-button
                class="toolBox addUser"
                @click="openDepartment"
                :disabled="$hasPermission('10006') == false"
                v-if="tableId"
              >
                {{ $t("manage.userForm.user11") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'dashboard'" class="tablea">
      <el-container v-loading="loading">
        <div class="container">
          <el-aside class="side" v-if="isAsideVisible">
            <el-tree
              ref="treeRef"
              :data="data"
              node-key="departmentId"
              :props="defaultProps"
              default-expand-all
              @node-click="handleNodeClick"
              highlight-current
              :current-node-key="tableId"
            >
              <template #default="{ data }">
                <span class="custom-tree-node">
                  <div class="admin-name">
                    <img src="@/assets/manage/admin.svg" alt="" class="admin" />
                    <el-tooltip
                      popper-class="toolTip"
                      :content="data.departmentName"
                    >
                      <div class="admin-text wid100">
                        {{ data.departmentName }}
                      </div>
                    </el-tooltip>
                  </div>
                  <span>
                    <el-dropdown @command="changeMore($event, data)">
                      <div class="moreIcon">
                        <img src="@/assets/base/more.svg" alt="" />
                      </div>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="1"
                            :disabled="$hasPermission('10008') == false"
                          >
                            <span class="dropText">{{
                              $t("manage.userForm.user12")
                            }}</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="2"
                            :disabled="$hasPermission('10010') == false"
                            v-if="data.children.length ==0&&data.userInfos.length ==0"
                          >
                            <span class="dropText">{{
                              $t("manage.userForm.user13")
                            }}</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </span>
                </span>
              </template>
            </el-tree>
          </el-aside>
          <div @click="toggleAside" class="toggleAside">
            <el-icon class="tagIcon">
              <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
            </el-icon>
          </div>
        </div>
        <el-main class="userMain">
          <tableUser :tableId="tableId" :tableName="tableName" :user="user">
          </tableUser>
        </el-main>
      </el-container>
    </div>
    <div v-if="activeTab === 'unit'" v-loading="loading2" class="tablea">
      <el-table
        :data="data"
        row-key="departmentId"
        default-expand-all
        class="tableHeight"
      >
        <el-table-column
          fixed
          prop="departmentName"
          :label="$t('manage.userForm.departmentName')"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.departmentName }}
          </template>
        </el-table-column>
        <el-table-column
          fixed
          prop="userNum"
          :label="$t('manage.userForm.user14')"
        />
        <el-table-column
          fixed
          prop="userNames"
          :label="$t('manage.userForm.user15')"
        >
          <template #default="scope">
            <AvatarGroup
              :avatars="scope.row.userInfos"
              :number="5"
            ></AvatarGroup>
          </template>
        </el-table-column>
        <el-table-column :label="$t('manage.userForm.user16')">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              :disabled="$hasPermission('10004') == false"
              @click="handleDetail(scope.row)"
            >
              {{ $t("manage.userForm.user17") }}
            </el-button>
            <el-button
              :disabled="$hasPermission('10006') == false"
              link
              type="primary"
              size="small"
              @click="addDepartment(scope.row)"
            >
              {{ $t("manage.userForm.user18") }}
            </el-button>
            <el-dropdown
              @command="changeMoreTree($event, scope.row)"
              trigger="click"
              class="changeMore"
            >
              <div class="moreIcon">
                <img src="@/assets/base/more.svg" alt="" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="1"
                    :disabled="$hasPermission('10008') == false"
                  >
                    <span class="dropText">{{
                      $t("manage.userForm.user19")
                    }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="$hasPermission('10009') == false"
                    :command="2"
                    v-if="
                      scope.row.children.length === 0 && scope.row.userNum == 0
                    "
                  >
                    <span class="dropText">{{
                      $t("manage.userForm.user20")
                    }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <!-- 部门详情 -->
  <departmentView
    :showDepartment="showDepartment"
    :dataDetail="dataDetail"
    @handleCancel="handleCancel"
    @handleAdd="handleAdd"
  >
  </departmentView>
  <!-- 部门编辑 -->
  <departmentAdd
    v-if="uploadDepartment"
    :formType="formType"
    :uploadDepartment="uploadDepartment"
    :departmentInfo="departmentInfo"
    @handleSave="handleSave"
    @handleClose="handleClose"
    @addSave="addSave"
    @editSave="editSave"
  ></departmentAdd>
  <!-- 修改部门名称 -->
  <userMember
    :showName="showName"
    :memberInfo="memberInfo"
    @closeMember="closeMember"
    @submitMember="submitMember"
  >
  </userMember>
</template>

<style lang="scss" scoped>
@use "./user.scss" as user;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-tree) {
  font-size: 18px;
  background-color: #f7f8ff;
  color: #3d3d3d;
}

:deep(.el-tree-node__content) {
  height: 50px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
}

.side {
  :deep(.el-tree) {
    background-color: #f7f8ff;
    color: #3d3d3d;
  }

  :deep(.el-tree-node__content) {
    height: 50px;
    border-radius: 16px !important;

    &:hover {
      background-color: #eceeff;
    }
  }

  :deep(.custom-tree-node) {
    font-size: 16px;
  }
}
.tablea {
  height: calc(100% - 65px);
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
  padding-left:10px
}
</style>

<script setup>  
// 定义组件接收的 props  
const props = defineProps({  
  background: Boolean,  
  currentPage: {  
    type: Number,  
    required: true  
  },  
  pageSize: {  
    type: Number,  
    required: true  
  },  
  layout: {  
    type: String,  
    default: 'total, sizes, prev, pager, next, jumper'  
  },  
  pageSizes: {  
    type: Array,  
    default: () => [10, 20, 30, 40]  
  },  
  pagerCount: {  
    type: Number,  
    default: 5  
  },  
  total: {  
    type: Number,  
    required: true  
  },  
  autoScroll: {  
    type: Boolean,  
    default: true  
  },  
  hidden: {  
    type: Boolean,  
    default: false  
  }  
});  
  
// 定义组件可以触发的事件  
const emit = defineEmits(['size-change', 'current-change']);  
  
// 处理每页显示条数变化的事件  
function handleSizeChange(newSize) {  
  emit('size-change', newSize);  
}  
  
// 处理当前页码变化的事件  
function handleCurrentChange(newPage) {  
  emit('current-change', newPage);  
};
</script>  
<template>  
    <div class="pagination-container">  
      <el-pagination  
      :background="background"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      :autoScroll="autoScroll"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size.sync="pageSize"
      :current-page.sync="currentPage" 
      />  
    </div>  
  </template>



<style lang="scss" scoped>
.pagination-container {
  background-color: #fff;
  padding: 3px 5px;
  display: flex;  
  flex-direction: column;
  align-items: flex-end;

}
.pagination-container.hidden {
  display: none;
}
</style>
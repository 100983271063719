<template>
  <div style="height: 100%">
    <iframe
      width="100%"
      height="100%"
      :src="`/pdf/web/viewer.html?file=${encodeURIComponent(props.pdfUrl)}`"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script setup>
const props = defineProps(["pdfUrl"]);
</script>

<style lang="scss" scoped></style>

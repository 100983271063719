<template>
    <div class="excelContent">
        <!-- 左侧知识库文件来源 -->
        <div class="excelLeft">
            <div class="leftTitle">知识库文档列表</div>
            <div class="leftContent" v-if="leftFileList.length != 0">
                <div v-for="item in leftFileList" :key="item.id" class="fileItem" @click="viewFile(item)">
                    <!-- <Icon v-if="item.id" class="fileIcon" :style="'color:' + getColor(item)" theme="filled"
                        :type="getType(item)" /> -->
                    <div class="fileNameItem">
                        {{ item.fileName }}
                    </div>
                </div>
            </div>
            <div v-else class="leftContent">
                <Empty />
            </div>
            <div class="leftUpfile">
                <UploadDragger name="file" :multiple="false" :showUploadList="false" :customRequest="customRequest"
                    action="#" :before-upload="beforeUpload">
                    <div>点击上传知识库文档</div>
                    <div>或拖拽上传</div>
                </UploadDragger>
            </div>
        </div>
        <!-- 右侧历史 -->
        <div class="excelRight">
            <div class="rightSearch">
                <Upload name="file" :multiple="false" :showUploadList="false" :customRequest="customRequest" action="#"
                    :before-upload="rightExcel">
                    <Button :loading="unfinishedNum != 0"> 上传文件进行自动填写 </Button>
                </Upload>
            </div>
            <div class="rightTop">
                <div class="rightBorderDiv" v-for="items in rightList" :key="items.id">
                    <div class="rightTopDiv">
                        <!-- <Icon class="rightTopIcon" style="color: rgba(16, 124, 65)" theme="filled" type="file-excel" /> -->
                        <div class="rightTopFileName">{{ items.fileName }}</div>
                        <!-- <Icon type="download" class="downIcon" @click="downExcel(items)" /> -->
                    </div>
                    <Divider />
                    <div v-for="(item, index) in items.list" :key="index" class="marginBottomFive">
                        <div>
                            <span class="rightQTitle">问题：</span>
                            <span class="rightQContent">{{ item.question }}</span>
                        </div>
                        <div>
                            <span class="rightQTitle">回答：</span>
                            <span class="rightQContent">{{ item.answer }}</span>
                        </div>
                        <div>
                            <span class="rightQTitle">来源：</span>
                            <span class="rightQContent">{{ item.resource }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- pdf -->
    <pdf :pdfUrl="pdfUrl" v-if="pdfType"></pdf>
    <!-- txt -->
    <txt class="txt" :textData="textData" v-if="txtType"></txt>
    <!-- word -->
    <word :wordUrl="wordUrl" v-if="wordType"></word>
    <!-- excel -->
    <excel v-if="excelType" :excelData="excelData" :excelBodyData="excelBodyData"></excel>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from "vue-i18n"
import { useStore } from "vuex";
import api from "@/api/index";
import { message, Empty, Button, UploadDragger,Divider ,Upload} from "ant-design-vue";
import pdf from "@/components/chunk/pdf.vue";
import txt from "@/components/chunk/txt.vue";
import excel from "@/components/chunk/excel.vue";
import word from "@/components/chunk/word.vue";
const pdfUrl = ref("");
const textData = ref("");
const wordUrl = ref("");
const excelData = ref();
const excelBodyData = ref();
const pdfType = ref(false);
const wordType = ref(false);
const excelType = ref(false);
const txtType = ref(false);
const { t } = useI18n()
const store = useStore();
const userId = ref(store.state.userId);
const baseItem = ref(store.state.baseItem);
const rightList = ref([]);
const leftFileList = ref([]);
const timer = ref(null);
const unfinishedNum = ref(0);
onMounted(() => {
    getLeft()
    getRight()
});
// 文件预览
const viewFile = (item) => {
    if (
        item.fileType == "xlsx" ||
        item.fileType == "xls" ||
        item.fileType == "XLS" ||
        item.fileType == "XLSX"
    ) {
        openExcel(item);
    } else if (item.fileType == "pdf" || item.fileType == "PDF") {
        openPdf(item);
    } else if (item.fileType == "txt" || item.fileType == "TXT") {
        openText(item);
    } else if (item.fileType == "doc" || item.fileType == "docx") {
        openDoc(item);
    }
}

// 打开Doc
const openDoc = (item) => {
    api.bot
        .exlcexDoc({
            fileId: item.id,
            userId: userId.value
        })
        .then((res) => {
            if (res.returnCode == 200) {
                wordUrl.value = res.data;
                wordType.value = true;
            }
        });
}
// 打开text
const openText = (item) => {
    api.bot
        .excelGetText(userId.value, item.id)
        .then((res) => {
            if (res.returnCode == 200) {
                txtType.value = true;
                textData.value = res.data;
            }
        });
}
// 打开PDF
const openPdf = (item) => {
    api.bot
        .excelPdfView({
            userId: userId.value,
            fileId: item.id,
            pageId: 1,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                pdfUrl.value = res.data.Url;
                pdfType.value = true;
            }
        });
}
// 打开excel
const openExcel = (item) => {
    excelData.value = [];
    excelBodyData.value = [];
    api.bot
        .excelView({ fileId: item.id, userId: userId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                excelData.value = res.res;
                excelBodyData.value = res.res[0].data.slice(1);
                excelType.value = true;
            }
        });
}
// 获取左侧
const getLeft = () => {
    api.bot
        .excelDemoFileList({ userId: userId.value })
        .then((res) => {
            if (res.returnCode == 200) {
                leftFileList.value = res.data;
            }
        });
}
// 下载
const downExcel = async (item) => {
    try {
        api.modelSquare.downExcel({ taskId: item.id }).then((response) => {
            // 创建一个 Blob 对象
            const url = window.URL.createObjectURL(new Blob([response]));
            // 创建一个 a 标签用于下载
            const link = document.createElement("a");
            link.href = url;
            // 设置文件名
            link.setAttribute("download", item.fileName);
            // 触发下载
            document.body.appendChild(link);
            link.click();
            // 清理
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        });
    } catch (error) {
        console.error("下载 Excel 失败:", error);
    }
}
// 右侧
const getRight = () => {
    api.bot
        .excelAnswerQuery({ userId: userId.value }).then((res) => {
            if (res.returnCode == 200) {
                console.log(res.data);
                
                // rightList.value = res.data;
                // unfinishedNum.value = res.unfinishedNum
                // if (res.unfinishedNum != 0) {
                //     timer.value = setTimeout(() => {
                //         getRight();
                //     }, 5000);
                // } else {
                //     clearTimeout(timer.value);
                //     timer.value = null;
                // }
            }
        });
}
const customRequest = () => { }
const getByteLength = (str) => {
    const encoder = ref(new TextEncoder());
    const encoded = ref(encoder.value.encode(str));
    return encoded.length;
}
// 右侧微模型
const rightExcel = (file) => {
    if (file.size > 20971520 && !this.$isBeat) {
        message.warning("文件超过20MB");
        return;
    }
    file.fileType = file.name.substring(file.name.lastIndexOf(".") + 1).replace(" ", "");
    let validFileTypes = ["xlsx", "xls", "XLS", "XLSX"];
    if (!validFileTypes.includes(file.fileType)) {
        message.warning("不支持该文件类型上传。");
        return;
    }
    let fileNamelen = String(file.name);
    if (getByteLength.value(fileNamelen) > 255) {
        message.warning("文件名称过长");
        return;
    }
    api.bot.excelFileAnswer([file],{}).then((res) => {
        if (res.returnCode == 200) {
            getRight();
        }
    });
};
// 左侧知识库
const beforeUpload = (file) => {
    if (file.size > 20971520 && !this.$isBeat) {
        message.warning("文件超过20MB");
        return;
    }
    file.fileType = file.name.substring(file.name.lastIndexOf(".") + 1).replace(" ", "");
    let validFileTypes = [
        "txt",
        "pptx",
        "pdf",
        "docx",
        "xlsx",
        "xls",
        "TXT",
        "PPTX",
        "PDF",
        "DOCX",
        "XLS",
        "XLSX",
    ];
    if (!validFileTypes.includes(file.fileType)) {
        message.warning("不支持该文件类型上传。");
        return;
    }
    let fileNamelen = String(file.name);
    if (getByteLength.value(fileNamelen) > 255) {
        message.warning("文件名称过长");
        return;
    }
    api.bot.excelFileUpload([file],{}).then((res) => {
        if (res.returnCode == 200) {
            getLeft();
        }
    });
}
// 获取颜色
const getColor = (item) => {
    let val = item.fileName.substring(item.fileName.lastIndexOf(".") + 1).replace(" ", "");
    if (val == "pdf" || val == "PDF") {
        return "rgba(224, 0, 18)";
    } else if (val == "txt" || val == "TXT") {
        return "rgba(38, 64, 149)";
    } else if (
        val == "doc" ||
        val == "docx" ||
        val == "DOC" ||
        val == "DOCX"
    ) {
        return "rgba(24, 90, 189)";
    } else if (
        val == "xls" ||
        val == "xlsx" ||
        val == "XLS" ||
        val == "XLSX"
    ) {
        return "rgba(16, 124, 65)";
    }
}
// 获取图标type
const getType = (item) => {
    let val = item.fileName.substring(item.fileName.lastIndexOf(".") + 1).replace(" ", "");
    if (val == "pdf" || val == "PDF") {
        return "file-pdf";
    } else if (val == "txt" || val == "TXT") {
        return "file-text";
    } else if (
        val == "doc" ||
        val == "docx" ||
        val == "DOC" ||
        val == "DOCX"
    ) {
        return "file-word";
    } else if (
        val == "xls" ||
        val == "xlsx" ||
        val == "XLS" ||
        val == "XLSX"
    ) {
        return "file-excel";
    }
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.excelContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
    display: flex;
}

.excelLeft {
    width: 380px;
    margin-right: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    overflow: auto;

    .leftTitle {
        font-size: 18px;
        font-family: "Bold";
        text-align: center;
        height: 30px;
        margin-bottom: 10px;
        line-height: 30px;
    }

    .leftContent {
        height: calc(100% - 150px);

        .fileIcon {
            margin-right: 10px;
            font-size: 18px;
            width: 20px;
        }

        .fileNameItem {
            width: calc(100% - 30px);
            font-family: Regular;
            vertical-align: middle;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 18px;
        }

        .fileItem {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .fileItem:hover {
            background-color: rgba(196, 205, 234);
            cursor: pointer;
        }
    }

    .leftUpfile {
        position: absolute;
        width: calc(100% - 20px);
        height: 100px;
        bottom: 10px;
    }
}

.excelRight {
    width: calc(100% - 400px);
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;

    .rightTopIcon {
        color: rgb(16, 124, 65);
        font-size: 20px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .rightTopFileName {
        font-size: 18px;
        font-family: "Bold";
        max-width: calc(100% - 30px);
    }

    .rightTopDiv {
        display: flex;
        align-items: center;
        height: 30px;
        position: relative;
    }

    :deep(.ant-divider-horizontal) {
        margin: 10px 0px;
    }

    .rightBorderDiv {
        border: 1px solid #0256ff;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .rightQTitle {
        font-size: 18px;
        font-family: "Bold";
    }

    .rightQContent {
        font-size: 18px;
        font-family: "Regular";
    }

    .rightTop {
        height: calc(100% - 50px);
        overflow: auto;
    }

    .rightSearch {
        height: 50px;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .downIcon {
        font-size: 20px;
        position: absolute;
        right: 10px;
    }
}

.marginBottomFive {
    margin-bottom: 5px;
}
</style>
<template>
  <div class="dialogView">
    <div class="flexBetween">
      <div class="dialogTitle">{{ t("base.base109") }}</div>
      <img :src="addClose" alt="" class="addClose" @click="closeDialog" />
    </div>
    <!-- 拥有者 -->
    <div v-if="baseItem.knowledge_role_id == 0">
      <div class="dialogTip">
        {{ t("base.base110") }}
      </div>
      <div class="flexBetween bottom17">
        <span class="addLabel nameLabel">{{ t("base.base26") }}</span>
        <span class="ptTip">{{ t("base.base112") }}</span>
      </div>
      <div class="flexBetween">
        <el-button
          v-for="item in ptList"
          :key="item.id"
          :class="getPt(item.id) ? 'ptDivItemActive' : 'ptDivItem'"
          :disabled="ptDisabled(item.id)"
          @click="clickPt(item.id)"
        >
          <img :src="item.icon" alt="" class="ptImg" />
        </el-button>
      </div>
    </div>
    <!-- 普通 -->
    <div v-if="baseItem.knowledge_role_id != 0">
      <div class="dialogTip">{{ t("base.base111") }}</div>
    </div>
    <div class="btnDiv" v-if="baseItem.knowledge_role_id == 0">
      <!-- 放弃 -->
      <el-button class="cancelBtn" @click="closeDialog">{{
        t("base.base113")
      }}</el-button>
      <!-- 重新分析 -->
      <el-button class="sureBtn" @click="changeBtn">{{
        t("base.base116")
      }}</el-button>
      <!-- 转移权限 -->
      <el-button class="sureBtn" @click="openPeople">{{
        t("base.base117")
      }}</el-button>
    </div>
    <div class="btnDiv" v-if="baseItem.knowledge_role_id != 0">
      <!-- 放弃 -->
      <el-button class="cancelBtn" @click="closeDialog">{{
        t("base.base113")
      }}</el-button>
      <!-- 退出 -->
      <el-button class="cancelBtn" @click="outBase">
        {{ t("base.base114") }}
      </el-button>
      <!-- 转为普通成员 -->
      <el-button class="sureBtn" @click="toPt">
        {{ t("base.base115") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <div v-if="dialogTableVisible" class="dialogView1">
    <div>
      <div class="flexBetween">
        <div class="dialogTitle">{{ t("base.base117") }}</div>
        <img
          :src="addClose"
          alt=""
          class="addClose"
          @click="dialogTableVisible = false"
        />
      </div>
    </div>
    <el-table :data="userList" :height="300" border v-loading="userLoading">
      <el-table-column :label="t('base.base126')" align="center">
        <template #default="scope">
          <span class="classTableItem">{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template #default="scope">
          <el-button class="sureBtn" @click="toUser(scope.row.userId)">
            {{ t("base.base313") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="mask1" v-if="dialogTableVisible"></div>
</template>

<script setup>
import addClose from "@/assets/base/addClose.svg";
import azure from "@/assets/base/azure.svg";
import aliyun from "@/assets/base/aliyun.svg";
import baidu from "@/assets/base/baidu.svg";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api";
import { message } from "ant-design-vue";
const { t } = useI18n();
const store = useStore();
const emit = defineEmits(["closeTipDialog", "getList"]);
const baseItem = ref(store.state.baseItem);
const userId = ref(store.state.userId);
const userLoading = ref(false);
const userList = ref([]);
const dialogTableVisible = ref(false);
const ptList = ref([
  {
    id: "Azure",
    icon: azure,
  },
  {
    id: "Ali",
    icon: aliyun,
  },
  {
    id: "Baidu",
    icon: baidu,
  },
]);
const ptActive = ref([]);
onMounted(() => {
  if (baseItem.value.aliEmbeddingOn == 1) {
    ptActive.value.push("Ali");
  }
  if (baseItem.value.azureEmbeddingOn == 1) {
    ptActive.value.push("Azure");
  }
  if (baseItem.value.baiduEmbeddingOn == 1) {
    ptActive.value.push("Baidu");
  }
});
// 判断有没有这个id 有就返回true  class判断
const getPt = (id) => {
  // 查找数组中是否存在该id
  const index = ptActive.value.findIndex((item) => item === id);
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 选择的平台
const clickPt = (id) => {
  // 查找数组中是否存在该id
  const index = ptActive.value.findIndex((item) => item === id);
  if (index !== -1) {
    // 如果存在，删除该id
    ptActive.value.splice(index, 1);
  } else {
    // 如果不存在，添加该id到数组中
    ptActive.value.push(id);
  }
};
// 关闭 放弃
const closeDialog = () => {
  emit("closeTipDialog");
};
// 重新分析
const changeBtn = () => {
  api.base
    .embeddingChange({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
      resourcePlatform: ptActive.value, //,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base314"));
        emit("getList");
      }
    });
};
// 推出知识库
const outBase = () => {
  // 退出微模型
  api.base
    .libraryExit(
      {
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base17"));
        emit("getList");
      }
    });
};
// 转普通
const toPt = () => {
  api.base
    .grTransfer({
      userId: userId.value,
      ownerId: userId.value,
      ownerType: 0,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base315"));
        emit("getList");
      }
    });
};
// 禁用设置
const ptDisabled = (id) => {
  if (ptActive.value.includes(id)) {
    return false;
  }
  const embeddingTypeKey = {
    Azure: "azureEmbedding",
    Ali: "aliEmbedding",
    Baidu: "baiduEmbedding",
  };
  if (!embeddingTypeKey[id]) {
    return true;
  }
  const ids = baseItem.value.current_user_embedding_type.filter((item) =>
    [embeddingTypeKey[id]].includes(item)
  );
  return ids.length === 0;
};
// 必须是部门创作者和部门管理员才可以展示按钮
const getBtn = () => {
  if (baseItem.value.knowledge_role_id == 1) {
    const result = baseItem.value.creator_info.some(
      (item) => item.isManager === true && item.ownerType === 1
    );
    return result;
  } else {
    return false;
  }
};
// 打开转让
const openPeople = () => {
  dialogTableVisible.value = true;
  getUser();
};
// 获取用户
const getUser = () => {
  userLoading.value = true;
  api.base
    .canUser({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      userLoading.value = false;
      if (res.returnCode == 200) {
        userList.value = res.data;
      }
    })
    .catch((err) => {
      userLoading.value = false;
    });
};
// 转让
const toUser = (id) => {
  api.base
    .baseTransfer({
      userId: userId.value,
      newOwnerId: id,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base316"));
        dialogTableVisible.value = false;
        closeDialog();
      }
    });
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.2;
}
.dialogView {
  position: fixed;
  width: 540px;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 200;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mask1 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 121;
  opacity: 0.2;
}
.dialogView1 {
  position: fixed;
  width: 540px;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 201;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.dialogTitle {
  font-size: 20px;
  font-family: "Medium";
  color: #3d3d3d;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  margin: 24px 0px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  line-height: 28px;
}
.addLabel {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Medium";
}
.bottom17 {
  margin-bottom: 17px;
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ptDivItemActive {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbdfff;
}
.cancelBtn {
  border: 2px solid #edeef6;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  padding: 12px 20px;
}
.sureBtn {
  border: 2px solid #3376ff;
  background-color: #3376ff;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  padding: 12px 20px;
}
.btnDiv {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.classTableItem {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

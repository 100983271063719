
import axios from "../server";
export default {
    // 首页数据
    getIndexTokens(data: any) {
        return axios({
            url: "/token-management/individual/tokens/remain2",
            method: "POST",
            data: data,
        });
    },
}
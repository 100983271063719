<template>
  <div class="cardDiv">
    <div class="flexCenter">
      <el-tooltip popper-class="toolTip" :content="props.title + '（CNY）'">
        <div class="carTitle">{{ props.title }}（CNY）</div>
      </el-tooltip>
      <img
        class="titleIcon"
        v-if="
          props.item.detail &&
          props.item.detail.length != 0 &&
          props.item.imgType
        "
        :src="upReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
      <img
        class="titleIcon"
        v-if="
          props.item.detail &&
          props.item.detail.length != 0 &&
          !props.item.imgType
        "
        :src="downReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
    </div>
    <div class="flexBetWeen">
      <div class="wid">
        <div class="flexCenter">
          <div class="textDiv">{{ t("statistics.name32") }}</div>
          <div class="bigNumber flexCenter wid40">
            <span class="numberDw">¥</span>
            <el-tooltip popper-class="toolTip" :content="String(props.left)">
              <div class="widDiv">{{ props.left.toFixed(2) }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="numberFlex">
          <div class="numberText">{{ t('statistics.name36') }}</div>
          <div
            :class="
              props.item.input_ratio < 0
                ? 'redDiv'
                : props.item.input_ratio > 0
                ? 'greenDiv'
                : props.item.input_ratio == null
                ? 'hsDiv'
                : ''
            "
          >
            <img
              v-if="props.item.input_ratio != null"
              :src="props.item.input_ratio < 0 ? downImg : upImg"
              alt=""
              class="downImg"
            />
            <span class="number">{{
              props.item.input_ratio != null
                ? props.item.input_ratio + "%"
                : "-"
            }}</span>
          </div>
        </div>
      </div>
      <div class="wid">
        <div class="flexCenter">
          <div class="textDiv">{{ t("statistics.name33") }}</div>
          <div class="bigNumber flexCenter wid40">
            <span class="numberDw">¥</span>
            <el-tooltip popper-class="toolTip" :content="String(props.right)">
              <div class="widDiv">{{ props.right.toFixed(2) }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="numberFlex">
          <div class="numberText">{{ t('statistics.name36') }}</div>
          <div
            :class="
              props.item.output_ratio < 0
                ? 'redDiv'
                : props.item.output_ratio > 0
                ? 'greenDiv'
                : props.item.output_ratio == null
                ? 'hsDiv'
                : ''
            "
          >
            <img
              v-if="props.item.output_ratio != null"
              :src="props.item.output_ratio < 0 ? downImg : upImg"
              alt=""
              class="downImg"
            />
            <span class="number">{{
              props.item.output_ratio != null
                ? props.item.output_ratio + "%"
                : "-"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <img
      :src="echarImg"
      class="echarImg"
      alt=""
      v-if="shouldDisplayChart"
      @click="openEchar(props.item)"
    />
  </div>
</template>

<script setup>
import downImg from "@/assets/statistics/down.svg";
import upImg from "@/assets/statistics/up.svg";
import upReson from "@/assets/chat/upReson.svg";
import downReson from "@/assets/chat/downReson.svg";
import echarImg from "@/assets/statistics/echar.svg";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["title", "left", "right", "item", "ldActive"]);
const emit = defineEmits(["openEchar"]);
const shouldDisplayChart = computed(() => {
  return (
    props.item.detail &&
    props.item.detail.length !== 0 &&
    ((props.ldActive === 0 && props.item.daily.length !== 0) ||
      (props.ldActive === 1 && props.item.monthly.length !== 0))
  );
});
const openEchar = (item) => {
  if (props.ldActive === 0) {
    emit("openEchar", item.daily, item.detail_cost);
  } else {
    emit("openEchar", item.monthly, item.detail_cost);
  }
};
</script>

<style lang="scss" scoped>
.cardDiv {
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 30px;
  width: calc(100% - 60px);
  height: 180px;
  position: relative;
}
.carTitle {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Medium";
  width: calc(100% - 20px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.titleIcon {
  width: 17px;
}
.bigNumber {
  font-size: 40px;
  color: #767bfa;
  font-family: "bold";
  text-align: center;
  height: 117px;
  line-height: 117px;
}
.numberDw {
  font-size: 32px;
  width: 21px;
}
.echarImg {
  width: 24px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.textDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  font-size: 16px;
  color: #0256ff;
  font-family: "Medium";
  padding: 6px;
  margin-right: 12px;
  width: 28px;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wid {
  width: 50%;
}
.widDiv {
  width: calc(100% - 21px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.wid40 {
  width: calc(100% - 40px);
}
.downImg {
  width: 6px;
  margin-right: 3px;
}
.number {
  font-size: 16px;
  font-family: "Medium";
}
.numberText {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
  margin-right: 12px;
}
.greenDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #e6f1e8;
  border-radius: 8px;
  justify-content: center;
  color: #14ae5c;
}
.redDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #fde2e7;
  border-radius: 8px;
  justify-content: center;
  color: #f02d63;
}
.numberFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hsDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #dfdfdf;
  border-radius: 8px;
  justify-content: center;
  color: #8a8a8a;
}
</style>

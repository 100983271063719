<template>
  <div :class="navActive == 0 ? 'tokenContent' : 'tokenContent1'" v-if="!show">
    <div>
      <!-- nav -->
      <div class="flexCenter height40">
        <div
          :class="navActive == index ? 'navTextAc' : 'navText'"
          v-for="(item, index) in navList"
          :key="item"
          @click="changeNav(index)"
        >
          {{ t(item) }}
        </div>
        <div class="navTip">
          <span class="navTipX">*</span>
          {{ t("statistics.name5") }}
        </div>
      </div>
      <!-- 菜单栏 -->
      <div class="flexBetWeen top15" v-if="!noLookType">
        <div class="flexCenter">
          <div class="roleDiv">
            <div class="roleTitle">{{ t("statistics.name6") }}</div>
            <el-dropdown @command="changeSearch($event, 1)">
              <span class="el-dropdown-link">
                {{ t(ztPtList[ptValue].label) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in ztPtList"
                    :key="item.value"
                    :command="item.value"
                  >
                    <span class="dropText">{{ t(item.label) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name7") }}</div>
            <el-dropdown
              @command="changeSearch($event, 2)"
              :max-height="200"
              v-if="zyzList.length != 0"
            >
              <span class="el-dropdown-link">
                {{ t(zyzList[zyzValue].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in zyzList"
                    :key="item.id"
                    :command="index"
                  >
                    <span class="dropText"> {{ item.name }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name8") }}</div>
            <el-dropdown @command="changeSearch($event, 3)">
              <span class="el-dropdown-link">
                {{ t(timeList[timeActive].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in timeList"
                    :key="item.value"
                    :command="item.value"
                  >
                    <span class="dropText">{{ t(item.name) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-date-picker
            class="picker"
            v-if="timeType"
            v-model="billTime"
            :disabledDate="disabledDate"
            type="daterange"
            :range-separator="t('manage.logForm.log3')"
            :start-placeholder="t('manage.logForm.log4')"
            :end-placeholder="t('manage.logForm.log5')"
            value-format="YYYY-MM-DD"
            :placeholder="t('statistics.name9')"
            @change="getAzure"
          />
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name10") }}</div>
            <el-tooltip
              popper-class="toolTip"
              :content="t('statistics.name34')"
            >
              <el-icon color="#8A8A8A" class="warIcon"><Warning /></el-icon>
            </el-tooltip>

            <el-dropdown @command="changeSearch($event, 4)">
              <span class="el-dropdown-link">
                {{ t(ldList[ldActive].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in ldList"
                    :key="item.value"
                    :command="item.value"
                  >
                    <span class="dropText"> {{ t(item.name) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-input
            v-if="navActive == 1"
            class="nameInput"
            v-model="namePattern"
            @input="getToken"
            :placeholder="t('statistics.name11')"
          ></el-input>
          <el-date-picker
            v-if="navActive == 1"
            v-model="tokenTime"
            type="daterange"
            value-format="YYYY-MM-DD"
            :range-separator="t('statistics.name12')"
            :start-placeholder="t('statistics.name13')"
            :end-placeholder="t('statistics.name14')"
            @change="getToken"
          />
        </div>
        <!-- <div class="exportBtn">导出成本统计</div> -->
      </div>
    </div>
    <template v-if="!noLookType">
      <div v-if="navActive == 0">
        <!-- 内容图 -->
        <el-row :gutter="20">
          <el-col :span="12" class="top15">
            <bigView
              :title="t('statistics.name15')"
              :item="billList"
              v-if="billList.cost != null"
              :money="billList.cost"
              :type="true"
              :ldActive="ldActive"
              @openEchar="openEchar"
            >
            </bigView>
          </el-col>
          <el-col :span="12" class="top15">
            <bigView
              :title="t('statistics.name16')"
              :item="billList"
              v-if="billList.avg_user_cost != null"
              :money="billList.avg_user_cost"
              :type="false"
              :ldActive="ldActive"
              @openEchar="openEchar"
            >
            </bigView>
          </el-col>
          <template v-if="billList.detail && billList.detail.length != 0">
            <template v-if="billList.imgType">
              <template v-for="(item, index) in billList.detail" :key="index">
                <el-col
                  :span="8"
                  class="top15"
                  v-if="item.input_cost != null && item.output_cost != null"
                >
                  <midelView
                    :item="item"
                    :title="item.functionType"
                    :left="item.input_cost"
                    :right="item.output_cost"
                    :ldActive="ldActive"
                    @openEchar="openEchar"
                  ></midelView>
                </el-col>
                <el-col :span="4" v-else class="top15">
                  <smillView
                    :item="item"
                    :title="item.functionType"
                    :money="item.cost"
                    :ldActive="ldActive"
                    @openEchar="openEchar"
                  ></smillView
                ></el-col>
              </template>
            </template>
            <template v-for="(item, index) in billList.detail" :key="index">
              <template v-if="billList.imgType && item.imgType">
                <template v-for="(items, indexs) in item.detail" :key="indexs">
                  <el-col
                    :span="8"
                    v-if="items.input_cost != null && items.output_cost != null"
                    class="top15"
                  >
                    <midelView
                      :item="items"
                      :title="items.name"
                      :left="items.input_cost"
                      :right="items.output_cost"
                      :ldActive="ldActive"
                      @openEchar="openEchar"
                    ></midelView>
                  </el-col>
                  <el-col :span="4" v-else class="top15">
                    <smillView
                      :item="items"
                      :title="items.name"
                      :money="items.cost"
                      :ldActive="ldActive"
                      @openEchar="openEchar"
                    ></smillView
                  ></el-col>
                </template>
              </template>
            </template>
          </template>
        </el-row>
        <div class="flexCenter top15">
          <div class="bottomDiv" v-if="leftOption.series.length != 0">
            <div class="flexCenter bottomTitle">
              <img class="echarImg" :src="echarImg" alt="" />
              <el-tooltip
                popper-class="toolTip"
                :content="t('statistics.name17')"
              >
                <div class="echartText">{{ t("statistics.name17") }}</div>
              </el-tooltip>
            </div>
            <div id="leftEchart" class="leftEchart"></div>
          </div>
          <div
            class="rightDiv"
            v-if="pieChartOption.series[0].data.length != 0"
          >
            <div class="flexCenter bottomTitle">
              <img class="echarImg" :src="echarImg" alt="" />
              <el-tooltip
                popper-class="toolTip"
                :content="t('statistics.name18')"
              >
                <div class="echartText">{{ t("statistics.name18") }}</div>
              </el-tooltip>
            </div>
            <div id="rightEchart" class="leftEchart"></div>
          </div>
        </div>
      </div>
      <div v-else class="top15 tableDiv">
        <el-table
          :data="tableData"
          border
          class="tableNav"
          v-loading="tokenLoading"
        >
          <el-table-column :label="t('base.base126')">
            <template #default="scope">
              <span class="classTableItem">{{ scope.row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="t('header.header32')">
            <template #default="scope">
              <span class="classTableItem">{{ scope.row.departmentName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="t('statistics.name19')">
            <template #default="scope">
              <el-row
                v-for="item in scope.row.modeldetail"
                :key="item.model"
                class="bottom12"
              >
                <el-col :span="12" class="classTableItem">{{
                  item.questionTokens
                }}</el-col>
                <el-col :span="12" class="tokenNumber">
                  <img
                    :src="
                      ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI
                    "
                    alt=""
                    class="ai"
                  />
                  <el-tooltip popper-class="toolTip" :content="item.model">
                    {{ item.model }}
                  </el-tooltip>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column :label="t('statistics.name20')">
            <template #default="scope">
              <el-row
                v-for="item in scope.row.modeldetail"
                :key="item.model"
                class="bottom12"
              >
                <el-col :span="12" class="classTableItem">{{
                  item.messageTokens
                }}</el-col>
                <el-col :span="12" class="tokenNumber">
                  <img
                    :src="
                      ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI
                    "
                    alt=""
                    class="ai"
                  />
                  <el-tooltip popper-class="toolTip" :content="item.model">
                    {{ item.model }}
                  </el-tooltip>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column :label="t('statistics.name21')">
            <template #default="scope">
              <el-row
                v-for="item in scope.row.modeldetail"
                :key="item.model"
                class="bottom12"
              >
                <el-col :span="12" class="classTableItem">{{
                  item.realQuestionTokens
                }}</el-col>
                <el-col :span="12" class="tokenNumber">
                  <img
                    :src="
                      ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI
                    "
                    alt=""
                    class="ai"
                  />
                  <el-tooltip popper-class="toolTip" :content="item.model">
                    {{ item.model }}
                  </el-tooltip>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column :label="t('statistics.name22')">
            <template #default="scope">
              <span class="classTableItem">{{
                scope.row.embeddingTokens
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageDiv">
          <el-pagination
            :current-page="pageId"
            :page-size="perPage"
            :default-page-size="perPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="changeSize"
            @current-change="changePage"
          />
        </div>
      </div>
    </template>
    <div v-if="noLookType" class="tokenErrorDiv">
      <img class="tokenError" :src="tokenError" alt="" />
      <el-button @click="show = true" class="openTokenBtn">{{
        t("statistics.name35")
      }}</el-button>
    </div>
  </div>
  <div v-else class="tokenContent1"></div>
  <tipDialog
    v-if="show"
    @closeDialog="closeDialog"
    @saveDialog="saveDialog"
  ></tipDialog>
</template>

<script setup>
import bigView from "./components/big.vue";
import midelView from "./components/midel.vue";
import smillView from "./components/smill.vue";
import echarImg from "@/assets/statistics/echar.svg";
import tokenError from "@/assets/statistics/tokenError.svg";
import AzureAI from "@/assets/manage/AzureAI.svg";
import aliAI from "@/assets/manage/aliAI.svg";
import baiduAI from "@/assets/manage/baiduAI.svg";
import tipDialog from "./components/dialog.vue";
import { useRouter } from "vue-router";
import { onBeforeRouteLeave } from "vue-router";
import * as echarts from "echarts";
import { ref, onMounted } from "vue";
import api from "@/api";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const tableData = ref();
const navList = ref(["statistics.name23", "statistics.name24"]);
const navActive = ref(0);
const pageId = ref(1);
const perPage = ref(10);
const total = ref(100);
const userId = ref(store.state.userId);
const tokenTime = ref([]);
const billTime = ref(null);
const show = ref(true);
const noLookType = ref(false);
const pieChartOption = ref({
  tooltip: {
    trigger: "item",
  },
  legend: {
    top: "5%",
    left: "center",
    type: "scroll",
  },
  grid: {
    top: "15%",
    containLabel: true,
  },
  series: [
    {
      type: "pie",
      textStyle: {},
      radius: ["50%", "60%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
});
const leftOption = ref({
  // 图例悬浮
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
  },
  //图例
  legend: {
    data: [],
    type: "scroll",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      // x轴
      data: [],
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
  series: [],
});
const ztPtList = ref([
  {
    value: 0,
    label: "statistics.name25",
  },
  {
    value: 1,
    label: "statistics.name26",
  },
  {
    value: 2,
    label: "statistics.name27",
  },
]);
const zyzList = ref([]); //资源组列表
const ptValue = ref(0); //切换资源平台
const zyzValue = ref(0);
const timeActive = ref(0);
const time = ref(null);
const billList = ref({});
const timeList = ref([
  {
    name: "statistics.name37",
    value: 0,
  },
  {
    name: "statistics.name28",
    value: 1,
  },
  {
    name: "statistics.name29",
    value: 2,
  },
]);
const timeType = ref(false);
const ldList = ref([
  {
    name: "statistics.name30",
    value: 0,
  },

  {
    name: "statistics.name31",
    value: 1,
  },
]);
const ldActive = ref(0);
const echarName = ref("");
const namePattern = ref("");
const tokenLoading = ref(false);
const router = useRouter();
onMounted(() => {
  show.value = true;
});
onBeforeRouteLeave((to, from, next) => {
  window.removeEventListener("resize", () => {});
  next();
});
const getDServe = (data) => {
  const uniqueGroups = Array.from(new Set(data.map((item) => item.groupName))); // 去重groupName
  return uniqueGroups.map((groupName) => {
    return {
      name: groupName,
      type: "line",
      showSymbol: false,
      stack: "Total",
      lineStyle: {
        width: 0,
      },
      areaStyle: {},
      data: data
        .filter((item) => item.groupName === groupName)
        .map((item) => {
          // 将单个数值转换为包含两个相同数值的数组，然后扁平化
          const cost = Number(item.cost).toFixed(2);
          return [cost, cost];
        })
        .flat(), // 使用 flat 方法扁平化数组
    };
  });
};
// 打开echar
const getServe = (data) => {
  const uniqueGroups = Array.from(new Set(data.map((item) => item.groupName))); // 去重groupName
  return uniqueGroups.map((groupName) => {
    return {
      name: groupName,
      type: "line",
      // stack: "Total",
      showSymbol: false,
      lineStyle: {
        width: 0,
      },
      areaStyle: {},
      data: leftOption.value.xAxis[0].data.map((date) => {
        const item = data.find(
          (i) => i.date === date && i.groupName === groupName
        );
        return (item && Number(item.cost).toFixed(2)) || 0;
      }),
    };
  });
};
// 不同意
const closeDialog = () => {
  show.value = false;
  noLookType.value = true;
  // router.go(-1);
};
//确定同意
const saveDialog = () => {
  show.value = false;
  noLookType.value = false;
  getZyz(true);
};
// 禁用时间
const disabledDate = (time) => {
  var date = new Date(time);
  var timezoneOffset = date.getTimezoneOffset();
  var timeDifference = timezoneOffset * 60000; // 将分钟转换为毫秒
  var gmtDate = new Date(date.getTime() - timeDifference);
  var gmtString = new Date(gmtDate.toUTCString()).getTime();
  let createTime = new Date(zyzList.value[zyzValue.value].createTime).getTime();
  let limitTime = new Date(zyzList.value[zyzValue.value].limitTime);
  // 减去一天
  limitTime.setDate(limitTime.getDate() - 1);
  // 获取修改后的时间戳
  let newLimitTime = limitTime.getTime();
  if (gmtString > newLimitTime) {
    return true;
  }
  if (gmtString < createTime) {
    return true;
  }
  return false;
};
const openEchar = (item, list) => {
  // 折线图
  leftOption.value.legend.data = [];
  leftOption.value.xAxis[0].data = [];
  leftOption.value.series = [];
  pieChartOption.value.series[0].data = [];
  let xData = [];
  // 获取图例
  leftOption.value.legend.data = item.map((item) => item.groupName);
  leftOption.value.legend.data = [...new Set(leftOption.value.legend.data)];
  leftOption.value.xAxis[0].data = item.map((item) => item.date);
  // x轴去重
  leftOption.value.xAxis[0].data = [...new Set(leftOption.value.xAxis[0].data)];
  xData = JSON.parse(JSON.stringify(leftOption.value.xAxis[0].data));
  // 如果只有1个则 在添加一个x
  if (leftOption.value.xAxis[0].data.length == 1) {
    leftOption.value.xAxis[0].data = [...xData, ...xData];
    leftOption.value.series = getDServe(item);
  } else {
    // 不为1 则获取
    leftOption.value.series = getServe(item);
  }
  if (list) {
    pieChartOption.value.series[0].data = list.map((item) => {
      return {
        value: Number(item.cost).toFixed(2),
        name: item.groupName,
      };
    });
  }
  if (leftOption.value.series && leftOption.value.series.length != 0) {
    echartInit();
    window.addEventListener("resize", () => {
      echartInit();
    });
  }
  if (
    pieChartOption.value.series &&
    pieChartOption.value.series[0].data.length != 0
  ) {
    rightEchartInit();
    window.addEventListener("resize", () => {
      echartInit();
    });
  }
};
const calculateDateRange = () => {
  let endTime = "";
  endTime = new Date(zyzList.value[zyzValue.value].limitTime);
  endTime.setDate(endTime.getDate() - 1);
  let startTime = new Date(endTime);
  let day = timeActive.value == 0 ? 7 : 30;
  startTime.setDate(startTime.getDate() - day);
  if (startTime < zyzList.value[zyzValue.value].createTime) {
    startTime = new Date(zyzList.value[zyzValue.value].createTime);
    startTime.setDate(startTime.getDate() + 1);
  }
  function lookDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear(); // 获取年份
    const month = date.getMonth() + 1; // 获取月份，月份从0开始，所以需要+1
    const day = date.getDate(); // 获取日
    return year + "-" + month + "-" + day;
  }
  return [lookDate(startTime), lookDate(endTime)];
};
// 获取azure账单
const getAzure = () => {
  if (timeActive.value != 2 || billTime.value == null) {
    billTime.value = calculateDateRange();
    timeType.value = false;
  }
  api.chat[
    ptValue.value == 0
      ? "azureBill"
      : ptValue.value == 2
      ? "aliBill"
      : "baiduBill"
  ]({
    userId: userId.value,
    startDate: billTime.value[0],
    endDate: billTime.value[1],
    resourceGroupId: zyzList.value[zyzValue.value].id,
  }).then((res) => {
    if (res.returnCode == 200) {
      if (res.data.length != 0) {
        billList.value = res.data;
        if (ldActive.value == 0) {
          openEchar(billList.value.daily, billList.value.detail_cost);
        } else {
          openEchar(billList.value.monthly, billList.value.detail_cost);
        }
      } else {
        billList.value = {
          avg_user_cost: 0,
          cost: 0,
          daily: [],
          detail: [],
        };
      }
    }
  });
};
// 获取资源组
const getZyz = (type) => {
  api.manage
    .resourceBy({ userId: userId.value, resourceType: ptValue.value })
    .then((res) => {
      if (res.returnCode == 200) {
        zyzList.value = res.data;
        if (type) {
          zyzValue.value = 0;
        }
        getAzure();
      }
    });
};
// 切换搜索项
const changeSearch = (e, index) => {
  // 切换资源平台
  if (index == 1) {
    ptValue.value = e;
    if (navActive.value == 1) {
      getToken();
    } else {
      getZyz(true);
    }
  } else if (index == 2) {
    zyzValue.value = e;
    getZyz(false);
  } else if (index == 3) {
    // 等于1  就为null
    timeActive.value = e;
    if (e == 2) {
      timeType.value = true;
    } else {
      time.value = null;
      timeType.value = false;
    }
    getZyz(false);
  } else {
    ldActive.value = e;
    getAzure();
  }
};
// 分页
const changeSize = (e) => {
  pageId.value = 1;
  perPage.value = e;
  getToken();
};
const changePage = (e) => {
  pageId.value = e;
  getToken();
};
// 切换nav
const changeNav = (index) => {
  navActive.value = index;
  ptValue.value = 0;
  namePattern.value = "";
  zyzValue.value = 0;
  timeActive.value = 0;
  time.value = null;
  tokenTime.value = [];
  timeType.value = false;
  if (index == 0) {
    getZyz(true);
  } else {
    getToken();
  }
};
const getToken = () => {
  tokenLoading.value = true;
  api.chat
    .tokenBill({
      resourceType: ptValue.value,
      namePattern: namePattern.value,
      userId: userId.value,
      startTime: tokenTime.value ? tokenTime.value[0] : null,
      endTime: tokenTime.value ? tokenTime.value[1] : null,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      tokenLoading.value = false;
      if (res.returnCode == 200) {
        tableData.value = res.data;
        total.value = res.totalNum;
      }
    })
    .catch((err) => {
      tokenLoading.value = false;
    });
};
const echartInit = () => {
  setTimeout(() => {
    echarts.dispose(document.getElementById("leftEchart"));
    let leftDom = document.getElementById("leftEchart");
    let leftChart = echarts.init(leftDom);
    leftChart.setOption(leftOption.value);
    window.addEventListener("resize", function () {
      setTimeout(() => {
        leftChart.resize();
      }, 100);
    });
  }, 100);
};
const rightEchartInit = () => {
  setTimeout(() => {
    echarts.dispose(document.getElementById("rightEchart"));
    let rightDom = document.getElementById("rightEchart");
    let rightChart = echarts.init(rightDom);
    rightChart.setOption(pieChartOption.value);
    window.addEventListener("resize", function () {
      setTimeout(() => {
        rightChart.resize();
      }, 100);
    });
  }, 100);
};
</script>

<style lang="scss" scoped>
.tokenContent {
  width: calc(100% - 60px);
  padding: 30px;
  margin: 0px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff7a;
  min-height: calc(100% - 60px);
  position: relative;
}
.tokenContent1 {
  width: calc(100% - 60px);
  padding: 30px;
  margin: 0px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff7a;
  height: calc(100% - 60px);
  position: relative;
}
.navTextAc {
  font-size: 20px;
  color: #0256ff;
  font-family: "Medium";
  margin-right: 24px;
  border-bottom: 3px solid #0256ff;
  padding-bottom: 6px;
  cursor: pointer;
}
.navText {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Medium";
  margin-right: 24px;
  border-bottom: 3px solid #8a8a8a;
  padding-bottom: 6px;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navTip {
  color: #8a8a8a;
  font-family: "Medium";
  width: calc(100% - 320px);
}
.navTipX {
  color: #f02d63;
  margin-right: 10px;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-right: 12px;
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
}
:deep(.el-dropdown) {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.exportBtn {
  border: 2px solid #edeef6;
  border-radius: 16px;
  background-color: #fefefe;
  padding: 10px 20px;
  font-size: 18px;
  color: #3376ff;
  font-family: "Regular";
  text-align: center;
  cursor: pointer;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top15 {
  margin-top: 15px;
}
.bottomDiv {
  background-color: #f7f8ff;
  padding: 30px;
  border-radius: 30px;
  height: 451px;
  width: calc(66% - 70px);
  margin-right: 20px;
}
.rightDiv {
  background-color: #f7f8ff;
  padding: 30px;
  border-radius: 30px;
  height: 451px;
  width: calc(33% - 70px);
}
.echarImg {
  width: 24px;
  margin-right: 8px;
}
.bottomTitle {
  height: 30px;
}
.echartText {
  font-size: 20px;
  font-family: "Medium";
  color: #3d3d3d;
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.leftEchart {
  height: calc(100% - 40px);
  margin-top: 10px;
}
.tableNav {
  height: calc(100% - 50px);
}
.pageDiv {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.height40 {
  height: 40px;
}
.tableDiv {
  height: calc(100% - 120px);
}
.nameInput {
  width: 300px;
  height: 48px;
  border-radius: 16px;
  margin-right: 12px;
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-family: "Regular";
  }
}
.classTableItem {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  background-color: #f7f8ff;
  height: 57px;
}
:deep(.el-range-editor.el-input__wrapper) {
  height: 44px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  margin-right: 12px;
}
:deep(.el-date-editor .el-range-input) {
  font-size: 18px;
  font-family: "Regular";
}
.ai {
  width: 16px;
  margin-right: 6px;
}
.tokenNumber {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.bottom12 {
  margin-bottom: 12px;
}
:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
  height: 48px;
  border-radius: 16px;
  margin-right: 12px;
}
:deep(.el-input__wrapper) {
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
}
:deep(.el-input__inner) {
  font-size: 18px;
  font-family: "Regular";
}
.warIcon {
  margin-left: 5px;
}
.tokenError {
  width: 360px;
  height: 360px;
  margin-bottom: 12px;
}
.tokenErrorDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.openTokenBtn {
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  border-radius: 16px;
  height: 48px;
  padding: 10px 20px;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
}
</style>

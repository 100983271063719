<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// 引入右侧展示内容
import Accredit from "./system/accredit.vue";
import Base from "./system/base.vue";
import Bot from "./system/bot.vue";
import User from "./system/user.vue";
import Group from "./system/group.vue";
import Log from "./system/log.vue";
import Micromodel from "./system/micromodel.vue";
import Role from "./system/role.vue";
import Resoure from "./system/resoure.vue";
import Tipic from "./system/tipic.vue";
import { useStore } from "vuex";
const store = useStore();
const activeIndex = ref("1");
const handleSelect = (key) => {
  activeIndex.value = key;
  if (key == 1) {
    store.commit("SET_MANGER", "manage.department");
  } else if (key == 2) {
    store.commit("SET_MANGER", "manage.group");
  } else if (key == 3) {
    store.commit("SET_MANGER", "manage.role");
  } else if (key == 4) {
    store.commit("SET_MANGER", "manage.resoure");
  } else if (key == 5) {
    store.commit("SET_MANGER", "manage.tipic");
  } else if (key == 6) {
    store.commit("SET_MANGER", "manage.micromodel");
  } else if (key == 7) {
    store.commit("SET_MANGER", "manage.bot");
  } else if (key == 8) {
    store.commit("SET_MANGER", "manage.accredit");
  } else if (key == 9) {
    store.commit("SET_MANGER", "manage.base");
  } else if (key == 10) {
    store.commit("SET_MANGER", "manage.log");
  }
};
onMounted(() => {
  activeIndex.value = "1";
  store.commit("SET_MANGER", "manage.department");
});
</script>
<template>
  <div class="container">
    <!-- 侧边导航 -->
    <el-container>
      <el-aside class="aside">
        <el-menu
          :default-active="activeIndex"
          @select="handleSelect"
          text-color="#3D3D3D"
          active-text-color="#0256FF"
          :collapse="false"
        >
          <el-menu-item-group :title="t('manage.team')">
            <el-menu-item index="1" v-if="$hasPermission('10002')==true">
              <!-- <el-tooltip :content="$t('manage.department')"> -->
              <span class="ellipsis">{{ t("manage.department") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="2" v-if="$hasPermission('10012')==true">
              <!-- <el-tooltip :content="$t('manage.group')"> -->
              <span class="ellipsis">{{ t("manage.group") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="3" v-if="$hasPermission('10017')==true">
              <!-- <el-tooltip :content="$t('manage.role')"> -->
              <span class="ellipsis">{{ t("manage.role") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="4" v-if="$hasPermission('10023')==true">
              <!-- <el-tooltip :content="$t('manage.resoure')"> -->
              <span class="ellipsis">{{ t("manage.resoure") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group :title="t('manage.knowledge')">
            <el-menu-item index="5" v-if="$hasPermission('10035')==true">
              <!-- <el-tooltip :content="$t('manage.tipic')"> -->
              <span class="ellipsis">{{ t("manage.tipic") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="6" v-if="$hasPermission('10039')==true">
              <!-- <el-tooltip :content="$t('manage.micromodel')"> -->
              <span class="ellipsis">{{ t("manage.micromodel") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="7" v-if="$hasPermission('10043')==true">
              <!-- <el-tooltip :content="$t('manage.bot')"> -->
              <span class="ellipsis">{{ t("manage.bot") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group :title="t('manage.deploy')">
            <el-menu-item index="8" v-if="$hasPermission('10048')==true">
              <!-- <el-tooltip :content="$t('manage.accredit')"> -->
              <span class="ellipsis">{{ t("manage.accredit") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="9" v-if="$hasPermission('10051')==true">
              <!-- <el-tooltip :content="$t('manage.base')"> -->
              <span class="ellipsis">{{ t("manage.base") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
            <el-menu-item index="10" v-if="$hasPermission('10062')==true">
              <!-- <el-tooltip :content="$t('manage.log')"> -->
              <span class="ellipsis">{{ t("manage.log") }}</span>
              <!-- </el-tooltip> -->
            </el-menu-item>
          </el-menu-item-group>
        </el-menu>
      </el-aside>
      <el-main class="main">
        <!-- 团队 -->
        <User v-if="activeIndex === '1'"></User>
        <Group v-if="activeIndex === '2'"></Group>
        <Role v-if="activeIndex === '3'"></Role>
        <Resoure v-if="activeIndex === '4'"></Resoure>
        <!-- 知识 -->
        <Tipic v-if="activeIndex === '5'"></Tipic>
        <Micromodel v-if="activeIndex === '6'"></Micromodel>
        <Bot v-if="activeIndex === '7'"></Bot>
        <!-- 部署 -->
        <Accredit v-if="activeIndex === '8'"></Accredit>
        <Base v-if="activeIndex === '9'"> </Base>
        <Log v-if="activeIndex === '10'"></Log>
      </el-main>
    </el-container>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-menu-item-group__title) {
  padding: 10px 0 10px 8px !important;
  line-height: normal;
  font-size: 22px;
  color: #3d3d3d;
  font-family: 'Bold';
}

:deep(.el-menu-item) {
  display: flex;
  align-items: center;
  height: 46px;
  font-size: 18px;
  padding-left: 40px !important;
}

:deep(.el-menu) {
  border-right: none;
}

:deep(.el-menu-item.is-active) {
  background-color: #f7f8ff;
  border-radius: 10px;
  color: #0256ff;
}

:deep(.el-main) {
  padding: 0 !important;
  height: 100% !important;
}

:deep(.el-menu-item:hover) {
  background-color: #f7f8ff;
  border-radius: 10px;
}

:deep(.el-container) {
  height: 100%;
}

:deep(.el-menu-item-group) {
  height: 100% !important;
}

.container {
  background-color: #f7f8ff;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

.aside {
  width: 228px;
  height: 100%;
  border-radius: 16px;
  margin-right: 16px;
  padding: 18px 18px 12px;
  background-color: #fff;
}

.main {
  flex: 1;
  height: 100%;
  border-radius: 16px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: 'Regular';
}
</style>

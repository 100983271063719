<template>
  <div class="cardDiv">
    <div class="flexCenter">
      <el-tooltip popper-class="toolTip" :content="props.title + '（CNY）'">
        <div class="carTitle">{{ props.title }}（CNY）</div>
      </el-tooltip>
      <img
        class="titleIcon"
        v-if="
          props.type &&
          props.item.detail &&
          props.item.detail.length != 0 &&
          props.item.imgType
        "
        :src="upReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
      <img
        class="titleIcon"
        v-if="
          props.type &&
          props.item.detail &&
          props.item.detail.length != 0 &&
          !props.item.imgType
        "
        :src="downReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
    </div>
    <div class="bigNumber">
      <span class="numberDw">¥</span>
      <el-tooltip popper-class="toolTip" :content="String(props.money)">
        <span>{{ props.money.toFixed(2) }}</span>
      </el-tooltip>
    </div>
    <div class="numberFlex">
      <div class="numberText">{{ t('statistics.name36') }}</div>
      <div
        v-if="props.type"
        :class="
          props.item.ratio < 0
            ? 'redDiv'
            : props.item.ratio > 0
            ? 'greenDiv'
            : props.item.ratio == null
            ? 'hsDiv'
            : ''
        "
      >
        <img
          v-if="props.item.ratio != null"
          :src="props.item.ratio < 0 ? downImg : upImg"
          alt=""
          class="downImg"
        />
        <span class="number">
          {{ props.item.ratio != null ? props.item.ratio + "%" : "-" }}
        </span>
      </div>
      <div
        v-else
        :class="
          props.item.ratio < 0
            ? 'redDiv'
            : props.item.ratio > 0
            ? 'greenDiv'
            : props.item.ratio == null
            ? 'hsDiv'
            : ''
        "
      >
        <img
          v-if="props.item.ratio != null"
          :src="props.item.ratio < 0 ? downImg : upImg"
          alt=""
          class="downImg"
        />
        <span class="number">
          {{
            props.item.avg_user_ratio != null
              ? props.item.avg_user_ratio + "%"
              : "-"
          }}
        </span>
      </div>
    </div>
    <img
      :src="echarImg"
      class="echarImg"
      alt=""
      v-if="shouldDisplayChart"
      @click="openEchar(props.item)"
    />
  </div>
</template>

<script setup>
import upReson from "@/assets/chat/upReson.svg";
import downReson from "@/assets/chat/downReson.svg";
import echarImg from "@/assets/statistics/echar.svg";
import downImg from "@/assets/statistics/down.svg";
import upImg from "@/assets/statistics/up.svg";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["title", "money", "item", "type", "ldActive"]);
const emit = defineEmits(["openEchar"]);
const shouldDisplayChart = computed(() => {
  return (
    props.type &&
    props.item.detail &&
    props.item.detail.length !== 0 &&
    ((props.ldActive === 0 && props.item.daily.length !== 0) ||
      (props.ldActive === 1 && props.item.monthly.length !== 0))
  );
});
const openEchar = (item) => {
  if (props.ldActive === 0) {
    emit("openEchar", item.daily, item.detail_cost);
  } else {
    emit("openEchar", item.monthly, item.detail_cost);
  }
};
</script>

<style lang="scss" scoped>
.cardDiv {
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 30px;
  width: calc(100% - 60px);
  height: 180px;
  position: relative;
}
.carTitle {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Medium";
  width: calc(100% - 20px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.titleIcon {
  width: 17px;
}
.bigNumber {
  font-size: 78px;
  color: #3376ff;
  font-family: "bold";
  text-align: center;
  height: 117px;
  line-height: 117px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.numberDw {
  font-size: 70px;
}
.echarImg {
  width: 24px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.downImg {
  width: 6px;
  margin-right: 3px;
}
.number {
  font-size: 16px;
  font-family: "Medium";
}
.numberText {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
  margin-right: 12px;
}
.greenDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #e6f1e8;
  border-radius: 8px;
  justify-content: center;
  color: #14ae5c;
}
.redDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #fde2e7;
  border-radius: 8px;
  justify-content: center;
  color: #f02d63;
}
.numberFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hsDiv {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background-color: #dfdfdf;
  border-radius: 8px;
  justify-content: center;
  color: #8a8a8a;
}
</style>

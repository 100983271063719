export default {
    CN: {
        chat: '新的对话',
        index: '主页',
        base: 'AI知识库',
        baseDetails: 'AI知识库',
        share: '知识共享',
        shareDetails: '知识共享',
        shareView: '知识共享',
        bot: 'AI智能体',
        botDetail:'AI智能体',
        statistics: '仪表盘',
        manage: '平台管理',
        login: '登录',
        lookAll: '查看全部',
        recentTopics: '最近话题',
        index1: '需要我为你做些什么呢？',
        index2: '为您推荐',
        index3: '知识共享',
        index4: '长期',
        index5: '更多智能体',
        index6: '探索新知识，验证新话题',
        index11: '来源',
        wordTool: "文档翻译",
        audioTool: "智能音频",
        imageTool: "图片转文档",
        billTool: "PDF文字提取",
        index21: '今日剩余资源',
        index22: '当前展示资源平台',
        index23: '文本生成消耗',
        index24: '令牌',
        index25: '图像生成',
        index26: '幅',
        index27: '学习知识库切片',
        index28: '片',
        index30: '用户日配额',
        index29: '上传文件页',
        index31: '页',
        index32: '最多'
    },
    HK: {
        chat: '新嘅对话',
        index: '主页',
        base: 'AI知识库',
        baseDetails: 'AI知识库',
        share: '知识共享',
        shareDetails: '知识共享',
        shareView: '知识共享',
        bot: 'AI智能体',
        botDetail:'AI智能体',
        statistics: '仪表盘',
        manage: '平台管理',
        login: '登录',
        lookAll: '查看全部',
        recentTopics: '最近话题',
        index1: '需要我为你做些什么呢？',
        index2: '为您推荐',
        index3: '知识共享',
        index4: '长期',
        index5: '更多智能体',
        index6: '探索新知识，验证新话题',
        index11: '来源',
        wordTool: "文档翻译",
        audioTool: "智能音频",
        imageTool: "图片转文档",
        billTool: "PDF文字提取",
        index21: '今日剩餘資源',
        index22: '當前展示資源平臺',
        index23: '文字生成消耗',
        index24: '權杖',
        index25: '影像生成',
        index26: '幅',
        index27: '學習知識庫切片',
        index28: '片',
        index30: '用戶日配額',
        index29: '上傳文件頁',
        index31: '頁',
        index32: '最多'
    },
    US: {
        chat: 'New Conversation',
        index: 'Home',
        base: 'AI Knowledge Base',
        baseDetails: 'AI Knowledge Base',
        share: 'Knowledge Sharing',
        shareDetails: 'Knowledge Sharing',
        shareView: 'Knowledge Sharing',
        bot: 'AI Bot',
        botDetail:'AI Bot',
        statistics: 'Dashboard',
        manage: 'Platform Management',
        login: 'Login',
        lookAll: 'View All',
        recentTopics: 'Recent Topics',
        index1: 'How can I assist you today?',
        index2: 'Recommendations for You',
        index3: 'Knowledge Sharing',
        index4: 'Long-term',
        index5: 'More Bots',
        index6: 'Explore New Knowledge, Verify New Topics',
        index11: 'Source',
        wordTool: "Document Translation",
        audioTool: "Intelligent Audio",
        imageTool: "Image to Document",
        billTool: "PDF Text Extraction",
        index21: 'Remaining Resources for Today',
        index22: 'Current Display Resource Platform',
        index23: 'Text generation consumption',
        index24: 'Token',
        index25: 'Image Generation',
        index26: 'Pcs',
        index27: 'Learning knowledge base slicing',
        index28: 'Chks',
        index30: 'Quota per user day',
        index29: 'Upload file page',
        index31: 'Pgs',
        index32: 'Most'
    },
};
import { createI18n } from 'vue-i18n';
import index from '@/lang/index'

const i18n = createI18n({
    legacy: false,
    locale: 'CN', // 默认语言
    globalInjection: true,
    messages: index,
    warnHtmlMessage: false
});
export default i18n;
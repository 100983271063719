<script setup>
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import api from "@/api/index";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { ElMessageBox } from "element-plus";
const { t } = useI18n();
const emit = defineEmits(["changeSize", "changePage"]);
const store = useStore();
const userId = ref(store.state.userId);
// 你可以在这里添加更多的点击处理逻辑
const props = defineProps({
  tableId: Number,
  tableName: String,
});
onMounted(() => {
  getUserTable();
});
watch(
  () => props.tableId,
  (newVal) => {
    listTable.value.departmentId = newVal;
    listTable.value.resourceType = null;
    status.value = -1;
    getUserTable();
  }
);
const limitMessages = ref({
  limitAliImageTimes: "manage.resoureForm.resoure65",
  limitBaiduImageTimes: "manage.resoureForm.resoure65",
  limitImageTimes: "manage.resoureForm.resoure65",
  limitDocumentChatNum: "manage.resoureForm.resoure66",
  limitDocumentNum: "manage.resoureForm.resoure66",
  limitAzureChunkTimes: "menu.index28",
  limitBaiduChunkTimes: "menu.index28",
  limitAliChunkTimes: "menu.index28",
  limitAzureOcrTimes: "menu.index31",
  limitBaiduOcrTimes: "menu.index31",
  limitAliOcrTimes: "menu.index31",
  limit35Token: "manage.resoureForm.resoure67",
  limit4Token: "manage.resoureForm.resoure67",
  limit4oToken: "manage.resoureForm.resoure67",
  limit4ominiToken: "manage.resoureForm.resoure67",
  limitQianFanModel1Token: "manage.resoureForm.resoure67",
  limitQianFanModel2Token: "manage.resoureForm.resoure67",
  limitQianFanModel3Token: "manage.resoureForm.resoure67",
  limitqwenMaxTokens: "manage.resoureForm.resoure67",
  limitqwenTurboTokens: "manage.resoureForm.resoure67",
  limitqwenPlusTokens: "manage.resoureForm.resoure67",
});
// 资源下拉
const status = ref(-1);
const statusList = ref([
  {
    value: -1,
    label: t("manage.resoureForm.resoure34"),
  },
  {
    value: 2,
    label: t("manage.resoureForm.resoure35"),
  },
  {
    value: 1,
    label: t("manage.resoureForm.resoure36"),
  },
  {
    value: 0,
    label: t("manage.resoureForm.resoure37"),
  },
]);
const statusBtn = (item) => {
  changeType(item);
  listTable.value.resourceType = item;
  getUserTable();
};
// 请求表格数据
const listTable = ref({
  userId: userId.value,
  departmentId: props.tableId,
  resourceType: null,
  ifLimit: true,
});
const tableMember = ref([]);
const getUserTable = () => {
  if (listTable.value.departmentId) {
    api.manage.getResourceListApi(listTable.value).then((res) => {
      if (res.returnCode == 200) {
        tableMember.value = res.data;
        editNum.value = true;
        btnType.value = res.data.some((item) => item.isBlocked == 0);
        console.log(btnType.value, res.data);
      }
    });
  }
};
// 多选
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val;
  multipleSelection.value = val;
};
// 批量禁用
const allDisabled = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.resoureForm.resoure38"));
  } else {
    ElMessageBox.confirm(
      t("manage.resoureForm.resoure39"),
      t("manage.resoureForm.resoure40"),
      {
        confirmButtonText: t("manage.resoureForm.resoure41"),
        cancelButtonText: t("manage.resoureForm.resoure42"),
        type: "warning",
      }
    ).then(() => {
      api.manage
        .resourceDisableApi({
          departmentId: props.tableId,
          resourceList: ids.value,
          userId: userId.value,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success(t("manage.resoureForm.resoure43"));
            getUserTable();
          }
        });
    });
  }
};
// 禁用按钮
const handleClick = (row) => {
  api.manage
    .resourceDisableApi({
      departmentId: props.tableId,
      resourceList: [
        {
          resourceType: row.resourceType,
          configurationId: row.configurationId,
          functionTypeId: row.functionTypeId,
        },
      ],
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.resoureForm.resoure44"));
        getUserTable();
      }
    });
};
// 启用按钮
const handleEnable = (row) => {
  api.manage
    .resourceEnableApi({
      departmentId: props.tableId,
      resourceList: [
        {
          resourceType: row.resourceType,
          configurationId: row.configurationId,
          functionTypeId: row.functionTypeId,
          BlockId: row.BlockId,
        },
      ],
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.resoureForm.resoure45"));
        getUserTable();
      }
    });
};
// 配额-单选
const editNum = ref(true);
// 编辑配额
const editNumBtn = () => {
  editNum.value = false;
};
const selectable = (row) => {
  return row.isBlocked != 2;
};
const limit = ref({});
const newObj = ref({});
const newType = ref("");
// 保存配额编辑
const submitNumBtn = () => {
  tableMember.value.forEach((item) => {
    limit.value[item.limit.limitType] = Number(item.limit.value);
    Object.assign(newObj.value, limit.value);
  });
  editNum.value = true;
  api.manage
    .quotaEditApi({
      userId: userId.value,
      limits: newObj.value,
      departmentId: props.tableId,
      resourceType: newType.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.resoureForm.resoure46"));
        getUserTable();
        newObj.value = {};
      } else {
        getUserTable();
      }
    });
};
// 禁用当前资源平台
const disabledType = () => {
  api.manage
    .enableApi({
      userId: userId.value,
      departmentId: props.tableId,
      resourceType: listTable.value.resourceType,
      isForbidden: 1,
    })
    .then((res) => {
      if (res.returnCode === 200) {
        message.success(t("manage.resoureForm.resoure47"));
        getUserTable();
      }
    });
};
const btnType = ref(true);
const changeType = (item) => {
  newType.value = item;
  api.manage
    .resourceStatusApi({
      userId: userId.value,
      departmentId: props.tableId,
      resourceType: item,
    })
    .then((res) => {
      getUserTable();
    });
};
// 启用当前资源平台
const enableType = () => {
  api.manage
    .enableApi({
      userId: userId.value,
      departmentId: props.tableId,
      resourceType: listTable.value.resourceType,
      isForbidden: 0,
    })
    .then((res) => {
      if (res.returnCode === 200) {
        message.success(t("manage.resoureForm.resoure48"));
        getUserTable();
      }
    });
};
// 日配额input失去焦点
const checkNum = (item) => {
  if (item.limit.value > item.parent_department_limit) {
    item.limit.value = item.parent_department_limit;
  } else {
    item.limit.value = item.limit.value;
  }
};
</script>
<template>
  <div class="mian">
    <div class="dashboard">
      <div class="companyName">{{ tableName }}</div>
    </div>
    <div class="search">
      <div class="searchLeft">
        <!-- 资源平台 -->
        <div class="searchItem">
          <el-select v-model="status" @change="statusBtn">
            <template #label="{ label }">
              <div style="display: flex; justify-content: space-between">
                <div>{{ $t("manage.resoureForm.resoure49") }} ：</div>
                <div>{{ label }}</div>
              </div>
            </template>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span class="selText">{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 功能区 -->
      <div style="display: flex">
        <el-button
          class="toolBox"
          :disabled="$hasPermission('10028') == false"
          style="color: #f02d63"
          @click="allDisabled"
        >
          {{ $t("manage.resoureForm.resoure52") }}
        </el-button>
        <div v-if="status !== -1">
          <el-button
            class="toolBox"
            style="color: #f02d63"
            @click="disabledType"
            :disabled="$hasPermission('10028') == false"
            v-if="btnType"
          >
            {{ $t("manage.resoureForm.resoure51") }}
          </el-button>
          <el-button
            class="toolBox"
            style="color: #11d7b2"
            @click="enableType"
            :disabled="$hasPermission('10028') == false"
            v-if="!btnType"
          >
            {{ $t("manage.resoureForm.resoure50") }}
          </el-button>
        </div>
        <el-button
          class="toolBox addUser"
          style="background-color: #767bfa"
          @click="submitNumBtn"
          v-if="!editNum"
        >
          {{ $t("manage.resoureForm.resoure53") }}
        </el-button>
        <el-button
          class="toolBox addUser"
          @click="editNumBtn"
          :disabled="$hasPermission('10027') == false"
          v-if="editNum"
        >
          {{ $t("manage.resoureForm.resoure54") }}
        </el-button>
      </div>
    </div>
    <el-table
      :data="tableMember"
      @selection-change="handleSelectionChange"
      class="tableHeight"
    >
      <el-table-column type="selection" :selectable="selectable" />
      <el-table-column
        prop="modelType"
        :label="t('manage.resoureForm.resoure55')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.modelType">
            <div class="wid100">{{ scope.row.modelType }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="resourceType"
        :label="t('manage.resoureForm.resoure56')"
      >
        <template #default="scope">
          <div class="flex gap-2">
            <span type="success" v-if="scope.row.resourceType === 0">{{
              $t("manage.resoureForm.resoure37")
            }}</span>
            <span type="success" v-else-if="scope.row.resourceType === 1">{{
              $t("manage.resoureForm.resoure36")
            }}</span>
            <span type="success" v-else-if="scope.row.resourceType === 2">{{
              $t("manage.resoureForm.resoure35")
            }}</span>
            <span type="success" v-else>{{
              $t("manage.resoureForm.resoure56")
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="functionType"
        :label="t('manage.resoureForm.resoure58')"
      />
      <el-table-column
        prop="isBlocked"
        :label="t('manage.resoureForm.resoure59')"
        width="100"
      >
        <template #default="scope">
          <div class="flex gap-2">
            <el-tag v-if="scope.row.isBlocked === 0">{{
              $t("manage.resoureForm.resoure60")
            }}</el-tag>
            <el-tag v-if="scope.row.isBlocked === 1" type="info">{{
              $t("manage.resoureForm.resoure61")
            }}</el-tag>
            <span v-if="scope.row.isBlocked === 2">-</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="parent_department_limit"
        :label="$t('manage.resoureForm.resoure63')"
      >
        <template #default="scope">
          <span v-if="scope.row.parent_department_limit == '-1'">{{
            $t("manage.resoureForm.resoure62")
          }}</span>
          <span v-else>{{ scope.row.parent_department_limit }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="limit"
        :label="$t('manage.resoureForm.resoure64')"
        class="limit"
      >
        <template #default="scope">
          <el-input
            v-if="scope.row.parent_department_limit == '-1'"
            type="number"
            v-model="scope.row.limit.value"
            :disabled="editNum"
            :max="2147483647"
          >
            <template #append>
              <div v-if="limitMessages[scope.row.limit.limitType]">
                {{ $t(limitMessages[scope.row.limit.limitType]) }}
              </div>
            </template>
          </el-input>
          <div v-else>
            <el-input
              type="number"
              v-model="scope.row.limit.value"
              :disabled="editNum"
              :max="scope.row.parent_department_limit"
              @blur="checkNum(scope.row)"
            >
              <template #append>
                <div v-if="limitMessages[scope.row.limit.limitType]">
                  {{ $t(limitMessages[scope.row.limit.limitType]) }}
                </div>
              </template>
            </el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('manage.resoureForm.resoure87')"
        class="operation"
        fixed="right"
      >
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            :disabled="$hasPermission('10028') == false"
            v-if="scope.row.isBlocked == 0"
            @click="handleClick(scope.row)"
            style="color: #f02d63"
          >
            {{ $t("manage.resoureForm.resoure61") }}
          </el-button>
          <el-button
            v-if="scope.row.isBlocked == 1"
            :disabled="$hasPermission('10028') == false"
            link
            type="primary"
            size="small"
            @click="handleEnable(scope.row)"
          >
            {{ $t("manage.resoureForm.resoure60") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="scss">
.search {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 48px;
    width: 160px !important;
    font-size: 16px !important;
  }
}

:deep(.el-input.is-disabled .el-input__inner) {
  width: 70px !important;
}

:deep(.el-input__inner) {
  width: 70px !important;
}

:deep(.el-input__wrapper) {
  padding: 0 2px;
  border-radius: 2px !important;
}

:deep(.el-input-group__append) {
  padding: 0 6px !important;
  width: 100px !important;
  color: #8a8a8a !important;
}
:deep(.el-checkbox) {
  height: 30px;
}

.main {
  width: 100%;
  height: 100%;

  .operation {
    width: 200px;
  }
}

.tableHeight {
  height: calc(100% - 130px);

  .remove {
    width: 50px;
    height: 30px;
    border-radius: 8px;
    line-height: 30px;
    color: #f02d63;
    text-align: center;
    // background-color: #F7F8FF;
    cursor: pointer;
    margin-right: 10px;
  }

  .remove:hover {
    background-color: #767bfa;
    color: #fff;
  }

  .remove1 {
    width: 50px;
    height: 30px;
    border-radius: 8px;
    line-height: 30px;
    color: #0256ff;
    text-align: center;
    // background-color: #F7F8FF;
    cursor: pointer;
  }

  .remove1:hover {
    background-color: #767bfa;
    color: #fff;
  }
}

.dialog {
  width: 200px;
}

.limit {
  width: 280px;
}

.pagination {
  margin-top: 16px;
}

.changeMore {
  margin-left: 5px;

  .moreIcon {
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 16px;
      height: 16px;
      margin: 4px auto;
    }
  }
}

.dashboard {
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  position: relative;

  .companyName {
    font-size: 22px;
    color: #3d3d3d;
    margin-right: 32px;
    font-weight: 600;
  }

  .companyNum {
    font-size: 18px;
    color: #8a8a8a;
    margin-right: 16px;
    margin-top: 4px;

    .num {
      color: #3d3d3d;
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;

  .searchLeft {
    display: flex;

    .date {
      height: 44px;
      line-height: 44px;
      border: #edeef6 solid 2px;
      border-radius: 14px;
      font-size: 18px;
      display: flex;
      font-size: 18px;
      text-align: center;
      padding: 0 18px;
      cursor: pointer;

      div:first-child {
        margin-right: 10px;
        color: #8a8a8a;
      }
    }
  }
}

.ellipsis {
  font-size: 16px;
  min-width: 44px;
}

.searchDiv {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  margin-left: 10px;
  cursor: pointer;
}

.toolBox {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #8a8a8a;
  margin-right: 10px;
}

.searchSpan {
  color: #3d3d3d;
  font-size: 16px;
  width: 100%;
  display: flex;
  margin-top: 13px;
}

.searchIcon {
  margin-left: 10px;
}

.searchDiv2 {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 6px;
  margin-left: 10px;
  cursor: pointer;

  .toolImg {
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.searchInput {
  margin-right: 10px;
  height: 44px;
}

.addUser {
  background-color: #0256ff;
  color: #fff;
  margin-right: 0;
}
.mian {
  height: calc(100% - 20px);
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.selText {
  font-size: 16px;
  color: #3d3d3d;
  font-family: "Regular";
}
</style>

<template>
  <div class="dialog">
    <div ref="dialogRef" class="div100">
      <div ref="topRef">
        <!-- 头 -->
        <div class="dialogHeaderFlex">
          <div class="dialogTitle">{{ t("login.login76") }}</div>
          <img :src="addClose" alt="" class="addClose" @click="closeView" />
        </div>
        <!-- 内容 -->
        <div class="contentText">
          {{ t("login.login77") }}
          {{ props.companyName }}
          {{ t("login.login78") }}
          <span class="numberColor">
            {{ props.conflictViewObj.currentPeopleNum }}
          </span>
          {{ t("login.login79") }}
          <span class="numberBlue">
            {{ props.conflictViewObj.maximumPeopleNum }}
          </span>
          {{ t("login.login80") }}
          <span class="numberBlue">{{ t("login.login81") }}</span>
          {{ t("login.login82") }}
        </div>
        <div class="contentText1" v-if="disabledNumber > 0">
          {{ t("login.login85") }}
          <span class="numberColor">
            {{ disabledNumber }}
          </span>
          {{ t("login.login86") }}
        </div>
        <!-- 搜索 -->
        <div class="flexBetween">
          <div class="searchTitle">{{ t("login.login83") }}:</div>
          <div class="flexCenter">
            <div class="roleDiv">
              <el-dropdown @command="changeDepartment" :max-height="300">
                <span class="dropText">
                  {{
                    departmentActive == null
                      ? t("base.base10")
                      : departmentList[departmentActive].departmentName
                  }}
                  <el-icon class="el-icon--right" color="#8A8A8A">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="all">
                      <span class="dropText">{{ t("base.base10") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-for="(item, index) in departmentList"
                      :key="item.departmentId"
                      :command="index"
                    >
                      <span class="dropText">{{ item.departmentName }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <el-button class="pljy" @click="jyAll">{{
              t("manage.userForm.user83")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        class="tableClass"
        :height="tableHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" />
        <el-table-column prop="userName" :label="t('base.base126')">
          <template #default="scope">
            <div class="flexCenter">
              <div
                class="nameDIv"
                :style="'background-color:' + scope.row.color"
              >
                {{ scope.row.userName.slice(0, 1) }}
              </div>
              <div class="tableText">
                {{ scope.row.userName }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" :label="t('base.base127')">
          <template #default="scope">
            <div class="tableText">{{ scope.row.departmentName }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="t('base.base36')" align="center">
          <template #default="scope">
            <el-button class="jyBtn" link @click="stopUser(scope.row.userId)"
              >{{ t("manage.userForm.user53") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 进入系统 -->
      <div class="bottomFlex" ref="bottomRef">
        <el-button
          class="goBtn"
          :disabled="disabledNumber > 0"
          @click="goLogin"
          >{{ t("login.login84") }}</el-button
        >
      </div>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import api from "@/api";
import addClose from "@/assets/base/addClose.svg";
import { message } from "ant-design-vue";
import { ref, onMounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
const props = defineProps(["companyName", "conflictViewObj"]);
const emit = defineEmits(["closeView", "goLogin"]);
const { t } = useI18n();
const dialogRef = ref(null);
const topRef = ref(null);
const bottomRef = ref(null);
const seleList = ref([]);
const tableHeight = ref(370);
const tableData = ref([]);
const departmentList = ref([]);
const departmentActive = ref(null);
const disabledNumber = ref(null);
onMounted(() => {
  disabledNumber.value = props.conflictViewObj.remainPeopleNum;
  getUser();
  getDepartment();
  getTableHeight();
});
// 进入系统
const goLogin = () => {
  emit("goLogin");
};
// 获取部门列表
const getDepartment = () => {
  api.login.loginGetDepartMent().then((res) => {
    if (res.returnCode == 200) {
      departmentList.value = res.data;
    }
  });
};
// 获取用户
const getUser = () => {
  api.login
    .loginGetUser({
      departmentId:
        departmentActive.value == null
          ? ""
          : departmentList.value[departmentActive.value].departmentId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        tableData.value = res.data;
        getTableHeight();
      }
    });
};
// 禁用单个
const stopUser = (id) => {
  api.login
    .loginDelete({
      userId: 1000000,
      deleteUserIds: [id],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.userForm.user76"));
        disabledNumber.value = disabledNumber.value - 1;
        props.conflictViewObj.currentPeopleNum =
          props.conflictViewObj.currentPeopleNum - 1;
        getUser();
      }
    });
};
// 关闭
const closeView = () => {
  emit("closeView");
};
// 动态获取高度
const getTableHeight = () => {
  nextTick(() => {
    if (dialogRef.value && topRef.value && bottomRef.value) {
      tableHeight.value =
        dialogRef.value.offsetHeight -
        topRef.value.offsetHeight -
        bottomRef.value.offsetHeight;
    }
  });
};
// 批量禁用
const jyAll = () => {
  if (seleList.value.length == 0) {
    message.warning(t("manage.userForm.user73"));
    return;
  }
  api.login
    .loginDelete({
      userId: 1000000,
      deleteUserIds: seleList.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.userForm.user76"));
        disabledNumber.value = disabledNumber.value - seleList.value.length;
        props.conflictViewObj.currentPeopleNum =
          props.conflictViewObj.currentPeopleNum - seleList.value.length;
        getUser();
      }
    });
};
// 切换部门
const changeDepartment = (e) => {
  if (e == "all") {
    departmentActive.value = null;
  } else {
    departmentActive.value = e;
  }
  getUser();
};
// 全选
const handleSelectionChange = (value) => {
  seleList.value = value.map((item) => item.userId);
};
</script>

<style scoped lang="scss">
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #8a8a8a;
  z-index: 100;
  opacity: 0.5;
}
.dialog {
  width: 540px;
  height: 690px;
  background-color: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 110;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  padding: 30px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.contentText {
  font-size: 18px;
  font-family: "Medium";
  color: #8a8a8a;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.contentText1 {
  font-size: 18px;
  font-family: "Medium";
  color: #8a8a8a;
  line-height: 28px;
  margin-bottom: 20px;
}
.dialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.dialogHeaderFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}
.numberColor {
  color: #f02d63;
}
.numberBlue {
  color: #3376ff;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 7px 15px;
  margin-right: 12px;
}
:deep(.el-dropdown) {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
  padding-left: 10px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
}
.searchTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
}
.pljy {
  border: 2px solid #edeef6;
  padding: 10px 20px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
  border-radius: 16px;
  height: 48px;
}
.tableClass {
  width: 100%;
}
.goBtn {
  background-color: #3376ff;
  color: #ffffff;
  border-radius: 16px;
  height: 48px;
  padding: 10px 20px;
  font-size: 18px;
  font-family: "Regular";
}
.bottomFlex {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  color: #8a8a8a;
}
.nameDIv {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Semibold";
  margin-right: 6px;
}
.tableText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.jyBtn {
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
.div100 {
  width: 100%;
  height: 100%;
}
</style>

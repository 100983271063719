<template>
  <div class="chatContent">
    <div :class="lookDialog ? 'fileIndex' : 'shareView'">
      <!-- 预览标志 -->
      <div class="lookDiv">{{ t("base.base185") }}</div>
      <!-- 内容 -->
      <div
        :class="{
          width1010: !rightType && !lookDialog, //没有展示
          width10103: !rightType && lookDialog,
          width10101: rightType && !lookDialog,
        }"
        ref="containerRef"
      >
        <template v-if="routerType != 'edit'">
          <!-- 默认 -->
          <messageView
            :message="t('base.base186')"
            class="messageView"
          ></messageView>
          <div v-for="(item, index) in topicList" :key="index">
            <qaIndex
              type="look"
              :item="item"
              @openFileView="openFileView"
              :id="'talk' + item.conversationId"
              @openTalk="openTalk"
            ></qaIndex>
            <div class="fillCenter" v-if="item.answers.length > 1">
              <img
                :src="item.chatActive != 1 ? leftFill : leftNoFill"
                alt=""
                class="fillImg"
                @click="changeItemPage(false, item)"
              />
              <span
                :class="item.chatActive == 1 ? 'fillColor' : ''"
                class="fillText"
                >{{ item.chatActive }}</span
              >
              <span
                class="fillText"
                :class="
                  item.chatActive == item.answers.length ? 'fillColor' : ''
                "
                >/{{ item.answers.length }}</span
              >
              <img
                :src="
                  item.chatActive == item.answers.length
                    ? rightNoFill
                    : rightFill
                "
                alt=""
                class="fillImg"
                @click="changeItemPage(true, item)"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="item in topicList"
            :key="item.oldData.id"
            :id="'talk' + item.oldData.id"
          >
            <shareQa
              v-if="item.mySaveData.conversationId"
              :item="item.mySaveData"
              @openFileView="openFileView"
              @openTalk="openTalk"
            ></shareQa>
            <shareQa
              v-else
              :item="item.oldData"
              @openFileView="openFileView"
              @openTalk="openTalk"
            ></shareQa>
          </div>
        </template>
      </div>
      <!-- 右侧不展示 -->
      <div
        v-if="!rightType && !lookDialog"
        @click="rightType = !rightType"
        class="rightIcon"
      >
        <img :src="sqLeftSvg" alt="" class="wid18" />
      </div>
      <!-- 右侧展示 -->
      <transition name="expand-transition">
        <div v-if="rightType" class="rightDivAbs">
          <div
            :class="routerType == 'look' ? 'flexRightEnd' : 'flexRightCenter'"
          >
            <div class="sc" v-if="routerType == 'edit'" @click="goEdit">
              <img :src="fhbj" alt="" class="marginRight8 wid25" />
              <div>{{ t("base.base187") }}</div>
            </div>
            <div
              class="color2 sc"
              v-if="routerType == 'edit'"
              @click="gogogo(0)"
            >
              <img :src="fvyzImg" alt="" class="marginRight8 wid25" />
              <div>{{ t("base.base159") }}</div>
            </div>
            <div class="iconRightCenter" @click="rightType = !rightType">
              <img :src="sqRightSvg" alt="" class="wid20" />
            </div>
          </div>
          <div class="hrefDiv" v-if="routerType == 'edit'">
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
            >
              <el-anchor-link
                :href="'#talk' + item.oldData.id"
                :title="item.oldData.question"
                v-for="(item, index) in topicList"
                :key="index"
              />
            </el-anchor>
          </div>
          <div class="hrefDiv" v-else>
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
            >
              <el-anchor-link
                :href="'#talk' + item.conversationId"
                :title="item.question"
                v-for="(item, index) in topicList"
                :key="index"
              />
            </el-anchor>
          </div>
        </div>
      </transition>
    </div>
    <!-- 文件预览 -->
    <fileView
      v-if="lookDialog && lookType == 'file'"
      type="chat"
      class="fileViewDiv"
      :chunkResource="chunkResource"
      :fileItem="fileItem"
      :chunkDialog="true"
      :qaType="qaType"
      @closeFileView="closeFileView"
      @getFileList="getFileList"
    ></fileView>
    <!-- 对话预览 -->
    <div v-if="lookDialog && lookType == 'talk'" class="talkViewDiv">
      <div class="hisBetween topTalk">
        <div class="hisCenter">
          <div class="stepBtn">{{ t("base.base320") }}</div>
          <el-tooltip
            popper-class="toolTip"
            
            :content="sessionInfo.sessionName"
          >
            <div class="talkTitle">
              {{
                sessionInfo.sessionName.length > 16
                  ? sessionInfo.sessionName.slice(0, 16) + "..."
                  : sessionInfo.sessionName
              }}
            </div>
          </el-tooltip>
          <img :src="topTime" alt="" class="itemTime" />
          <div class="itemFont20s">
            {{ getYear(sessionInfo.sessionCreateTime) }}
          </div>
          <img :src="topYes" alt="" class="itemTime" />
          <div class="itemFont20s">
            {{
              sessionInfo.expireDate == null
                ? t("base.base176")
                : getYear(sessionInfo.expireDate)
            }}
          </div>
          <img
            :src="hotPng"
            alt=""
            class="wid16"
            v-if="sessionInfo.result != 0 && sessionInfo.result != null"
          />
          <div
            class="topicItem"
            v-if="sessionInfo.result != 0 && sessionInfo.result != null"
          >
            {{ sessionInfo.result }}
          </div>
        </div>
        <img
          :src="talkCloseSvg"
          class="talkCloseSvg"
          alt=""
          @click="closeTalk"
        />
      </div>
      <div class="talkQaDiv">
        <qaIndex
          v-for="(item, index) in talkList"
          :key="index"
          type="chat"
          :item="item"
          :id="'talk' + item.conversationId"
          @openFileView="qaOpenFileView"
          @openTalk="openTalk"
        >
        </qaIndex>
      </div>
    </div>
    <!-- 锚点提醒 -->
    <tipView
      v-if="cdDialog"
      :title="t('base.base188')"
      :tip="t('base.base189')"
      :cancel="t('base.base113')"
      :ok="t('base.base190')"
      @cancel="closeCt"
      @ok="gogogo(1)"
    ></tipView>
  </div>
</template>

<script setup>
import talkCloseSvg from "@/assets/chat/talkClose.svg";
import topTime from "@/assets/index/time.svg";
import topYes from "@/assets/index/yes.svg";
import hotPng from "@/assets/header/hot.svg";
import sqLeftSvg from "@/assets/chat/sqLeft.svg";
import sqRightSvg from "@/assets/chat/sqRight.svg";
import qaIndex from "@/components/qa/index.vue";
import fvyzImg from "@/assets/share/fbyzView.svg";
import fhbj from "@/assets/share/fhbj.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import fileView from "@/components/fileView/index.vue";
import shareQa from "@/components/qa/shareQa.vue";
import tipView from "@/components/tipDialog/index.vue";
import messageView from "@/components/message/index.vue";
import { ref, onMounted, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import api from "@/api";
import { getYear } from "@/utils/utils";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const rightType = ref(false);
const containerRef = ref();
const routerType = ref();
const userId = ref(store.state.userId);
const yzItem = ref(store.state.yzItem);
const topicList = ref([]); //渲染列表用
const topicItem = ref(store.state.topicItem);
const fileItem = ref(); //文件详情
const lookDialog = ref(false); //文件弹出
const cdDialog = ref(false);
const chunkResource = ref([]);
const talkList = ref([]);
const lookType = ref("file"); //根据file 和talk判断要展示什么
const sessionInfo = ref();
const qaType = ref(false);
onMounted(() => {
  routerType.value = route.query.type;
  if (routerType.value == "edit") {
    // 验证进入
    getDesc(1);
  } else {
    // 预览
    getTopic(1);
  }
});
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.yzItem;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = store.state.topicItem;
  }
);
const getFileList = () => {
  routerType.value = route.query.type;
  if (routerType.value == "edit") {
    // 验证进入
    getDesc(0);
  } else {
    // 预览
    getTopic(0);
  }
};
const closeTalk = () => {
  lookDialog.value = false;
};
// 打开话题
const openTalk = (item) => {
  console.log(item);
  if (item.readable == 0) {
    message.warning(t("base.base321"));
    return;
  }
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: item.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.conversationInfo.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
            item.tipDialog = false;
            item.editType = false;
            item.loading = false;
          });
        }
        talkList.value = res.data.conversationInfo;
        sessionInfo.value = res.data.sessionInfo;
        rightType.value = false;
        lookDialog.value = true;
        lookType.value = "talk";
      }
    });
};
// 放弃冲突
const closeCt = () => {
  cdDialog.value = false;
  router.push("/share");
};
// 发布
const gogogo = (isOver) => {
  api.share
    .verifyPublish({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      libraryId: yzItem.value.libraryId,
      isOver: isOver,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        cdDialog.value = false;
        message.success(t("base.base181"));
        router.push("/share");
      } else if (res.returnCode == 201) {
        cdDialog.value = true;
      }
    });
};
// 加减页数
const changeItemPage = (type, item) => {
  if (type && item.chatActive < item.answers.length) {
    item.chatActive++;
  }
  if (!type && item.chatActive > 1) {
    item.chatActive--;
  }
  nextTick(() => {
    scrollBottom();
  });
};
// 获取数据库条数
const getTopic = () => {
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.conversationInfo.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
          });
        }
        topicList.value = res.data.conversationInfo;
        if (number == 1) {
          rightType.value = topicList.value.length > 1 ? true : false;
        } else {
          rightType.value = false;
        }
        nextTick(() => {
          scrollBottom();
        });
      }
    });
};
const scrollBottom = () => {
  nextTick(() => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  });
};
// 获取详情
const getDesc = (number) => {
  api.share
    .recordAll({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId, //4,//12,
      libraryId: yzItem.value.libraryId, //必须
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          if (item.mySaveData.status == 3) {
            item.mySaveData.question = t("login.login41");
            item.oldData.question = t("login.login41");
          }
          // item.question = t('login.login41')
        });
        // res.data = res.data.filter(item => item.mySaveData.status !== 3);
        topicList.value = res.data;
        if (number == 1) {
          rightType.value = true;
        } else {
          rightType.value = false;
        }
      }
    });
};
// 打开文件预览
const qaOpenFileView = (item, list) => {
  qaType.value = true;
  fileItem.value = item;
  chunkResource.value = list;
  rightType.value = false;
  lookType.value = "file";
  lookDialog.value = true;
};
// 打开文件预览
const openFileView = (item, list) => {
  qaType.value = false;
  fileItem.value = item;
  chunkResource.value = list;
  rightType.value = false;
  lookType.value = "file";
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 返回编辑
const goEdit = () => {
  router.push({
    name: "shareDetails",
    query: {
      type: "edit",
    },
  });
};
</script>

<style lang="scss" scoped>
.shareView {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff75;
  padding: 30px;
  position: relative;
}
.fileIndex {
  width: 467px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  margin-right: 20px;
}
.lookDiv {
  background-color: #767bfa;
  padding: 3px 11px;
  color: #ffffff;
  border-radius: 16px;
  font-size: 18px;
  font-family: "Semibold";
  position: absolute;
  top: 30px;
  left: 30px;
  // box-shadow: 20px 20px 20px 0px #ffffff;
  z-index: 10;
}
.chatContent {
  width: 100%;
  height: 100%;
  display: flex;
}
.width1010 {
  min-width: 1010px;
  width: calc(100% - 530px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
  padding: 0px 10px;
}
.width10101 {
  min-width: 1010px;
  width: calc(100% - 511px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
  padding: 0px 10px;
}

.width10103 {
  width: calc(100% - 80px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
  overflow: auto;
  padding: 0px 10px;
}
.rightIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rightIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid18 {
  width: 18px;
}
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: all 0.5s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.expand-transition-enter-to,
.expand-transition-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 330px;
  height: calc(100% - 60px);
}
.flexRightCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.flexRightEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
}
.marginRight8 {
  margin-right: 8px;
}
.wid25 {
  width: 25px;
}
.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}
.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid20 {
  width: 20px;
}
.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 84px);
  overflow: auto;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillColor {
  color: #d9d9d9;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.messageView {
  margin-bottom: 52px;
}
:deep(.el-anchor__link) {
  font-family: "Regular";
  font-size: 16px !important;
  margin-bottom: 14px;
}
:deep(.el-anchor .el-anchor__list .el-anchor__item a) {
  font-family: "Regular";
  font-size: 16px !important;
  margin-bottom: 14px;
}
.talkViewDiv {
  width: calc(100% - 607px);
  height: calc(100% - 60px);
  border-radius: 30px;
  overflow: auto;
  background-color: #fefefe;
  padding: 30px;
}
.leftqaTitle {
  position: absolute;
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  top: 30px;
  left: 30px;
  box-shadow: 20px 20px 20px 0px #ffffff;
  z-index: 199;
}
.leftqaBtn {
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  margin-right: 12px;
}
.leftqaTitles {
  position: absolute;
  top: 30px;
  left: 30px;
  // box-shadow: 20px 20px 20px 0px #ffffff;
  display: flex;
  align-items: center;
  z-index: 9;
}
.exportImg {
  width: 17px;
}
.exportImgDiv {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(255 255 255 / 80%);
}
.qaIndex {
  margin-bottom: 43px;
}
.qaIndexOp {
  opacity: 0.5;
}
.anchorImg {
  width: 18px;
}
.anchorImgDiv {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  right: 30px;
  bottom: 30px;
  box-shadow: -20px 0px 24px 0px #fefefe;
}
.anchorHover {
  width: 100%;
  padding-left: 10px;
  border-left: 4px solid #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.hrefNoac {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 16px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefNoac::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #8a8a8a;
  position: absolute;
  left: -13px;
}
.hrefAc {
  color: #0256ff;
  font-family: "Regular";
  font-size: 16px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefAc::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #0256ff;
  position: absolute;
  left: -13px;
}
.bottom20 {
  margin-bottom: 20px;
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d !important;
  padding-left: 10px;
  max-width: 400px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.stepBtn {
  background-color: #767bfa;
  height: 31px;
  text-align: center;
  line-height: 31px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Semibold";
  padding: 0px 15px;
}
.itemFont20s {
  font-size: 18px;
  font-family: "Regular";
  color: #8a8a8a;
  margin-right: 15px;
  max-width: 185px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemTime {
  width: 18px;
  margin: 0px 10px 0px 0px;
}
.talkTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin: 0px 12px;
}
.talkQaDiv {
  height: calc(100% - 60px);
  overflow: auto;
}
.talkCloseSvg {
  width: 36px;
  cursor: pointer;
}
.topTalk {
  height: 40px;
  margin-bottom: 20px;
}
.hisCenter {
  display: flex;
  align-items: center;
}

.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wid16 {
  width: 16px;
}
.topicItem {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin: 0px 12px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<script setup>
import tableUser from "../components/resoureTable.vue";
import resoureDetail from "../components/resoureDetail.vue";
import resoureEdit from "../components/resoureEdit.vue";
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { ElMessageBox } from "element-plus";
const { t } = useI18n();
onMounted(() => {
  getTableList();
  getTableData();
});
const loading = ref(false);
const store = useStore();
const userId = ref(store.state.userId);
// 导航条切换
const activeTab = ref("dashboard");
const tabs = [
  {
    name: "dashboard",
    label: t("manage.resoureForm.resoure1"),
    contentKey: "User",
  },
  {
    name: "unit",
    label: t("manage.resoureForm.resoure2"),
    contentKey: "Department",
  },
];
function setActiveTab(name) {
  activeTab.value = name;
}
// 部门组收缩
const isAsideVisible = ref(true);
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value;
};
// 左侧部门菜单
const defaultProps = ref({
  children: "children",
  label: "label",
});
const listForm = ref({
  userId: userId,
  departmentName: "",
});
// 部门组列表
const tableName = ref(""); //部门名称
const tableId = ref(null); //部门ID
const data = ref([]);
watch(
  () => listForm.value.departmentName,
  (val) => {
    listForm.value.departmentName = val;
    getTableList();
  }
);
const getTableList = () => {
  loading.value = true;
  api.manage.getGroupList(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      data.value = res.data;
      if (res.data.length == 1) {
        tableId.value = res.data[0]?.departmentId; //默认选中第一个部门ID
        tableName.value = res.data[0]?.departmentName; //默认选中第一个部门名称
      }
      loading.value = false;
    }
  });
};
// 部门树点击
const currentNodeId = ref(0);
const handleNodeClick = (data) => {
  currentNodeId.value = data.departmentId;
  tableId.value = data.departmentId;
  tableName.value = data.departmentName;
};
// 资源下拉
const status = ref("全部");
const groupIdsValue = ref([]);
const statusList = ref([
  {
    value: -1,
    label: "全部",
  },
  {
    value: 2,
    label: "阿里",
  },
  {
    value: 1,
    label: "baidu",
  },
  {
    value: 0,
    label: "Azure",
  },
]);
// 筛选条件
const statusBtn = (item) => {
  listTable.value.resourceType = item;
  getTableData();
};
// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const tableList = ref({
  pageId: currentPage.value,
  perPage: pageSize.value,
  userId: userId.value,
});
const tableDepartment = ref([]);
const totalItems = ref(0);
const getTableData = () => {
  api.manage.getWhiteListApi(tableList.value).then((res) => {
    if (res.returnCode == 200) {
      tableDepartment.value = res.data;
      totalItems.value = res.totalNums;
    }
  });
};
// 分页操作
const updatePageSize = (val) => {
  pageSize.value = val;
};
const updateCurrentPage = (val) => {
  currentPage.value = val;
};
watch(
  () => pageSize.value,
  (newVal) => {
    tableList.value.perPage = newVal;
    getTableData();
  }
);
watch(
  () => currentPage.value,
  (newVal) => {
    tableList.value.pageId = newVal;
    getTableData();
  }
);
const openShow = ref(false);
const resoureInfo = ref({ groups: [], resourceInfo: [] });
const rowRuleId = ref(null);
// 操作
const handleDetail = (row) => {
  rowRuleId.value = row.ruleId;
  api.manage
    .getSingleWhiteListApi(
      {
        userId: userId.value,
      },
      row.ruleId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        openShow.value = true;
        resoureInfo.value = res.data;
      }
    });
};
// 关闭详情
const handleCancel = () => {
  openShow.value = false;
};
const formType = ref("add");
const showEdit = ref(false);
// 打开编辑
const handleAdd = () => {
  openShow.value = false;
  showEdit.value = true;
  formType.value = "edit";
};
// 编辑关闭
const closeEdit = () => {
  showEdit.value = false;
  getTableData();
};
// 编辑确定
const submitEdit = (form) => {
  showEdit.value = false;
  api.manage.editWhiteListApi(form, rowRuleId.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.resoureForm.resoure3"));
      getTableData();
    }
  });
};
// 新增白名单
const addWhiteBtn = () => {
  openShow.value = false;
  showEdit.value = true;
  formType.value = "add";
  resoureInfo.value = {
    groups: [],
    resourceInfo: [],
  };
};
// 新增确定
const submitAdd = (form) => {
  showEdit.value = false;
  api.manage.addWhiteListApi(form).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.resoureForm.resoure4"));
      getTableData();
    }
  });
};
const userIdList = ref([]);
const roleId = ref(null);
const showAddRole = ref(false);
const addDepartment = (row) => {
  groupIdsValue.value = row.groupIds;
  roleId.value = row.ruleId;
  showAddRole.value = true;
};
// 确定添加群组
const saveUser = (arr) => {
  showAddRole.value = false;
  api.manage
    .addWhiteListGroupApi(
      {
        userId: userId.value,
        groupIds: arr,
      },
      roleId.value
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.resoureForm.resoure5"));
        getTableData();
      }
    });
};
// 更多操作
const changeMore = (key, item) => {
  if (key === 1) {
    rowRuleId.value = item.ruleId;
    item.groupIds = item.groups.map((item) => item.groupId);
    resoureInfo.value = JSON.parse(JSON.stringify(item));
    formType.value = "edit";
    showEdit.value = true;
  } else if (key === 2) {
    api.manage
      .delWhiteListApi({
        ruleIds: [item.ruleId],
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.resoureForm.resoure6"));
          getTableData();
        }
      });
  } else if (key === 3) {
    api.manage
      .allWhiteListApi({
        ruleIds: [item.ruleId],
        isActive: 0,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.resoureForm.resoure7"));
          getTableData();
        }
      });
  } else if (key === 4) {
    api.manage
      .allWhiteListApi({
        ruleIds: [item.ruleId],
        isActive: 1,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.resoureForm.resoure8"));
          getTableData();
        }
      });
  }
};
// 白名单列表--多选
const ids = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val.map((item) => item.ruleId);
};
// 批量禁用
const allDisabled = () => {
  if (ids.value.length == 0) {
    message.warning(t("manage.resoureForm.resoure9"));
    return;
  }
  ElMessageBox.confirm(
    t("manage.resoureForm.resoure10"),
    t("manage.resoureForm.resoure11"),
    {
      confirmButtonText: t("manage.resoureForm.resoure12"),
      cancelButtonText: t("manage.resoureForm.resoure13"),
      type: "warning",
    }
  ).then(() => {
    api.manage
      .allWhiteListApi({
        ruleIds: ids.value,
        isActive: 0,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.resoureForm.resoure14"));
          getTableData();
        } else {
          message.error(t("manage.resoureForm.resoure15"));
        }
      });
  });
};
// 批量删除
const allDelete = () => {
  if (ids.value.length == 0) {
    message.warning(t("manage.resoureForm.resoure16"));
    return;
  }
  ElMessageBox.confirm(
    t("manage.resoureForm.resoure17"),
    t("manage.resoureForm.resoure11"),
    {
      confirmButtonText: t("manage.resoureForm.resoure12"),
      cancelButtonText: t("manage.resoureForm.resoure13"),
      type: "warning",
    }
  ).then(() => {
    api.manage
      .delWhiteListApi({
        ruleIds: ids.value,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.resoureForm.resoure18"));
          getTableData();
        } else {
          message.error(t("manage.resoureForm.resoure19"));
        }
      });
  });
};
</script>

<template>
  <div class="main">
    <div
      style="position: relative; display: flex; justify-content: space-between"
    >
      <div class="tabs">
        <div
          class="tab"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab === tab.name }"
          @click="setActiveTab(tab.name)"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="tip">
        <div class="tipSpan">*</div>
        <div>{{ t("manage.resoureForm.resoure20") }}</div>
      </div>
    </div>
    <div
      v-if="activeTab === 'dashboard'"
      class="heia"
    >
      <el-container>
        <div class="container">
          <el-aside class="side" v-if="isAsideVisible" v-loading="loading">
            <el-tree
              ref="treeRef"
              :data="data"
              node-key="departmentId"
              :props="defaultProps"
              default-expand-all
              @node-click="handleNodeClick"
              highlight-current
              :current-node-key="tableId"
            >
              <template #default="{ data }">
                <span class="custom-tree-node">
                  <div class="admin-name">
                    <img src="@/assets/manage/admin.svg" alt="" class="admin" />
                    <el-tooltip :content="data.departmentName">
                      <span class="admin-text">{{ data.departmentName }}</span>
                    </el-tooltip>
                  </div>
                </span>
              </template>
            </el-tree>
          </el-aside>
          <div @click="toggleAside" class="toggleAside">
            <el-icon class="tagIcon">
              <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
            </el-icon>
          </div>
        </div>
        <el-main class="userMain">
          <tableUser :tableId="tableId" :tableName="tableName" />
        </el-main>
      </el-container>
    </div>
    <div
      v-if="activeTab === 'unit'"
      class="heia"
    >
      <div class="search">
        <div class="searchLeft">
          <!-- 资源平台 -->
          <!-- <div class="searchItem">
            <el-select v-model="status" clearable @change="statusBtn">
              <template #label="{ label, value }">
                <div style="display: flex; justify-content: space-between;">
                  <div>资源平台： </div>
                  <div>{{ label }}</div>
                </div>
              </template>
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div> -->
        </div>
        <!-- 功能区 -->
        <div style="display: flex">
          <el-button
            :disabled="$hasPermission('10033') == false"
            class="toolBox"
            style="color: #f02d63"
            @click="allDisabled"
          >
            {{ $t("manage.resoureForm.resoure21") }}
          </el-button>
          <el-button
            class="toolBox"
            :disabled="$hasPermission('10032') == false"
            style="color: #f02d63"
            @click="allDelete"
          >
            {{ $t("manage.resoureForm.resoure22") }}
          </el-button>
          <el-button
            class="toolBox addUser"
            @click="addWhiteBtn"
            :disabled="$hasPermission('10030') == false"
          >
            {{ $t("manage.resoureForm.resoure23") }}
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableDepartment"
        @selection-change="handleSelectionChange"
        class="tableHeight"
      >
        <el-table-column type="selection" />
        <el-table-column
          fixed
          prop="name"
          :label="$t('manage.resoureForm.resoure24')"
        />
        <el-table-column
          fixed
          prop="groups"
          :label="$t('manage.resoureForm.resoure25')"
        >
          <template #default="scope">
            <avatarGroupName
              :avatars="scope.row.groups"
              :number="5"
            ></avatarGroupName>
          </template>
        </el-table-column>
        <el-table-column
          fixed
          prop="resourceInfo"
          :label="$t('manage.resoureForm.resoure29')"
        >
          <template #default="scope">
            <baseName :avatars="scope.row?.resourceInfo" :number="2"></baseName>
          </template>
        </el-table-column>
        <el-table-column :label="$t('manage.resoureForm.resoure27')">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              :disabled="$hasPermission('10064') == false"
              @click="handleDetail(scope.row)"
            >
              {{ $t("manage.resoureForm.resoure26") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="addDepartment(scope.row)"
              :disabled="$hasPermission('10030') == false"
            >
              {{ $t("manage.resoureForm.resoure28") }}
            </el-button>
            <el-dropdown
              @command="changeMore($event, scope.row)"
              trigger="click"
              class="changeMore"
            >
              <div class="moreIcon">
                <img src="@/assets/base/more.svg" alt="" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :disabled="$hasPermission('10031') == false"
                    :command="1"
                  >
                    <span class="dropText">{{
                      $t("manage.resoureForm.resoure92")
                    }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="2"
                    :disabled="$hasPermission('10032') == false"
                  >
                    <span class="dropText">{{
                      $t("manage.resoureForm.resoure31")
                    }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="3"
                    :disabled="$hasPermission('10033') == false"
                    v-if="scope.row.isActive == 1"
                  >
                    <span class="dropText">{{
                      $t("manage.resoureForm.resoure32")
                    }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="4"
                    :disabled="$hasPermission('10033') == false"
                    v-if="scope.row.isActive == 0"
                  >
                    <span class="dropText">{{
                      $t("manage.resoureForm.resoure33")
                    }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :currentPage="currentPage"
        :pageSize="pageSize"
        :total="totalItems"
        @size-change="updatePageSize"
        @current-change="updateCurrentPage"
        class="pagination"
      />
    </div>
  </div>
  <!-- 白名单详情 -->
  <resoureDetail
    :openShow="openShow"
    :resoureInfo="resoureInfo"
    @handleCancel="handleCancel"
    @handleAdd="handleAdd"
  >
  </resoureDetail>
  <!-- 白名单编辑 -->
  <resoureEdit
    v-if="showEdit"
    :showEdit="showEdit"
    :formType="formType"
    :resoureInfo="resoureInfo"
    @closeEdit="closeEdit"
    @submitEdit="submitEdit"
    @submitAdd="submitAdd"
  ></resoureEdit>
  <!-- 给白名单增加组 -->
  <resoureGroup
    v-if="showAddRole"
    :show="showAddRole"
    :roleId="roleId"
    :groupIdsValue="groupIdsValue"
    @closeUser="showAddRole = false"
    @saveUser="saveUser"
  >
  </resoureGroup>
</template>

<style lang="scss" scoped>
@use "./resoure.scss" as resoure;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// :deep(.el-tree-node) {
//     width: 225px;
//     display: table;
// }
.search {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 48px;
    width: 160px !important;
    font-size: 16px !important;
  }
}

:deep(.el-tree) {
  font-size: 18px;
  background-color: #f7f8ff;
  color: #3d3d3d;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-container) {
  height: 100% !important;
}

.side {
  :deep(.el-tree) {
    background-color: #f7f8ff;
    color: #3d3d3d;
  }

  :deep(.el-tree-node__content) {
    height: 50px;
    border-radius: 16px !important;

    &:hover {
      background-color: #eceeff;
    }
  }

  :deep(.custom-tree-node) {
    font-size: 16px;
  }
}
.heia {
  height: calc(100% - 65px);
}
.dropText {
  font-size: 16px;
  font-family: "Regular";
  color: #3d3d3d;
  padding-left:10px
}
</style>

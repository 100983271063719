export default {
    CN: {
        // ... 其他部分保持不变
        login1: '企业租户',
        login2: '登录NextAI',
        login3: 'SSO登录',
        login4: '找回密码',
        login5: '激活',
        login6: '返回',
        login7: '请输入您的账号',
        login8: '请输入您的密码',
        login9: '登录',
        login10: '请输入您的企业邮箱账号',
        login11: '下一步',
        login12: '请输入NextAI发送的验证码',
        login13: '重新发送',
        login14: '剩余',
        login15: '秒',
        login16: '再次输入新密码',
        login17: '完成',
        login18: '请输入您的激活码',
        login19: '我已阅读并同意',
        login20: '免责声明',
        login21: '前往激活',
        login22: '更多登录方式',
        login23: '发送成功',
        login24: '密码长度不能少于6位',
        login25: '两次密码不一致',
        login26: '重置密码成功',
        login27: '激活成功',
        login28: '登录成功',
        login29: 'NextAI平台服务协议',
        login30: '我已阅读',
        login31: '请先阅读并勾选免责声明',
        login32: '今日剩余资源中展示你所在资源组中的默认资源用量，你可以通过你所在部门信息，向资源管理员询问你所在的资源组及默认资源配置。',
        login33: '文本生成模型',
        login34: '回复模式',
        login35: '回复令牌消耗',
        login36: '提示词令牌消耗',
        login37: '用户提问令牌消耗',
        login38: '联网检索事件',
        login39: '时间',
        login40: '话题',
        login41: '该问答已被否决不可查看',
        login42: '正在上传中...',
        login43: '文档数量:',
        login44: '对话数量:',
        login45: '成员数量:',
        login46: '知识库详情',
        login47: '对象存储应用范围',
        login48: '为了确保使用体验，NextAI平台会在必要时使用您配置的对象存储缓存您的资源，资源平台会对对象存储的存入和下载/查看操作收取费用。您可以选择我们在何处应用对象存储（AI图像生成为必选）。',
        login49: '存储',
        login50: '已结束对话,开始新对话',
        login51: '结束上下文',
        login52: '请先选择知识库',
        login53: '取消',
        login54: '存储成功',
        login55: 'AI智能对话',
        login56: 'AI知识库',
        login57: 'AI图像生成',
        login58: '第三方登录URL',
        login59: '请输入',
        login60: '开启SSO登录成功',
        login61: '关闭SSO登录成功',
        login62: '暂无SSO登陆地址',
        login63: '系统检测到您负责的部门资源权限发生变化，不符合该知识库当前的资源平台配置，请联系知识库拥有者处理该冲突，或联系资源管理员授予您所需资源权限。',
        login64: '暂无资源',
        login65: '正在输出信息请稍等',
        login66: '正在输出信息，确认离开页面？',
        login67: '部门名称长度不能超过20字符',
        login68: '资源组名称长度不能超过20字符',
        login69: '资源ID长度不能超过255字符',
        login70: '配置名称长度不能超过20字符',
        login71: '白名单名称长度不能超过20字符',
        login72: '白名单描述长度不能超过100字符',
        login73: '精准算法：利用HyDE嵌入算法生成精准拟合的上下文，专注于高效检索与问题密切相关的信息，适合事实性问答和明确目标查询。',
        login74: '全面算法：通过假设查询嵌入算法，扩大检索范围，全面召回相关上下文，适用于探索性问题或主题广泛的信息收集场景。',
        login75: '深度算法：拆解问题为子问题，多轮推理总结答案，逐步深入生成完整回答，适合处理复杂逻辑推导和多阶段问题场景。',
        login76: '用户席位冲突',
        login77: '系统检测到当前您的企业租户「',
        login78: '」下已启用用户席位（',
        login79: '席位）超过可用用户席位（',
        login80: '席位）， 请将启用用户减至',
        login81: '不超过可用用户席位',
        login82: '，否则您的企业租户下所有成员将无法正常登录使用NextAI平台。',
        login83: '用户列表',
        login84: '进入系统',
        login85: '还需禁用',
        login86: '个用户',
        login87: '热门',
        login88: '权威',
    },
    HK: {
        login1: '企业租戶',
        login2: '登入NextAI',
        login3: 'SSO登入',
        login4: '找回密碼',
        login5: '啟動',
        login6: '返回',
        login7: '請輸入您的帳號',
        login8: '請輸入您的密碼',
        login9: '登入',
        login10: '請輸入您的企業郵箱帳號',
        login11: '下一步',
        login12: '請輸入NextAI發送的驗證碼',
        login13: '重新發送',
        login14: '剩餘',
        login15: '秒',
        login16: '再次輸入新密碼',
        login17: '完成',
        login18: '請輸入您的啟動碼',
        login19: '我已閱讀並同意',
        login20: '免责声明',
        login21: '前往啟動',
        login22: '更多登入方式',
        login23: '發送成功',
        login24: '密碼長度不能少於6位',
        login25: '兩次密碼不一致',
        login26: '重置密碼成功',
        login27: '啟動成功',
        login28: '登入成功',
        login29: 'NextAI平台服務協議',
        login30: '我已閱讀',
        login31: '請先閱讀並勾選免责声明',
        login32: '今日剩餘資源中展示你所在資源組中的默認資源用量，你可以通過你所在部門資訊，向資源管理員詢問你所在的資源組及默認資源配置。',
        login33: '文本生成模型',
        login34: '回復模式',
        login35: '回復令牌消耗',
        login36: '提示詞令牌消耗',
        login37: '用戶提問令牌消耗',
        login38: '聯網檢索事件',
        login39: '時間',
        login40: '話題',
        login41: '該問答已被否決不可查看',
        login42: '正在上傳中...',
        login43: '文件數量:',
        login44: '對話數量:',
        login45: '成員數量:',
        login46: '知識庫詳情',
        login47: '物件存儲應用範圍',
        login48: '為了確保使用體驗，NextAI平臺會在必要時使用您配置的物件存儲緩存您的資源，資源平臺會對物件存儲的存入和下載/查看操作收取費用。 您可以選擇我們在何處應用物件存儲（AI圖像生成為必選）。 ',
        login49: '存儲',
        login50: '已結束對話，開始新對話',
        login51: '結束上下文',
        login52: '請先選擇知識庫',
        login53: '取消',
        login54: '存儲成功',
        login55: 'AI智能對話',
        login56: 'AI知識庫',
        login57: 'AI圖像生成',
        login58: '第三方登錄URL',
        login59: '請輸入',
        login60: '開啟SSO登錄成功',
        login61: '關閉SSO登錄成功',
        login62: '暫無SSO登陸位址',
        login63: '系統檢測到您負責的部門資源許可權發生變化，不符合該知識庫當前的資源平臺配置，請聯繫知識庫擁有者處理該衝突，或聯繫資源管理員授予您所需資源許可權。',
        login64: '暫無資源',
        login65: '正在輸出資訊請稍等',
        login66: '正在輸出資訊，確認離開頁面？',
        login67: '部門名稱長度不能超過20字符',
        login68: '資源組名稱長度不能超過20字符',
        login69: '資源ID長度不能超過255字符',
        login70: '配置名稱長度不能超過20字符',
        login71: '白名單名稱長度不能超過20字符',
        login72: '白名單描述長度不能超過100字符',
        login73: '精確算法：利用HyDE嵌入算法生成精確擬合的上下文，專注於高效檢索與問題密切相關的信息，適合事實性問答和明確目標查詢。',
        login74: '全面算法：通過假設查詢嵌入算法，擴大檢索範圍，全面召回相關上下文，適用於探索性問題或主題廣泛的信息收集場景。',
        login75: '深度算法：拆解問題為子問題，多輪推理總結答案，逐步深入生成完整回答，適合處理複雜邏輯推導和多階段問題場景。',
        login76: '『使用者席位衝突』',
        login77: '系統檢測到當前您的企業租戶「',
        login78: '」下已啟用使用者席位（',
        login79: '席位）超過可用使用者席位（',
        login80: '席位）， 請將啟用使用者減至',
        login81: '『不超過可用使用者席位』',
        login82: '，否則您的企業租戶下所有成員將無法正常登錄使用NextAI平臺。 ',
        login83: '用戶清單',
        login84: '進入系統',
        login85: '還需禁用',
        login86: '個用戶',
        login87: '熱門',
        login88: '權威',
    },
    US: {
        login1: 'Enterprise Tenant',
        login2: 'Log in to NextAI',
        login3: 'SSO Login',
        login4: 'Retrieve Password',
        login5: 'Activate',
        login6: 'Return',
        login7: 'Please enter your account',
        login8: 'Please enter your password',
        login9: 'Login',
        login10: 'Please enter your enterprise email account',
        login11: 'Next Step',
        login12: 'Please enter the verification code sent by NextAI',
        login13: 'Resend',
        login14: 'Remaining',
        login15: 'seconds',
        login16: 'Re-enter the new password',
        login17: 'Complete',
        login18: 'Please enter your activation code',
        login19: 'I have read and agree to',
        login20: 'Disclaimer',
        login21: 'Go to Activate',
        login22: 'More Login Methods',
        login23: 'Sent Successfully',
        login24: 'Password length must be at least 6 characters',
        login25: 'The two passwords do not match',
        login26: 'Password Reset Successful',
        login27: 'Activation Successful',
        login28: 'Login Successful',
        login29: 'NextAI Platform Service Agreement',
        login30: 'I have read',
        login31: 'Please read and check the Disclaimer first',
        login32: "Today's remaining resources display the default resource usage in your resource group. You can inquire about your resource group and default resource configuration by using your department information with the resource administrator.",
        login33: 'LLM model',
        login34: 'response mode',
        login35: 'response token/s',
        login36: 'query token/s',
        login37: 'prompt token/s',
        login38: 'internet search event/s',
        login39: 'time',
        login40: 'Topic',
        login41: 'The Q&A has been denied and is not viewable',
        login42: 'Upgrading...',
        login43: 'Number of Documents:',
        login44: 'Number of conversations.:',
        login45: 'Number of members:',
        login46: 'knowledge base details',
        login47: 'Object storage applications',
        login48: 'To ensure the user experience, the NextAI platform caches your resources using the object storage you configure when necessary, and the resource platform charges for the storage and download/view operations of the object storage. You can choose where we apply object storage (AI image generation is mandatory). ',
        login49: 'shelf',
        login50: 'The conversation has been closed and a new conversation has been started',
        login51: 'End context',
        login52: 'Please select the knowledge base first',
        login53: 'cancel',
        login54: 'Storage successful',
        login55: 'AI intelligent dialogue',
        login56: 'AI knowledge base',
        login57: 'AI image generation',
        login58: 'Third-party login URL',
        login59: 'Please enter',
        login60: 'Successful login with SSO enabled',
        login61: 'Successful login with SSO disabled',
        login62: 'There is no SSO login address',
        login63: 'If the system detects that the resource permissions of the department you are responsible for have changed and do not conform to the current resource platform configuration of the knowledge base, contact the knowledge base owner to handle the conflict, or contact the resource administrator to grant you the required resource permissions.',
        login64: 'No resources are available',
        login65: 'Exporting information, please wait!',
        login66: 'Exporting information, confirm that you are leaving the page?',
        login67: 'Department name cannot exceed 20 characters in length',
        login68: 'The length of the resource group name cannot exceed 20 characters',
        login69: 'Resource ID cannot exceed 255 characters',
        login70: 'The length of the configuration name cannot exceed 20 characters',
        login71: 'The whitelist name cannot exceed 20 characters',
        login72: 'The length of the whitelist description cannot exceed 100 characters',
        login73: 'Precision Algorithm:HyDE embedding algorithm is used to generate precisely fitting contexts, focusing on efficient retrieval of information that is closely related to the question, suitable for factual question answering and explicit target queries. ',
        login74: 'Comprehensive Algorithm:Broaden the search scope and comprehensively recall relevant context by embedding algorithms with hypothetical queries, suitable for exploratory questions or information gathering scenarios with a wide range of topics. ',
        login75: 'Depth Algorithm:Dismantling a problem as a sub-problem, summarizing the answer in multiple rounds of reasoning, and gradually generating a complete answer in depth, is suitable for dealing with complex logical derivation and multi-stage problem scenarios. ',
        login76: 'User seat conflict',
        login77: 'The system has detected your current enterprise tenant',
        login78: 'User seats are enabled (',
        login79: 'seats) exceed available user seats (',
        login80: 'seat), please reduce the enabled user to ',
        login81: 'No more than available user seats',
        login82: ', otherwise all members of your enterprise tenant will not be able to log in to the NextAI platform. ',
        login83: 'user list',
        login84: 'Enter system',
        login85: 'It is still necessary to disable',
        login86: 'the user',
        login87: 'Hot',
        login88: 'Authority',
    },
};
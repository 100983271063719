<template>
  <div class="qwDialog" @click.stop="">
    <div class="header">
      <div class="qwTitle">{{ t("publish.index6") }}</div>
      <img :src="addClose" alt="" class="addClose" @click="closeQw" />
    </div>
    <div class="diaTitle">
      {{ props.type == "base" ? t("publish.index53") : t("publish.index7") }}
    </div>
    <div class="publishDiv">
      <div class="inputTitle">
        {{ t("publish.index8") }}<span class="red">*</span>
      </div>
      <div class="divInput">
        <div class="urlText">
          {{ nowUrl }}/openai/publish/new/2/1000/{{
            props.configInfo.corpId ? props.configInfo.corpId + "/" : ""
          }}{{
            props.configInfo.agentId ? props.configInfo.agentId + "/" : ""
          }}callback
        </div>
        <img class="copySvg" :src="copySvg" alt="" @click="copyFn" />
      </div>
      <div class="inputTitle">Token<span class="red">*</span></div>
      <el-input
        :placeholder="t('publish.index3') + 'Token'"
        v-model="props.configInfo.token"
      ></el-input>
      <div class="inputTitle">EncodingAESKey<span class="red">*</span></div>
      <el-input
        :placeholder="t('publish.index3') + 'EncodingAESKey'"
        v-model="props.configInfo.encodingAesKey"
      ></el-input>
      <div class="inputTitle">CorpId<span class="red">*</span></div>
      <el-input
        :placeholder="t('publish.index3') + 'CorpId'"
        v-model="props.configInfo.corpId"
      ></el-input>
      <div class="inputTitle">CorpSecret<span class="red">*</span></div>
      <el-input
        :placeholder="t('publish.index3') + 'CorpSecret'"
        v-model="props.configInfo.corpSecret"
      ></el-input>
      <div class="inputTitle">AgentId<span class="red">*</span></div>
      <el-input
        :placeholder="t('publish.index3') + 'AgentId'"
        v-model="props.configInfo.agentId"
      ></el-input>
    </div>
    <div class="flexRight">
      <el-button class="cancel" @click="closeQw">{{
        t("publish.index4")
      }}</el-button>
      <el-button class="saveBtn" @click="save">{{
        t("publish.index5")
      }}</el-button>
    </div>
  </div>
  <div class="mask" @click.stop=""></div>
</template>

<script setup>
import addClose from "@/assets/base/addClose.svg";
import copySvg from "@/assets/publish/copy.svg";
import clipboard3 from "vue-clipboard3";
import { ref } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const nowUrl = ref(window.location.origin);
const { t } = useI18n();
const { toClipboard } = clipboard3();
const emit = defineEmits(["closeQw", "save"]);
const props = defineProps(["configInfo", "type"]);
const closeQw = () => {
  emit("closeQw");
};
// 复制
const copyFn = async () => {
  if (!props.configInfo.corpId) {
    message.warning(t("publish.index3") + "CorpId");
    return;
  }
  if (!props.configInfo.agentId) {
    message.warning(t("publish.index3") + "AgentId");
    return;
  }
  let url =
    nowUrl.value +
    "/openai/publish/new/2/1000/" +
    props.configInfo.corpId +
    "/" +
    props.configInfo.agentId +
    "/callback";
  await toClipboard(url); // str 为需要复制的文字
  message.success(t("base.base216"));
};
// 保存
const save = () => {
  for (const key in props.configInfo) {
    if (!props.configInfo[key]) {
      return message.warning(t("publish.index3") + key);
    }
  }
  emit("save", props.configInfo, 2);
};
</script>

<style lang="scss" scoped>
.addClose {
  width: 14px;
  cursor: pointer;
}
.qwDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  border-radius: 30px;
  padding: 30px;
  background-color: #fefefe;
  transform: translate(-50%, -50%);
  z-index: 110;
  width: 540px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 109;
  opacity: 0.2;
}
.qwTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.diaTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
  margin: 30px 0px;
}
.inputTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Medium";
  padding-left: 15px;
  padding-bottom: 17px;
}
.red {
  color: #f02d63;
  margin-left: 5px;
}
.divInput {
  border-radius: 16px;
  height: 48px;
  background-color: #edeef6;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.copySvg {
  width: 36px;
  cursor: pointer;
}
.urlText {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  width: calc(100% - 36px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-input__wrapper) {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  margin-bottom: 24px;
  font-size: 18px;
  font-family: "Regular";
}
.cancel {
  border: 2px solid #edeef6;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #f02d63;
  font-family: "Regular";
}
.saveBtn {
  border: 2px solid #3376ff;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  font-family: "Regular";
  background-color: #3376ff;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}
.publishDiv {
  max-height: 450px;
  overflow: auto;
}
</style>

<template>
    <div class="avatar-group">
        <div style="display: flex;" class="box">
            <div v-for="(avatar, index) in props.avatars" :key="index" class="avatar" v-show="index < number">
                <div class="img" >
                    {{ avatar }}
                </div>
            </div>
        </div>
        <div v-if="props.avatars?.length > number" class="moreSpan"> +{{ props.avatars.length - number }}</div>
        <div v-else></div>
    </div>
</template>

<script setup>
const props = defineProps({
    avatars: Array,
    number: Number
})
const avatars = props.avatars
const number = props.number;    
</script>
<style scoped lang="less">
.avatar-group {
    display: flex;
}

.avatar {
    margin-left: -6px;
    text-align: center;
}

.avatar .img {
    padding: 4px 8px;
    height: 30px;
    border-radius: 50%;
    color: #3D3D3D;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
}

.box {
    margin-right: 10px;
}

.moreSpan {
    margin-top: 4px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #3D3D3D;
    font-size: 14px;
}
</style>
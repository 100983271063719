<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import uploadIndex from "@/components/upload/index.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const percentage1 = ref(null);
const percentage2 = ref(null);
const customColor = ref("#11D7B2");
const loading = ref(false);
const toWeb = () => {
  window.open("https://www.qinchengsoft.com/");
};
const activeForm = ref({});
const allDays = ref(null);
const remainDays = ref(null);
const allUser = ref(null);
const remainUser = ref(null);
onMounted(() => {
  getActive();
  getLogo();
});
// 激活状态
const getActive = () => {
  loading.value = true;
  api.manage
    .getBaseStatusApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        activeForm.value = res.data;
        allDays.value = res.data.totalDays.substring(
          0,
          res.data.totalDays.indexOf(" days")
        );
        remainDays.value = res.data.remainDays.substring(
          0,
          res.data.remainDays.indexOf(" days")
        );
        allUser.value = res.data.maximunUsers;
        remainUser.value = res.data.maximunUsers - res.data.remainPeople;
        percentage2.value = parseInt((remainUser.value / allUser.value) * 100);
        percentage1.value = parseInt(
          ((allDays.value - remainDays.value) / allDays.value) * 100
        );
        loading.value = false;
      }
    });
};
// 上传图片
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      console.log(res.data);
      api.manage
        .uploadLogoApi({
          userId: userId.value,
          imageId: res.data,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success(t("manage.accrditForm.accrdit1"));
            getLogo();
          }
        });
    }
  });
};
let imgUrl = ref("");
const getLogo = () => {
  api.manage
    .getLogoApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode) {
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" });
            // 生成 URL
            imgUrl.value = URL.createObjectURL(blob);
          })
          .catch((error) => (getImgLoading.value = false));
      }
    });
};
</script>

<template>
  <div class="main" v-loading="loading">
    <div class="company">
      <div class="left" v-if="$hasPermission('10050') == true">
        <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
          <div class="upload">
            <img
              v-if="!imgUrl"
              src="@/assets/manage/QC.svg"
              alt=""
              class="uploadImg"
            />
            <img v-if="imgUrl" :src="imgUrl" alt="" class="uploadImg" />
          </div>
        </uploadIndex>
      </div>
      <div v-else class="left">
        <div class="upload">
          <img
            v-if="!imgUrl"
            src="@/assets/manage/QC.svg"
            alt=""
            class="uploadImg"
          />
          <img v-if="imgUrl" :src="imgUrl" alt="" class="uploadImg" />
        </div>
      </div>
      <div class="right">{{ activeForm.corporateName }}</div>
    </div>
    <div class="cardInfo" v-if="$hasPermission('10049') == true">
      <div class="leftInfo">
        <div class="tit">
          <div class="tit_img">
            <img src="@/assets/manage/cardTime.svg" alt="" />
          </div>
          <div class="info">
            NextAI Platform™️ {{ $t("manage.accrditForm.accrdit2") }}
          </div>
        </div>
        <div class="tag_info">
          {{ $t("manage.accrditForm.accrdit3") }}
        </div>
        <div class="tag_num">{{ allDays - remainDays }} / {{ allDays }}</div>
        <!-- 进度条 -->
        <el-progress
          class="progress"
          :percentage="percentage1"
          :color="customColor"
        />
        <div class="jump" @click="toWeb">
          {{ $t("manage.accrditForm.accrdit4") }}
        </div>
      </div>
      <div class="leftInfo">
        <div class="tit">
          <div class="tit_img">
            <img src="@/assets/manage/cardUser.svg" alt="" />
          </div>
          <div class="info">
            NextAI Platform™️ {{ $t("manage.accrditForm.accrdit5") }}
          </div>
        </div>
        <div class="tag_info">
          {{ $t("manage.accrditForm.accrdit6") }}
        </div>
        <div class="tag_num">
          {{ activeForm.maximunUsers - activeForm.remainPeople }} /
          {{ activeForm.maximunUsers }}
        </div>
        <!-- 进度条 -->
        <el-progress
          class="progress"
          :percentage="percentage2"
          :color="customColor"
        />
        <div class="jump" @click="toWeb">
          {{ $t("manage.accrditForm.accrdit4") }}
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit7") }}：</div>
        <div class="value">
          <div class="logo">
            <img src="@/assets/manage/logo_N.svg" alt="" />
          </div>
          <div class="text">
            NextAI Platform™️ {{ $t("manage.accrditForm.accrdit8") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit9") }}：</div>
        <div class="value">
          <div class="text">
            V{{ activeForm.version }}
            {{ $t("manage.accrditForm.accrdit10") }} Build 4869
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit11") }}：</div>
        <div class="value">
          <div class="text">{{ activeForm.maximunUsers }}</div>
          <div class="num">
            <span>( {{ $t("manage.accrditForm.accrdit12") }}</span>
            <span class="span">{{ activeForm.remainPeople }}</span
            ><span> {{ $t("manage.accrditForm.accrdit21") }} )</span>
          </div>
          <div class="jump" @click="toWeb">
            {{ $t("manage.accrditForm.accrdit4") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit13") }}：</div>
        <div class="value">
          <div class="text">{{ allDays }}</div>
          <div class="num">
            <span>( {{ $t("manage.accrditForm.accrdit12") }}</span>
            <span class="span">{{ remainDays }}</span
            ><span> {{ $t("manage.accrditForm.accrdit22") }} )</span>
          </div>
          <div class="jump" @click="toWeb">
            {{ $t("manage.accrditForm.accrdit4") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit14") }}：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationCode }}</div>
        </div>
      </div>
      <div class="item item1">
        <div class="key">{{ $t("manage.accrditForm.accrdit15") }}：</div>
        <div class="value">
          <!-- 启用 -->
          <div class="tag" v-if="activeForm.activationStatus == 1">
            <div class="tag-icon">
              <img src="@/assets/manage/dui.svg" alt="" />
            </div>
            <div class="tag-text">{{ $t("manage.accrditForm.accrdit16") }}</div>
          </div>
          <!-- 禁用 -->
          <div class="tag_cuo" v-else>
            <div class="tag-icon">
              <img src="@/assets/manage/cuo.svg" alt="" />
            </div>
            <div class="tag-text">{{ $t("manage.accrditForm.accrdit17") }}</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit18") }}：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationStartDate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="key">{{ $t("manage.accrditForm.accrdit19") }}：</div>
        <div class="value">
          <div class="text">{{ activeForm.activationEndDate }}</div>
        </div>
      </div>
      <div class="btn" @click="toWeb">
        {{ $t("manage.accrditForm.accrdit20") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  padding: 30px;
  background-color: #fff;
}

.item1 {
  margin-bottom: 10px !important;
}

.upload {
  cursor: pointer;
  width: 85px;
  height: 85px;
  border-radius: 12px;
  margin-right: 12px;
  .uploadImg {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}

.company {
  display: flex;
  margin-left: 15px;

  .left {
    width: 85px;
    height: 85px;
    border-radius: 12px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .right {
    font-size: 30px;
    margin-top: 27px;
    font-family: 'Bold';
  }
}

.cardInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 1220px;
  height: 270px;
  padding-left: 12px;
  padding-right: 30px;

  .leftInfo {
    width: 560px;
    height: 250px;
    border-radius: 20px;
    background-color: #f7f8ff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    font-family: 'Regular';
    .tit {
      display: flex;

      .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .info {
        font-size: 20px;
        color: #3d3d3d;
      }
    }

    .tag_info {
      margin-top: 20px;
      font-size: 18px;
      color: #8a8a8a;
    }

    .tag_num {
      font-size: 25px;
      color: #3d3d3d;
      margin-top: 9px;
    }

    .progress {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }
}

.jump {
  font-size: 16px;
  color: #3376ff;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Regular';
}

.tag {
  display: flex;
  justify-content: space-between;
  background-color: #b8e6cd;
  color: #14ae5c;
  padding: 0px 6px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  margin: auto 0;

  .tag-icon {
    width: 18px;
    height: 18px;
    display: block;
    margin-top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tag-text {
    margin-left: 4px;
  }
}

.tag_cuo {
  display: flex;
  justify-content: space-between;
  background-color: #fbd4df;
  color: #f02d63;
  padding: 0px 6px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  margin: auto 0;

  .tag-icon {
    width: 18px;
    height: 18px;
    display: block;
    margin-top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tag-text {
    margin-left: 4px;
  }
}

.introduce {
  margin-top: 20px;
  position: relative;

  .item {
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 600;
    display: flex;

    .key {
      color: #3d3d3d;
      margin-left: 10px;
      font-family: 'Bold';
    }

    .value {
      display: flex;

      .logo {
        width: 23px;
        height: 23px;
        border-radius: 8px;
        margin-right: 6px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .text {
        color: #3d3d3d;
        margin-right: 10px;
        font-family: 'Regular';
        .span {
          font-size: 17px;
          color: #f02d63 !important;
          font-weight: 600;
        }
      }

      .num {
        margin-right: 10px;
        font-family: 'Regular';
        span {
          color: #8a8a8a;
          font-size: 15px;
          font-weight: 400;
        }

        .span {
          color: #f02d63;
          font-weight: 600;
        }
      }
    }
  }
}

.btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 10px;
  height: 40px;
  border-radius: 16px;
  background-color: #3376ff;
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  font-family: 'Regular';
}
</style>

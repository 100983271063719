import axios from "../server";
export default {
    // 日志列表
    getLogs(data: any) {
        return axios({
            url: "/logger-management/logger/view",
            method: "POST",
            data,
        });
    },
    // 导出
    expotrExcel(data: any) {
        return axios({
            url: "/logger-management/logger/export",
            method: "POST",
            data,
            responseType: 'blob'
        });
    },
    // 导出
    expotrAd(data: any) {
        return axios({
            url: "/ad/metadata",
            method: "GET",
            data,
            responseType: 'blob'
        });
    },
    // 获取ip
    getIps(data: any) {
        return axios({
            url: "logger-management/logger/operationip",
            method: "POST",
            data,
        });
    },
    // 操作模块
    getLogger(data: any) {
        return axios({
            url: "logger-management/logger/operationmodule",
            method: "POST",
            data,
        });
    },
    // 操作类型
    getType(data: any) {
        return axios({
            url: "logger-management/logger/operationtype",
            method: "POST",
            data,
        });
    },
    // 获取角色
    queryUserRole(data: any) {
        return axios({
            url: "/user-management/roles/view",
            method: "POST",
            data,
        });
    },
    //    获取可添加的部门和角色
    getDepartmentName(data: any) {
        return axios({
            url: "/user-management/department/view",
            method: "POST",
            data,
        });
    },
}
<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["handleCancel", "handleAdd"]);
const props = defineProps({
  showDepartment: Boolean,
  dataDetail: Object,
});
const name = ref("部门");
const show = ref(props.showDepartment);
watch(
  () => props.showDepartment,
  (newVal) => {
    show.value = newVal;
  }
);
const dataDetail = ref(props.dataDetail);
watch(
  () => props.dataDetail,
  (newVal) => {
    dataDetail.value = newVal;
  }
);
const title = ref(t("manage.userForm.user21"));
// 关闭
const handleCancel = () => {
  show.value = !show.value;
  emit("handleCancel");
};
//同意
const handleConfirm = () => {
  show.value = !show.value;
  emit("handleAdd");
};
</script>

<template>
  <div>
    <!-- 部门详情 -->
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ title }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider class="divider" />
      <div class="content">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <div
              class="iconImg"
              :style="'background-color:' + dataDetail.color"
            >
              {{ name.split("")[0] }}
            </div>
            <div class="name">{{ dataDetail.departmentName }}</div>
          </div>
        </div>
        <el-form :model="dataDetail" class="form" label-position="top">
          <el-form-item prop="departmentName">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.user22") }}</p>
              <p class="comtent">{{ dataDetail.departmentName }}</p>
            </div>
          </el-form-item>
          <el-form-item prop="parenrtName">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.user23") }}</p>
              <div v-if="dataDetail.parenrtName == null">-</div>
              <div class="el-tag" v-else>
                <div class="tag-img">
                  <img src="@/assets/manage/qincheng.svg" alt="" />
                </div>
                <div>{{ dataDetail.parenrtName }}</div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item prop="manager">
                        <div class="form-name">
                            <p class="tit">{{ t('manage.userForm.DepartmentHead') }}</p>
                            <div style="display: flex;">
                                <div class="comtent">{{ dataDetail.manager }}</div>
                            </div>
                        </div>
                    </el-form-item> -->
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleCancel" class="cancel">{{
            t("manage.userForm.user24")
          }}</el-button>
          <el-button
            :disabled="$hasPermission('10008') == false"
            @click="handleConfirm"
            class="confirm"
            >{{ t("manage.userForm.user25") }}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.iconImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
}

.divider {
  margin-bottom: 18px;
}

.close-icon {
  font-size: 20px;
}

.avatar1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-family: "Semibold";
  margin-right: 10px;
}

.content {
  font-size: 18px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 500;
  }

  .avatar {
    height: 50px;
    width: 50px;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #b8e6cd;
    color: #14ae5c;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .tag_cuo {
    display: flex;
    justify-content: space-between;
    background-color: #fbd4df;
    color: #f02d63;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .reset {
    color: #0256ff;
    margin: auto 10px;
  }
}

.form {
  margin-top: 10px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>
